import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

class ModalView extends React.Component {
    constructor(props) {
        super(props);
    }

    handleSaveClick = (event) => {
        this.props.onclick();
    };
    hanleClickModal = (event) => {
        event.stopPropagation();
    };
    handleBack = () => {
        if (this.props.isMergeModal) {
            this.props.setSubmitButtonText("Next");
        }
    };
    componentWillUnmount() {}
    renderFooter() {
        if (this.props.footer !== "false") {
            return (
                <Modal.Footer>
                    <Button onClick={this.handleSaveClick}>
                        {this.props.submitButtonText}
                    </Button>
                </Modal.Footer>
            );
        } else {
            return null;
        }
    }
    renderHeader() {
        if (this.props.showHeader !== "false") {
            return (
                <Modal.Header closeButton style={{ borderBottom: "0px solid" }}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.headerTitle}
                    </Modal.Title>
                </Modal.Header>
            );
        } else {
            return null;
        }
    }
    renderCancelButton = () => {
        return (
            <React.Fragment>
                <div className="row doCenter" title=" " id="main">
                    {this.props.isMergeModal ? this.renderBackButton() : null}
                    {this.props.isMergeModal ? (
                        <Button
                            id="cancelButton"
                            title="Cancel"
                            value="Cancel"
                            onClick={this.props.onHide}
                            // className={classes.button}
                            variant={"outlined"}
                            style={{
                                width: "auto",
                                marginRight: "5px",
                                minWidth: "8rem",
                                borderRadius: "10px",
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                height: "2.5rem",
                            }}
                        >
                            Cancel
                        </Button>
                    ) : (
                        <Button
                            id="cancelButton"
                            title={
                                this.props.title === "Apply" &&
                                this.props.modalId !== "add_Document_Modal"
                                    ? "Reset"
                                    : "Cancel"
                            }
                            value="Cancel"
                            onClick={this.props.onHide}
                            // className={classes.button}
                            variant={"outlined"}
                            style={
                                this.props.heading
                                    ? {
                                          width: "auto",
                                          marginTop: "12px",
                                          marginLeft: "774px",
                                          minWidth: "8rem",

                                          borderRadius: "10px",
                                          border: "1px solid rgba(0, 0, 0, 0.23)",
                                          height: "2.5rem",
                                      }
                                    : this.props.isDashboardAlert === true
                                    ? {
                                          width: "auto",
                                          marginRight: "5px",
                                          minWidth: "8rem",
                                          marginLeft: "7px",
                                          borderRadius: "10px",
                                          border: "1px solid rgba(0, 0, 0, 0.23)",
                                          height: "2.5rem",
                                      }
                                    : {
                                          width: "auto",
                                          marginRight: "5px",
                                          minWidth:
                                              this.props.modal === "addInstance"
                                                  ? "6rem"
                                                  : "8rem",
                                          marginLeft:
                                              this.props.modal === "addInstance"
                                                  ? "250px"
                                                  : "142px",
                                          borderRadius: "10px",
                                          border: "1px solid rgba(0, 0, 0, 0.23)",
                                          height: "2.5rem",
                                      }
                            }
                        >
                            {this.props.title === "Yes"
                                ? "No"
                                : this.props.title === "Apply" &&
                                  this.props.modalId !== "add_Document_Modal"
                                ? "Reset"
                                : "Cancel"}
                        </Button>
                    )}

                    {!this.props.heading &&
                        (this.props.modal === "addInstance" ? (
                            <button
                                variant="contained"
                                color="primary"
                                onClick={
                                    this.props.disable === ""
                                        ? null
                                        : this.props.onSubmit
                                }
                                className="bw_btn"
                                style={{
                                    width: "92px",
                                    height: "38px",
                                    padding: "6px 16px",
                                    gap: "8px",
                                    borderRadius: "4px",
                                }}
                            >
                                {this.props.title}
                            </button>
                        ) : (
                            <button
                                className={
                                    this.props.disable === ""
                                        ? "cursorNotAllowed btn share modalButton"
                                        : "cursorPointer btn share modalButton"
                                }
                                type="button"
                                id={this.props.title}
                                style={{
                                    minWidth: "8rem",
                                    height: "38px",
                                    marginTop: "35px",
                                    marginLeft: "10px",
                                    background: "#0798EA",
                                    textTransform: "capitalize",
                                }}
                                title={this.props.title}
                                value={this.props.value}
                                onClick={
                                    this.props.disable === ""
                                        ? null
                                        : this.props.onSubmit
                                }
                            >
                                {this.props.title}
                            </button>
                        ))}
                </div>
            </React.Fragment>
        );
    };

    renderBackButton = () => {
        return (
            <Button
                id="backButton"
                title="Back"
                value="Back"
                onClick={this.handleBack}
                // className={classes.button}
                variant={"outlined"}
                style={{
                    width: "auto",
                    marginRight: "5px",
                    minWidth: "8rem",
                    borderRadius: "10px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    height: "2.5rem",
                }}
            >
                Back
            </Button>
        );
    };

    renderCloaseButton = () => {
        return (
            <div className="row w-100 m-0 modalCloseIconRow" title=" ">
                <button
                    type="submit-cancel"
                    onClick={this.props.onHide}
                    class="close modalCloseIcon"
                    data-dismiss="modal"
                    title="Close"
                    id={
                        this.props.closeBtnId
                            ? this.props.closeBtnId
                            : "closeModalButton"
                    }
                >
                    &times;
                </button>
            </div>
        );
    };
    render() {
        return (
            <div title=" ">
                <Modal
                    sx={{ left: "100px" }}
                    onClick={this.props.onclick ? this.hanleClickModal : null}
                    {...this.props}
                    size={
                        this.props.size !== undefined ? this.props.size : "lg"
                    }
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className={
                        this.props.modalBodyClass !== undefined
                            ? this.props.modalBodyClass
                            : ""
                    }
                    id={this.props.modalId}
                    onHide={this.props.onHide || null}
                >
                    <Modal.Body title=" ">
                        {this.props.showHeader === true
                            ? this.renderHeader()
                            : this.props.isDashboardAlert === true
                            ? null
                            : this.renderCloaseButton()}
                        {this.props.children}
                        {this.props.type === "submit-cancel"
                            ? this.renderCancelButton()
                            : null}
                    </Modal.Body>
                    {this.renderFooter()}
                    {this.props.subChild && this.props.subChild}
                </Modal>
            </div>
        );
    }
}
export default ModalView;
