import React, { useEffect, useState } from "react";
import { Modal, Box } from "@mui/material";
import { ButtonComponent } from "../../../../../button/button";
import ControlledTreeView from "../../../../../stylized/tree-view/TreeView";
import { useSelector } from "react-redux";
import http from "../../../../../../bae-client/HTTP";
import Loader from "../../../../../stylized/Loader";
import "./StorageModal.css";
import { Button } from "react-bootstrap";

const StorageModal = ({
    open,
    handleClose,
    selectedWorkspace,
    selectedSpace,
    renderDropdowns,
    handleAddFolderClick,
    handleNext,
    selectedFolder,
    setSelectedSpace,
    setSelectedWorkspace,
    setSelectedFolder,
}) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        bgcolor: "background.paper",
        border: "1px solid #D7DEEA",
        boxShadow: 24,
        padding: "24px",
    };

    const [selectedAll, setSelectedAll] = React.useState([]);
    const [selected, setSelected] = React.useState(null);
    const [treeData, setTreeData] = React.useState([]);
    const [expanded, setExpanded] = React.useState([]);
    const [error, setError] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const workspaceList = useSelector((state) => state.workspaceList);
    useEffect(() => {
        if (selected) {
            if (!selectedAll.includes(selected)) {
                setSelectedAll([...selectedAll, selected]);
            }
        }
    }, [selected]);
    useEffect(() => {
        if (workspaceList && workspaceList.length) buildTree();
    }, [workspaceList]);

    const NewFolder = () => {
        return (
            <Button
                className=""
                disabled={
                    (selectedWorkspace.workspaceId && selectedSpace.spaceId) ||
                    selectedFolder.folderId
                        ? false
                        : true
                }
                onClick={() => handleAddFolderClick()}
                style={{
                    textTransform: "none",
                    width: "119px",
                    height: "36px",
                    padding: "6px 16px",
                    gap: "8px",
                    borderRadius: "4px",
                    border: "1px solid #D7DEEA",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                    textAlign: "left",
                    color: "black",
                    background: "transparent",
                }}
            >
                New Folder
            </Button>
        );
    };

    const buildTree = async () => {
        const data = [];
        for (let i = 0; i < workspaceList.length; i++) {
            if (workspaceList[i].workspaceType !== "SYSTEM") {
                let children =
                    workspaceList[i].workspaceId ===
                    selectedWorkspace.workspaceId
                        ? await http.sendRestRequest(
                              `workspace/spaceList?workspaceId=${workspaceList[i].workspaceId}`
                          )
                        : [];
                data.push({
                    id: workspaceList[i].workspaceId,
                    name: workspaceList[i].workspaceName,
                    type: "workspace",
                    children: children.map((s) => ({
                        id: s.spaceId,
                        name: s.spaceName,
                        type: "space",
                    })),
                });
            }
        }

        setTreeData(data);
    };
    const updateTreeDataWithResponse = (
        data,
        workspaceId,
        spaceId,
        folderId,
        response
    ) => {
        const findFolderAndUpdate = (nodes, targetId) => {
            for (let i = 0; i < nodes.length; i++) {
                if (nodes[i].id === targetId) {
                    // If the folder is found, update its children with the response
                    nodes[i].children = response;
                    return true; // Return true to indicate the folder was found and updated
                } else if (nodes[i].children) {
                    // Recursively search in children nodes if they exist
                    if (findFolderAndUpdate(nodes[i].children, targetId)) {
                        return true;
                    }
                }
            }
            return false; // Return false if the target folder was not found
        };

        // Make a copy of the data to avoid direct mutation
        const newData = [...data];

        // Find the workspace by workspaceId
        const workspaceIndex = newData.findIndex(
            (item) => item.id === workspaceId
        );
        if (workspaceIndex !== -1) {
            // Find the space within the workspace by spaceId
            const space = newData[workspaceIndex].children.find(
                (item) => item.id === spaceId
            );
            if (space) {
                // Find the folder by folderId and update it recursively
                findFolderAndUpdate(space.children, folderId);
            }
        }

        return newData;
    };

    const selectHandler = async (
        workspaceId,
        spaceId,
        folderId,
        folderName
    ) => {
        if (!folderId && spaceId) {
            if (!selectedAll.includes(spaceId)) {
                setShowLoader(true);
                getSpaceData(workspaceId, spaceId, 0)
                    .then((response) => {
                        const oldData = [...treeData];
                        const workspaceIndex = oldData.findIndex(
                            (item) => item.id === workspaceId
                        );
                        const spaceIndex = oldData[
                            workspaceIndex
                        ].children.findIndex((item) => item.id === spaceId);
                        oldData[workspaceIndex].children[spaceIndex].children =
                            response;

                        setTreeData(oldData);
                        setSelectedSpace({
                            spaceId: spaceId,
                            spaceName:
                                oldData[workspaceIndex].children[spaceIndex]
                                    .name,
                        });
                        setError("");
                        setShowLoader(false);
                        if (!expanded.includes(spaceId)) {
                            setExpanded([...expanded, spaceId]);
                        }
                    })
                    .catch((err) => setShowLoader(false));
            }
        } else if (!spaceId) {
            if (!selectedAll.includes(workspaceId)) {
                const spaceList = await http.sendRestRequest(
                    `workspace/spaceList?workspaceId=${workspaceId}`
                );
                const oldData = [...treeData];
                const workspaceIndex = oldData.findIndex(
                    (item) => item.id === workspaceId
                );
                oldData[workspaceIndex].children = spaceList.map((s) => ({
                    id: s.spaceId,
                    name: s.spaceName,
                    type: "space",
                }));

                setTreeData(oldData);
                setSelectedWorkspace({
                    workspaceId: workspaceId,
                    workspaceName: oldData[workspaceIndex].name,
                });
                if (!expanded.includes(workspaceId)) {
                    setExpanded([...expanded, workspaceId]);
                }
            }
        } else {
            setSelectedFolder({
                folderId: folderId,
                folderName: folderName,
                documentId: "Document ID",
                childrenFolderNames: [],
            });

            if (!selectedAll.includes(folderId)) {
                setShowLoader(true);
                getSpaceData(workspaceId, spaceId, folderId)
                    .then((response) => {
                        const findFolderAndUpdate = (
                            nodes,
                            targetId,
                            response
                        ) => {
                            for (let i = 0; i < nodes.length; i++) {
                                if (nodes[i].id === targetId) {
                                    nodes[i].children = response;
                                    return true;
                                } else if (nodes[i].children) {
                                    if (
                                        findFolderAndUpdate(
                                            nodes[i].children,
                                            targetId,
                                            response
                                        )
                                    ) {
                                        return true;
                                    }
                                }
                            }

                            return false;
                        };
                        const oldData = [...treeData];
                        const workspaceIndex = oldData.findIndex(
                            (item) => item.id === workspaceId
                        );
                        const spaceIndex = oldData[
                            workspaceIndex
                        ].children.findIndex((item) => item.id === spaceId);

                        const folderIndex = oldData[workspaceIndex].children[
                            spaceIndex
                        ].children.findIndex((item) => item.id === folderId);
                        const folder = oldData[workspaceIndex].children[
                            spaceIndex
                        ].children.find((item) => item.id === folderId);
                        if (folderIndex !== -1 && folder.id === folderId) {
                            oldData[workspaceIndex].children[
                                spaceIndex
                            ].children[folderIndex].children = response;
                        } else {
                            findFolderAndUpdate(oldData, folderId, response);
                        }

                        setTreeData(oldData);
                        setError("");
                        if (!expanded.includes(folderId)) {
                            setExpanded([...expanded, folderId]);
                        }
                    })
                    .catch((err) => setShowLoader(false));
            }
        }
    };

    const getSpaceData = async (workspaceId, spaceId, id) => {
        const spaceData = await http.sendRestRequest(
            `folders/folderData?spaceId=${spaceId}&folderId=${id}`
        );
        const response = convertData(spaceData);
        return response;
    };

    const convertData = (data) => {
        const response = [];
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                const element = data[key];
                if (element && element.documentId === -1) {
                    response.push({
                        id: element.objectId,
                        name: element.objectName,
                        children: convertData(element.children),
                        type: "folder",
                    });
                }
            }
        }
        return response;
    };

    const cancelBtnStyle = {
        width: "69px",
        height: "36px",
        padding: "6px 16px",
        gap: "8px",
        borderRadius: "4px",
        border: "1px solid #D7DEEA",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0.4px",
        textAlign: "left",
    };

    const NextBtnStyle = {
        padding: "6px 26px",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0.4px",
        textAlign: "left",
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                disableEnforceFocus={true} // Disable focus enforcement
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <span
                            className="MuiTypography-root MuiStepLabel-label MuiStepLabel-completed MuiTypography-body2 MuiTypography-displayBlock"
                            style={{
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "24px",
                                textAlign: "left",
                            }}
                        >
                            Customize Storage
                        </span>
                    </div>
                    <div
                        className="tree-view invisibleScroller"
                        style={{ border: "none" }}
                    >
                        <ControlledTreeView
                            data={treeData}
                            setSelected={setSelected}
                            setExpanded={setExpanded}
                            expanded={expanded}
                            selected={selected}
                            selectHandler={selectHandler}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 0px 0px 0px",
                        }}
                    >
                        {NewFolder()}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gridGap: "30px",
                            }}
                        >
                            <ButtonComponent
                                className="bw_btn"
                                type={"outlined"}
                                text={"Back"}
                                BtnHandler={handleClose}
                                btnStyle={cancelBtnStyle}
                            />
                            <ButtonComponent
                                className="bw_btn"
                                type={"primary"}
                                text={"Next"}
                                BtnHandler={handleNext}
                                btnStyle={NextBtnStyle}
                            />
                        </div>
                    </div>
                </Box>
            </Modal>
            <Loader show={showLoader} />
        </>
    );
};

export default StorageModal;
