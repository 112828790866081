import React from "react";
import ContactList from "../../communicator/contactList";
import { setActiveChat } from "../../../../../../actions/CommunicatorActions";
import { useDispatch, useSelector } from "react-redux";
import { EmptyHomeScreen } from "../../../../../stylized/EmptyHomeScreen";
import { setActivePage } from "../../../../../../actions";
import Loading from "../../../../../stylized/Loading";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
const NewChat = ({ isLoadingInformationFeed }) => {
    const navigate = useNavigate();
    let basePathName = window.runtimeConfig.instanceName
        ? "/" + window.runtimeConfig.instanceName
        : "";
    const dispatch = useDispatch();
    const chatList = useSelector((state) => state.chatList);
    const handleChatSelection = (chatDetails, chat, index) => {
        if (!chatDetails) {
            dispatch(setActivePage("Communicator"));
            navigate(`${basePathName}/chat`);
        } else {
            let chatObj = { chatDetails, chat, selectedContactIndex: index };
            dispatch(setActiveChat(chatObj));
            dispatch(setActivePage("Communicator"));
            navigate(`${basePathName}/chat`);
        }
    };

    return (
        <>
            {isLoadingInformationFeed ? (
                <Loading />
            ) : chatList.length === 0 ? (
                <>
                    <EmptyHomeScreen
                        heading="Information Feed"
                        image="./images/home.file.svg"
                        subTitle="No information yet!"
                    />
                </>
            ) : (
                <div>
                    <span style={{ width: "auto" }} className="heading">
                        Information Feed
                    </span>

                    <div className="invisibleScroller newChat">
                        <ContactList
                            contactList={
                                chatList !== undefined && chatList !== null
                                    ? chatList
                                    : []
                            }
                            handleChatSelection={handleChatSelection}
                        ></ContactList>
                    </div>
                </div>
            )}
        </>
    );
};

export default NewChat;
