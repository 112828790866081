import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import "../style.css";
import OptionMenu from "./option-cell";
import { Chip } from "@mui/material";
import { getChatTimestamp } from "../../../../../../../../bae-client/Utils";
import { useSelector } from "react-redux";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getChip = (instance) => {
    if (
        instance.status.toUpperCase() === "DONE" ||
        instance.status.toUpperCase() === "INACTIVE" ||
        instance.status.toUpperCase() === "COMPLETED"
    )
        return <Chip label={"DONE"} color={"primary"}></Chip>;
    else if (
        instance.status.toUpperCase() === "PENDING" ||
        instance.status.toUpperCase() === "ACTIVE"
    ) {
        return <Chip label={"PENDING"}></Chip>;
    } else return <Chip label={"DELAYED"} color={"error"}></Chip>;
};

function createData(
    name,
    generic,
    status,
    lastDocumentOn,
    lastDocument,
    nextDocument,
    nextDocumentTime,
    expectedFinishTime,
    actions,
    instanceId,
    genericId,
    genericName
) {
    const lastDocOn = getChatTimestamp(lastDocumentOn, "mm/dd/yyyy hh:mm aa");
    const nextDocTime = getChatTimestamp(
        nextDocumentTime,
        "mm/dd/yyyy hh:mm aa"
    );
    const expFinishTime = getChatTimestamp(
        expectedFinishTime,
        "mm/dd/yyyy hh:mm aa"
    );
    return {
        name,
        generic,
        status,
        lastDocumentOn: lastDocOn,
        lastDocument,
        nextDocument,
        nextDocumentTime: nextDocTime,
        expectedFinishTime: expFinishTime,
        actions,
        id: instanceId,
        instanceId,
        genericId,
        genericName,
    };
}

const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Instance Name",
    },
    {
        id: "generic",
        numeric: false,
        disablePadding: false,
        label: "Generic Name",
    },
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
    },
    {
        id: "lastDocument",
        numeric: false,
        disablePadding: false,
        label: "Last Document",
    },
    {
        id: "lastDocumentOn",
        numeric: false,
        disablePadding: false,
        label: "Last Document On",
    },

    {
        id: "nextDocument",
        numeric: false,
        disablePadding: false,
        label: "Next Document",
    },
    {
        id: "nextDocumentTime",
        numeric: false,
        disablePadding: false,
        label: "Next Document Expected Date",
    },
    {
        id: "expectedFinishTime",
        numeric: false,
        disablePadding: false,
        label: "Expected Finish Date",
    },
    {
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: "Actions",
    },
];
function EnhancedTableHead(props) {
    const { onRequestSort, orderBy, order } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead sx={{ position: "sticky", top: "0px", background: "white" }}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function CustomTable({
    type,
    openSpaceOrFolder,
    setSelectedItems,
    instanceOperations,
    allInstances,
    openInstance,
    id,
    modal,
}) {
    const genericIdToDisplayNameMap = useSelector(
        (state) => state.genericIdToDisplayNameMap
    );
    const [order, setOrder] = React.useState("asc");
    const [rows, setRows] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState("lastDocumentOn");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [sortingOn, setSortingOn] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    // React.useEffect(() => {
    //     setSelectedItems(selected);
    // }, [selected]);
    React.useEffect(() => {
        const newRows = allInstances.map((row) =>
            createData(
                row.instanceName,
                genericIdToDisplayNameMap.get(row.genericId),
                row.status,
                row.lastStepExecutedOn,
                row.lastStep,
                row.nextStep,
                row.nextStepExpectedOn,
                row.endDateExpected,
                "",
                row.instanceId,
                row.genericId,
                row.genericName
            )
        );
        setRows([]);
        setTimeout(() => {
            setRows(newRows);
        });
    }, [allInstances]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setSortingOn(true);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // const newSelecteds = rows.map((n) => n.name);
            setSelected(rows);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id, item) => {
        const selectedIndex = selected.map((item) => item.id).indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, item);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => {
        return selected.map((item) => item.id).indexOf(id) !== -1;
    };
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const getSortedRows = () => {
        const existingRows = [...rows];
        if (!existingRows.length) return [];
        const collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: "base",
        });
        const sorted =
            order === "asc"
                ? existingRows.sort((a, b) => {
                      return collator.compare(a[orderBy], b[orderBy]);
                  })
                : existingRows.sort((a, b) => {
                      return collator.compare(b[orderBy], a[orderBy]);
                  });
        return sorted;
    };

    React.useEffect(() => {
        if (rows.length) {
            setRows([]);
            setTimeout(() => {
                const sorted = getSortedRows();
                setRows(sorted);
            });
        }
        // setRows(stableSort(rows, getComparator(order, orderBy)));
    }, [order, orderBy, page, rowsPerPage]);

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <Paper
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
                id={modal ? modal + "_tab" : id && rows.length ? id : null}
            >
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer
                    sx={{ paddingTop: "0px", height: "90%", flexBasis: "90%" }}
                    className="invisibleScroller"
                >
                    {!rows.length ? (
                        <div
                            className="instance-list-view"
                            style={{
                                visibility: sortingOn ? "hidden" : "visible",
                            }}
                        >
                            With Selected filters there is no data to show!
                        </div>
                    ) : (
                        <Table
                            sx={{ height: "inherit" }}
                            aria-labelledby="tableTitle"
                            style={{ height: "fit-content" }}
                            // size={dense ? "small" : "medium"}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                type={type}
                            />
                            <TableBody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                                {rows
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(
                                            row.id
                                        );
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.id,
                                                        row
                                                    )
                                                }
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.name}
                                                selected={isItemSelected}
                                                sx={{ height: "40px" }}
                                            >
                                                <TableCell
                                                    onClick={() => {
                                                        openInstance(row);
                                                    }}
                                                    style={{
                                                        color: "blue",
                                                        cursor: "pointer",
                                                        width: "20%",
                                                    }}
                                                    title={row.name}
                                                    id={row.name}
                                                >
                                                    {row.name.slice(0, 20) +
                                                        (row.name.length > 20
                                                            ? "..."
                                                            : "")}
                                                </TableCell>
                                                <TableCell title={row.generic}>
                                                    {row.generic.slice(0, 20) +
                                                        (row.generic > 20
                                                            ? "..."
                                                            : "")}
                                                </TableCell>
                                                <TableCell>
                                                    {getChip(row)}
                                                </TableCell>
                                                <TableCell
                                                    title={row.lastDocument}
                                                >
                                                    {row.lastDocument.slice(
                                                        0,
                                                        20
                                                    ) +
                                                        (row.lastDocument
                                                            .length > 20
                                                            ? "..."
                                                            : "")}
                                                </TableCell>
                                                <TableCell>
                                                    {row.lastDocumentOn}
                                                </TableCell>
                                                <TableCell
                                                    title={row.nextDocument}
                                                >
                                                    {row.nextDocument.slice(
                                                        0,
                                                        20
                                                    ) +
                                                        (row.nextDocument
                                                            .length > 20
                                                            ? "..."
                                                            : "")}
                                                </TableCell>
                                                <TableCell>
                                                    {row.nextDocumentTime}
                                                </TableCell>
                                                <TableCell>
                                                    {row.expectedFinishTime}
                                                </TableCell>
                                                <TableCell>
                                                    <OptionMenu
                                                        selectedRow={row}
                                                        instanceOperations={
                                                            instanceOperations
                                                        }
                                                        openInstance={
                                                            openInstance
                                                        }
                                                    ></OptionMenu>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {/* {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ height: "10%", flexBasis: "10%", overflow: "hidden" }}
                />
            </Paper>
            {/* <FormControlLabel
                control={
                    <Switch checked={dense} onChange={handleChangeDense} />
                }
                label="Dense padding"
            /> */}
        </Box>
    );
}
