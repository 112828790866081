export const _URL = "http://localhost:3001/";
export const _REST_APP = "BW_UNITY_CENTRAL_QA/rest";
export const _AUTH =
    "basic:YXNoaXNoLmJhbHVqYUBib2FyZHdhbGt0ZWNoLmNvbTowOkJvYXJkd2Fsa3RlY2h8SW50ZXJuYWx8RW5naW5lZXJpbmd8MTAyNA==";
export const _BASE_QUERY = {};

export const _USER_AGREEMENT_ENDPOINT = "/userLicenseAgreement/listByStatus";

export const _ALL_WORKSPACE_ENDPOINT = "/workspace/workspaceList";

export const _WORKSPACE_ENDPOINT = "/workspaces";

export const _ALL_USERS_ENDPOINT = "rest/user";

export const _SPACE_ENDPOINT = "/spaces";

export const _SPACE_DATA_ENDPOINT = "/files";

export const _HOME_RESOURCES_ENDPOINT = "/home/resources";

export const _HOME_ELEMENTS_COUNT_ENDPOINT = "/home/elementCounts";

export const _HOME_ALERT_ENDPOINT = "/alerts";

export const _HOME_RECENT_ACTIVITY_ENDPOINT =
    "/userInteractions/recentActivity";

//http methods
export const _DELETE = "DELETE";
export const _GET = "GET";
export const _POST = "POST";
export const _PUT = "PUT";
