import React, { useState, useEffect, useRef } from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Paper } from "@material-ui/core";
import "../UnityCentral/index.css";
import "./ControlTower.css";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getIconPathByType } from "../common/IconList";

const getRightMargin = () => {
    let marginRight;
    if (window.innerWidth > 1500) {
        marginRight = window.innerWidth % 1500;
        return marginRight + "px";
    }
};

const getLeftMargin = () => {
    if (window.innerWidth < 1300) return "20%";
    if (window.innerWidth < 1650) return "15%";
    else if (window.innerWidth < 1920) return "13%";
    else if (window.innerWidth < 2250) return "11%";
    else if (window.innerWidth < 2500) return "9%";
    else return "8%";
    // let marginLeft;
    // if (window.innerWidth < 1400) {
    //     marginLeft = window.innerWidth / divider;
    //     return "-" + marginLeft + "px";
    // } else if (window.innerWidth > 2100) {
    //     marginLeft = "-900px";
    //     return marginLeft;
    // } else if (window.innerWidth > 2350) {
    //     marginLeft = "-800px";
    //     return marginLeft;
    // } else {
    //     marginLeft = "-750px";
    //     return marginLeft;
    // }
};
var openInstanceValue = null;
var openDocumentId = null;
function TimelineView(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedRow, setselectedData] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event, document, documentId, item) => {
        // openInstanceValue = documentId;
        openDocumentId = documentId;
        setAnchorEl(event.currentTarget);
        setselectedData(item);
    };

    const openDocument = () => {
        props.onNodeClick(openDocumentId);
        setAnchorEl(null);
    };

    const handleClose = (e) => {
        if (e && e.target && e.target.innerText) {
            if (e.target.innerText === "Open Document") {
                handleOpenDocument();
            } else if (e.target.innerText === "Open Instance") {
                handleOpenInstance();
            }
        }
        setAnchorEl(null);
    };
    const handleOpenDocument = () => {
        openDocument();
        // props.openDocument(selectedRow.document, selectedRow.documentId);
        // setAnchorEl(null);
    };
    const handleOpenInstance = () => {
        const libData = {
            genericId: selectedRow.genericId,
            instanceIds: [selectedRow.instanceId],
        };
        props.openInstance(libData);
    };
    const useStyles = makeStyles({
        timeline: {
            transform: "rotate(270deg)",
            // marginLeft: "9%",
            // marginLeft: `${props.marginMultiplier > 6
            //     ? (props.rippleTimeline)
            //         ? (props.marginMultiplier * 80 + 100) > 670 ? 670 : props.marginMultiplier * 80 + 100
            //         : props.marginMultiplier * 65
            //     : (!props.rippleTimeline && props.marginMultiplier > 6)
            //         ? props.marginMultiplier * 75 : props.marginMultiplier * 90
            //     }px`,
            margin: "10px",
            // paddingTop: `${(props.rippleTimeline && props.isDrawerOpen)
            //     ? (props.documentList && props.documentList.length > 30) ? props.documentList.length * 40 : props.marginMultiplier * 60
            //     : props.marginMultiplier * 30}px`,
            paddingTop: `${
                props.rippleTimeline && props.isDrawerOpen
                    ? props.marginMultiplier < 5
                        ? props.marginMultiplier !== 0
                            ? props.marginMultiplier * 100
                            : 100
                        : 100
                    : 0
            }px`,
            // marginRight: getRightMargin(),
            height: "25rem",
            width: "auto",
            // marginLeft: getLeftMargin(),
            flexGrow: "inherit",
        },
        timelineContentContainerFirst: {
            textAlign: "left",
            margin: "0px",
        },
        timelineContentContainer: {
            textAlign: "left",
            margin: "-53px -30px 0px", //'0px -25px 0px',
        },
        timelineRoot: {
            margin: "0px -10px 0px",
        },
        timelineContent: {
            display: "inline-block",
            justifyContent: "center",
            alignItems: "center",
            transform: "rotate(90deg)",
            textAlign: "center",
            minWidth: 50,
            maxWidth: "15rem",
            maxHeight: "100px",
            cursor: "pointer",
            height: "5rem",
            width: "13rem",
            wordBreak: "break-word",
            margin: "15px -55px 33px", //'-15px -10px 35px',
            borderRadius: "4px",
            boxShadow:
                "0px 0px 1px rgba(0, 0, 0, 0.48), 0px 0px 4px rgba(0, 0, 0, 0.12);",
        },
        timelineExceptionContent: {
            width: "13rem",
            cursor: "pointer",
            height: "5rem",
            margin: "15px -55px 33px",
            display: "inline-block",
            maxWidth: "15rem",
            minWidth: "50px",
            transform: "rotate(90deg)",
            border: "1px solid red",
            boxShadow:
                "0px 0px 1px rgb(0 0 0 / 48%), 0px 0px 4px rgb(0 0 0 / 12%)",
            maxHeight: "100px",
            textAlign: "center",
            wordBreak: "break-word",
            alignItems: "center",
            borderRadius: "10px",
            justifyContent: "center",
        },
        timelineDate: {
            margin: "-2px 16px", //'0px -5px',
            fontSize: "11px",
            fontWeight: "600",
            width: "max-content",
        },
        timelineDateWrapper: {
            padding: "7px 1px", //"3px 16px",
            width: "100px",
        },
        timelineIcon: {
            transform: "rotate(-90deg)",
        },
        titleText: {
            fontSize: "10px",
            fontFamily: "sans-serif",
            fontWeight: "bold",
            lineHeight: "1.0",
            letterSpacing: "0.00938em",
            padding: "3px 2px",
            textAlign: "left",
        },
        subtitleText: {
            fontSize: "10px",
            fontFamily: "sans-serif",
            fontWeight: "400",
            lineHeight: "1.0",
            letterSpacing: "0.00938em",
            padding: "6px 6px",
            textAlign: "left",
        },
        timlineWrapper: {
            height: "40vh",
            overflowX: "auto",
            overflowY: "hidden",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
            borderTop: "1px solid black",
            padding: "0px 10px 0px",
        },
        timelineDisplayNone: {
            display: "none",
        },
        timelineHorizConnector: {
            //transform: "rotate(90deg)",
            maxHeight: "2px",
            maxWidth: "10px",
            margin: "13px 6px 4px", //'13px 10px 0px'
        },
    });
    const handleNodeClick = (props, nodeName, documentId) => {
        props.onNodeClick(nodeName, documentId);
    };
    const handleTimelineViewClick = (props) => {
        if (props.setTimelineView) {
            props.setTimelineView();
        }
    };
    const getExpandedIcon = (props) => {
        let iconclass = null;
        props.timelineExpanded
            ? (iconclass = "fas fa-angle-double-down")
            : (iconclass = "fas fa-angle-double-up");
        return iconclass;
    };
    const classes = useStyles();

    const timelineDate = props.documentList.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
    );
    const itemLength = timelineDate.length;
    const renderDocumentCardView = (item, index) => {
        const fileType = item.document.split(".").pop();

        return (
            <div className="documentCardContainer">
                <div
                    className="row m-0 w-100 titleRow"
                    style={{ height: "2rem" }}
                >
                    <div className="col-2 p-0 doCenter">
                        <img
                            className="leftIcon"
                            src={getIconPathByType(fileType.toLowerCase())}
                            alt=""
                        />
                    </div>
                    <div className="col-8 p-0 documentTitleCol">
                        <span
                            className="documentCardTitle textEllipsis"
                            title={item.genericStepName}
                        >
                            {item.genericStepName}
                        </span>
                    </div>
                    <div
                        className="col-2 p-0 doCenter rightIcon"
                        id="vertical_options"
                        onClick={(event) =>
                            handleClick(
                                event,
                                item.document,
                                item.documentId,
                                item
                            )
                        }
                    >
                        <img
                            alt={"options"}
                            title="option"
                            id={`btn_three_dot_${index + 1}`}
                            src="./images/vertical_menu.svg"
                        />
                    </div>
                </div>
                <div className="row m-0 w-100 pl-2 documentSubtitleRow">
                    <p
                        style={{ marginBottom: "0px" }}
                        className="documentCardSubtitle"
                    >
                        {item.document}
                    </p>
                </div>
            </div>
        );
    };
    return (
        <div
            id={"timeline-view"}
            className="row ml-2 mr-2 doCenter"
            style={{
                border: props.hideBorder
                    ? "none"
                    : "1px solid rgb(165, 165, 165)",
                borderRadius: "10px",
            }}
        >
            {!props.isFlowTimeline ? (
                <div
                    className="col-12 ml-4 mr-4 doCenter"
                    onClick={() => handleTimelineViewClick(props)}
                    style={{ cursor: "pointer", justifyContent: "flex-start" }}
                >
                    <span className="timelineExpandButton">
                        <i className={getExpandedIcon(props)}></i>
                    </span>
                    <span className="alertlineTitle">Timeline</span>
                </div>
            ) : null}
            {!props.timelineExpanded ? null : (
                <div
                    className="col-12 ml-4 mr-4 invisibleScroller"
                    style={{
                        height: "18rem",
                        overflowX: "auto",
                        overflowY: "hidden",
                        //border: '1px solid black',
                        padding: "0px 10px 0px",
                        // paddingLeft:"1.5rem ",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Timeline
                        id="DC_TimeLine"
                        className={classes.timeline}
                        align="alternate"
                    >
                        {/* {timelineDate.length > 0 ? (
                            <TimelineItem key={"idx"}>
                                <TimelineSeparator>
                                    <TimelineDot color="primary" />
                                    <TimelineConnector className="timelineConnector" />
                                </TimelineSeparator>
                                <TimelineContent
                                    className={
                                        classes.timelineContentContainerFirst
                                    }
                                ></TimelineContent>
                            </TimelineItem>
                        ) : null} */}
                        <Menu
                            classes={{ list: "menu-custom" }}
                            id="demo-customized-menu"
                            MenuListProps={{
                                "aria-labelledby": "demo-customized-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem
                                className="menu-item-custom"
                                onClick={handleOpenDocument}
                            >
                                <div id="div_open_document_btn">
                                    <img
                                        style={{
                                            width: "15px",
                                            height: "15px",
                                        }}
                                        className="renameIcon"
                                        src="./images/open.png"
                                        alt="Edit"
                                        title="Open Document"
                                    />
                                    <span
                                        className="btn_title"
                                        title="Open Document"
                                        id="open_document_btn"
                                    >
                                        Open Document
                                    </span>
                                </div>
                            </MenuItem>
                            <MenuItem onClick={handleOpenInstance}>
                                <div>
                                    <img
                                        className="deleteIcon"
                                        title="Open Instance"
                                        src="./images/Rename.svg"
                                        alt="Open Instance"
                                    />
                                    <span
                                        className="btn_title"
                                        title="Open Instance"
                                    >
                                        Open Instance
                                    </span>
                                </div>
                            </MenuItem>
                        </Menu>
                        {timelineDate.map((item, idx) => {
                            let tooltipText = !props.rippleTimeline
                                ? (item.genericStepName || "") +
                                  "\n" +
                                  (item.document || "Undefined") +
                                  "\n" +
                                  ("From : " + item.from || "") +
                                  "\n" +
                                  ("To : " + item.to || "")
                                : item.document || "Undefined";
                            return (
                                <TimelineItem key={idx}>
                                    <TimelineOppositeContent
                                        className={classes.timelineDateWrapper}
                                    >
                                        <Typography
                                            color="primary"
                                            className={classes.timelineDate}
                                            style={{
                                                transform: "rotate(90deg)",
                                            }}
                                        >
                                            {item.date
                                                ? item.date.split(" ")[0]
                                                : "Day"}
                                        </Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        {idx ===
                                        timelineDate.length - 1 ? null : (
                                            <TimelineDot color="primary" />
                                        )}
                                        {idx ===
                                        timelineDate.length - 1 ? null : (
                                            <TimelineConnector className="timelineConnector" />
                                        )}
                                        {idx !== itemLength - 1 ? null : (
                                            <TimelineDot color="primary" />
                                        )}
                                    </TimelineSeparator>
                                    <TimelineConnector
                                        className={
                                            classes.timelineHorizConnector
                                        }
                                        style={{ visibility: "hidden" }}
                                    />
                                    <TimelineContent
                                        className={
                                            classes.timelineContentContainer
                                        }
                                    >
                                        <Paper
                                            style={{
                                                margin: "25px -50px 0px",
                                            }}
                                            className={
                                                item.exception
                                                    ? classes.timelineExceptionContent
                                                    : classes.timelineContent
                                            }
                                            title={tooltipText}
                                            variant={"elevation"}
                                            elevation={5}
                                            onDoubleClick={() =>
                                                handleNodeClick(
                                                    props,
                                                    item.document,
                                                    item.documentId
                                                )
                                            }
                                        >
                                            {renderDocumentCardView(item, idx)}
                                            {/* <Typography
                                                className={classes.titleText}
                                                noWrap={true}
                                            >
                                                {item.genericStepName !== ""
                                                    ? item.genericStepName
                                                    : ""}
                                            </Typography>
                                            <div
                                                // style={{ marginLeft: "-12px" }}
                                                className="row m-0 w-100"
                                            >
                                                <div className="col-10 p-0">
                                                    <Typography
                                                        className={
                                                            classes.subtitleText
                                                        }
                                                        noWrap={true}
                                                    >
                                                        {!props.rippleTimeline
                                                            ? item.document !==
                                                              ""
                                                                ? `${
                                                                      idx + 1
                                                                  }. ${
                                                                      item.document
                                                                  }`
                                                                : "Undefined Name"
                                                            : item.document !==
                                                              ""
                                                            ? `${item.document}`
                                                            : "Undefined Name"}
                                                    </Typography>
                                                </div>
                                                <div
                                                    className="col-2 p-0"
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            item.document,
                                                            item.documentId,
                                                            item
                                                        )
                                                    }
                                                >
                                                    <img
                                                        alt={"options"}
                                                        title="option"
                                                        src="./images/vertical_menu.svg"
                                                    />
                                                </div>
                                            </div> */}
                                        </Paper>
                                    </TimelineContent>
                                </TimelineItem>
                            );
                        })}
                    </Timeline>
                </div>
            )}
        </div>
    );
}
export default TimelineView;
