import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DialogView from "../../../../../../../stylized/DialogView";
import FormWrapper from "../../../../Integration/FormWrapper/FormWrapper";
import { convertObjectMapToArrayMap } from "../../../../../../../stylized/multilevel-select-option";
import ShareRipple from "./ShareRipple";
import ShareInstanceStep from "./ShareStep";
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

export default function ShareOptions({ shareAttachment, disable }) {
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [displayShow, setDisplayShow] = React.useState(false);
    const [shareItem, setShareItem] = React.useState(null);
    const [rippleShare, setRippleShare] = React.useState("");
    const [showUploadOption, setShowUploadOption] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        setAnchorEl(null);
        if (event.target.id === "ripple") setRippleShare("yes");
        else setRippleShare("no");
        if (
            event &&
            event.target &&
            (event.target.id === "ripple" ||
                event.target.id === "space" ||
                event.target.id === "workspace" ||
                event.target.id === "folder" ||
                event.target.id === "file" ||
                event.target.id === "MSG_INSTANCE_STEP" ||
                event.target.id === "MSG_FLOW")
        ) {
            setShareItem(event.target.id);
            setShowUploadOption(true);
        }
    };
    const handleCloseUploadDialog = () => {
        setShowUploadOption(false);
        setRippleShare(false);
    };
    const handleShareAttachment = (shareObject, folderStructure) => {
        let spaceFolderStructure = convertObjectMapToArrayMap(folderStructure);
        const updatedShareObject = {
            ...shareObject,
            shareUpdates: true,
            receiveUpdates: true,
        };
        if (shareItem === "workspace") {
            shareAttachment(
                updatedShareObject,
                spaceFolderStructure,
                "MSG_WORKSPACE_OBJECT"
            );
        } else
            shareAttachment(
                updatedShareObject,
                shareItem === "space" ? spaceFolderStructure : folderStructure
            );
    };
    const handleInstanceStepSubmit = (stepObj) => {
        handleCloseUploadDialog();
        shareAttachment(stepObj, "", shareItem);
    };

    return (
        <>
            <button
                id={"shareButton"}
                style={{
                    float: "left",
                    paddingTop: "8px",
                    border: "none",
                    background: "transparent",
                    width: "auto",
                }}
                onClick={handleClick}
                className={
                    isReadOnlyUser || disable
                        ? "cursorNotAllowed"
                        : "cursorPointer"
                }
                disabled={isReadOnlyUser || disable}
            >
                <img
                    className={
                        isReadOnlyUser || disable
                            ? "cursorNotAllowed"
                            : "cursorPointer"
                    }
                    alt="Share File"
                    title="Share"
                    src="./images/shareOption.svg"
                ></img>
            </button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    id={"ripple"}
                    onClick={handleClose}
                    disableRipple
                    style={{ fontFamily: "Montserrat" }}
                >
                    <span
                        className={"MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"}
                    >
                        <img
                            id={"ripple"}
                            className={"cursorPointer"}
                            alt="new chat"
                            src="./images/Ripple.svg"
                        ></img>
                    </span>
                    Ripple Query
                </MenuItem>
                {/* <MenuItem
                    id={"workspace"}
                    onClick={handleClose}
                    disableRipple
                    style={{ fontFamily: "Montserrat" }}
                >
                    <span
                        className={"MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"}
                    >
                        <img
                            className={"cursorPointer"}
                            alt="new chat"
                            src="./images/workspace-logo.png"
                        ></img>
                    </span>
                    Workspace
                </MenuItem> */}
                <MenuItem
                    id={"space"}
                    onClick={handleClose}
                    disableRipple
                    style={{ fontFamily: "Montserrat" }}
                >
                    <span
                        className={"MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"}
                    >
                        <img
                            id={"space"}
                            className={"cursorPointer"}
                            alt="new chat"
                            src="./images/Space 3.png"
                        ></img>
                    </span>
                    Space
                </MenuItem>
                <MenuItem
                    id={"folder"}
                    onClick={handleClose}
                    disableRipple
                    style={{ fontFamily: "Montserrat" }}
                >
                    <span
                        className={"MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"}
                    >
                        <img
                            id={"folder"}
                            className={"cursorPointer"}
                            alt="new chat"
                            src="./images/shareFolder.png"
                        ></img>
                    </span>
                    Folder
                </MenuItem>
                <MenuItem
                    id={"file"}
                    onClick={handleClose}
                    disableRipple
                    style={{ fontFamily: "Montserrat" }}
                >
                    <span
                        className={"MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"}
                        style={{ marginRight: "18px" }}
                    >
                        <img
                            className={"cursorPointer"}
                            alt="new chat"
                            id={"file"}
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                height: "22px",
                                paddingLeft: "4px",
                            }}
                            src="./images/shareFile.png"
                        ></img>
                    </span>
                    File
                </MenuItem>
                {/* <MenuItem
                    id={"MSG_FLOW"}
                    onClick={handleClose}
                    disableRipple
                    style={{ fontFamily: "Montserrat" }}
                >
                    <span
                        className={"MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"}
                        style={{ marginRight: "18px" }}
                    >
                        <img
                            className={"cursorPointer"}
                            alt="new chat"
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                height: "22px",
                                width: "20px",
                                // paddingLeft: "4px",
                            }}
                            src="./images/UC-Icons-Color/flow-icon-color.svg"
                        ></img>
                    </span>
                    Flow
                </MenuItem> */}
                {/* <MenuItem
                    id={"MSG_INSTANCE_STEP"}
                    onClick={handleClose}
                    disableRipple
                    style={{ fontFamily: "Montserrat" }}
                >
                    <span
                        className={"MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"}
                        style={{ marginRight: "18px" }}
                    >
                        <img
                            className={"cursorPointer"}
                            alt="new chat"
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                height: "22px",
                                width: "20px",
                                // paddingLeft: "4px",
                            }}
                            src="./images/UC-Icons-Color/flow-icon-color.svg"
                        ></img>
                    </span>
                    Instance
                </MenuItem> */}
            </StyledMenu>
            <DialogView
                show={showUploadOption}
                size="lg"
                handleClose={handleCloseUploadDialog}
                showTitle={false}
                showFooter={false}
                onBackdropClick={false}
                style={{
                    display: displayShow ? "none" : "block",
                }}
                id={
                    shareItem === "workspace" ||
                    shareItem === "space" ||
                    shareItem === "folder"
                        ? "overflowHide"
                        : ""
                }
            >
                {rippleShare === "yes" ? (
                    <ShareRipple
                        onClose={handleCloseUploadDialog}
                        handleAttach={handleShareAttachment}
                    ></ShareRipple>
                ) : null}

                {rippleShare === "no" ? (
                    shareItem === "MSG_INSTANCE_STEP" ||
                    shareItem === "MSG_FLOW" ? (
                        <ShareInstanceStep
                            close={handleCloseUploadDialog}
                            submit={handleInstanceStepSubmit}
                            isFlow={shareItem === "MSG_FLOW"}
                        ></ShareInstanceStep>
                    ) : (
                        <FormWrapper
                            onHide={handleCloseUploadDialog}
                            accountType={"headerTitle"}
                            defaultStep={1}
                            Display={setDisplayShow}
                            // formType={"shareChat"}
                            handleShareAttachment={handleShareAttachment}
                            heading={"Share " + shareItem}
                            hideFile={shareItem === "folder"}
                            hideFolder={shareItem === "space"}
                            hideSpace={shareItem === "workspace"}
                        ></FormWrapper>
                    )
                ) : null}
            </DialogView>
        </>
    );
}
