import { TablePagination } from "@material-ui/core";
import React, { Component } from "react";
import AlarmIcon from "@material-ui/icons/Alarm";
import Column from "./Column";
import InlineRow from "./InlineRow";
import {
    getParsedDateByDateAndTime,
    convertUTCTimeToLocalTime,
} from "../../bae-client/Utils";
class RippleLogView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startCount: 0,
            lastCount: 10,
            page: 0,
            rowsPerPage: 100,
            selectedRowIndex: -1,
            showHistory: true,
            showNameModal: true,
        };
        this.rowData = [];
    }
    selectRow = (index) => {
        let rowIndex = this.state.rowsPerPage * this.state.page + index;
        this.props.handleRowSelected(rowIndex);
    };
    handleViewBtnClick = (index) => {
        let rowIndex = this.state.rowsPerPage * this.state.page + index;
        this.props.viewBtnCliked(rowIndex);
    };

    handleShareBtnClick = (index) => {
        this.props.shareBtnClicked(index);
    };

    handleRecureBtnClick = (index) => {
        this.props.recureBtnClicked(index);
    };

    handleLinkBtnClick = (index) => {
        this.props.linkBtnClicked(index);
    };
    handleHistoryBtnClick = () => {
        this.state.showHistory === false
            ? this.setState({ showHistory: true })
            : this.setState({ showHistory: false });
        this.props.historyBtnClicked(this.state.showHistory);
    };
    handleSaveBtnClick = (index, rowId) => {
        this.props.saveBtnClicked(index, rowId);
    };
    renderHeaderBar = () => {
        return (
            <InlineRow classname="headerRow doCenter m-0 w-100">
                <Column size="4">
                    <label className="headerLabel m-0 ">Ripple Name</label>
                </Column>
                {/* <Column size="3 doCenter">
                    <label className="headerLabel m-0 doCenter">
                        Created Date
                    </label>
                </Column> */}
                <Column size="4 doCenter">
                    <label className="headerLabel m-0 doCenter"> Date</label>
                </Column>
                <Column size="4 doCenter">
                    <label className="headerLabel m-0  doCenter">
                        Ripple Search
                    </label>
                </Column>
            </InlineRow>
        );
    };
    handlechangeCount = (value) => {};
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
        this.props.handlePageChange();
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };
    renderPagination = () => {
        return (
            <TablePagination
                id="pagination_rippleLog"
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={this.rowData.length}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                rowsPerPage={this.state.rowsPerPage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
        );
    };

    renderCardRow = () => {
        // let rowData = this.props.rowItems;
        let rowsForLogs = [];
        if (this.rowData.length > 0) {
            this.rowData
                .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                )
                .map((rowItem, index) => {
                    // this.rowData.forEach((rowItem, index) => {
                    let classname =
                        this.props.selectedIndex % this.state.rowsPerPage ===
                        index
                            ? "rippleLogRowOutline"
                            : "";

                    let parsedDate = getParsedDateByDateAndTime(
                        rowItem.updatedOn
                    );
                    let createdDate = getParsedDateByDateAndTime(
                        rowItem.createdOn
                    );
                    let tempSearchCriteria = rowItem.searchCriteria.split("&");

                    let searchCriteria = tempSearchCriteria.join(",");
                    let rippleLogTitle = "";

                    if (rowItem.rippleRecur.split("|")[0] === "DISABLE") {
                        rippleLogTitle =
                            rowItem.rippleRecur.split("|")[0] +
                            "|" +
                            rowItem.rippleRecur.split("|")[1] +
                            "|" +
                            convertUTCTimeToLocalTime(
                                rowItem.rippleRecur.split("|")[2]
                            );
                    } else {
                        rippleLogTitle =
                            rowItem.rippleRecur.split("|")[0] +
                            "|" +
                            convertUTCTimeToLocalTime(
                                rowItem.rippleRecur.split("|")[1]
                            );
                    }
                    rowsForLogs.push(
                        <InlineRow
                            classname={"rippleLogRow doCenter " + classname}
                            onclick={(event) => this.selectRow(index)}
                            id={"row_rippleLog_" + index}
                            noRow={true}
                        >
                            <Column size="4" className="pr-0">
                                <div className="row p-0 m-0 w-100">
                                    <label
                                        style={
                                            rowItem.rippleRecur.split(
                                                "|"
                                            )[0] === "DISABLE"
                                                ? {
                                                      color: "#989898",
                                                  }
                                                : {}
                                        }
                                        className={
                                            rowItem.rippleRecur.split(
                                                "|"
                                            )[0] === "DISABLE"
                                                ? " textEllipsis m-0 cur_pointer"
                                                : "rippleLogRowTitle textEllipsis m-0 cur_pointer"
                                        }
                                        title={rowItem.rippleName}
                                        id={"row_rippleLog_rippleName_" + index}
                                        onClick={(event) =>
                                            this.handleViewBtnClick(index)
                                        }
                                    >
                                        {rowItem.rippleName !== undefined &&
                                        rowItem.rippleName !== "" &&
                                        rowItem.rippleName !== "Name Ripple"
                                            ? rowItem.rippleName.trim()
                                            : "Unsaved Ripple"}
                                    </label>
                                </div>
                                {rowItem.rippleRecur !== "0" &&
                                rowItem.rippleRecur !== "" ? (
                                    <div className="row p-0 m-0 w-100">
                                        <label
                                            style={
                                                rowItem.rippleRecur.split(
                                                    "|"
                                                )[0] === "DISABLE"
                                                    ? {
                                                          color: "#989898",
                                                      }
                                                    : {}
                                            }
                                            className={
                                                rowItem.rippleRecur.split(
                                                    "|"
                                                )[0] === "DISABLE"
                                                    ? "textEllipsis m-0 cur_pointer"
                                                    : "rippleLogRowTitle textEllipsis m-0 cur_pointer"
                                            }
                                            title={rippleLogTitle}
                                        >
                                            {rippleLogTitle}
                                        </label>
                                    </div>
                                ) : null}
                            </Column>

                            <Column size="4 doCenter">
                                <label
                                    className="textEllipsis w-100 m-0 "
                                    style={
                                        rowItem.rippleRecur.split("|")[0] ===
                                        "DISABLE"
                                            ? {
                                                  color: "#989898",
                                                  textAlign: "center",
                                              }
                                            : { textAlign: "center" }
                                    }
                                    title={parsedDate}
                                >
                                    {parsedDate}
                                </label>
                            </Column>

                            <Column size="4 doCenter">
                                <InlineRow
                                    classname="textEllipsis w-100 doCenter"
                                    noRow={true}
                                >
                                    {tempSearchCriteria.map((element) => (
                                        <label
                                            className="m-0 textEllipsis"
                                            style={
                                                rowItem.rippleRecur.split(
                                                    "|"
                                                )[0] === "DISABLE"
                                                    ? {
                                                          color: "#989898",
                                                          textAlign: "center",
                                                          maxWidth: "80%",
                                                      }
                                                    : {
                                                          textAlign: "center",
                                                          maxWidth: "80%",
                                                      }
                                            }
                                            title={element}
                                        >
                                            <span>
                                                {"<"} {element} {">"}
                                            </span>
                                        </label>
                                    ))}
                                </InlineRow>
                            </Column>
                        </InlineRow>
                    );
                });
        }

        return rowsForLogs;
    };
    render() {
        this.rowData = this.props.rowItems;
        return (
            <div
                className="rippleMainDiv invisibleScroller"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "85vh", // Ensure the container spans the full viewport height
                    background: "#FFFFFF",
                    boxShadow: "1px 1px 20px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                }}
            >
                {this.props.rippleLogShare ? (
                    <>
                        {this.renderCardRow()}
                        <div style={{ marginTop: "auto" }}>
                            {this.renderPagination()}
                        </div>
                    </>
                ) : (
                    <>
                        {this.renderHeaderBar()}
                        {this.renderCardRow()}
                        {this.rowData.length > 0 ? (
                            <div style={{ marginTop: "auto" }}>
                                {this.renderPagination()}
                            </div>
                        ) : null}
                    </>
                )}
            </div>
        );
    }
}

export default RippleLogView;
