import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSearchDropdown from "../../stylized/CustomDropDown/index";
import CustomDateDropdown from "../../stylized/CustomDateDropdown";
import http from "../../../bae-client/HTTP";
import { getFormattedDate, getDateByRange } from "../../../bae-client/Utils";
import Loader from "../../stylized/Loader";
import { setRippleFilterAction } from "../../../services/services/app";

export default function RippleFilters({ rippleFilterData1, applyFilters,
    selectedWorkspaceArrayLocal,
    selectedSpaceArrayLocal,
    selectedFolderArrayLocal,
    dateErr,
    selectedTimeFilterLocal,
    selectedTimeFilter,
    setSelectedWorkspaceArrayLocal,
    setSelectedSpaceArrayLocal,
    setSelectedFolderArrayLocal,
    setDateErr,
    setSelectedTimeFilterLocal,
    setSelectedTimeFilter,
    handleReset
 }) {
    const workspaceList = useSelector((state) => state.workspaceList);
    const rippleFilterData = useSelector((state) => state.rippleFilterData);

    const dispatch = useDispatch();

    const [spaceListData, setSpaceListData] = useState([]);
    const [folderListData, setFolderListData] = useState([]);
    // const [selectedWorkspaceArrayLocal, setSelectedWorkspaceArrayLocal] =
    //     useState([]);
    // const [selectedSpaceArrayLocal, setSelectedSpaceArrayLocal] = useState([]);
    // const [selectedFolderArrayLocal, setSelectedFolderArrayLocal] = useState(
    //     []
    // );
    // const [dateErr, setDateErr] = useState(false);
    // const [selectedTimeFilterLocal, setSelectedTimeFilterLocal] = useState({
    //     startDate: "",
    //     endDate: "",
    //     selectedTimeType: "",
    // });
    // const [selectedTimeFilter, setSelectedTimeFilter] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (rippleFilterData) {
            let selectedWorkspaces = [];
            if (workspaceList !== null && workspaceList !== undefined) {
                rippleFilterData.workspaces.forEach((workspaceId) => {
                    workspaceList.forEach((element) => {
                        if (workspaceId === element.workspaceId) {
                            selectedWorkspaces.push({
                                text: element.workspaceName,
                                value: element.workspaceName,
                                id: element.workspaceId,
                            });
                        }
                    });
                });
            }
            setSelectedWorkspaceArrayLocal(selectedWorkspaces);
            // rippleFilterData.date && setSelectedTimeFilterLocal(rippleFilterData.date);
            // rippleFilterData.timeFilter && setSelectedTimeFilter(rippleFilterData.timeFilter);
            rippleFilterData.timeFilter &&
                rippleFilterData.timeFilter.length &&
                getDateFilterData(rippleFilterData.timeFilter);
        }
    }, [rippleFilterData]);

    useEffect(() => {
        if (selectedWorkspaceArrayLocal.length) {
            let workspaceIds = selectedWorkspaceArrayLocal.map(
                (selectedWS) => selectedWS.id
            );
            getSpaceListForSelectedWorkspaces(workspaceIds);
            setSelectedSpaceArrayLocal([]);
        } else {
            setSelectedSpaceArrayLocal([]);
            setSpaceListData([]);
        }
    }, [selectedWorkspaceArrayLocal]);

    useEffect(() => {
        if (selectedSpaceArrayLocal.length) {
            let spaceIds = selectedSpaceArrayLocal.map(
                (selectedSpace) => selectedSpace.id
            );
            getFolderListForSelectedSpaces(spaceIds);
            setSelectedFolderArrayLocal([]);
        } else {
            setSelectedFolderArrayLocal([]);
            setFolderListData([]);
        }
    }, [selectedSpaceArrayLocal]);

    useEffect(() => {
        if (rippleFilterData && rippleFilterData.spaces.length) {
            let selectedSpaces = [];
            if (spaceListData !== null && spaceListData !== undefined) {
                rippleFilterData.spaces.forEach((spaceId) => {
                    spaceListData.forEach((element) => {
                        if (spaceId === element.spaceId) {
                            selectedSpaces.push({
                                text: element.spaceName,
                                value: element.spaceName,
                                id: element.spaceId,
                            });
                        }
                    });
                });
            }
            setSelectedSpaceArrayLocal(selectedSpaces);
        }
    }, [spaceListData]);

    useEffect(() => {
        if (rippleFilterData && rippleFilterData.folders.length) {
            let selectedFolders = [];
            if (folderListData && folderListData.length > 0) {
                rippleFilterData.folders.forEach((folderId) => {
                    folderListData.forEach((folder) => {
                        if (folderId === folder.folderId) {
                            selectedFolders.push({
                                text: folder.folderName,
                                value: folder.folderName,
                                id: folder.folderId,
                            });
                        }
                    });
                });
            }
            setSelectedFolderArrayLocal(selectedFolders);
        }
    }, [folderListData]);

    const getSpaceListForSelectedWorkspaces = (workspaceIds) => {
        let url =
            "workspace/selectedWorkspaces/spaceList?workspaceIds=" +
            workspaceIds.toString();
        setShowLoader(true);
        http.sendRestRequest(url).then((spaceList) => {
            setSpaceListData(spaceList);
            setShowLoader(false);
        });
    };

    const getFolderListForSelectedSpaces = (spaceIds) => {
        let url =
            "space/selectedSpaces/rootFolderList?spaceIds=" +
            spaceIds.toString();
        setShowLoader(true);
        http.sendRestRequest(url).then((response) => {
            let folderList = [];
            Object.keys(response).forEach((folderId) => {
                let obj = {
                    folderId: folderId,
                    folderName: response[folderId].objectName,
                };
                folderList.push(obj);
            });
            setFolderListData(folderList);
            setShowLoader(false);
        });
    };

    const generateWorkspaceDropdown = () => {
        // console.log(this.hierarchyTree);
        let workspaceItems = [];
        if (workspaceList !== null && workspaceList !== undefined) {
            workspaceList.forEach((element) => {
                workspaceItems.push({
                    text: element.workspaceName,
                    value: element.workspaceName,
                    id: element.workspaceId,
                });
            });
        }
        return (
            <>
                <CustomSearchDropdown
                    items={workspaceItems}
                    label={"Workspaces"}
                    id={"Workspaces"}
                    customClassName={"rippleDD rippleDDFilterView"}
                    multiSelect={true}
                    isSearchable={true}
                    isChipView={false}
                    handleSelection={handleWorkspacesSelection}
                    selection={selectedWorkspaceArrayLocal}
                    dropdownPosition={"ripple"}
                    returnSelectionOnFocusOut={true}
                ></CustomSearchDropdown>
            </>
        );
    };
    const generateSpaceDropdown = () => {
        let spaceItems = [];
        if (spaceListData !== null && spaceListData !== undefined) {
            spaceListData.forEach((element) => {
                spaceItems.push({
                    text: element.spaceName,
                    value: element.spaceName,
                    id: element.spaceId,
                });
            });
        }
        return (
            <CustomSearchDropdown
                items={spaceItems}
                label={"Spaces"}
                id={"Spaces"}
                customClassName={"rippleDD rippleDDFilterView"}
                multiSelect={true}
                isSearchable={true}
                isChipView={true}
                handleSelection={handleSpacesSelection}
                selection={selectedSpaceArrayLocal}
                dropdownPosition={"ripple"}
                returnSelectionOnFocusOut={true}
            ></CustomSearchDropdown>
        );
    };
    const generateFolderDropdown = () => {
        let spaceFolderList = [];
        if (folderListData && folderListData.length > 0) {
            folderListData.forEach((folder) => {
                spaceFolderList.push({
                    text: folder.folderName,
                    value: folder.folderName,
                    id: folder.folderId,
                });
            });
        }

        return (
            <CustomSearchDropdown
                items={spaceFolderList}
                label={"Folders"}
                id={"Folders"}
                customClassName={"rippleDD rippleDDFilterView"}
                multiSelect={true}
                isSearchable={true}
                isChipView={false}
                handleSelection={handleFoldersSelection}
                selection={selectedFolderArrayLocal}
                dropdownPosition={"ripple"}
                returnSelectionOnFocusOut={true}
            />
        );
    };
    const generateDateDropdown = () => {
        return (
            <CustomDateDropdown
                dateErr={dateErr}
                setDateErr={setDateErr}
                setSelectedTimeFilter={setSelectedTimeFilterData}
                selection={selectedTimeFilterLocal}
                module={"dropDownWidth"}
                customClassName={"rippleDD rippleDDFilterView"}
            ></CustomDateDropdown>
        );
    };

    const handleWorkspacesSelection = (index, values) => {
        setSelectedWorkspaceArrayLocal(values);
    };

    const handleSpacesSelection = (index, values) => {
        setSelectedSpaceArrayLocal(values);
    };

    const handleFoldersSelection = (index, values) => {
        setSelectedFolderArrayLocal(values);
    };

    const getDateFilterData = (dateFilter) => {
        let dateObj = {};
        if (dateFilter.length === 2) {
            dateObj.selectedTimeType = "Custom";
            dateObj.startDate = dateFilter[0];
            dateObj.endDate = dateFilter[1];
        } else if (dateFilter[0] === "Last 7 days") {
            dateObj.selectedTimeType = "Last 7 days";
            let today = new Date();
            dateObj.startDate = getDateByRange("Last 7 days");
            dateObj.endDate = getFormattedDate(today, true);
        } else if (dateFilter[0] === "Last 14 days") {
            dateObj.selectedTimeType = "Last 14 days";
            let today = new Date();
            dateObj.startDate = getDateByRange("Last 14 days");
            dateObj.endDate = getFormattedDate(today, true);
        } else if (dateFilter[0] === "Last 30 days") {
            dateObj.selectedTimeType = "Last 30 days";
            let today = new Date();
            dateObj.startDate = getDateByRange("Last 30 days");
            dateObj.endDate = getFormattedDate(today, true);
        } else if (dateFilter[0] === "Last 90 days") {
            dateObj.selectedTimeType = "Last 90 days";
            let today = new Date();
            dateObj.startDate = getDateByRange("Last 90 days");
            dateObj.endDate = getFormattedDate(today, true);
        } else if (dateFilter[0] === "Last Year") {
            dateObj.selectedTimeType = "Last Year";
            let today = new Date();
            dateObj.startDate = getDateByRange("Last Year");
            dateObj.endDate = getFormattedDate(today, true);
        }
        setSelectedTimeFilterData(dateObj);
    };

    const setSelectedTimeFilterData = (dateObj) => {
        setSelectedTimeFilterLocal({
            startDate:
                dateObj.startDate !== "NaN-NaN-NaN" ? dateObj.startDate : "",
            endDate: dateObj.endDate !== "NaN-NaN-NaN" ? dateObj.endDate : "",
            selectedTimeType: dateObj.selectedTimeType,
        });

        if (dateObj.selectedTimeType === "Custom") {
            let startDate =
                dateObj.startDate !== "NaN-NaN-NaN" ? dateObj.startDate : "";
            let endDate =
                dateObj.endDate !== "NaN-NaN-NaN" ? dateObj.endDate : "";
            if (startDate && endDate) {
                setSelectedTimeFilter([startDate, endDate]);
            } else setSelectedTimeFilter(["Custom"]);
        } else {
            setSelectedTimeFilter([
                dateObj.selectedTimeType ? dateObj.selectedTimeType : "",
            ]);
        }
    };

    const handleApply = () => {
        let filterObj = {
            workspaces: selectedWorkspaceArrayLocal.map(
                (workspace) => workspace.id
            ),
            spaces: selectedSpaceArrayLocal.map((space) => space.id),
            folders: selectedFolderArrayLocal.map((folder) => folder.id),
            // date : selectedTimeFilterLocal,
            timeFilter: selectedTimeFilter,
        };
        applyFilters(null, filterObj,"ApplyBtn");
    };

    // const handleReset = () => {
    //     setDateErr(false);
    //     setSelectedWorkspaceArrayLocal([]);
    //     setSelectedSpaceArrayLocal([]);
    //     setSelectedFolderArrayLocal([]);
    //     setSelectedTimeFilterLocal({
    //         startDate: "",
    //         endDate: "",
    //         selectedTimeType: "",
    //     });
    //     setSelectedTimeFilter([]);
    //     dispatch(setRippleFilterAction(null));
    // };

    return (
        <div>
            <div className="filterContainer mb-2">
                <div className="row m-0 w-100 doCenter">
                    {/* {this.generateFilterWorkspaceDropdown()} */}
                    {generateWorkspaceDropdown()}
                </div>
                <div className="row m-0 w-100 doCenter">
                    {/* {this.generateFilterSpaceDropdown()} */}
                    {generateSpaceDropdown()}
                </div>
                <div className="row m-0 w-100 doCenter">
                    {/* {this.generateFilterFolderList()} */}
                    {generateFolderDropdown()}
                </div>
                <div className="row m-0 w-100 doCenter">
                    {/* {this.renderDateFilter("right")} */}
                    {generateDateDropdown()}
                </div>

                <div className="row m-0 w-100 doCenter">
                    <button
                        className="reset-button"
                        onClick={handleReset}
                        title="Reset"
                        id="reset_ripple"
                    >
                        Reset
                    </button>
                    <button
                        title="Apply"
                        className="apply-button"
                        id="apply_ripple"
                        style={{
                            cursor: dateErr ? "not-allowed" : "pointer",
                        }}
                        onClick={dateErr ? null : handleApply}
                    >
                        Apply
                    </button>
                </div>
            </div>
            <Loader show={showLoader} />
        </div>
    );
}
