import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { getIconUrls } from "../constant";
import "../Workspaces.css";
import { convertUTCToLocal } from "../Workspaces";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        headCells,
        breadcrumbData,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {!breadcrumbData.some((item) => item.name === "Flow") &&
                    !(
                        breadcrumbData.length === 1 &&
                        breadcrumbData[0].name === "Emails"
                    ) &&
                    !breadcrumbData.some(
                        (item) => item.name === "Integration"
                    ) && (
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                indeterminate={
                                    numSelected > 0 && numSelected < rowCount
                                }
                                checked={
                                    rowCount > 0 && numSelected === rowCount
                                }
                                onChange={onSelectAllClick}
                                inputProps={{
                                    "aria-label": "select all desserts",
                                }}
                                // padding={"none"}
                                size={"small"}
                            />
                        </TableCell>
                    )}

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"left"}
                        // padding={"none"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            sx={{
                                paddingBottom: "16px",
                                fontSize: "15px",
                                fontWeight: "600",
                            }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

// function EnhancedTableToolbar(props) {
//     const { numSelected } = props;

//     return (
//         <Toolbar
//             sx={{
//                 pl: { sm: 2 },
//                 pr: { xs: 1, sm: 1 },
//                 ...(numSelected > 0 && {
//                     bgcolor: (theme) =>
//                         alpha(
//                             theme.palette.primary.main,
//                             theme.palette.action.activatedOpacity
//                         ),
//                 }),
//             }}
//         >
//             {numSelected > 0 ? (
//                 <Typography
//                     sx={{ flex: "1 1 100%" }}
//                     color="inherit"
//                     variant="subtitle1"
//                     component="div"
//                 >
//                     {numSelected} selected
//                 </Typography>
//             ) : (
//                 <Typography
//                     sx={{ flex: "1 1 100%" }}
//                     variant="h6"
//                     id="tableTitle"
//                     component="div"
//                 >
//                     Nutrition
//                 </Typography>
//             )}

//             {numSelected > 0 ? (
//                 <Tooltip title="Delete">
//                     <IconButton>
//                         <DeleteIcon />
//                     </IconButton>
//                 </Tooltip>
//             ) : (
//                 <Tooltip title="Filter list">
//                     <IconButton>
//                         <FilterListIcon />
//                     </IconButton>
//                 </Tooltip>
//             )}
//         </Toolbar>
//     );
// }

// EnhancedTableToolbar.propTypes = {
//     numSelected: PropTypes.number.isRequired,
// };

export default function CustomTable({
    order,
    setOrder,
    orderBy,
    setOrderBy,
    spaceTableData,
    rowsSelected,
    setRowsSelected,
    setSelectedValues,
    cellClickAction,
    setCheckType,
    searchText,
    setSearchText,
    selectedValues,
    page,
    setPage,
    filteredRow,
    breadcrumbData,
}) {
    const [rows, setRows] = React.useState([]);
    const [headCells, setHeadCells] = React.useState([]);
    const [selected, setSelected] = React.useState([]);

    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    React.useEffect(() => {
        if (spaceTableData) {
            if (searchText.length === 0) {
                setSelected([]);
                setRows(spaceTableData.rows);
                setHeadCells(spaceTableData.columns);
            } else if (filteredRow && filteredRow.rows) {
                setSelected([]);
                setRows(filteredRow.rows);
                setHeadCells(spaceTableData.columns);
            } else {
                console.error(
                    "filteredRow or filteredRow.rows is null or undefined"
                );
            }
        }
    }, [spaceTableData, searchText, filteredRow]);

    React.useEffect(() => {
        return () => {
            setRowsSelected([]);
            setSelectedValues([]);
        };
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            setRowsSelected(newSelected);
            setSelectedValues(rows);
            return;
        }
        setRowsSelected([]);
        setSelectedValues([]);
        setSelected([]);
    };

    const handleClick = (event, id, type, row) => {
        setCheckType(type);
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        let newSelectedValues = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
            newSelectedValues = newSelectedValues.concat(selectedValues, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            newSelectedValues = newSelectedValues.concat(
                selectedValues.slice(1)
            );
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            newSelectedValues = newSelectedValues.concat(
                selectedValues.slice(0, -1)
            );
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
            newSelectedValues = newSelectedValues.concat(
                selectedValues.slice(0, selectedIndex),
                selectedValues.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
        setRowsSelected(newSelected);
        setSelectedValues(newSelectedValues);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            ),
        [order, orderBy, page, rowsPerPage, rows]
    );

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <Paper
                sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
                elevation={3}
            >
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer
                    sx={{
                        height: "90%",
                        paddingTop: "0px",
                        backgroundColor: "white",
                    }}
                >
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                            breadcrumbData={breadcrumbData}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) =>
                                            handleClick(
                                                event,
                                                row.id,
                                                row.type,
                                                row
                                            )
                                        }
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                    >
                                        {!breadcrumbData.some(
                                            (item) => item.name === "Flow"
                                        ) &&
                                            !(
                                                breadcrumbData.length === 1 &&
                                                breadcrumbData[0].name ===
                                                    "Emails"
                                            ) &&
                                            !breadcrumbData.some(
                                                (item) =>
                                                    item.name === "Integration"
                                            ) && (
                                                <TableCell
                                                    padding="checkbox"
                                                    sx={{ cursor: "pointer" }}
                                                >
                                                    <Checkbox
                                                        id={
                                                            "chk_spItem_" +
                                                            row.id
                                                        }
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            "aria-labelledby":
                                                                labelId,
                                                        }}
                                                        // padding={"none"}
                                                        size="small"
                                                    />
                                                </TableCell>
                                            )}
                                        {headCells.map((headCell, colIndex) => (
                                            <TableCell
                                                key={colIndex}
                                                title={
                                                    headCell?.id ===
                                                        "updatedOn" ||
                                                    headCell?.id === "createdOn"
                                                        ? convertUTCToLocal(
                                                              row[headCell.id]
                                                          )
                                                        : row[headCell.id]
                                                }
                                            >
                                                {headCell.type ? (
                                                    headCell.type === "star" ? (
                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (
                                                                    row.type !==
                                                                        "file" &&
                                                                    row.type !==
                                                                        "email_attachment"
                                                                )
                                                                    cellClickAction(
                                                                        e,
                                                                        row,
                                                                        true
                                                                    );
                                                            }}
                                                            className="workspace-actions"
                                                            style={{
                                                                gap: "8px",
                                                                cursor:
                                                                    row.type !==
                                                                        "file" &&
                                                                    row.type !==
                                                                        "email_attachment"
                                                                        ? "pointer"
                                                                        : "not-allowed",
                                                            }}
                                                        >
                                                            <img
                                                                src={getIconUrls(
                                                                    row[
                                                                        headCell
                                                                            .id
                                                                    ],
                                                                    row.name
                                                                )}
                                                                alt=""
                                                                style={{
                                                                    width: "30px",
                                                                    height: "30px",
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            id={
                                                                "table_row_" +
                                                                row.type +
                                                                "_" +
                                                                index
                                                            }
                                                            onClick={(e) =>
                                                                cellClickAction(
                                                                    e,
                                                                    row
                                                                )
                                                            }
                                                            className="workspace-actions"
                                                            style={{
                                                                gap: "8px",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    breadcrumbData.some(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.name ===
                                                                            "Flow"
                                                                    )
                                                                        ? getIconUrls(
                                                                              row.type ===
                                                                                  "file"
                                                                                  ? "file"
                                                                                  : "flows",
                                                                              row.name
                                                                          )
                                                                        : // : breadcrumbData.some(
                                                                          //       (
                                                                          //           item
                                                                          //       ) =>
                                                                          //           item.name ===
                                                                          //           "Emails"
                                                                          //   ) &&
                                                                          //   row.name.includes(
                                                                          //       "."
                                                                          //   )
                                                                          // ? getIconUrls(
                                                                          //       "extension",
                                                                          //       row.name
                                                                          //   )
                                                                          // : breadcrumbData.some(
                                                                          //       (
                                                                          //           item
                                                                          //       ) =>
                                                                          //           item.name ===
                                                                          //           "Emails"
                                                                          //   )
                                                                          // ? getIconUrls(
                                                                          //       "emails",
                                                                          //       row.name
                                                                          //   )
                                                                          getIconUrls(
                                                                              row.type,
                                                                              row.name
                                                                          )
                                                                }
                                                                alt=""
                                                                style={{
                                                                    width: "30px",
                                                                    height: "30px",
                                                                }}
                                                            />

                                                            <span
                                                                className="textEllipsis"
                                                                style={{
                                                                    width: "300px",
                                                                }}
                                                            >
                                                                {
                                                                    row[
                                                                        headCell
                                                                            .id
                                                                    ]
                                                                }
                                                            </span>
                                                        </div>
                                                    )
                                                ) : headCell?.id ===
                                                      "updatedOn" ||
                                                  headCell?.id ===
                                                      "createdOn" ? (
                                                    convertUTCToLocal(
                                                        row[headCell.id]
                                                    )
                                                ) : (
                                                    row[headCell.id]
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })}
                            {/* {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        width: "100%",
                        height: "10%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                    }}
                />
            </Paper>
        </Box>
    );
}
