import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalView from "../../../../../stylized/ModalView";
import CustomDropdown from "../../../../../stylized/CustomDropdown";
import http from "../../../../../../bae-client/HTTP";
import {
    fetchFilterData,
    setSelectedBranchDropdownValue,
    setSelectedGenericDropdownValue,
    setSelectedInstanceDropdownValue,
    setSelectedTransactionDropdownValue,
} from "../../../../../../actions/DashboardActions";
import {
    setSelectedGenericDropdownValueFilterOne,
    setSelectedInstanceDropdownValueFilterOne,
} from "../../../../../../actions/FlowCompareActions";

import Loader from "../../../../../stylized/Loader";
import { makeStyles } from "@material-ui/core/styles";
import "./addDocuments.css";
import { setSnackbarAction } from "../../../../../../services/services/app";
import DropdownComponent from "../../../../../stylized/dropdown/index";
import DropdownWithSearch from "../../../../../dropdown";
import CustomSearchDropdown from "../../../../../stylized/CustomDropDown/index";

const useStyles = makeStyles({
    root: {
        // maxWidth: "9rem",
        borderRadius: "6px !important",
        display: "inline-flex",
        flexDirection: "column",
        position: "relative",
        minWidth: "0px",
        padding: "0px",
        marginBottom: "8px",
        border: "0px",
        verticalAlign: "top",
        width: "100%",
    },
});
const AddDocuments = ({
    openAddInstanceModal,
    showDocumentModal,
    closeModal = () => {},
    documentId,
    values,
    folderId,
    showAlertView,
    setAlertDialogShow,
    setSelectedRow,
    module,
    handleSaveClick,
    flowInfo,
    documentCloseAlert = () => {},
}) => {
    const classes = useStyles();
    const filterRawDataRedux = useSelector(
        (state) => state.DashboardDCFilterData
    );
    const selectedStepDDValue = useSelector((state) => {
        return state.selectedStepDropdownValue;
    });
    //ON SELECTION OF GENERIC & INSTANCE DATA CALL THE INSTANCE API(BRANCH ID,NAME AND STEP NAME)
    //GET THE NAME OF BRANCH FROM THERE > ON SELECTION OF BRANCH > FILTER THE INSTANCE DD > TO GET THE STEP NAME > SEND THE STEP ID TO API
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [disableApply, setDisableApply] = useState("disable");
    const [genericList, setGenericList] = React.useState([]);
    const [instanceList, setInstanceList] = React.useState([]);
    const [StepList, setStepList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [genericSteps, setGenericSteps] = useState([]);
    const [instanceDetails, setInstanceDetails] = useState([]);

    const selectedGenericDDValue = useSelector((state) => {
        return state.selectedGenericDropdownValue;
    });

    const [
        selectedInstanceDropdownLocalValue,
        setSelectedInstanceDropdownLocalValue,
    ] = useState([]);

    const [
        selectedBranchDropdownLocalValue,
        setSelectedBranchDropdownLocalValue,
    ] = useState([]);
    const [selectedStepDropdownLocalValue, setSelectedStepDropdownLocalValue] =
        useState([]);
    const selectedInstanceDDValue = useSelector((state) => {
        return state.selectedInstanceDropdownValue;
    });
    const selectedBranchDDValue = useSelector((state) => {
        return state.selectedBranchDropdownValue;
    });

    const [
        selectedGenericDropdownLocalValue,
        setSelectedGenericDropdownLocalValue,
    ] = useState(null);
    useEffect(() => {
        if (selectedBranchDropdownLocalValue) {
            if (selectedGenericDropdownLocalValue) {
                let genericIndex = getSelectedGenericIndex();
                createStepDropdownList(genericIndex, instanceDetails);
            }
        }
    }, [selectedBranchDropdownLocalValue, flowInfo]);
    useEffect(() => {
        setSelectedGenericDropdownLocalValue(selectedGenericDDValue);
        setSelectedBranchDropdownLocalValue(selectedBranchDDValue);
        setSelectedInstanceDropdownLocalValue(selectedInstanceDDValue);
        setSelectedStepDropdownLocalValue(selectedStepDDValue);
    }, []);
    useEffect(() => {
        if (selectedGenericDropdownLocalValue) {
            setLoader(true);
            setSelectedBranchDropdownLocalValue([]);
            setSelectedInstanceDropdownLocalValue([]);
            setSelectedStepDropdownLocalValue([]);
            setSelectedRow(selectedGenericDropdownLocalValue);
            http.sendRestRequest(
                `documentChain/generic/stepList?genericId=${selectedGenericDropdownLocalValue.id}`
            )
                .then((result) => {
                    setGenericSteps(result);
                    setLoader(false);
                })
                .catch((error) => {
                    setLoader(false);
                    console.error(error);
                });
        }
    }, [selectedGenericDropdownLocalValue]);
    useEffect(() => {
        if (selectedInstanceDropdownLocalValue.id) {
            if (
                selectedInstanceDropdownLocalValue.id &&
                selectedGenericDropdownLocalValue
            ) {
                setLoader(true);
                http.sendRestRequest(
                    `documentChain/instanceData?genericId=${selectedGenericDropdownLocalValue.id}&instanceId=${selectedInstanceDropdownLocalValue.id}`
                )
                    .then((instanceSteps) => {
                        setInstanceDetails(instanceSteps);
                        createBranchDropdownList(instanceSteps);

                        setLoader(false);
                    })
                    .catch((error) => {
                        setLoader(false);
                    });
            }
        }
    }, [selectedInstanceDropdownLocalValue]);
    useEffect(() => {
        setInstanceDetails(instanceDetails);
    }, [
        selectedInstanceDropdownLocalValue.id,
        selectedGenericDropdownLocalValue,
    ]);
    useEffect(() => {
        if (!filterRawDataRedux) {
            setLoader(true);
            http.sendRestRequest("documentChain/objects")
                .then((response) => {
                    dispatch(fetchFilterData(response));
                    setLoader(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoader(false);
                });
        }
    }, []);
    useEffect(() => {
        if (filterRawDataRedux) {
            setLoader(false);

            createGenericDropdownList();
        } else setLoader(true);
    }, [filterRawDataRedux]);

    useEffect(() => {
        if (selectedGenericDropdownLocalValue) {
            let genericIndex = getSelectedGenericIndex();

            createInstanceDropdownList(selectedGenericDropdownLocalValue);
        }
    }, [selectedGenericDropdownLocalValue]);

    console.log(selectedGenericDropdownLocalValue, filterRawDataRedux);
    useEffect(() => {
        if (selectedGenericDropdownLocalValue) {
            let genericIndex = getSelectedGenericIndex();

            createInstanceDropdownList(selectedGenericDropdownLocalValue);
        }
    }, [filterRawDataRedux]);

    useEffect(() => {
        if (flowInfo && flowInfo.stepName && genericSteps.length) {
            const steps = [];

            genericSteps.forEach((generic, index) => {
                steps.push({
                    text: generic.genericStepName,
                    value: generic.genericStepName,
                    id: index,
                });
            });
            if (
                genericSteps.find(
                    (generic) => generic.genericStepName === flowInfo.stepName
                )
            )
                setSelectedStepDropdownLocalValue({
                    text: flowInfo.stepName,
                    value: flowInfo.stepName,
                    id: flowInfo.stepName,
                });
            setStepList(steps);
        }
    }, [flowInfo, genericSteps]);

    function getSelectedGenericIndex() {
        let genericIndex;
        filterRawDataRedux.forEach((generic, index) => {
            if (
                generic.genericRegistry.genericId ===
                selectedGenericDropdownLocalValue?.id
            ) {
                genericIndex = index;
            }
        });
        return genericIndex;
    }
    const handleBranchSelection = (index, selectedBranch) => {
        setSelectedBranchDropdownLocalValue(selectedBranch);
        if (flowInfo && flowInfo.stepName) return;
        setSelectedStepDropdownLocalValue([]);
    };
    const onCloseInstanceModal = () => {
        dispatch(setSelectedGenericDropdownValue(null));
        closeModal();
    };

    const createStepDropdownList = (genericIndex, instance) => {
        let itemList = [];

        const filteredItems = instance.filter(
            (item) =>
                item.instanceBlockDocumentName === "" &&
                item.branchName === selectedBranchDropdownLocalValue.value
        );
        const filteredItem = filteredItems.filter(
            (item) => item.durationStatus !== "SKIPPED"
        );
        filterRawDataRedux[genericIndex].stepNames.forEach((item) => {
            filteredItem.filter((instance, value) => {
                if (instance.instanceStepName === item) {
                    itemList.push({
                        text: instance.instanceStepName,
                        value: instance.instanceStepName,
                        id: instance.instanceStepId,
                        orderId: value,
                    });
                }
            });
        });
        const sortedData = itemList.sort((a, b) => a.orderId - b.orderId);
        setStepList(sortedData);
    };
    const handleSubmit = () => {
        let workspaceId = 0;
        let spaceId = 0;
        let folderId = 0;
        // const instance = instanceDetails.find(
        //     (item) =>
        //         item.instanceStepName === selectedStepDropdownLocalValue.text
        // );
        if (documentId) {
            http.sendRestRequest("document/documentInfo", "POST", [
                parseInt(documentId),
            ])
                .then((result) => {
                    const locationIds = result[0]?.locationIds?.split("\\");
                    workspaceId = locationIds[0];
                    spaceId = locationIds[1];
                    if (locationIds.length === 2) {
                        folderId = 0;
                    } else {
                        folderId = locationIds[locationIds.length - 1];
                    }
                    if (selectedStepDropdownLocalValue) {
                        dispatch(setSelectedGenericDropdownValue(null));
                        setLoader(true);
                        http.sendRestRequest(
                            `documentChain/instance/step/uploadFromWorkspace?instanceStepId=${selectedStepDropdownLocalValue.id}&genericId=${selectedGenericDropdownLocalValue.id}&workspaceId=${workspaceId}&spaceId=${spaceId}&documentId=${documentId}&folderId=${folderId}`,
                            "PUT"
                        )
                            .then(() => {
                                closeModal();

                                if (module === "workSpace") handleSaveClick();
                                documentCloseAlert();
                                const flowStepName =
                                    selectedStepDropdownLocalValue.text;
                                const successMessage = `The document have been successfully attached to "${flowStepName}"!`;

                                dispatch(
                                    setSnackbarAction({
                                        message: successMessage,
                                        open: true,
                                        type: "success",
                                    })
                                );
                                setLoader(false);
                            })
                            .catch((error) => {
                                setLoader(false);
                            });
                        setLoader(false);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    dispatch(
                        setSnackbarAction({
                            snackbarOpen: true,
                            snackbarType: "error",
                            snackbarMessage:
                                err?.error || "Something went wrong!",
                        })
                    );
                    setLoader(false);
                });
        }
    };

    const createGenericDropdownList = () => {
        let itemList = [];
        let selectedItem = null;
        filterRawDataRedux.forEach((filterItem) => {
            itemList.push({
                text: filterItem.genericRegistry.genericDisplayName,
                value: filterItem.genericRegistry.genericDisplayName,
                id: filterItem.genericRegistry.genericId,
            });
            if (
                flowInfo &&
                flowInfo.flowName ===
                    filterItem.genericRegistry.genericDisplayName
            ) {
                selectedItem = {
                    text: filterItem.genericRegistry.genericDisplayName,
                    value: filterItem.genericRegistry.genericDisplayName,
                    id: filterItem.genericRegistry.genericId,
                };
            }
        });
        itemList.sort((a, b) => a.text.localeCompare(b.text));
        setGenericList(itemList);
        if (selectedItem && !selectedGenericDDValue)
            setSelectedGenericDropdownLocalValue(selectedItem);
    };

    const createInstanceDropdownList = (selectedGenericDropdownLocalValue) => {
        http.sendRestRequest(
            `documentChain/instance/list?genericId=${selectedGenericDropdownLocalValue.id}`
        )
            .then((instanceData) => {
                let instances = instanceData;
                let instanceList = [];
                let selectedItem = null;
                instances.sort((a, b) =>
                    a.instanceName.localeCompare(b.instanceName)
                );
                instances.forEach((instance) => {
                    instanceList.push({
                        text: instance.instanceName,
                        value: instance.instanceName,
                        id: instance.instanceId,
                    });
                    if (
                        flowInfo &&
                        flowInfo.instanceName === instance.instanceName
                    ) {
                        selectedItem = {
                            text: instance.instanceName,
                            value: instance.instanceName,
                            id: instance.instanceId,
                        };
                    }
                });
                setInstanceList(instanceList);
                if (selectedItem) {
                    setSelectedInstanceDropdownLocalValue(selectedItem);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const createBranchDropdownList = (instanceDetails) => {
        const filteredInstances = instanceDetails.filter(
            (item) => item.instanceId === selectedInstanceDropdownLocalValue.id
        );
        let branchList = filteredInstances
            .filter((instance) => instance.branchName !== "")
            .map((instance) => {
                return {
                    text: instance.branchName,
                    value: instance.branchName,
                    id: instance.instanceId,
                    branchId: instance.branchId,
                };
            });
        const tempBranchList = branchList.map((branch) => branch.text);
        branchList = tempBranchList
            .filter((branch, index) => tempBranchList.indexOf(branch) === index)
            .map((branch) => ({
                text: branch,
                value: branch,
                id: branchList.find((item) => item.text === branch).id,
            }));
        setBranchList(branchList);
        let selectedItem = null;
        if (flowInfo && flowInfo.branchName) {
            if (
                branchList.find((branch) => branch.text === flowInfo.branchName)
            ) {
                selectedItem = {
                    text: flowInfo.branchName,
                    value: flowInfo.branchName,
                    id: flowInfo.branchName,
                };
                setSelectedBranchDropdownLocalValue(selectedItem);
            }
        }
    };

    useEffect(() => {
        if (
            selectedGenericDropdownLocalValue &&
            selectedInstanceDropdownLocalValue &&
            selectedStepDropdownLocalValue &&
            selectedGenericDropdownLocalValue.hasOwnProperty("id") &&
            selectedInstanceDropdownLocalValue.hasOwnProperty("id") &&
            selectedStepDropdownLocalValue.hasOwnProperty("id")
        ) {
            setDisableApply("disable");
        } else setDisableApply("");
    }, [
        selectedGenericDropdownLocalValue,
        selectedInstanceDropdownLocalValue,
        selectedStepDropdownLocalValue,
    ]);
    const handleGenericSelection = (index, selectedGeneric) => {
        setSelectedGenericDropdownLocalValue(selectedGeneric);
        dispatch(setSelectedGenericDropdownValue(selectedGeneric));
        setSelectedBranchDropdownLocalValue([]);
        setSelectedInstanceDropdownLocalValue([]);
        setSelectedStepDropdownLocalValue([]);
        setSelectedRow(selectedGeneric);
        setLoader(true);
        http.sendRestRequest(
            `documentChain/generic/stepList?genericId=${selectedGeneric.id}`
        )
            .then((result) => {
                setGenericSteps(result);
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
                console.log(error);
            });
    };
    const handleInstancesSelection = (index, selectedInstances) => {
        setSelectedInstanceDropdownLocalValue(selectedInstances);
        setSelectedStepDropdownLocalValue([]);
        setSelectedBranchDropdownLocalValue([]);
    };

    const handleStepSelection = (index, selectedSteps) => {
        setSelectedStepDropdownLocalValue(selectedSteps);
    };
    return (
        <ModalView
            showHeader="false"
            footer="false"
            show={showDocumentModal}
            size="md"
            onHide={onCloseInstanceModal}
            title="Apply"
            id="btn_folder_submit"
            modalId={module === "workSpace" ? "add_Document_Modal" : ""}
            onSubmit={handleSubmit}
            value="Name"
            onclick={onCloseInstanceModal}
            type="submit-cancel"
            submitButtonText="Close"
            disable={disableApply !== "" ? "false" : ""}
        >
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                {/* <img src="./images/Space 2.svg"></img> */}
                <div
                    style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "20px",
                        lineHeight: "27px",
                        color: "#404040",
                        width: "430px",
                        marginTop: "-30px",
                        marginBottom: "28px",
                        marginRight: "-45px",
                    }}
                >
                    Attach Document to Flow Step
                </div>
                <div className="flex-center" style={{ width: "75%" }}>
                    <CustomSearchDropdown
                        items={genericList}
                        label={"Select Flow"}
                        id={"btn_select_generic"}
                        idInfo={"generic"}
                        multiSelect={false}
                        isSearchable={true}
                        isChipView={false}
                        handleSelection={handleGenericSelection}
                        selection={selectedGenericDropdownLocalValue}
                        customClassName={classes.root}
                        customWidth={92}
                    />
                    <CustomSearchDropdown
                        items={instanceList}
                        label={"Select Instance"}
                        id={"btn_select_instance"}
                        idInfo={"instance"}
                        multiSelect={false}
                        isSearchable={true}
                        isChipView={false}
                        disable={genericSteps.length === 0 ? true : false}
                        handleSelection={handleInstancesSelection}
                        selection={selectedInstanceDropdownLocalValue}
                        customClassName={classes.root}
                        customWidth={124}
                        openAddInstanceModal={openAddInstanceModal}
                        showInstanceCreate={
                            genericList.length &&
                            selectedGenericDropdownLocalValue
                                ? true
                                : false
                        }
                    />
                    <CustomSearchDropdown
                        items={branchList}
                        label={"Select Branch"}
                        id={"btn_select_generic"}
                        idInfo={"generic"}
                        multiSelect={false}
                        isSearchable={true}
                        isChipView={false}
                        disable={genericSteps.length === 0 ? true : false}
                        handleSelection={handleBranchSelection}
                        selection={selectedBranchDropdownLocalValue}
                        customClassName={classes.root}
                        dropdownWidth={400}
                        customWidth={127}
                    />
                    <CustomSearchDropdown
                        items={StepList}
                        label={"Select Step"}
                        id={"btn_select_transaction"}
                        idInfo={"generic"}
                        multiSelect={false}
                        isSearchable={true}
                        isChipView={false}
                        disable={genericSteps.length === 0 ? true : false}
                        handleSelection={handleStepSelection}
                        selection={selectedStepDropdownLocalValue}
                        customClassName={classes.root}
                        customWidth={127}
                    />
                </div>
            </div>
            <Loader show={loader} />
        </ModalView>
    );
};

export default AddDocuments;
