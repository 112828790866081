import * as React from "react";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

export default function FileSelectionTree({
    data,
    selectHandler,
    setSelected,
    setExpanded,
    expanded,
    selected,
    checkFolder,
}) {
    const newImage = "./images/spaceIcon.png";
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };

    const getChildren = (children, workspaceId, spaceId) => {
        return children.map((child) => (
            <TreeItem
                nodeId={child.id}
                title={child.name}
                label={<span className="textEllipsis">{child.name}</span>}
                key={child.id}
                workspaceId={workspaceId}
                spaceId={spaceId}
                icon={
                    child.documentId === -1 ? (
                        <>
                            <ChevronRightIcon />
                            <img
                                src="./images/fol1.svg"
                                alt=""
                                style={{ paddingRight: "20px" }}
                            />
                        </>
                    ) : (
                        <>
                            <img
                                src="./images/Subtract.png"
                                alt=""
                                style={{ paddingRight: "20px" }}
                            />
                        </>
                    )
                }
                onClick={() => {
                    checkFolder
                        ? selectHandler({ ...child, parentId: spaceId })
                        : selectHandler(
                              workspaceId,
                              spaceId,
                              child.id,
                              child.name
                          );
                }}
            >
                {child.children &&
                    getChildren(child.children, workspaceId, spaceId)}
            </TreeItem>
        ));
    };
    return (
        <Box sx={{ minHeight: 270, flexGrow: 1 }}>
            <TreeView
                aria-label="controlled"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
            >
                {data?.length &&
                    data.map((item) => (
                        <TreeItem
                            key={item.id}
                            nodeId={item.id}
                            title={item.name}
                            label={
                                <span className="textEllipsis">
                                    {item.name}
                                </span>
                            }
                            icon={
                                item.documentId === -1 ? (
                                    <>
                                        <ChevronRightIcon />
                                        <img
                                            src="./images/fol1.svg"
                                            alt=""
                                            style={{ paddingRight: "20px" }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <img
                                            src="./images/Subtract.png"
                                            alt=""
                                            style={{ paddingRight: "20px" }}
                                        />
                                    </>
                                )
                            }
                            onClick={() => {
                                checkFolder
                                    ? selectHandler({ ...item, parentId: 0 })
                                    : selectHandler(item.id);
                            }}
                        >
                            {item.children.map((child) => (
                                <TreeItem
                                    className=""
                                    nodeId={child.id}
                                    title={child.name}
                                    label={
                                        <span className="textEllipsis">
                                            {child.name}
                                        </span>
                                    }
                                    key={child.id}
                                    workspaceId={item.id}
                                    onClick={() => {
                                        checkFolder
                                            ? selectHandler({
                                                  ...child,
                                                  parentId: item.id,
                                              })
                                            : selectHandler(item.id, child.id);
                                    }}
                                    icon={
                                        child.documentId === -1 ? (
                                            <>
                                                <ChevronRightIcon />
                                                <img
                                                    src="./images/fol1.svg"
                                                    alt=""
                                                    style={{
                                                        paddingRight: "20px",
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src="./images/Subtract.png"
                                                    alt=""
                                                    style={{
                                                        paddingRight: "20px",
                                                    }}
                                                />
                                            </>
                                        )
                                    }
                                >
                                    {child.children &&
                                        getChildren(
                                            child.children,
                                            item.id,
                                            child.id
                                        )}
                                </TreeItem>
                            ))}
                        </TreeItem>
                    ))}
            </TreeView>
        </Box>
    );
}

const styles = {
    treeView: {
        padding: "10px",
    },
    treeIcon: {
        paddingRight: "10px",
    },
    textEllipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "200px",
        display: "inline-block",
        paddingLeft: "23px",
        verticalAlign: "middle",
    },
    treeItem: {
        padding: "5px 0",
        ".MuiTreeItem-label": {
            display: "flex",
            alignItems: "center",
            padding: "5px 10px",
            borderRadius: "4px",
            transition: "background-color 0.3s ease",
            // ":hover": {
            //     backgroundColor: "rgba(0, 0, 0, 0.1)",
            // },
        },
        ".css-1dewol9-MuiTreeItem-root .MuiTreeItem-content.Mui-selected .MuiTreeItem-label":
            {
                backgroundColor: "none",
            },
        // ".MuiTreeItem-content.Mui-selected .MuiTreeItem-label": {
        //     backgroundColor: "rgba(0, 0, 255, 0.1)",
        // },
    },
};
