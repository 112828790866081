import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useMemo, useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchFilterData,
    loadDashboardData,
    openInstance,
    setDashboardAlertData,
    setGenericIdToDisplayNameMap,
    setGenericIdToNameMap,
} from "../../../../../../actions/DashboardActions";
import http from "../../../../../../bae-client/HTTP";
import AlertDialog from "../../../../../stylized/AlertDialog";
import DocViwer from "../../../../../stylized/DocViwer";
import Loader from "../../../../../stylized/Loader";
import DocumentViewerModal from "../../../../../stylized/DocumentViewerModal";
import TimelineView from "../../../TimlelineView";
import DashboardAlert from "./dashboardAlert";
import DocumentListView from "./DocumentListView";
import HeaderView1 from "./HeaderSection/HeaderView1";
import ControlTowerView from "../../../ControlTower/ControlTowerContainer/ControlTowerView";
import ControlTowerMap from "../../../ControlTower/ControlTowerContainer/ControlTowerMap/ControlTowerMap";
import "./index.css";
import LineChart from "./LineChart";
import StatsCard from "./StatsCard";
import TableCard from "./TableCard";
import CustomTable from "../aggr-dashboard/table";
import { setSelectedFlows } from "../../../../../../actions/DocumentChainActions";
import { setSnackbarAction } from "../../../../../../services/services/app";
const mockData = {
    initiated: {
        amount: "750",
        perc: "50",
        subtitle: "Compared to 659 last year.",
    },
};

const Dashboard = ({ controlTowerDashboard }) => {
    const [showLoader, setShowLoader] = useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertState, setAlertState] = useState([]);
    const [alertSteps, setAlertSteps] = useState({});
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const dashboardData = useSelector((state) => state.DashboardData);
    const filterRawData = useSelector((state) => state.DashboardDCFilterData);
    const genericIdToNameMap = useSelector((state) => state.genericIdToNameMap);
    const selectedTimeFilter = useSelector(
        (state) => state.selectedTimeModelIndex
    );
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [currentShowFileLocation, setCurrentShowFileLocation] = useState("");
    const [documentViewType, setDocumentViewType] = useState("grid");
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [timeLineData, setTimelineData] = useState([]);
    const [filterData, setFilterData] = useState(null);
    const [genericId, setGenericId] = useState(null);
    const [ctMapView, setCTMapView] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [alertDisableDate, setAlertDisableDate] = useState({});

    const renderViewDocumentModal = () => {
        return (
            <DocViwer
                docName={selectedDocument}
                fileLocation={currentShowFileLocation}
            ></DocViwer>
        );
    };
    const closeDocumentDisplayModal = () => {
        setShowDocumentModal(false);
        setCurrentShowFileLocation("");
    };

    const openInstancePage = (data) => {
        const libData = {
            genericId: data.genericId,
            genericName: genericIdToNameMap.get(data.genericId),
            instanceIds: data.instanceIds,
        };
        dispatch(openInstance(libData));
    };
    const processedData = useMemo(() => {
        if (dashboardData && dashboardData.genericElements.length) {
            return dashboardData.genericElements
                .filter((item) => item.stepOrder !== -1)
                .sort((a, b) => a.stepOrder - b.stepOrder);
        }
        return [];
    }, [dashboardData]);
    const processedDataLineChart = useMemo(() => {
        if (dashboardData && dashboardData.genericElements.length) {
            return dashboardData.genericElements
                .filter(
                    (item) =>
                        item.stepOrder !== -1 &&
                        item.genericStepName !== "Email"
                )
                .sort((a, b) => a.stepOrder - b.stepOrder);
        }
        return [];
    }, [dashboardData]);
    const closeAlert = (data) => {
        setShowLoader(true);
        const values = Object.keys(data).map((id) => ({
            genericId: alertState.find(
                (alert) => alert.exceptionLogObjectId === parseInt(id)
            ).genericId,
            exceptionLogObjectId: parseInt(id),
            status: "CLOSED",
        }));

        return new Promise((resolve, reject) => {
            let url = "documentChain/exception/status";
            http.sendRestRequest(url, "PUT", values)
                .then((documentObjects) => {
                    resolve(documentObjects);
                    http.sendRestRequest(
                        "documentChain/dashboardData",
                        "POST",
                        filterData
                    )
                        .then((response) => {
                            response.exceptions.sort((a, b) => {
                                return (
                                    Date.parse(b.createdOn) -
                                    Date.parse(a.createdOn)
                                );
                            });
                            setAlertSteps(response.exceptionStepNames);
                            const filteredAlert = response.exceptions.filter(
                                (alert) => alert.status !== "CLOSED"
                            );
                            setAlertState(filteredAlert);
                            createMapGenericIdToName();
                            if (response && response.exceptions) {
                                dispatch(
                                    setDashboardAlertData(response.exceptions)
                                );
                            } //alert data in redux store
                            if (response && response.instanceElements)
                                dispatch(loadDashboardData(response));
                            setShowLoader(false);
                            dispatch(
                                setSnackbarAction({
                                    open: true,
                                    message: "Alert closed successfully!",
                                    severity: "success",
                                })
                            );
                        })
                        .catch((error) => {
                            setShowLoader(false);
                            setShowAlertDialog(true);
                            setAlertDialogMessage(error.error);
                        });
                })
                .catch((err) => {
                    setShowLoader(false);
                    console.log(err);
                    reject(err);
                });
        });
    };
    const openDocument = (document, docId) => {
        let documentId = docId ? docId : document;
        setShowLoader(true);
        http.sendRestRequest(`document/documentDetail?documentId=${documentId}`)
            .then((response) => {
                setSelectedDocument(response.documentName);
                setShowDocumentModal(true);
                setShowLoader(false);
                const fileType = response.documentName.split(".").pop();
                if (fileType === "pdf") {
                    setTimeout(() => {
                        setCurrentShowFileLocation(response);
                    }, 1);
                } else {
                    setCurrentShowFileLocation(response);
                }
            })
            .catch((error) => {
                setShowLoader(false);
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };

    const udpateFilterData = async () => {
        setShowLoader(true);
        http.sendRestRequest("documentChain/objects")
            .then((response) => {
                dispatch(fetchFilterData(response));
                let genericIdToDisplayNameMap = new Map();
                let flows = response.map((item) => item.genericRegistry);
                setShowLoader(false);
                flows.map((item) =>
                    genericIdToDisplayNameMap.set(
                        item.genericId,
                        item.genericDisplayName
                    )
                );
                dispatch(
                    setGenericIdToDisplayNameMap(genericIdToDisplayNameMap)
                );
                const flowNames = flows.map((flow) => flow.genericDisplayName);
                dispatch(setSelectedFlows(flowNames));
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };
    useEffect(() => {
        udpateFilterData();
    }, []);
    useEffect(() => {
        if (selectedTimeFilter && !filterData) {
            setFilterData(selectedTimeFilter);
        }
    }, [selectedTimeFilter]);

    useEffect(() => {
        if (dashboardData) {
            dashboardData.instanceElements.sort((a, b) => {
                return Date.parse(a.actualDate) - Date.parse(b.actualDate);
            });

            setData(dashboardData);
            setAlertSteps(dashboardData.exceptionStepNames);
            const filteredAlert = dashboardData.exceptions.filter(
                (alert) => alert.status !== "CLOSED"
            );
            setAlertState(filteredAlert);

            let newArray = dashboardData.instanceElements.map((ele) => {
                return {
                    document: ele.instanceBlockDocumentName,
                    date: ele.actualDate,
                    documentId: ele.instanceBlockDocumentId,
                    genericName: genericIdToNameMap.get(ele.genericId),
                    genericStepName: ele.instanceStepName,
                    genericId: ele.genericId,
                    instanceId: ele.instanceId,
                };
            });
            setTimelineData(
                newArray.sort(function (a, b) {
                    return new Date(a.date) - new Date(b.date);
                })
            );
        }
    }, [dashboardData]);
    useEffect(() => {
        return () => {
            setData(null);
            setAlertSteps({});
            setAlertState([]);
            setTimelineData([]);
            setGenericId(null);
            setFilterData(null);
        };
    }, []);
    const changeOpenDialogFlag = (value) => {
        setShowAlertDialog(value);
        setAlertDialogMessage("");
    };
    const createMapGenericIdToName = () => {
        let genericIdToNameMap = new Map();
        let genericIdToDisplayNameMap = new Map();
        filterRawData.forEach((filterItem) => {
            genericIdToNameMap.set(
                filterItem.genericRegistry.genericId,
                filterItem.genericRegistry.genericDisplayName
            );
            genericIdToDisplayNameMap.set(
                filterItem.genericRegistry.genericId,
                filterItem.genericRegistry.genericDisplayName
            );
        });
        dispatch(setGenericIdToNameMap(genericIdToNameMap));
        dispatch(setGenericIdToDisplayNameMap(genericIdToDisplayNameMap));
    };
    const clearDashboardData = () => {
        dispatch(setDashboardAlertData([]));
        dispatch(
            loadDashboardData({
                instanceElements: [],
                genericElements: [],
                instances: [],
                contacts: [],
                elementPairs: [],
                exceptions: [],
                instancesInitiated: 0,
                instancesCompleted: 0,
                instancesDelayed: 0,
                totalDocuments: 0,
                totalProactiveStepAlerts: 0,
                totalAlerts: 0,
                totalDelayedAlerts: 0,
                totalSKippedAlerts: 0,
            })
        );
    };
    const hanldeFilterSubmit = (filterData) => {
        if (controlTowerDashboard === undefined) {
            setFilterData(filterData);
            setShowLoader(true);
            http.sendRestRequest(
                "documentChain/dashboardData",
                "POST",
                filterData
            )
                .then((response) => {
                    response.exceptions.sort((a, b) => {
                        return (
                            Date.parse(b.createdOn) - Date.parse(a.createdOn)
                        );
                    });
                    setAlertSteps(response.exceptionStepNames);
                    const filteredAlert = response.exceptions.filter(
                        (alert) => alert.status !== "CLOSED"
                    );
                    setAlertState(filteredAlert);
                    createMapGenericIdToName();
                    if (response && response.exceptions) {
                        dispatch(setDashboardAlertData(response.exceptions));
                    } //alert data in redux store
                    if (response && response.instanceElements)
                        dispatch(loadDashboardData(response));
                    setShowLoader(false);
                })
                .catch((error) => {
                    setShowLoader(false);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                });
        } else {
            setGenericId(filterData.genericId);
            setFilterData(null);
        }
    };
    const dashboardProperties = [
        { title: "Total", amount: "totalAlerts" },
        { title: "Skipped", amount: "totalSKippedAlerts" },
        { title: "Delayed", amount: "totalDelayedAlerts" },
        // { title: "Proactive", amount: "totalProactiveAlerts" },
    ];
    const proActiveProperties = [
        { title: "Instances", amount: "totalProactiveInstanceAlerts" },
        { title: "Steps", amount: "totalProactiveStepAlerts" },
    ];
    const statsCardProperties = [
        { title: "Total Documents", amount: "totalDocuments" },
        { title: "Total Initiated​ Instances", amount: "instancesInitiated" },
        { title: "Total On Time​ Instances", amount: "instancesDelayed" },
        { title: "Total Delayed​ Instances", amount: "instancesDelayed" },
        { title: "Total Completed​ Instances", amount: "instancesCompleted" },
    ];
    return (
        <div id="mainDashboard" className="invisibleScroller ">
            <div>
                <div className="Header_dashboard">
                    <HeaderView1
                        handleSubmitFilter={hanldeFilterSubmit}
                        setFilterData={setFilterData}
                        clearDashboardData={clearDashboardData}
                        exceptionSteps={alertSteps}
                        alertData={alertState}
                        setAlertDisableDate={setAlertDisableDate}
                    ></HeaderView1>
                </div>
                <Col className="timeline_dashboard">
                    <div className="row m-0 w-100 timelineTitleRow">
                        <div className="documentTitle">
                            {controlTowerDashboard ? (
                                ctMapView ? (
                                    <span>Control Tower Map</span>
                                ) : (
                                    <span>Control Tower Diagram</span>
                                )
                            ) : (
                                <>
                                    <span
                                        id="documentTimeLine"
                                        className="titles"
                                    >
                                        Document Timeline
                                    </span>
                                    {timeLineData.length > 50 && (
                                        <span
                                            style={{
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                marginLeft: "10px",
                                            }}
                                        >
                                            &#40;Narrow Filters Above to View
                                            More&#41;
                                        </span>
                                    )}
                                </>
                            )}
                        </div>
                        {controlTowerDashboard ? (
                            ctMapView ? (
                                <div
                                    className="documentViewToggel cursorPointer"
                                    onClick={() => setCTMapView(false)}
                                >
                                    <img
                                        style={{
                                            height: "16px",
                                            width: "20px",
                                        }}
                                        src="./images/listViewIcon.svg"
                                        alt=""
                                    />
                                    <span className="ml-2">CT Diagram</span>
                                </div>
                            ) : (
                                <div
                                    className="documentViewToggel cursorPointer"
                                    onClick={() => setCTMapView(true)}
                                >
                                    <img
                                        style={{
                                            height: "16px",
                                            width: "20px",
                                        }}
                                        src="./images/listViewIcon.svg"
                                        alt=""
                                    />
                                    <span className="ml-2">CT Map</span>
                                </div>
                            )
                        ) : documentViewType === "grid" ? (
                            <div
                                className="documentViewToggel cursorPointer"
                                id="list-view-btn"
                                onClick={() => setDocumentViewType("list")}
                            >
                                <img
                                    style={{ height: "16px", width: "20px" }}
                                    src="./images/listViewIcon.svg"
                                    alt=""
                                />
                                <span className="ml-2 subTitles">
                                    List View
                                </span>
                            </div>
                        ) : (
                            <div
                                className="documentViewToggel cursorPointer"
                                onClick={() => setDocumentViewType("grid")}
                            >
                                <img
                                    style={{ height: "16px", width: "20px" }}
                                    src="./images/timeline-icon.svg"
                                    alt=""
                                />
                                <span className="ml-2 subTitles">
                                    Timeline View
                                </span>
                            </div>
                        )}
                    </div>
                    {controlTowerDashboard ? (
                        ctMapView ? (
                            <ControlTowerMap></ControlTowerMap>
                        ) : (
                            <ControlTowerView
                                settings={true}
                                genericId={genericId}
                            ></ControlTowerView>
                        )
                    ) : documentViewType === "grid" ? (
                        timeLineData && timeLineData.length ? (
                            <TimelineView
                                documentList={
                                    timeLineData.length > 50
                                        ? timeLineData.slice(0, 50)
                                        : timeLineData
                                }
                                setTimelineView={() => {}}
                                onNodeClick={openDocument}
                                openInstance={openInstancePage}
                                closeAlert={closeAlert}
                                timelineExpanded={true}
                                marginMultiplier={
                                    timeLineData.length > 12
                                        ? timeLineData.length % 12
                                        : 0
                                }
                                hideBorder={true}
                                rippleTimeline={true}
                                isFlowTimeline={true}
                            ></TimelineView>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    marginTop: "40px",
                                }}
                            >
                                <div
                                    className="headings-flow"
                                    style={{
                                        paddingBottom: "20px",
                                        color: "#9da5b4",
                                    }}
                                >
                                    Select Filters Above to View Timeline
                                </div>
                                <div>
                                    <img
                                        src="./images/emptyTimeline.svg"
                                        className="img-flow-dash"
                                    ></img>
                                </div>
                            </div>
                        )
                    ) : timeLineData && timeLineData.length ? (
                        <DocumentListView
                            openDocument={openDocument}
                            openInstance={openInstancePage}
                            list={
                                dashboardData
                                    ? dashboardData.instanceElements
                                    : []
                            }
                        ></DocumentListView>
                    ) : (
                        <div
                            style={{
                                textAlign: "center",
                                marginTop: "40px",
                            }}
                        >
                            <div
                                className="headings-flow"
                                style={{
                                    paddingBottom: "20px",
                                    color: "#9da5b4",
                                }}
                            >
                                Select Filters Above to View Timeline
                            </div>
                            <div>
                                <img
                                    src="./images/emptyTimeline.svg"
                                    className="img-flow-dash"
                                ></img>
                            </div>
                        </div>
                    )}
                </Col>
                <>
                    <div className="new-alert">
                        <div className="dashboard_items">
                            <DashboardAlert
                                closeAlert={closeAlert}
                                openInstance={openInstancePage}
                                alertData={
                                    alertState.length > 50
                                        ? alertState.slice(0, 50)
                                        : alertState
                                }
                                setAlertData={setAlertState}
                                showHint={alertState.length > 50 ? true : false}
                                instanceList={
                                    dashboardData ? dashboardData.instances : []
                                }
                                alertDisableDate={alertDisableDate}
                            />
                        </div>
                        <div className="cardContainer">
                            {statsCardProperties.map((item, index) => {
                                return (
                                    <div
                                        style={{
                                            borderBottom: "1px solid #cfc6c6",
                                        }}
                                    >
                                        <StatsCard
                                            title={item.title}
                                            amount={
                                                index === 2
                                                    ? Math.max(
                                                          (data &&
                                                              data[
                                                                  "instancesCompleted"
                                                              ]) -
                                                              (data &&
                                                                  data[
                                                                      "instancesDelayed"
                                                                  ]),
                                                          0
                                                      )
                                                    : data &&
                                                      data[item.amount] > 0
                                                    ? data[item.amount]
                                                    : 0
                                            }
                                            perc={mockData.initiated.perc}
                                            subtitle={
                                                mockData.initiated.subtitle
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div style={{ width: "21%" }}>
                            <div
                                className="dashboard_col dashboard_cards"
                                style={{ width: "100%" }}
                            >
                                {/* <div style={{ borderBottom: "1px solid #cfc6c6" }}>
                                    <StatsCard title={"Proactive Alerts"} />
                                </div> */}
                                <div
                                    style={{
                                        borderBottom: "1px solid #cfc6c6",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "15px",
                                        height: "63px",
                                    }}
                                >
                                    {/* <StatsCard title={"Step Alerts"} /> */}
                                    <div style={{ marginRight: "15px" }}>
                                        <img
                                            style={{ width: "36px" }}
                                            src="./images/Alert.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                            lineHeight: "20px",
                                            letterSpacing:
                                                "0.15000000596046448px",
                                            textAlign: "left",
                                        }}
                                    >
                                        ProActive Alerts
                                    </div>
                                </div>

                                <div>
                                    {proActiveProperties.map((item) => {
                                        return (
                                            <div
                                                id="table_Proactive_alerts"
                                                style={{
                                                    borderBottom:
                                                        "1px solid #cfc6c6",
                                                }}
                                            >
                                                <TableCard
                                                    isProActive={true}
                                                    title={item.title}
                                                    amount={
                                                        dashboardData &&
                                                        dashboardData[
                                                            item.amount
                                                        ] >= 0
                                                            ? dashboardData[
                                                                  item.amount
                                                              ]
                                                            : "0"
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div
                                className="dashboard_col dashboard_cards"
                                style={{ width: "100%" }}
                            >
                                <div
                                    style={{
                                        borderBottom: "1px solid #cfc6c6",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "15px",
                                        height: "63px",
                                    }}
                                >
                                    {/* <StatsCard title={"Step Alerts"} /> */}
                                    <div style={{ marginRight: "15px" }}>
                                        <img
                                            style={{ width: "36px" }}
                                            src="./images/fileTypeIcon/stepAlert.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                            lineHeight: "20px",
                                            letterSpacing:
                                                "0.15000000596046448px",
                                            textAlign: "left",
                                        }}
                                    >
                                        Step Alerts
                                    </div>
                                </div>
                                <div>
                                    {dashboardProperties.map((item) => {
                                        return (
                                            <div
                                                id="table_alerts"
                                                style={{
                                                    borderBottom:
                                                        "1px solid #cfc6c6",
                                                }}
                                            >
                                                <TableCard
                                                    isProActive={true}
                                                    title={item.title}
                                                    amount={
                                                        dashboardData &&
                                                        dashboardData[
                                                            item.amount
                                                        ] >= 0
                                                            ? dashboardData[
                                                                  item.amount
                                                              ]
                                                            : "0"
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                <div className="row">
                    <div className="col-12">
                        <div className="dashboard_items1">
                            <span className="dashboard-doc">Step Summary</span>
                            <div
                                className="invisibleScroller"
                                style={{
                                    width: "1106px",
                                    padding: "10px 1px 1px",
                                    width: "100%",
                                    position: "relative",
                                    overflowX: "hidden",
                                }}
                            >
                                {dashboardData &&
                                dashboardData.genericElements.length ? (
                                    <CustomTable
                                        height={"275px"}
                                        spaceData={processedData}
                                        type="report"
                                        setSelectedItems={setSelectedItems}
                                        popup
                                        className="dash-flow"
                                    ></CustomTable>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            textAlign: "center",
                                            margin: "70px",
                                        }}
                                    >
                                        <div
                                            className="img-flow-alert"
                                            style={{ paddingBottom: "20px" }}
                                        >
                                            <img
                                                src="./images/q11.svg"
                                                alt=""
                                            />
                                        </div>
                                        <div className="grey-head ">
                                            Select Filters Above <br></br>to
                                            View Documents
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="dashboard_chart"
                    style={{ paddingBottom: "43px" }}
                >
                    <div className="dashboard_chart-items">
                        <Col className="dashboard_graph">
                            {data && data.genericElements.length ? (
                                <LineChart
                                    data={timeLineData}
                                    type={"Spike"}
                                    filterData={filterData}
                                />
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        textAlign: "center",
                                        marginTop: "50px",
                                    }}
                                >
                                    <div
                                        className="img-flow-alert"
                                        style={{ paddingBottom: "20px" }}
                                    >
                                        <img src="./images/g1.svg" alt="" />
                                    </div>
                                    <div className="grey-head">
                                        Select Filters Above <br></br>to View
                                        Chart
                                    </div>
                                </div>
                            )}
                        </Col>
                    </div>
                    <div className="dashboard_chart-items">
                        <Col className="dashboard_graph">
                            {data && data.genericElements.length ? (
                                <LineChart
                                    data={processedDataLineChart}
                                    type={"Performance"}
                                    filterData={filterData}
                                />
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        textAlign: "center",
                                        marginTop: "50px",
                                    }}
                                >
                                    <div
                                        className="img-flow-alert"
                                        style={{ paddingBottom: "20px" }}
                                    >
                                        <img src="./images/g1.svg" alt="" />
                                    </div>
                                    <div className="grey-head">
                                        Select Filters Above <br></br>to View
                                        Chart
                                    </div>
                                </div>
                            )}
                        </Col>
                    </div>
                </div>
            </div>
            {showDocumentModal ? (
                <DocumentViewerModal
                    showHeader={true}
                    footer="false"
                    documentId={currentShowFileLocation.documentId}
                    show={showDocumentModal}
                    size="lg"
                    onHide={closeDocumentDisplayModal}
                    title={selectedDocument}
                    onclick={() => {}}
                    submitButtonText="Close"
                    isChecked={true}
                    headerTitle={selectedDocument}
                    fileSource={currentShowFileLocation}
                >
                    {renderViewDocumentModal()}
                </DocumentViewerModal>
            ) : null}
            <Loader show={showLoader}></Loader>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
                setOpen={changeOpenDialogFlag}
            ></AlertDialog>
        </div>
    );
};

export default Dashboard;
