import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import http from "../../../bae-client/HTTP";

export default function ControlledTreeView({
    data,
    selectHandler,
    setSelected,
    setExpanded,
    expanded,
    selected,
}) {
    const newImage = "./images/spaceIcon.png";
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
        console.log(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
        console.log(nodeIds);
    };

    const getChildren = (children, workspaceId, spaceId) => {
        return children.map((child) => (
            <TreeItem
                nodeId={child.id}
                title={child.name}
                label={<span className="textEllipsis">{child.name}</span>}
                key={child.id}
                workspaceId={workspaceId}
                spaceId={spaceId}
                icon={
                    <>
                        <ChevronRightIcon />
                        <img
                            src="./images/fol1.svg"
                            alt=""
                            style={{ paddingRight: "20px" }}
                        />
                    </>
                }
                onClick={() =>
                    selectHandler(workspaceId, spaceId, child.id, child.name)
                }
            >
                {child.children &&
                    getChildren(child.children, workspaceId, spaceId)}
            </TreeItem>
        ));
    };
    return (
        <Box sx={{ minHeight: 270, flexGrow: 1 }}>
            <TreeView
                aria-label="controlled"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
            >
                {data.map((item) => (
                    <TreeItem
                        key={item.id}
                        nodeId={item.id}
                        title={item.name}
                        label={
                            <span className="textEllipsis">{item.name}</span>
                        }
                        icon={
                            item.type === "workspace" ? (
                                <>
                                    <ChevronRightIcon />
                                    <img
                                        src="./images/wk1.svg"
                                        alt=""
                                        style={{ paddingRight: "20px" }}
                                    />
                                </>
                            ) : null
                        }
                        onClick={() => selectHandler(item.id)}
                    >
                        {item.children.map((child) => (
                            <TreeItem
                                className=""
                                nodeId={child.id}
                                title={child.name}
                                label={
                                    <span className="textEllipsis">
                                        {child.name}
                                    </span>
                                }
                                key={child.id}
                                workspaceId={item.id}
                                onClick={() => selectHandler(item.id, child.id)}
                                icon={
                                    !child.children ||
                                    child.type === "space" ? (
                                        <>
                                            <ChevronRightIcon />
                                            <img
                                                src="./images/sp1.svg"
                                                alt=""
                                                style={{ paddingRight: "20px" }}
                                            />
                                        </>
                                    ) : null
                                }
                            >
                                {child.children &&
                                    getChildren(
                                        child.children,
                                        item.id,
                                        child.id
                                    )}
                            </TreeItem>
                        ))}
                    </TreeItem>
                ))}
            </TreeView>
        </Box>
    );
}
