import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./workspaceModal.css";
import { Avatar, Grid, Tooltip } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import http from "../../bae-client/HTTP";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Attribute from "./Attribute";
import AttributeContainer from "./AttributeContainer";
import { useUsersQuery } from "../../services/services/usersAPI";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function WorkspaceModalInfo({
    open,
    onHide,
    handleClose,
    heading,
    setSelectedInstance,
    selectedGenericInstances,
    handleRedirection,
    setShowMembersModal,
    isFlowInfoModal,
    selectedRow,
    breadcrumbData,
    rowsSelected,
    currentRows,
    activeSpace,
    activeFolder,
    allUsers,
    setAllUsers,
    setCurrentMembers,
    setMembersList,
    membersList,
    disableManageMembersBtn = false,
}) {
    const { data: users } = useUsersQuery();
    const dispatch = useDispatch();
    const [emailInfo, setEmailInfo] = React.useState("");
    const [spaceListValue, setSpaceListValue] = React.useState([]);
    const [displayViewData, setDisplayViewData] = React.useState([]);
    const [reload, setReload] = React.useState(true);

    const activeWorkspace = useSelector((state) => state.activeWorkspace);
    const spaces = useSelector((state) => state.spaceListData);
    const [attributesData, setAttributesData] = React.useState([]);

    React.useEffect(() => {
        if (activeWorkspace && activeWorkspace.workspaceName === "Emails") {
            let url = "emailServer/emailAddress";
            http.sendRestRequest(url, "GET")
                .then((resp) => {
                    setEmailInfo(resp);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [activeWorkspace]);

    React.useEffect(() => {
        if (isFlowInfoModal) {
            let url =
                "documentChain/instance/starred/list?genericId=" +
                selectedRow.genericId;

            http.sendRestRequest(url, "GET")
                .then((resp) => {
                    setSpaceListValue(resp);
                })
                .catch((err) => {
                    console.log(err);
                });
            let starredUrl = `userInteractions/flow/recentlyViewed?genericId=${selectedRow.genericId}`;
            http.sendRestRequest(starredUrl, "GET")
                .then((resp) => {
                    setDisplayViewData(resp);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            let starredURL =
                "workspace/starredObjects?workspaceId=" +
                activeWorkspace?.workspaceId;

            http.sendRestRequest(starredURL, "GET")
                .then((resp) => {
                    const merged = [...resp.spaces, ...resp.folders];
                    setSpaceListValue(merged);
                })
                .catch((err) => {
                    console.log(err);
                });
            let url =
                "userInteractions/workspace/recentlyViewed?workspaceId=" +
                activeWorkspace?.workspaceId;

            http.sendRestRequest(url, "GET")
                .then((resp) => {
                    setDisplayViewData(resp);
                })
                .catch((err) => {
                    console.log(err);
                });

            setAllUsers(users);
        }
    }, []);

    React.useEffect(() => {
        if (breadcrumbData) {
            if (breadcrumbData.length === 1) {
                http.sendRestRequest(
                    `share/users/workspace?workspaceId=${activeWorkspace.workspaceId}`
                )
                    .then((response) => {
                        const sharedUsers = response.map(
                            (user) => user.sharedUserId
                        );
                        setMembersList(
                            allUsers.filter((user) =>
                                sharedUsers.includes(user.userId)
                            )
                        );
                        setCurrentMembers(response);
                    })
                    .catch((erro) => console.log(erro));
            } else if (breadcrumbData.length === 2) {
                http.sendRestRequest(
                    `share/users/space?spaceId=${
                        activeSpace?.id || breadcrumbData[1]?.id
                    }`
                )
                    .then((response) => {
                        const sharedUsers = response.map(
                            (user) => user.sharedUserId
                        );
                        setMembersList(
                            allUsers.filter((user) =>
                                sharedUsers.includes(user.userId)
                            )
                        );
                        setCurrentMembers(response);
                    })
                    .catch((erro) => console.log(erro));
            } else if (breadcrumbData.length > 2) {
                http.sendRestRequest(
                    `share/users/folder?folderId=${
                        activeFolder?.id || breadcrumbData[2]?.id
                    }`
                )
                    .then((response) => {
                        const sharedUsers = response.map(
                            (user) => user.sharedUserId
                        );
                        setMembersList(
                            allUsers.filter((user) =>
                                sharedUsers.includes(user.userId)
                            )
                        );
                        setCurrentMembers(response);
                    })
                    .catch((erro) => console.log(erro));
            }
        }
    }, [breadcrumbData]);
    const close = () => {
        handleClose(false);
    };
    const handleMembers = () => {
        handleClose(false);
        if (isFlowInfoModal) {
            onHide(true);
        } else {
            setShowMembersModal(true);
        }
    };
    const renderData = () => {
        return displayViewData.length
            ? displayViewData.map((item, index) => {
                  let parsedDescription = {};
                  try {
                      if (item.description || item.description) {
                          parsedDescription = JSON.parse(
                              item.description || item.description
                          );
                      }
                  } catch (error) {
                      console.error("Error parsing JSON:", error);
                  }

                  const { objectName, objectType, objectId, spaceId } =
                      parsedDescription;
                  let imageSrc = "./images/shareFolder.png";
                  if (
                      (activeWorkspace?.workspaceName === "Emails" &&
                          objectType === "space") ||
                      (activeWorkspace?.workspaceName === "Emails" &&
                          objectType === "folder")
                  ) {
                      imageSrc = "./images/email.svg";
                  } else if (
                      activeWorkspace?.workspaceName === "Flow" &&
                      objectType === "space"
                  ) {
                      imageSrc = "./images/instance 1 1.svg";
                  } else if (objectType === "space") {
                      imageSrc = "./images/spaceIcon.png";
                  } else if (objectType === "folder") {
                      imageSrc = "./images/shareFolder.png";
                  } else if (objectType === "INSTANCE") {
                      imageSrc = "./images/Flow Instances.svg";
                  }

                  return objectType === "space" ||
                      objectType === "folder" ||
                      objectType === "INSTANCE" ? (
                      <div
                          id={"div_recentlyView_" + index}
                          key={index}
                          style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "1px 1px",
                              marginTop: "12px",
                              cursor: "pointer",
                          }}
                          onClick={(e) =>
                              onClickElement(
                                  e,
                                  objectType === "INSTANCE"
                                      ? {
                                            instanceId: objectId,
                                            instanceName: objectName,
                                        }
                                      : {
                                            spaceId: spaceId || objectId,
                                            objectId:
                                                objectType === "folder"
                                                    ? 0
                                                    : null,
                                            spacePersistenceId:
                                                objectType === "folder"
                                                    ? objectId
                                                    : null,
                                            folderRegistryId:
                                                objectType === "folder"
                                                    ? objectId
                                                    : null,
                                        },
                                  objectType === "INSTANCE" && "INSTANCE"
                              )
                          }
                      >
                          <img
                              src={imageSrc}
                              alt=""
                              style={{ width: "30px", height: "30px" }}
                          />
                          <Typography
                              variant="body1"
                              style={{ paddingLeft: "20px", width: "36%" }}
                              className="textEllipsis"
                              title={objectName}
                          >
                              {objectName}
                          </Typography>
                      </div>
                  ) : null;
              })
            : null;
    };

    const onClickElement = (event, node, type) => {
        if (node.hasOwnProperty("instanceName")) {
            let instance = selectedGenericInstances.find(
                (item) => item.instanceId === node.instanceId
            );
            setSelectedInstance(instance);
            handleClose(false);
        } else if (!node.folderRegistryId) {
            const space = spaces.find((item) => item.spaceId === node.spaceId);
            const breadCrumbItems = [
                {
                    id: activeWorkspace?.workspaceId,
                    name: activeWorkspace?.workspaceName,
                    type: "workspace",
                    parentId: 0,
                },
                {
                    id: space.spaceId,
                    name: space.spaceName,
                    type: "space",
                    parentId: activeWorkspace?.workspaceId,
                },
            ];
            handleRedirection(
                {
                    activeSpace: {
                        id: space.spaceId,
                        name: space.spaceName,
                        updatedOn: space.updatedOn,
                        createdOn: space.createdOn,
                        isShared: space.isShared ? "Shared" : "Personal",
                        isStarred: space.isStarred,
                        type: "space",
                        documentId: null,
                    },
                    breadCrumbItems: breadCrumbItems,
                    folderId: 0,
                    folderName: space.spaceName,
                },
                true
            );
        } else {
            const folderId = node.folderRegistryId;
            let folderName = "";
            http.sendRestRequest(`folders/folderDetails?folderId=${folderId}`)
                .then((resp) => {
                    const folderIdArray = resp.folderIdPath.split("/");
                    const folderNameArray = resp.folderNamePath.split("/");
                    let space = null;
                    const breadCrumbItems = [
                        {
                            id: activeWorkspace?.workspaceId,
                            name: activeWorkspace?.workspaceName,
                            type: "workspace",
                            parentId: 0,
                        },
                    ];
                    if (spaces.length) {
                        space = spaces.find(
                            (item) => item.spaceId === node.spaceId
                        );
                        breadCrumbItems.push({
                            id: space.spaceId,
                            name: space.spaceName,
                            type: "space",
                            parentId: activeWorkspace?.workspaceId,
                        });
                    }
                    folderIdArray.forEach((element, index) => {
                        if (parseInt(folderId) === parseInt(element)) {
                            folderName = folderNameArray[index];
                        }
                        breadCrumbItems.push({
                            id: parseInt(element),
                            name: folderNameArray[index],
                            type: "folder",
                            parentId:
                                index === 0
                                    ? 0
                                    : parseInt(folderIdArray[index - 1]),
                        });
                    });
                    const redirectionObject = {
                        breadCrumbItems: breadCrumbItems,
                        folderId: parseInt(folderId),
                        folderName,
                        type: "folder",
                        activeSpace: {
                            id: space.spaceId,
                            name: space.spaceName,
                            updatedOn: space.updatedOn,
                            createdOn: space.createdOn,
                            isShared: space.isShared ? "Shared" : "Personal",
                            isStarred: space.isStarred,
                            type: "space",
                            documentId: null,
                        },
                    };
                    handleRedirection(redirectionObject, type);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="invisibleScroller">
                    <div className="about">
                        <div>
                            {heading ? (
                                <p className="flow-heading">{heading}</p>
                            ) : null}
                            <p className="about_inside ">
                                {breadcrumbData?.length > 0
                                    ? breadcrumbData[breadcrumbData.length - 1]
                                          ?.name
                                    : null}
                            </p>
                        </div>
                        <div>
                            <img
                                onClick={close}
                                src="./images/cancel.svg"
                                style={{ cursor: "pointer" }}
                                alt=""
                            />
                        </div>
                    </div>
                    {activeWorkspace &&
                        activeWorkspace.workspaceName === "Emails" && (
                            <div
                                style={{
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    marginBottom: "1em",
                                    fontFamily: "Montserrat",
                                    lineHeight: "20px",
                                    letterSpacing: "-0.006em",
                                    textAlign: "left",
                                }}
                            >
                                Email ID: {emailInfo}
                            </div>
                        )}

                    <div className="members">
                        <div className="members-inside">
                            <span className="member">Members</span>

                            <p className="membersP" title="Manage Members">
                                <a
                                    style={{
                                        cursor: disableManageMembersBtn
                                            ? "not-allowed"
                                            : "pointer",
                                    }}
                                    onClick={
                                        disableManageMembersBtn
                                            ? null
                                            : handleMembers
                                    }
                                >
                                    Manage Members
                                </a>
                            </p>
                        </div>
                        <Grid container spacing={2} alignItems="center">
                            {membersList && membersList.length > 0
                                ? membersList.map((member) => {
                                      const { firstName, lastName, userEmail } =
                                          member;
                                      const avatar =
                                          firstName.charAt(0) +
                                          lastName.charAt(0);
                                      return (
                                          <Grid item>
                                              <Tooltip
                                                  title={
                                                      firstName + " " + lastName
                                                  }
                                                  arrow
                                              >
                                                  <Avatar
                                                      aria-label="user"
                                                      style={{
                                                          background: "#1B75BC",
                                                          height: "35px",
                                                          width: "35px",
                                                          fontSize: "1rem",
                                                          cursor: "pointer",
                                                          textAlign: "center",
                                                      }}
                                                  >
                                                      {avatar}
                                                  </Avatar>
                                              </Tooltip>
                                          </Grid>
                                      );
                                  })
                                : null}
                        </Grid>
                    </div>
                    <div className="starred" style={{ marginTop: "50px" }}>
                        <span
                            className="member"
                            style={{ marginBottom: "15px" }}
                        >
                            Starred
                        </span>
                        <div
                            className="stared-value invisibleScroller"
                            style={{ marginTop: "12px" }}
                        >
                            {spaceListValue.length
                                ? spaceListValue.map((element, index) => (
                                      <div
                                          id={"div_instnaceList_star_" + index}
                                          key={element.type}
                                          style={{
                                              display: "flex",
                                              marginBottom: "8px",
                                              alignItems: "center",
                                              cursor: "pointer",
                                          }}
                                          onClick={(e) =>
                                              onClickElement(e, element)
                                          }
                                      >
                                          <img
                                              src={
                                                  activeWorkspace?.workspaceName ===
                                                      "Flow" &&
                                                  !element.hasOwnProperty(
                                                      "spacePersistenceId"
                                                  )
                                                      ? "./images/instance 1 1.svg"
                                                      : activeWorkspace?.workspaceName ===
                                                        "Emails"
                                                      ? "./images/email.svg"
                                                      : element.instanceName
                                                      ? "./images/Flow Instances.svg"
                                                      : element.spaceName
                                                      ? "./images/spaceIcon.png"
                                                      : "./images/shareFolder.png"
                                              }
                                              style={{
                                                  width: "30px",
                                                  height: "30px",
                                              }}
                                              alt=""
                                          />
                                          <Typography
                                              variant="body1"
                                              style={{
                                                  paddingLeft: "20px",
                                                  width: "70%",
                                              }}
                                              className="textEllipsis"
                                              title={
                                                  (spaceListValue.spaceName !==
                                                      "" &&
                                                      element.spaceName) ||
                                                  (spaceListValue.folderName !==
                                                      "" &&
                                                      element.folderName) ||
                                                  element.instanceName ||
                                                  null
                                              }
                                          >
                                              {(spaceListValue.spaceName !==
                                                  "" &&
                                                  element.spaceName) ||
                                                  (spaceListValue.folderName !==
                                                      "" &&
                                                      element.folderName) ||
                                                  element.instanceName ||
                                                  null}
                                          </Typography>
                                          <div>
                                              <img
                                                  className="star"
                                                  src="./images/star.svg"
                                                  alt=""
                                              />
                                          </div>
                                      </div>
                                  ))
                                : null}
                        </div>
                    </div>

                    <div
                        className="recently-viewed"
                        style={{ marginTop: "25px", marginBottom: "15px" }}
                    >
                        <span className="member">Recently Viewed</span>
                        <div
                            className="invisibleScroller"
                            style={{ maxHeight: "21vh", marginTop: "12px" }}
                        >
                            {renderData()}
                        </div>
                    </div>
                    {breadcrumbData && breadcrumbData.length > 1 ? (
                        <div
                            className="recently-viewed"
                            style={{
                                marginRight: "16px",
                                marginLeft: "-16px",
                                marginBottom: "15px",
                            }}
                        >
                            <Accordion elevation={2}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    {"Attributes"}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div
                                        className="invisibleScroller"
                                        style={{
                                            maxHeight:
                                                attributesData?.length > 0 &&
                                                "200px",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <div className="attribute-container">
                                            <AttributeContainer
                                                options={breadcrumbData}
                                                reload={reload}
                                                setAttributesData={
                                                    setAttributesData
                                                }
                                                setReload={setReload}
                                                rowsSelected={rowsSelected}
                                                currentRows={currentRows}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Attribute
                                            options={breadcrumbData}
                                            reload={reload}
                                            setReload={setReload}
                                        />
                                    </div>
                                    {/* <div>
                                    <ButtonComponent
                                        value={"Add Attribute"}
                                        type={"ghost"}
                                        text={"Add Attribute"}
                                        icon={"./images/plus.svg"}
                                        BtnHandler={() => {}}
                                    />
                                </div> */}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ) : null}
                </Box>
            </Modal>
        </div>
    );
}
