import React, { useEffect } from "react";
import ModalView from "../../../../../stylized/ModalView";
import TextBox from "../../../../../stylized/TextBox";
import ChipsView from "../../../../../stylized/ChipsView";
import { validateName } from "../../../../../../bae-client/Validations";
import "./style.css";
import Inputs from "../../../../../Inputs";
import ChipNew from "../../../../../chip/ChipNew";
import { Chip } from "@mui/material";
import "./AddInstance.css";

const AddInstance = ({
    showAddInstanceModal,
    closeModal,
    disabled,
    steps,
    selectedInstance,
    currentFamilyTags,
    currentInstanceName,
    addNewInstance,
    branchsId,
    branchsName,
    heading,
    editNewInstance,
    selectedGenericInstances,
}) => {
    const [name, setName] = React.useState(currentInstanceName);
    const [branchId, setBranchId] = React.useState("1");
    const [branchName, setBranchName] = React.useState("1");
    const [updatedName, setUpdatedName] = React.useState("");
    const [tags, setTags] = React.useState("");
    const [emailError, setEmailError] = React.useState("");
    let [chipsArray, setChipsArray] = React.useState(currentFamilyTags);
    let [searchText, setsearchText] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errMessage, setErrMessage] = React.useState("");
    const [errMsg, setErrMsg] = React.useState("");
    const [showMore, setShowMoreFlag] = React.useState(false);
    const [branchIdChanged, setBranchIdChanged] = React.useState(false);

    useEffect(() => {
        setName(currentInstanceName);
        setChipsArray(currentFamilyTags);
        setBranchId(branchsId);
        setBranchName(branchsName);
    }, [currentInstanceName, currentFamilyTags, branchsId, branchsName]);
    // useEffect(() => {
    //     if (branchId === "") {
    //         setBranchId(1);
    //     }
    //     if (branchName === "") {
    //         setBranchName("Main");
    //     }
    // }, []);
    const onClickRippleChip = (text) => {
        searchText = text;
        onClickRipple();
    };
    const handleKeyDown = (value, key) => {
        if (value.trim() !== "") {
            createChip(value.trim());
        }
    };
    const onClickRipple = () => {
        if (searchText.trim() !== "") {
            createChip(searchText);
        } else {
            setEmailError("Please add at least one keyword to search.");
        }
    };

    const createChip = (value) => {
        if (value.trim() !== "") {
            let flag = false;
            chipsArray.forEach((item) => {
                if (item.label === searchText.trim()) {
                    flag = true;
                }
            });
            if (flag === false) {
                putTextInChips(searchText);
                searchText = "";
            }
            document.getElementById("familyTagsValue").value = "";
        }
    };
    const putTextInChips = (text) => {
        if (chipsArray.length === null || chipsArray.length === 0) {
            chipsArray.push({
                key: 0,
                label: text.trim(),
            });
        } else {
            let index = 0;
            index = chipsArray.length;
            chipsArray.push({
                key: index,
                label: text.trim(),
            });
        }
        setChipsArray((chipsArray) => [...chipsArray]);
    };

    const getChipsText = () => {
        let str = "";
        chipsArray.forEach((element, index) => {
            if (index < chipsArray.length - 1) {
                str = str + element.label + "&";
            } else {
                str = str + element.label;
            }
        });
        return str;
    };
    const handleDeleteChipItem = (chipToDelete) => {
        let items = chipsArray;
        let indexToRemove = items.indexOf(chipToDelete);
        items.splice(indexToRemove, 1);
        setChipsArray((items) => [...items]);
    };
    const renderClassForChips = () => {
        if (chipsArray.length <= 3) {
            return "col-" + chipsArray.length * 3;
        } else {
            return "col-9";
        }
    };

    // const handleSubmit_rfctrd = () => {
    //     if (searchText !== "") {
    //         createChip(searchText);
    //     }
    //     let strFamilyTags = getChipsText();
    //     const isValidName = validateName(name);

    //     if(isValidName !== true) {
    //         setErrorMessage(isValidName);
    //         console.log(errorMessage);
    //     } else {
    //         const instanceNames = selectedGenericInstances.map(
    //             (instanceName) =>
    //                 instanceName.instanceName.toUpperCase().trim()
    //         );
    //         if (
    //             instanceNames.includes(name.trim().toUpperCase())
    //         ) {
    //             setErrorMessage("Instance Name Already Exist");
    //         }
    //     }

    //     if(heading !== "Edit Instance") {
    //         if(branchId === "") {
    //             setErrMessage("This field should not be empty");
    //         }

    //         const isValidBranchName = validateName(branchName);
    //         if(isValidBranchName !== true) {
    //             setErrMsg(isValidBranchName);
    //         }
    //         if(!errorMessage && !errMessage && !errMsg) {
    //             addNewInstance(
    //                 name,
    //                 strFamilyTags,
    //                 branchId,
    //                 branchName
    //             );
    //         }
    //     } else {
    //         if(!errorMessage) {
    //             let data = {
    //                 status: selectedInstance.status,
    //                 instanceId: selectedInstance.instanceId,
    //                 instanceName: name,
    //                 familyTags: strFamilyTags,
    //             };
    //             editNewInstance(data);
    //         }
    //     }
    // }

    const handleSubmit = () => {
        const isValidName = validateName(name);
        const isValidBranchName = validateName(branchName);
        const isValidBranchId = validateName(branchId);

        if (typeof searchText === "string" && searchText !== "") {
            createChip(searchText);
        }

        let strFamilyTags = getChipsText();

        if (
            heading === "Add New Instance" &&
            branchId === "" &&
            name === "" &&
            branchName === ""
        ) {
            setErrMessage("This field should not be empty");
            setErrorMessage("This field should not be empty");
            setErrMsg("This field should not be empty");
        } else {
            if (heading === "Add New Instance" && branchId === "") {
                setErrMessage("This field should not be empty");
            } else {
                // Add type check for name
                if (
                    (heading === "Add New Instance" &&
                        isValidName === true &&
                        isValidBranchName === true &&
                        isValidBranchId === true) ||
                    (isValidName === true && heading === "Edit Instance")
                ) {
                    const instanceNames = selectedGenericInstances.map(
                        (instanceName) =>
                            instanceName.instanceName.toUpperCase().trim()
                    );

                    if (
                        typeof name === "string" &&
                        currentInstanceName !== name &&
                        instanceNames.includes(name.trim().toUpperCase())
                    ) {
                        setErrorMessage("Instance Name Already Exist");
                    } else if (heading === "Edit Instance") {
                        let data = {
                            status: selectedInstance.status,
                            instanceId: selectedInstance.instanceId,
                            instanceName: name,
                            familyTags: strFamilyTags,
                        };

                        editNewInstance(data);
                    } else {
                        addNewInstance(
                            name,
                            strFamilyTags,
                            branchId,
                            branchName
                        );
                        setErrMessage("");
                        setErrMsg("");
                        setBranchId("");
                        setBranchName("");
                    }
                } else {
                    setErrorMessage(isValidName);
                    setErrMsg(isValidBranchName);
                    setErrMessage(isValidBranchId);
                }
            }
        }
    };

    const setFromText = (value, id, event) => {
        searchText = value;
        setEmailError("");
    };
    const onChange = (value) => {
        if (errorMessage) setErrorMessage("");
        if (value.length === 1 && value === " ") setName(value.trim());
        else setName(value);
    };
    const onBranchChange = (value) => {
        if (value !== "") {
            setBranchIdChanged(true);
        } else {
            setBranchIdChanged(false);
        }
        if (errMessage) setErrMessage("");
        if (value.length === 1 && value === " ") setBranchId(value.trim());
        else setBranchId(value);
    };
    const onBranchNameChange = (value) => {
        if (errMsg) setErrMsg("");
        if (/[^a-zA-Z0-9\s]+/.test(value)) {
            setErrMsg("Special characters are not allowed in the name.");
        } else if (value.length === 1 && value === " ") {
            setBranchName(value.trim());
            if (
                !branchIdChanged &&
                (branchId === "" || branchId === branchName)
            )
                setBranchId(value);
        } else {
            setBranchName(value);
            if (
                !branchIdChanged &&
                (branchId === "" || branchId === branchName)
            )
                setBranchId(value);
        }
    };

    useEffect(() => {
        setErrMessage("");
        setErrMsg("");
    }, [showAddInstanceModal]);
    const onTagChange = (value) => {
        if (errorMessage) setErrorMessage("");
        setTags(value.trim());
    };
    useEffect(() => {
        if (!showAddInstanceModal) {
            setName("");
            setTags("");
            setChipsArray([]);
            setErrorMessage("");
        }
    }, [showAddInstanceModal]);
    const onCloseInstanceModal = () => {
        setShowMoreFlag(false);
        closeModal();
    };
    const styles = {
        display: "flex",
        justifyContent: "space-between",
        textellipsis: "true",
        fontFamily: "Montserrat",
        fontSize: "13px",
        fontWeight: "500",
        color: "#0B5CD7",
        borderRadius: "100px",
        border: `1px solid #0B5CD7`,
        lineHeight: "18px",
        letterSpacing: "0.1599999964237213px",
        textAlign: "left",
        background: "white !important",
    };
    return (
        <ModalView
            showHeader="false"
            footer="false"
            show={showAddInstanceModal}
            size="md"
            onHide={onCloseInstanceModal}
            title="Confirm"
            modal="addInstance"
            id="btn_folder_submit"
            onSubmit={handleSubmit}
            value="Name"
            onclick={onCloseInstanceModal}
            type="submit-cancel"
            submitButtonText="Close"
        >
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                <div
                    style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "22px",
                        lineHeight: "27px",
                        color: "#404040",
                        width: "430px",
                        marginTop: "-32px",
                        marginBottom: "28px",
                        marginRight: "-9px",
                    }}
                    id={heading === "Edit Instance" ? "div_edit" : "div_add"}
                >
                    {heading}
                </div>

                {
                    <div className="modalRow" style={{ width: "87%" }}>
                        <div>Instance Name</div>
                        <Inputs
                            onChange={onChange}
                            value={name}
                            type="text"
                            id="stepName"
                            placeholder="Name"
                            // handleKeyDown={handleKeyDown}
                            isError={errMsg && errMsg.length > 0}
                            icon={false}
                        />
                    </div>
                }
            </div>
            {errorMessage.length ? (
                <>
                    <span style={{ color: "red", paddingLeft: "18px" }}>
                        {errorMessage}
                    </span>
                </>
            ) : null}
            <div
                className="row doCenter"
                style={{
                    flexDirection: "column",
                    textDecoration: "underline",
                    marginTop: "12px",
                    alignItems: "start",
                    marginLeft: "6px",
                }}
            >
                <div
                    class="formLabel cursorPointer"
                    onClick={() => {
                        setShowMoreFlag(!showMore);
                    }}
                    style={{
                        textDecoration: "underline",
                    }}
                    id={showMore ? "div_showLess" : "div_showMore"}
                >
                    {showMore
                        ? "Hide Advanced Settings"
                        : "Show Advanced Settings"}
                </div>
            </div>
            {showMore && (
                <>
                    {!(heading === "Edit Instance") && (
                        <>
                            {
                                <div
                                    className="row doCenter"
                                    style={{
                                        flexDirection: "column",
                                        marginTop: "4px",
                                    }}
                                >
                                    <div
                                        className="modalRow"
                                        style={{ width: "87%" }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "6px",
                                            }}
                                        >
                                            <span>Branch Name</span>
                                            <img
                                                src="./images/info-circle-fill.svg"
                                                alt="info"
                                            />
                                        </div>
                                        <Inputs
                                            onChange={onBranchNameChange}
                                            value={branchName}
                                            type="text"
                                            id="stepName"
                                            placeholder="Branch Name"
                                            // handleKeyDown={handleKeyDown}
                                            isError={
                                                errMsg && errMsg.length > 0
                                            }
                                            icon={false}
                                        />
                                    </div>
                                </div>
                            }
                            {errMsg.length ? (
                                <>
                                    <span
                                        style={{
                                            color: "red",
                                            paddingLeft: "18px",
                                        }}
                                    >
                                        {errMsg}
                                    </span>
                                </>
                            ) : null}
                            {
                                <div
                                    className="row doCenter"
                                    style={{
                                        flexDirection: "column",
                                        marginTop: "4px",
                                    }}
                                >
                                    <div
                                        className="modalRow"
                                        style={{ width: "87%" }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "6px",
                                            }}
                                        >
                                            <span>Branch ID </span>
                                            <img
                                                src="./images/info-circle-fill.svg"
                                                alt="info"
                                            />
                                        </div>
                                        <Inputs
                                            onChange={onBranchChange}
                                            value={branchId}
                                            type="text"
                                            id="stepName"
                                            placeholder="Branch ID"
                                            isError={
                                                errMessage &&
                                                errMessage.length > 0
                                            }
                                            icon={false}
                                        />
                                    </div>
                                </div>
                            }
                            {errMessage.length ? (
                                <>
                                    <span
                                        style={{
                                            color: "red",
                                            paddingLeft: "18px",
                                        }}
                                    >
                                        {errMessage}
                                    </span>
                                </>
                            ) : null}
                        </>
                    )}
                    <div
                        className="row doCenter"
                        style={{ flexDirection: "column", marginBottom: "6%" }}
                    >
                        <div
                            style={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "22px",
                                lineHeight: "27px",
                                color: "#404040",
                                width: "430px",
                                marginTop: "28px",
                                marginBottom: "18px",
                                marginRight: "-9px",
                            }}
                        >
                            Add Family Table Tags
                        </div>
                        <div className="col-8 pl-0 doCenter pr-0">
                            <div
                                className=" m-0 instanceChip"
                                id="serachBarDiv"
                                style={{ width: "450px" }}
                            >
                                <ChipsView
                                    items={chipsArray}
                                    styles={styles}
                                    close={true}
                                    ondelete={handleDeleteChipItem}
                                    heading={heading}
                                ></ChipsView>
                                <TextBox
                                    divClass={"rippleSerachDiv instanceText"}
                                    className="getAddress form-control serachInputBox"
                                    id="familyTagsValue"
                                    clearAfterChange={true}
                                    type="text"
                                    field="Family Tags"
                                    style={{ marginLeft: "10px" }}
                                    onkeydown={handleKeyDown}
                                    handleRippleKeys={handleKeyDown}
                                    onchange={setFromText}
                                ></TextBox>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </ModalView>
    );
};

export default AddInstance;
