export const setShowDrawer = (showDrawer) => {
    return {
        type: "SET_DRAWER",
        payload: showDrawer,
    };
};

export const setRippleDocumentResponse = (rippleDocumentResponse) => {
    return {
        type: "SET_RIPPLE_DOCUMENT_RESPONSE",
        payload: rippleDocumentResponse,
    };
};
export const setRippleId = (rippleId) => {
    return {
        type: "SET_RIPPLE_ID",
        payload: rippleId,
    };
};
export const setDeleteRippleId = (rippleDeleteId) => {
    return {
        type: "SET_DELETE_RIPPLE_ID",
        payload: rippleDeleteId,
    };
};
export const setRippleKeywordSearched = (rippleKeywordSearched) => {
    return {
        type: "SET_RIPPLE_KEYWORD_SEARCHED",
        payload: rippleKeywordSearched,
    };
};

export const setTimelineDocumentList = (timelineDocumentList) => {
    return {
        type: "SET_TIMELINE_DOCUMENT_LIST",
        payload: timelineDocumentList,
    };
};
export const setSelectedNextDoc = (selectedNextDoc) => {
    return {
        type: "SET_NEXT_DOC",
        payload: selectedNextDoc,
    };
};
export const setChipArray = (chipsArray) => {
    return {
        type: "SET_CHIP_ARRAY",
        payload: chipsArray,
    };
};
export const setRedirectToRipplelog = (redirectToRipplelog) => {
    return {
        type: "SET_REDIRECT_TO_RIPPLELOG",
        payload: redirectToRipplelog,
    };
};
export const setUpdatedInstanceBranch = (instanceBranch) => {
    return {
        type: "SET_UPDATED_INSTANCE_BRANCH",
        payload: instanceBranch,
    };
};
export const setSelectedWorkspaceIndex = (selectedWorkspaceIndex) => {
    return {
        type: "SET_SELECTED_WORKSPACE_INDEX",
        payload: selectedWorkspaceIndex,
    };
};
export const setSelectedWorkspaceArray = (selectedWorkspaceArray) => {
    return {
        type: "SET_SELECTED_WORKSPACE_ARRAY",
        payload: selectedWorkspaceArray,
    };
};
export const setSelectedSpaceArray = (selectedSpaceArray) => {
    return {
        type: "SET_SELECTED_SPACE_ARRAY",

        payload: selectedSpaceArray,
    };
};
export const setSelectedDateArray = (selectedDateArray) => {
    return {
        type: "SET_SELECTED_DATE_ARRAY",
        payload: selectedDateArray,
    };
};
export const setSelectedFolderArray = (selectedFolderArray) => {
    return {
        type: "SET_SELECTED_FOLDER_ARRAY",
        payload: selectedFolderArray,
    };
};
export const setSelectedSpaceIndex = (selectedSpaceIndex) => {
    return {
        type: "SET_SELECTED_SPACE_INDEX",
        payload: selectedSpaceIndex,
    };
};
export const setSelectedFolderIndex = (selectedFolderIndex) => {
    return {
        type: "SET_SELECTED_FOLDER_INDEX",
        payload: selectedFolderIndex,
    };
};
export const setSelectedStartDate = (selectedStartDate) => {
    return {
        type: "SET_SELECTED_START_DATE",
        payload: selectedStartDate,
    };
};
export const setSelectedPeriod = (selectedPeriod) => {
    return {
        type: "SET_SELECTED_PERIOD",
        payload: selectedPeriod,
    };
};
export const setSelectedTimePeriod = (startDate, endDate, selectedTimeType) => {
    return {
        type: "SET_SELECTED_TIME_PERIOD",
        payload: {
            startDate,
            endDate,
            selectedTimeType,
        },
    };
};

export const setSelectedEndDate = (selectedEndDate) => {
    return {
        type: "SET_SELECTED_END_DATE",
        payload: selectedEndDate,
    };
};
export const setRippleNameStr = (rippleNameStr) => {
    return {
        type: "SET_RIPPLE_NAME_STR",
        payload: rippleNameStr,
    };
};
export const setSpaceArray = (spaceArray) => {
    return {
        type: "SET_SPACE_ARRAY",
        payload: spaceArray,
    };
};
export const setValue = (showValue) => {
    return {
        type: "SET_VALUE",
        payload: showValue,
    };
};
