import { getJsDateFromExcel } from "excel-date-to-js";
import React, { Component } from "react";
import { Navigate, redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
    setActivePage,
    setActiveRipple,
    setChatRippleMsg,
    setCollabInfo,
    setPageType,
    setAlertData,
    setAlertManager,
    setAlertsWidget,
    setWorkspaceManager,
} from "../../../actions";
import {
    setChipArray,
    setRedirectToRipplelog,
} from "../../../actions/Rippleaction";
import Ripple from "../../../bae-client/Ripple";
import RippleManager from "../../../bae-client/RippleManager";
import AlertDialog from "../../stylized/AlertDialog";
import ChipsView from "../../stylized/ChipsView";
import DocViwer from "../../stylized/DocViwer";
import Loader from "../../stylized/Loader";
import ModalView from "../../stylized/ModalView";
import DocumentViewerModal from "../../stylized/DocumentViewerModal";
import RippleLogTableView from "../../stylized/RippleLogTableView";
import RippleLogView from "../../stylized/RippleLogView";
import EditRippleNameModal from "./ContentPane/RippleLog/EditRippleNameModal";
import RecureModalView from "./ContentPane/RippleLog/RecureModalView";
import "./ContentPane/RippleLog/RippleLog.css";
import RippleLogHeaderView from "./ContentPane/RippleLog/RippleLogHeaderView";
import RippleLogLinkModal from "./ContentPane/RippleLog/RippleLogLinkModal";
import RippleLogLinkToSpaceModal from "./ContentPane/RippleLog/RippleLogLinkToSpaceModal";
import RippleLogShareModal from "./ContentPane/RippleLog/RippleLogShareModal";
import TitleBar from "./TiltleBar";
import EmptyRipple from "../../stylized/EmptyRipple";
import http from "../../../bae-client/HTTP";
import * as Mixpanel from "../../../mix-panel/Mixpanel";
import GeneralTable from "./LeftPane/GeneralTable";
import {
    convertUTCTimeToLocalTime,
    getParsedDateByDateAndTime,
} from "../../../bae-client/Utils";
import { setSnackbarAction } from "../../../services/services/app";

class RippleLog extends Component {
    constructor(props) {
        super(props);
        this.basePathName = window.runtimeConfig.instanceName;
        this.state = {
            showViewModal: false,
            showLinkToAlertModal: false,
            showLinkToSpaceModal: false,
            showRecureModal: false,
            showShareModal: false,
            showDocModal: false,
            showHistory: false,
            showRippleNameModal: false,
            currentShowFileName: "",
            currentShowFileLocation: "",
            showLoder: false,
            selectedRowIndex: -1,
            chipsArray: [],
            logRowsData: [],
            isRowSelected: false,
            sortBy: "descending",
            sortedByDate: "createdOn",
            showAlertDialog: false,
            alertDialogTitle: "",
            alertDialogMessage: "",
            isAlert: false,
            showRippleLog: false,
            redirectToRipple: false,
            page: 0,
            rowsPerPage: 10,
        };
        this.viewModalData = [];
        this.chipsArray = [
            {
                key: 0,
                label: "java",
            },
            {
                key: 1,
                label: "Angular",
            },
            {
                key: 2,
                label: "sql",
            },
        ];

        this.cardColData = [];
        this.cardRowData = [];
        this.idToRippleName = {};
        this.rippleRowData = [];
        this.rippleHandlers = [];
        this.selectedRowIndex = -1;
        this.selectedRowId = -1;
        this.activeRipple = null;
        this.rippleManager = null;
        this.alertManager = null;
        this.nameToRippleId = null;
        this.rippleName = "";
        this.data = "";
        this.columns = [
            {
                id: "rippleName",
                label: "Name",
                minWidth: "100px",
                textAlign: "left",
                renderCell: (value, row, index) => {
                    let rippleLogTitle = "";
                    if (row.rippleRecur) {
                        if (row.rippleRecur.split("|")[0] === "DISABLE") {
                            rippleLogTitle =
                                row.rippleRecur.split("|")[0] +
                                "|" +
                                row.rippleRecur.split("|")[1] +
                                "|" +
                                convertUTCTimeToLocalTime(
                                    row.rippleRecur.split("|")[2]
                                );
                        } else {
                            rippleLogTitle =
                                row.rippleRecur.split("|")[0] +
                                "|" +
                                convertUTCTimeToLocalTime(
                                    row.rippleRecur.split("|")[1]
                                );
                        }
                    }

                    return (
                        <>
                            <span
                                className="rippleLogRowTitleNew textEllipsis"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleViewBtnClick(index);
                                }}
                                title={value}
                            >
                                {value === "" ? "Unsaved Ripple" : value}
                            </span>
                            <div
                                style={{
                                    cursor: "pointer",
                                    fontWeight: "bolder",
                                    width: "200px",
                                }}
                            >
                                {rippleLogTitle}
                            </div>
                        </>
                    );
                },
            },
            {
                id: "updatedOn",
                label: "Date",
                minWidth: "150px",
                textAlign: "center",
                renderCell: (value) => getParsedDateByDateAndTime(value),
            },
            {
                id: "searchCriteria",
                label: "Ripple Search",
                minWidth: 150, // Define the minimum width for consistency
                textAlign: "center",
                renderCell: (value) => {
                    // Split the string by '&' to handle each part individually
                    const valuesArray = value.split("&");

                    return (
                        <span className="textEllipsis" title={value}>
                            {valuesArray.map((item, index) => (
                                <React.Fragment key={index}>
                                    &lt;{item}&gt;{" "}
                                    {index < valuesArray.length - 1 && ","}
                                </React.Fragment>
                            ))}
                        </span>
                    );
                },
            },
        ];
        this.rippleManager = new RippleManager();
    }
    changeOpenDialogFlag = (flag) => {
        this.setState({ showAlertDialog: flag });
    };

    showAlertView = (title, body) => {
        this.setState({
            showAlertDialog: true,
            alertDialogMessage: body,
            alertDialogTitle: title,
        });
    };
    // componentDidUpdate(prevProps, prevState) {
    //     const { redirectToRipple, showRippleLog } = this.state;

    //     if (redirectToRipple) {
    //         this.props.setChipArray([]);
    //         this.props.setActiveRipple(this.props.activeRipple);
    //         this.closeModal();
    //         this.props.setChatRippleMsg("");
    //         this.props.setActivePage("Ripple");
    //     }

    //     if (showRippleLog) {
    //         this.props.setRippleLog(false);
    //     }
    // }

    componentDidMount() {
        Mixpanel.callPageViewEvent("Ripple Log");
        this.title = "Ripple A";
        this.headCells = [
            {
                id: "Action",
                numeric: true,
                disablePadding: true,
                width: 60,
                label: "",
                align: "center",
            },
            {
                id: "Type",
                numeric: true,
                disablePadding: true,
                width: 30,
                label: "Type",
                align: "left",
            },
            {
                id: "name",
                numeric: false,
                disablePadding: true,
                width: 180,
                label: "Name",
                align: "left",
            },
            {
                id: "space",
                numeric: true,
                disablePadding: true,
                width: 180,
                label: "Location",
                align: "left",
            },
            {
                id: "createdDate",
                numeric: true,
                disablePadding: true,
                width: 80,
                label: "Created Date",
                align: "center",
            },
            {
                id: "addedToSpaceDate",
                numeric: true,
                disablePadding: true,
                width: 90,
                label: "Added To Space",
                align: "center",
            },
        ];
        this.setState({ chipsArray: this.chipsArray });
        this.generateRippleLogRowData();
    }

    generateRippleLogRowData = () => {
        this.setState({ showLoder: true });
        this.rippleManager = new RippleManager();
        this.rippleManager
            .loadRippleLog()
            .then((resp) => {
                this.setState({ logRowsData: resp });
                this.sortDataByExistingState();
                this.setState({ selectedRowIndex: -1 });

                this.setState({
                    showLoder: false,
                });
            })
            .catch((err) => {
                this.showAlertView("", err.error);
                this.setState({ showLoder: false });
            });
    };
    handleUpdateRippleName = (updatedRippleName) => {
        this.closeModal();
        this.setState({ showLoder: true });
        let selectedRowData = this.state.logRowsData[this.selectedRowIndex];
        let data = {
            rippleRegistryId: selectedRowData.rippleId,
            rippleName: updatedRippleName,
        };

        this.rippleManager
            .updateRippleName(data)
            .then((resp) => {
                if (this.state.sortedByDate === "createdOn") {
                    this.setState({
                        logRowsData: resp,
                        selectedRowIndex: -1,
                        isRowSelected: false,
                    });
                    this.props.setRenameRipple({
                        rippleName: updatedRippleName,
                        rippleRegistryId: selectedRowData.rippleId,
                    });
                    this.props.setSnackbarAction({
                        open: true,
                        message: "Item renamed successfully",
                        severity: "success",
                    });
                    this.sortDataByExistingState();
                    this.setState({ showLoder: false });
                } else {
                    var updatedRow = resp.filter(function (resp) {
                        return resp.rippleId == data.rippleRegistryId;
                    });

                    let rowsData = this.state.logRowsData;
                    var index = rowsData.findIndex(function (rowsData) {
                        return rowsData.rippleId == updatedRow[0].rippleId;
                    });
                    this.setState({
                        selectedRowIndex: -1,
                        isRowSelected: false,
                    });
                    this.props.setRenameRipple({
                        rippleName: updatedRippleName,
                        rippleRegistryId: selectedRowData.rippleId,
                    });
                    this.props.setSnackbarAction({
                        open: true,
                        message: "Item renamed successfully",
                        severity: "success",
                    });
                    this.state.logRowsData[index] = updatedRow[0];
                    this.setState({ showLoder: false });
                }
            })
            .catch((err) => {
                console.log(err);

                this.showAlertView("", err.error);
                this.setState({ showLoder: false });
            });
    };
    sortDataByExistingState = () => {
        this.state.logRowsData.sort((a, b) => {
            return (
                new Date(b.createdOn).getTime() -
                new Date(a.createdOn).getTime()
            );
        });
    };
    sortDataByDate = (sortType) => {
        if (sortType === "assending") {
            this.state.logRowsData.sort((a, b) => {
                return (
                    new Date(a.updatedOn).getTime() -
                    new Date(b.updatedOn).getTime()
                );
            });
            // this.setState({ logRowsData: rawData });
        } else if (sortType === "descending") {
            this.state.logRowsData.sort((a, b) => {
                return (
                    new Date(b.updatedOn).getTime() -
                    new Date(a.updatedOn).getTime()
                );
            });
            // this.setState({ logRowsData: rawData });
        }
    };

    sortDataByDate = (sortType) => {
        if (sortType === "assending") {
            this.state.logRowsData.sort((a, b) => {
                return (
                    new Date(a.updatedOn).getTime() -
                    new Date(b.updatedOn).getTime()
                );
            });
            // this.setState({ logRowsData: rawData });
        } else if (sortType === "descending") {
            this.state.logRowsData.sort((a, b) => {
                return (
                    new Date(b.updatedOn).getTime() -
                    new Date(a.updatedOn).getTime()
                );
            });
            // this.setState({ logRowsData: rawData });
        }
    };
    sortByAlphabetically = (sortType) => {
        if (sortType === "assending") {
            this.state.logRowsData.sort((a, b) =>
                a.rippleName.localeCompare(b.rippleName)
            );
        } else if (sortType === "descending") {
            this.state.logRowsData.sort((a, b) =>
                b.rippleName.localeCompare(a.rippleName)
            );
        }
    };
    handleDateFilter = (listValue, sortBy) => {
        // sortBtnClicked(listValue);
        let actionType = listValue;
        if (actionType === "Date") {
            if (sortBy === "asc") {
                this.sortDataByDate("descending");
                // this.setState({ sortBy: "descending" });
            } else if (sortBy === "desc") {
                this.sortDataByDate("assending");
                // this.setState({ sortBy: "assending" });
            }
        } else if (actionType === "Name") {
            if (sortBy === "asc") {
                this.sortByAlphabetically("descending");
                // this.setState({ sortAplphaType: "descending" });
            } else if (sortBy === "desc") {
                this.sortByAlphabetically("assending");
                // this.setState({ sortAplphaType: "assending" });
            }
        }
    };
    handleSortActions = (actionType) => {
        if (actionType === "date") {
            if (this.state.sortBy === "assending") {
                this.sortDataByDate("descending");
                this.setState({
                    sortBy: "descending",
                    sortedByDate: "updatedOn",
                    selectedRowIndex: -1,
                    isRowSelected: false,
                });
            } else if (this.state.sortBy === "descending") {
                this.sortDataByDate("assending");
                this.setState({
                    sortBy: "assending",
                    sortedByDate: "updatedOn",
                    selectedRowIndex: -1,
                    isRowSelected: false,
                });
            }
        }
    };
    showRipplenameModal = () => {
        this.setState({ showRippleNameModal: true });
    };
    showRippleLog = () => {
        this.setState({ showRippleLog: true });
    };

    renderCardRow = () => {
        if (this.state.logRowsData) {
            //    const menuItems = [
            //         {
            //             condition: true, // This determines whether the Menu should render
            //             anchorEl: true, // Reference to the anchor element (use null if initially closed)
            //             items: [
            //                 {
            //                     content: "Rename", // Display text or content for the MenuItem
            //                     onClick: () => console.log("Option 1 clicked"), // Function to run when clicked
            //                 },
            //                 {
            //                     content: "Recur",
            //                     onClick: () => console.log("Option 2 clicked"),
            //                 },
            //             ],
            //         },];
            if (this.state.logRowsData.length)
                return (
                    // <RippleLogView
                    //     rowItems={this.state.logRowsData}
                    //     selectedIndex={this.state.selectedRowIndex}
                    //     handleRowSelected={this.handleRowSelected}
                    //     viewBtnCliked={this.handleViewBtnClick}
                    //     handlePageChange={this.handlePageChange}
                    // ></RippleLogView>
                    <GeneralTable
                        columns={this.columns}
                        data={this.state.logRowsData}
                        onRowClick={this.handleRowSelected}
                        viewBtnCliked={this.handleViewBtnClick}
                        onSort={this.handleDateFilter}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        module={"RippleLog"}
                        selectedRowIndex={this.state.selectedRowIndex}
                        // menuItems={menuItems}
                    />
                );
            else if (!this.state.showLoder) return <EmptyRipple></EmptyRipple>;
        }
    };
    renderHeader = () => {
        // if (this.state.isRowSelected === true) {
        return (
            <RippleLogHeaderView
                showRippleLog={this.showRippleLog}
                loadLog={this.generateRippleLogRowData}
                registryId={this.state.logRowsData[this.selectedRowIndex]}
                showData={this.state.logRowsData}
                selectedIndex={this.state.selectedRowIndex}
                removeHeader={this.removeHeader}
                showActions={this.state.isRowSelected}
                sortBtnClicked={this.handleSortActions}
                shareBtnClicked={this.handleShareBtnClick}
                linkBtnClicked={this.handleLinkBtnClick}
                recureBtnClicked={this.handleRecureBtnClick}
                editRippleBtnClicked={this.showRipplenameModal}
            ></RippleLogHeaderView>
        );
        // }
    };
    renderRippleNameModal = () => {
        if (this.state.showRippleNameModal === true) {
            return (
                <EditRippleNameModal
                    rippleName={this.rippleName}
                    updateRippleName={this.handleUpdateRippleName}
                    open={this.state.showRippleNameModal}
                    closeModal={this.closeModal}
                    rippledList={this.state.logRowsData}
                ></EditRippleNameModal>
            );
        }
    };
    renderShareDataModal = () => {
        return (
            <RippleLogShareModal
                open={this.state.showShareModal}
                closeModal={this.closeModal}
                handleShareBtnClickInModal={this.handleShareBtnClickInModal}
            ></RippleLogShareModal>
        );
    };
    renderLinkDataModal = () => {
        // modal for link to alert
        if (this.state.showLinkToAlertModal === true) {
            return (
                <RippleLogLinkModal
                    open={this.state.showLinkToAlertModal}
                    closeModal={this.closeModal}
                    handleLinkBtnClickInModal={this.handleLinkToAlertClick}
                ></RippleLogLinkModal>
            );
        }
    };
    renderLinkToSpaceDataModal = () => {
        // modal for link to alert
        if (this.state.showLinkToSpaceModal === true) {
            return (
                <RippleLogLinkToSpaceModal
                    open={this.state.showLinkToSpaceModal}
                    closeModal={this.closeModal}
                    handleLinkBtnClickInModal={this.handleLinkToAlertClick}
                ></RippleLogLinkToSpaceModal>
            );
        }
    };
    handleRecureBtnClick = (index) => {
        // this.selectedRowIndex = index;
        this.setState({ showRecureModal: true });
    };
    renderRecureModal = () => {
        if (this.state.showRecureModal === true) {
            let recureStr = "";

            if (this.state.logRowsData[this.selectedRowIndex] !== undefined) {
                if (
                    this.state.logRowsData[this.selectedRowIndex]
                        .rippleRecur !== "" &&
                    this.state.logRowsData[this.selectedRowIndex]
                        .rippleRecur !== "0"
                ) {
                    recureStr =
                        this.state.logRowsData[this.selectedRowIndex]
                            .rippleRecur;
                    this.data = recureStr;
                }
            }

            return (
                <RecureModalView
                    data={recureStr}
                    show={this.state.showRecureModal}
                    onHide={this.closeModal}
                    handleDisableRipple={this.handleDisableRipple}
                    onclick={this.closeModal}
                    onSubmit={this.handleRecureModalSubmit}
                ></RecureModalView>
            );
        }
    };
    parseDateExcelToUTC = (dateValue) => {
        let strDate = "" + dateValue;
        let localDate = getJsDateFromExcel(dateValue).toLocaleDateString(
            "en-US",
            {
                timeZone: "UTC",
            }
        );
        let time = getJsDateFromExcel(dateValue).toLocaleTimeString("en-US", {
            timeZone: "UTC",
            timeSyle: "short",
        });
        let temp = time.split(" ")[0].split(":");
        temp = temp[0] + ":" + temp[1] + " " + time.split(" ")[1];
        return localDate + " - " + temp;
    };

    getRecureValueByRippleId = (id) => {
        let temp = "";
        for (let index = 0; index < this.rippleRowData.length; index++) {
            let item = this.rippleRowData[index];
            if (item.rowId === parseInt(id)) {
                temp = item.stringValue[1];
                break;
            }
        }
        // this.rippleRowData.forEach((item) => {
        //     if (item.rowId === parseInt(id)) {
        //         temp = item.stringValue[0];
        //         break;
        //     }
        // });
        return temp;
    };
    getRippleNameById = (id) => {
        return this.idToRippleName[id];
    };
    getRippleIdByName = (name) => {
        return this.nameToRippleId[name];
    };

    // handleShowHistoryBtnClick = (flag) => {
    //     // this.state.showHistory === false
    //     //     ? this.setState({ showHistory: true })
    //     //     : this.setState({ showHistory: false });
    //     this.setState({ showHistory: flag });
    // };
    renderTitle = () => {
        return (
            <TitleBar
                username={this.props.username}
                title={"Ripple Log"}
            ></TitleBar>
        );
    };
    handleBackBtnClick = (event) => {};
    handleViewTableRowClick = (e, index) => {};
    handleViewInRippleClick = () => {
        let data = this.state.logRowsData[this.selectedRowIndex];
        this.setState({ redirectToRipple: true });
        this.props.setRedirectToRipplelog(false);
        const { showRippleLog } = this.state;
        this.props.setChipArray([]);
        this.props.setActiveRipple(data);
        this.closeModal();
        this.props.setChatRippleMsg("");
        this.props.setActivePage("Ripple");

        if (showRippleLog) {
            this.props.setRippleLog(false);
        }
    };
    handleDeleteChipItem = (chipToDelete) => {
        let items = this.chipsArray;
        items.splice(chipToDelete.key, 1);
        this.setState({ chipsArray: items });
    };
    generateRippledChips = (searchWord) => {
        this.chipsArray = [];
        if (
            searchWord !== undefined &&
            searchWord !== null &&
            searchWord !== ""
        ) {
            let chipsArray = searchWord.split("&");
            chipsArray.forEach((word, index) => {
                this.chipsArray.push({ key: index, label: word });
            });
        }
    };
    onClickRippleChip = (text) => {};
    renderViewDocumentVieverModal = (event) => {
        // document Viewer component to show documents
        return (
            <DocViwer
                docName={this.state.currentShowFileName}
                fileLocation={this.state.currentShowFileLocation}
            ></DocViwer>
        );
    };
    showDocModalView = (event, index, fileName, row) => {
        http.sendRestRequest(
            `document/documentDetail?documentId=${row.documentId}`
        )
            .then((response) => {
                this.setState({
                    showDocModal: true,
                    currentShowFileName: fileName,
                });
                const fileType = response.documentName.split(".").pop();
                if (fileType === "pdf") {
                    setTimeout(() => {
                        this.setState({
                            currentShowFileLocation: response,
                        });
                    }, 1);
                } else {
                    this.setState({
                        currentShowFileLocation: response,
                    });
                }
            })
            .catch((error) => {
                this.showAlertView("", error.error);
                console.log(error);
            });
    };
    renderDocumentViever = () => {
        return (
            <DocumentViewerModal
                showHeader={true}
                footer="false"
                show={this.state.showDocModal}
                documentId={this.state.currentShowFileLocation.documentId}
                size="lg"
                onHide={this.closeDocViever}
                title=""
                onclick={() => {}}
                headerTitle={this.state.currentShowFileName}
                submitButtonText="Close"
                isChecked={true}
                fileSource={this.state.currentShowFileLocation}
            >
                {this.renderViewDocumentVieverModal()}
            </DocumentViewerModal>
        );
    };
    renderViewDocumentModal = () => {
        if (
            this.state.logRowsData.length > 0 &&
            this.selectedRowIndex !== -1 &&
            this.state.logRowsData[this.selectedRowIndex] !== undefined
        ) {
            let data = this.state.logRowsData[this.selectedRowIndex];

            this.generateRippledChips(data.searchCriteria);

            return (
                <div className="container">
                    <div
                        className="row w-100"
                        style={{
                            justifyContent: "space-between",
                        }}
                    >
                        <span
                            className="mainTitle textEllipsis mb-3"
                            style={{ marginLeft: "19px", marginTop: "-27px" }}
                        >
                            {data.rippleName === "" ||
                            data.rippleName === "Name Ripple"
                                ? "Unsaved Ripple"
                                : data.rippleName}
                        </span>
                        {/* <span onClick={this.closeModal}>X</span> */}
                    </div>
                    <div className="row w-100">
                        <div className="col-8">
                            <ChipsView
                                items={this.chipsArray}
                                onclick={this.onClickRippleChip}
                            ></ChipsView>
                        </div>
                        <div
                            className="col-4"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <button
                                class="btn btnLabel doCenter newFontMontserrat"
                                id="viewInRippleModelBtn"
                                title="VIEW IN RIPPLE"
                                onClick={this.handleViewInRippleClick}
                            >
                                VIEW IN RIPPLE
                            </button>
                        </div>
                    </div>
                    <RippleLogTableView
                        title={this.title}
                        rows={this.viewModalData}
                        showModal={this.state.showViewModal}
                        columns={this.headCells}
                        showDocModalView={this.showDocModalView}
                        handleClick={(e, index) => {
                            this.handleViewTableRowClick(e, index);
                        }}
                    ></RippleLogTableView>
                </div>
            );
        } else {
            return null;
        }
    };
    closeModal = () => {
        this.setState({
            showViewModal: false,
            showLinkToAlertModal: false,
            showLinkToSpaceModal: false,
            showRecureModal: false,
            showShareModal: false,
            alertInfoModal: false,
            showRippleNameModal: false,
            redirectToRipple: false,
        });
    };
    closeDocViever = () => {
        this.setState({
            showDocModal: false,
            currentShowFileLocation: "",
        });
    };
    renderViewDataModal = () => {
        return (
            <ModalView
                showHeader="false"
                footer="false"
                show={this.state.showViewModal}
                size="xl"
                onHide={this.closeModal}
                title=""
                // onclick={this.closeModal}
                submitButtonText="Close"
            >
                {this.state.showViewModal && this.renderViewDocumentModal()}
            </ModalView>
        );
    };
    handleLinkToAlertClick = () => {
        this.setState({ showLoder: true });
        let rowData = [];
        if (this.state.logRowsData.length > 0 && this.selectedRowIndex !== -1) {
            let data = this.state.logRowsData[this.selectedRowIndex];
            let alertBody = "Recur : " + data.recure + " Data : " + data.date;
            rowData.push(data.name);
            rowData.push(-1);
            rowData.push("ripple");
            rowData.push("true");
            rowData.push(alertBody);
            this.closeModal();
        }
    };

    handleLinkToSpaceClick = () => {};

    setFromText = (value, id, event) => {
        switch (id) {
            case "username":
                {
                    this.usename = value;
                }
                break;
            case "rippleName":
                {
                    this.rippleName = value;
                }
                break;
            default:
                break;
        }
    };
    handleShareBtnClickInModal = () => {};
    formatTimeForRecure = (timeString) => {
        let aa = timeString.split(" ")[1];
        let dateArray = timeString.split(" ")[0].split(":");
        let hh = aa === "PM" ? parseInt(dateArray[0]) + 12 : dateArray[0];
        let mm = dateArray[1];
        let ss = "00";
        return "" + hh + ":" + mm + ":" + ss;
    };
    formatDaysString = (dateObj) => {};
    handleDisableRipple = (enableId) => {};

    handleRecureModalSubmit = (
        selectedEvery,
        selectedWeek,
        selectedTime,
        dateObj,
        isClick,
        enableId
    ) => {
        this.closeModal();
        this.setState({ showLoder: true });

        let recureStrNew = "";
        let daysArray = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

        // Validate dateObj and construct recureStrNew
        if (dateObj && typeof dateObj === "object") {
            daysArray.forEach((day) => {
                if (dateObj[day]) {
                    recureStrNew += day.toLocaleUpperCase() + ",";
                }
            });
        }
        if (!recureStrNew) {
            recureStrNew = "MON,TUE,WED,THU,FRI,SAT,SUN"; // Default value
        } else {
            recureStrNew = recureStrNew.slice(0, -1); // Remove trailing comma
        }
        recureStrNew += "|" + selectedTime;

        let selectedRowData = this.state.logRowsData[this.selectedRowIndex];
        if (!selectedRowData) {
            console.error("Invalid selectedRowData.");
            this.setState({ showLoder: false });
            return;
        }

        let data = {
            rippleRegistryId: selectedRowData.rippleId,
            rippleRecur: recureStrNew,
        };

        // Update ripple name
        this.rippleManager
            .updateRippleName(data)
            .then((resp) => {
                if (this.state.sortedByDate === "createdOn") {
                    this.setState({
                        logRowsData: resp,
                        selectedRowIndex: -1,
                        isRowSelected: false,
                    });
                    this.sortDataByExistingState();
                } else {
                    let updatedRow = resp.find(
                        (item) => item.rippleId === data.rippleRegistryId
                    );

                    if (updatedRow) {
                        let rowsData = [...this.state.logRowsData];
                        let index = rowsData.findIndex(
                            (row) => row.rippleId === updatedRow.rippleId
                        );
                        if (index !== -1) {
                            rowsData[index] = updatedRow;
                            this.setState({ logRowsData: rowsData });
                        }
                    }
                }
                this.setState({ showLoder: false });
            })
            .catch((err) => {
                console.error("Error updating ripple:", err);
                this.showAlertView("", err?.error || "An error occurred.");
                this.setState({ showLoder: false });
            });

        if (isClick === true) {
            this.closeModal();
            this.setState({ showLoder: true });

            let disabled = this.data?.split("|")[0] === "DISABLE" ? 1 : 0;
            let selectedRowData = this.state.logRowsData[this.selectedRowIndex];

            if (!selectedRowData) {
                console.error("Invalid selectedRowData.");
                this.setState({ showLoder: false });
                return;
            }

            let data = {
                rippleRegistryId: selectedRowData.rippleId,
            };

            http.sendRestRequest(
                `ripple/recur?rippleRegistryId=${data.rippleRegistryId}&isRecurEnabled=${disabled}`,
                "PUT"
            )
                .then(() => {
                    this.generateRippleLogRowData();
                })
                .catch((error) => {
                    console.error(
                        "Error enabling/disabling ripple recur:",
                        error
                    );
                    this.showAlertView(
                        "",
                        error?.error || "An error occurred."
                    );
                })
                .finally(() => {
                    this.setState({ showLoder: false });
                });
        }
    };

    getDocumentNameByRemovedTimestamp = (fileName) => {
        let tempDocumentName = fileName.split("_");
        let documentName = "";
        if (tempDocumentName.length > 1) {
            for (let index = 0; index < tempDocumentName.length - 1; index++) {
                documentName = documentName + tempDocumentName[index];
            }
            return documentName;
        } else {
            return fileName;
        }
    };
    generateViewRawData = () => {
        let rawData = [];
        if (this.rippleViewData !== null && this.rippleViewData !== undefined) {
            this.rippleViewData.forEach((documentItem) => {
                let fileName = documentItem.docName;
                let docType = fileName.split(".").pop();
                let tempRawData = {
                    type: docType,
                    name: fileName,
                    documentName: fileName,
                    documentId: documentItem.documentId,
                    Sapce: documentItem.spaceName,
                    location: documentItem.location,
                    createdDate: documentItem.createdOn,
                    addedToSpace: documentItem.createdOn,
                    btn: "View",
                };
                rawData.push(tempRawData);
            });
        }
        return Promise.all(rawData);
    };
    handleViewBtnClick = (index) => {
        this.setState({ showLoder: true });
        this.selectedRowIndex = index;
        // this.setState({ showViewModal: true, showLoder: false });
        let rippleHandler = new Ripple();
        rippleHandler.setRippleData(this.state.logRowsData[index]);
        let docArray = rippleHandler.getRippleDocumentsIdList();
        if (docArray !== null) {
            rippleHandler
                .getDocuments(docArray)
                .then((documentObjects) => {
                    this.rippleViewData = documentObjects;
                    this.generateViewRawData()
                        .then((rawResponse) => {
                            this.viewModalData = rawResponse;

                            this.setState({
                                showViewModal: true,
                                showLoder: false,
                            });
                        })
                        .catch((err) => {
                            this.showAlertView("", err.error);
                            this.setState({ showLoder: false });
                        });
                })
                .catch((err) => {
                    this.showAlertView("", err.error);
                    this.setState({ showLoder: false });
                });
        } else {
            this.rippleViewData = [];
            this.generateViewRawData().then((rawResponse) => {
                this.viewModalData = rawResponse;
                this.setState({
                    showViewModal: true,
                    showLoder: false,
                });
            });
        }
    };
    removeHeader = () => {
        this.setState({ isRowSelected: false });
    };
    handleRowSelected = (index) => {
        this.selectedRowIndex = index;
        let selectedRowData = this.state.logRowsData[this.selectedRowIndex];

        this.selectedRowId = selectedRowData.rippleId;
        this.rippleName = selectedRowData.rippleName;
        this.setState({ isRowSelected: true, selectedRowIndex: index });
    };

    handlePageChange = () => {
        this.setState({ isRowSelected: false, selectedRowIndex: -1 });
    };
    handleShareBtnClick = (index) => {
        // this.selectedRowIndex = index;
        this.setState({ showShareModal: true });
    };

    handleLinkBtnClick = (type) => {
        // this.selectedRowIndex = index;
        if (type === "toAlert") {
            this.setState({ showLinkToAlertModal: true });
        } else {
            this.setState({ showLinkToSpaceModal: true });
        }
    };
    render() {
        this.alertManager = this.props.alertManager;
        return (
            <>
                <div className="w-100 pl-0 pr-0 contentPaneBottom">
                    {this.state.logRowsData && this.state.logRowsData.length ? (
                        this.renderHeader()
                    ) : (
                        <div
                            class="rippleLog_header"
                            style={{ height: "10%" }}
                        ></div>
                    )}
                    {this.renderCardRow()}
                    {this.renderViewDataModal()}
                    {this.renderLinkDataModal()}
                    {this.renderLinkToSpaceDataModal()}
                    {this.renderShareDataModal()}
                    {this.renderRecureModal()}
                    {this.renderDocumentViever()}
                    {this.renderRippleNameModal()}
                    {/* {this.renderRippleNameModal()} */}
                </div>
                <Loader show={this.state.showLoder} />
                <AlertDialog
                    open={this.state.showAlertDialog}
                    message={this.state.alertDialogMessage}
                    title={this.state.alertDialogTitle}
                    isAlert={this.state.isAlert}
                    setOpen={this.changeOpenDialogFlag}
                ></AlertDialog>
                {this.state.redirectToRipple ? (
                    <Navigate
                        to={`${
                            this.basePathName ? "/" + this.basePathName : ""
                        }/ripple`}
                        replace={true}
                    />
                ) : null}
            </>
        );
    }
}
const mapDispatchToProps = () => {
    return {
        setActiveRipple,
        setChatRippleMsg,
        setActivePage,
        setPageType,
        setWorkspaceManager,
        setAlertData,
        setAlertsWidget,
        setAlertManager,
        setCollabInfo,
        setChipArray,
        setRedirectToRipplelog,
        setSnackbarAction,
    };
};

const mapStateToProps = (state) => {
    return {
        collabInfo: state.collabInfo,
        activeRipple: state.activeRipple,
        alertManager: state.alertManager,
        chipsArray: state.chipsArray,
        cuboidInfoByName: state.cuboidInfoByName,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(RippleLog);
