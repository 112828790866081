import React from "react";
import CustomTable from "./table";
import "./style.css";

const InstanceListView = ({
    instanceOperations,
    allInstances,
    openInstance,
    modal,
}) => {
    return (
        <>
            <CustomTable
                modal={modal}
                instanceOperations={instanceOperations}
                allInstances={allInstances}
                openInstance={openInstance}
                id={"instance_table"}
            ></CustomTable>
        </>
    );
};

export default InstanceListView;
