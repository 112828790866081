import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    BrowserRouter,
    NavLink,
    useNavigate,
    useLocation,
} from "react-router-dom";
import {
    fetchWorkspaceListAction,
    setActivePageAction,
    setActiveWorkspaceAction,
    setAddWorkspaceModalAction,
    setSnackbarAction,
    setSpaceListAction,
} from "../../../../services/services/app";
import http from "../../../../bae-client/HTTP";
import WorkspaceModal from "./WorkspaceModal";
import { validateWorkspaceUserName } from "../../../../bae-client/Validations";
import { validateName, validateNameLength } from "../../../../bae-client/Utils";
import { Mixpanel } from "../../../../mix-panel/Mixpanel";
import Loader from "../../../stylized/Loader";
import "./style.css";
import RecentActivityManager from "../../../../bae-client/RecentActivityManager";

const WorkspaceList = ({ showModal, setShowModal }) => {
    let basePathName = window.runtimeConfig.instanceName
        ? "/" + window.runtimeConfig.instanceName
        : "";

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const showAddWorkspaceModal = useSelector(
        (state) => state.addWorkspaceModal
    );
    const workspaceList = useSelector((state) => state.workspaceList);
    const location = useLocation();

    const sortedWorkspaces = [...workspaceList];

    const [addWorkspaceModalError, setAddWorkspaceModalError] = useState("");
    const [workspaceName, setWorkspaceName] = useState("");
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState("");
    const [open, setOpen] = useState(false);

    const setCurrentWorkspace = (workspace) => {
        localStorage.setItem("workspaceId", workspace.workspaceId);
        http.sendRestRequest(
            `workspace/spaceList?workspaceId=${workspace.workspaceId}`
        )
            .then((response) => {
                dispatch(setActiveWorkspaceAction(workspace));
                dispatch(setSpaceListAction(response));
                dispatch(setActivePageAction("Workspace"));
                setShowModal(false);
            })
            .catch((err) => console.log(err));
    };

    const customSort = (a, b) => {
        if (a.workspaceType === "SYSTEM" && b.workspaceType === "SYSTEM") {
            return a.workspaceId - b.workspaceId;
        } else if (a.isNewWorkspace && !b.isNewWorkspace) {
            return -1;
        } else if (!a.isNewWorkspace && b.isNewWorkspace) {
            return 1;
        } else if (
            a.workspaceType === "SYSTEM" ||
            b.workspaceType === "SYSTEM"
        ) {
            return a.workspaceType === "SYSTEM" ? 1 : -1;
        } else {
            return new Date(b.createdOn) - new Date(a.createdOn);
        }
    };

    sortedWorkspaces.sort(customSort);

    const setText = (value, id, event) => {
        if (value && value.length > 100)
            setAddWorkspaceModalError("Name should not exceed 100 char length");
        else setAddWorkspaceModalError("");
        if (id === "workspacename") setWorkspaceName(value);
        const pattern = /[^A-Za-z0-9\s]{2}/;
        let isValidName = validateWorkspaceUserName(value);
        if (isValidName === true) {
            if (pattern.test(value)) {
                setWorkspaceName("");
                setAddWorkspaceModalError(
                    "Two consecutive special characters are not allowed."
                );
            } else {
                setAddWorkspaceModalError("");
                return true;
            }
        } else {
            setAddWorkspaceModalError(isValidName);
        }
    };

    const handleAddWorkspaceClick = () => {
        if (addWorkspaceModalError) return;
        const existingWorkspaces = sortedWorkspaces.map(
            (ws) => ws.workspaceName
        );
        if (existingWorkspaces.includes(workspaceName.trim())) {
            setAddWorkspaceModalError("Workspace already exists");
            return;
        }
        if (workspaceName === null || workspaceName.trim().length === 0) {
            setWorkspaceName("");
            setAddWorkspaceModalError("Please enter workspace name!");
        } else if (!validateNameLength(workspaceName.trim())) {
            let msg = "Workspace name should be less than 100 characters";
            setWorkspaceName("");
            setAddWorkspaceModalError(msg);
            dispatch(setAddWorkspaceModalAction(true));
            return;
        } else {
            dispatch(setAddWorkspaceModalAction(false));
            setLoading(true);
            const url = "workspace/workspace";
            const payload = {
                workspaceName: workspaceName.trim(),
            };
            http.sendRestRequest(url, "POST", payload)
                .then((response) => {
                    setWorkspaceName("");
                    dispatch(fetchWorkspaceListAction());
                    setCurrentWorkspace(response);
                    navigate(`${basePathName}/workspace`);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: "Workspace created successfully",
                            severity: "success",
                        })
                    );
                    let recentActivity = new RecentActivityManager();
                    let data = {
                        objectType: "WORKSPACE",
                        objectId: response.workspaceId,
                        description: `Created ${response.workspaceName}`,
                        path: "",
                    };
                    recentActivity
                        .createRecentActivity(data)
                        .then((recentRes) => {})
                        .catch((err) => console.log(err.error));
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message:
                                err?.error ||
                                "Something went wrong, please try again!",
                            severity: "error",
                        })
                    );
                });
        }
    };

    useEffect(() => {
        if (sortedWorkspaces && sortedWorkspaces.length === 0)
            dispatch(fetchWorkspaceListAction());
        if (workspaceList && workspaceList.length > 0) {
            const workspaceId = localStorage.getItem("workspaceId");
            let workspace = null;
            if (workspaceId) {
                workspace = workspaceList.find(
                    (ws) => ws.workspaceId === parseInt(workspaceId)
                );
            }
            if (!workspace || workspace === undefined) {
                const tempWorkspaces = [...workspaceList];
                tempWorkspaces.sort((a, b) => {
                    return new Date(b.updatedOn) - new Date(a.updatedOn);
                });
                workspace = tempWorkspaces[0];
            }

            if (workspace && location.pathname.includes("workspace")) {
                setCurrentWorkspace(workspace);
            }
        }
    }, [workspaceList]);
    const showSystemWorkspace = () => {
        setOpen(!open);
    };
    return (
        <>
            {showModal && (
                <div
                    id="subMenu"
                    className="mainPageLeftPaneBG pl-0 pr-0"
                    style={{
                        width: "267px",
                        position: "absolute",
                        zIndex: "10001",
                        background: "#27292C",
                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {sortedWorkspaces && sortedWorkspaces.length > 0 && (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "12px",
                                }}
                            >
                                <div title="Workspace">Workspaces</div>
                                <div
                                    id="div_leftPane_workspaceDropdown"
                                    onClick={() => {
                                        dispatch(
                                            setAddWorkspaceModalAction(true)
                                        );
                                        setShowModal(false);
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <img
                                        title="Add Workspace"
                                        id="addWorkspace"
                                        src="./images/fileTypeIcon/plus 3.svg"
                                        alt="add workspace"
                                    />
                                </div>
                            </div>
                            <div className="invisibleScroller">
                                <ul>
                                    <li
                                        className="ws-items"
                                        onClick={showSystemWorkspace}
                                        style={{ listStyleType: "none" }}
                                    >
                                        <img
                                            src="./images/database-cutout.svg"
                                            alt="System Icon"
                                            style={{
                                                marginLeft: "-30px",
                                                position: "absolute",
                                            }}
                                        />
                                        System
                                        <img
                                            src={
                                                open
                                                    ? "./images/chevron-up.svg"
                                                    : "./images/chevron-dn.png"
                                            }
                                            alt="System Icon"
                                            style={{
                                                marginLeft: "150px",
                                                position: "absolute",
                                            }}
                                        />
                                    </li>
                                    {sortedWorkspaces.map((workspace) =>
                                        workspace.workspaceType === "SYSTEM" &&
                                        open ? (
                                            <NavLink
                                                exact
                                                to={`${basePathName}/workspace`}
                                                key={workspace.workspaceId}
                                                style={{
                                                    color: "white",
                                                    fontWeight: "600",
                                                }}
                                                onClick={() => {
                                                    dispatch(
                                                        setActivePageAction(
                                                            "Workspace"
                                                        )
                                                    );
                                                    setActive(
                                                        workspace.workspaceName
                                                    );

                                                    setShowModal(false);
                                                }}
                                            >
                                                <li
                                                    style={{
                                                        listStyleType: "none",
                                                    }}
                                                    title={
                                                        workspace.workspaceName
                                                    }
                                                    className={`ws-items ${
                                                        active ===
                                                        workspace.workspaceName
                                                            ? "ws-active"
                                                            : ""
                                                    }`}
                                                    onClick={() =>
                                                        setCurrentWorkspace(
                                                            workspace
                                                        )
                                                    }
                                                >
                                                    {workspace.workspaceName}
                                                </li>
                                            </NavLink>
                                        ) : null
                                    )}
                                </ul>
                                <ul
                                    style={{
                                        paddingLeft: "9px",
                                        marginTop: "-16px",
                                    }}
                                >
                                    {sortedWorkspaces.map((workspace) =>
                                        workspace.workspaceType === "USER" ? (
                                            <NavLink
                                                exact
                                                to={`${basePathName}/workspace`}
                                                key={workspace.workspaceId}
                                                style={{
                                                    color: "white",
                                                    fontWeight: "600",
                                                    display: "block",
                                                }}
                                                onClick={() => {
                                                    dispatch(
                                                        setActivePageAction(
                                                            "Workspace"
                                                        )
                                                    );
                                                    setActive(
                                                        workspace.workspaceName
                                                    );
                                                    setOpen(false);
                                                    setShowModal(false);
                                                }}
                                            >
                                                <li
                                                    title={
                                                        workspace.workspaceName
                                                    }
                                                    className={`ws-items textEllipsis ${
                                                        active ===
                                                        workspace.workspaceName
                                                            ? "ws-active"
                                                            : ""
                                                    }`}
                                                    onClick={() =>
                                                        setCurrentWorkspace(
                                                            workspace
                                                        )
                                                    }
                                                >
                                                    {workspace.workspaceName}
                                                </li>
                                            </NavLink>
                                        ) : null
                                    )}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
            )}
            {showAddWorkspaceModal && (
                <WorkspaceModal
                    addWorkspaceModalShow={showAddWorkspaceModal}
                    closeModal={() => {
                        dispatch(setAddWorkspaceModalAction(false));
                        setWorkspaceName("");
                        setAddWorkspaceModalError("");
                    }}
                    setText={setText}
                    handleAddWorkspaceClick={handleAddWorkspaceClick}
                    error={addWorkspaceModalError}
                />
            )}
            <Loader loading={loading} />
        </>
    );
};

export default WorkspaceList;
