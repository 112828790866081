import { Avatar, Card, CardHeader } from "@material-ui/core";
import React from "react";
import "./style.css";
import { getChatTimestamp } from "../../../../../../../bae-client/Utils";
import ContactItemView from "./ContactItemView";

export default function Contact({
    chat,
    handleSelectedChat,
    contactIndex,
    isContactSelected,
}) {
    const chatCardArray = chat.participantUserIds.filter(
        (item) => item.id.toString() !== sessionStorage.getItem("userId")
    );
    const messageBy = chat.participantUserIds.filter(
        (item) => item.id === chat.messageBy
    );
    const chatCard = chatCardArray[0];
    const getAvatar = () => {
        let displayResult = "";
        for (let i = 0; i < chatCardArray.length; i++) {
            if (chatCardArray.length > 2) {
                if (i == 2) {
                    displayResult = displayResult;
                    //     +
                    //     "+ "
                    //      +
                    //     parseInt(chatCardArray.length - 2);
                    break;
                } else {
                    displayResult =
                        displayResult +
                        chatCardArray[i].address.charAt(0).toLocaleUpperCase() +
                        " ";
                }
            } else if (chatCardArray.length == 2) {
                if (i === chatCardArray.length - 1) {
                    displayResult =
                        displayResult +
                        chatCardArray[i].address.charAt(0).toLocaleUpperCase();
                } else {
                    displayResult =
                        displayResult +
                        chatCardArray[i].address.charAt(0).toLocaleUpperCase() +
                        " ";
                }
            } else {
                displayResult =
                    chatCard.firstName && chatCard.lastName
                        ? chatCard.firstName.charAt(0).toLocaleUpperCase() +
                          chatCard.lastName.charAt(0).toLocaleUpperCase()
                        : chatCard.address.charAt(0).toLocaleUpperCase();
            }
        }

        if (displayResult) return displayResult;
    };
    const getTitle = () => {
        let result = [];
        for (let i = 0; i < chatCardArray.length; i++) {
            if (chatCardArray.length > 2) {
                if (i == 2) {
                    result.push(
                        <span>
                            {", + " + parseInt(chatCardArray.length - 2)}
                        </span>
                    );
                    break;
                } else if (i === 0) {
                    result.push(<span>{chatCardArray[i].firstName}</span>);
                } else {
                    result.push(
                        <span>{", " + chatCardArray[i].firstName}</span>
                    );
                }
            } else if (chatCardArray.length == 2) {
                if (i === chatCardArray.length - 1) {
                    result.push(<span>{chatCardArray[i].firstName}</span>);
                } else {
                    result.push(
                        <span>{chatCardArray[i].firstName + " and "}</span>
                    );
                }
            } else {
                result =
                    chatCard.firstName && chatCard.lastName
                        ? chatCard.firstName + " " + chatCard.lastName
                        : chatCard.address;
            }
        }
        return result;
    };

    const getValue = () => {
        let result = "";
        for (let i = 0; i < chatCardArray.length; i++) {
            if (chatCardArray.length > 1) {
                if (i === chatCardArray.length - 1) {
                    result = result + chatCardArray[i].firstName;
                } else {
                    result = result + chatCardArray[i].firstName + ", ";
                }
            } else {
                result =
                    chatCard.firstName && chatCard.lastName
                        ? chatCard.firstName + " " + chatCard.lastName
                        : chatCard.address;
            }
        }

        return result;
    };
    const getMessage = () => {
        let msg = "";
        if (
            chat.messageType !== "MESSAGE" ||
            chat.messageType !== "FILE_OBJECT" ||
            chat.messageType !== "FOLDER_OBJECT" ||
            chat.messageType !== "SPACE_OBJECT" ||
            chat.messageType !== "RIPPLE_OBJECT"
            // chat.messageType !== "MSG_INSTANCE_STEP" ||
            // chat.messageType !== "MSG_FLOW"
        ) {
            const messageArray = chat.message.split("|");
            messageArray.forEach((element, index) => {
                if (index) msg = msg + element;
                if (
                    !index &&
                    messageArray.length === 1 &&
                    chat.messageType === "MSG_WORKSPACE_OBJECT"
                )
                    msg = msg + element;
            });
        }
        if (chat.messageType === "MSG_FILE_OBJECT") {
            if (msg.split(":").length === 2) return msg.split(":")[1];
            else {
                const sharedObject = JSON.parse(msg);
                return sharedObject.folderFileName;
            }
        } else if (
            chat.messageType === "MSG_FOLDER_OBJECT" ||
            chat.messageType === "MSG_SPACE_OBJECT"
        ) {
            const sharedObject = JSON.parse(msg);
            return Object.keys(sharedObject)[0];
        } else if (chat.messageType === "MSG_WORKSPACE_OBJECT") {
            const sharedObject = JSON.parse(msg);
            return sharedObject.workspaceName;
        } else if (chat.messageType === "MSG_RIPPLE_OBJECT") {
            const sharedObject = JSON.parse(msg);
            return sharedObject.rippleName || "Unsaved Ripple";
        } else if (chat.messageType === "MSG_INSTANCE_STEP") {
            const sharedObject = JSON.parse(msg);
            return sharedObject.genericStepName || sharedObject.instanceName;
        } else if (chat.messageType === "MSG_FLOW") {
            if (msg) {
                const sharedObject = JSON.parse(msg);
                return sharedObject.genericDisplayName;
            } else return "Undefined";
        } else if (chat.messageType === "FILE_OBJECT") {
            return chat.message.split(":")[1];
        } else if (
            chat.messageType === "FOLDER_OBJECT" ||
            chat.messageType === "SPACE_OBJECT"
        ) {
            const sharedObject = JSON.parse(chat.message);
            return Object.keys(sharedObject)[0];
        } else if (chat.messageType === "RIPPLE_OBJECT") {
            const sharedObject = JSON.parse(chat.message);
            return sharedObject.rippleName || "Unsaved Ripple";
        } else return chat.message;
    };
    return (
        <div
            id={`communicatorChat${chatCard.address}`}
            style={{
                background: isContactSelected ? "rgb(220, 240, 255)" : "",
                position: "relative",
            }}
            onClick={() =>
                handleSelectedChat(chatCardArray, chat, contactIndex)
            }
        >
            <ContactItemView
                chatCard={chatCard}
                value={<span className="demo-2">{getTitle()}</span>}
                title={getValue()}
                subTitle={
                    (messageBy && messageBy.length && messageBy[0].firstName
                        ? messageBy[0].firstName
                        : messageBy && messageBy.length
                        ? messageBy[0].address
                        : "Message") +
                    ": " +
                    getMessage()
                }
                time={getChatTimestamp(chat.messageOn, "mm/dd/yyyy")}
                status={chat.isRead}
                avatar={getAvatar()}
            ></ContactItemView>
        </div>
    );
}
