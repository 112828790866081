import React, { Component, useEffect } from "react";
import user from "../../../bae-client/User";
import Loader from "../../stylized/Loader";
import AlertDialog from "../../stylized/AlertDialog";
import ResetOptions from "./ResetOptions";
import SettingsGeneral from "./SettingsGeneral";
import TitleBar from "./TiltleBar";
import WorkspaceSettings from "./WorkspaceSettings";
import AppInfo from "./AppInfo";
import RefreshData from "./RefreshData";
import config from "../../../config";
import FlowSettings from "./ContentPane/document-chain/settings";
import http from "../../../bae-client/HTTP";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import * as Mixpanel from "../../../mix-panel/Mixpanel";
import Trash_Bin from "./Trash_Bin";

class Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoader: false,
            emailConfigurationCuboidId: "",
            dashboardReportRuleConfCuboidId: "",
            value: "1",
            showAlertModal: false,
        };
        this.bwUserInfo = user.getBwUserInfo();
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    hideAlertView = () => {
        this.setState({ showAlertModal: false });
    };

    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };

    renderTitle = () => {
        return <TitleBar username={this.props.username} title={"Settings"} />;
    };

    handleTabChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    componentDidMount = () => {
        Mixpanel.callPageViewEvent("Settings");
    };

    renderContent = () => {
        const tabStyles = {
            width: "159px",
            height: "28px",
            color: "black",
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontSize: "18px",
            lineHeight: "29px",
            textTransform: "capitalize",
        };

        return (
            <Box sx={{ width: "100%", typography: "body1", height: "90vh" }}>
                <TabContext value={this.state.value}>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                            marginLeft: "24px",
                        }}
                    >
                        <TabList
                            onChange={this.handleTabChange}
                            aria-label="Settings Tabs"
                        >
                            <Tab
                                label="General"
                                value="1"
                                sx={tabStyles}
                                title="General"
                            />
                            <Tab
                                label="Workspaces"
                                value="3"
                                sx={tabStyles}
                                title="Workspaces"
                            />
                            {/* <Tab label="Rules" value="4" sx={tabStyles} />
                            {window.runtimeConfig.showRefreshDataTab && (
                                <Tab
                                    label="Refresh Data"
                                    value="4"
                                    sx={tabStyles}
                                />
                            )} */}
                            {/* <Tab
                                label="Email Configuration"
                                value="5"
                                sx={{ ...tabStyles, width: "250px" }}
                            /> */}
                            <Tab
                                label="Trash"
                                value="4"
                                sx={tabStyles}
                                title="Trash"
                            />
                            <Tab
                                label="App Info"
                                value="5"
                                sx={tabStyles}
                                title="App Info"
                            />
                        </TabList>
                    </Box>
                    <TabPanel value="1" style={{ height: "92%" }}>
                        <SettingsGeneral
                            bwUserInfo={this.bwUserInfo}
                            handleLogout={this.props.handleLogout}
                            setUser={this.props.setUser}
                        />
                    </TabPanel>
                    {/* <TabPanel value="2">
                        <ResetOptions />
                    </TabPanel> */}
                    <TabPanel value="3">
                        <WorkspaceSettings />
                    </TabPanel>
                    {/* <TabPanel value="4">
                        <RefreshData />
                    </TabPanel> */}
                    {/* <TabPanel value="5">
                        <FlowSettings config={config}></FlowSettings>
                    </TabPanel> */}
                    <TabPanel value="4">
                        <Trash_Bin />
                    </TabPanel>
                    <TabPanel value="5">
                        <AppInfo />
                    </TabPanel>
                </TabContext>
            </Box>
        );
    };

    render() {
        return (
            <>
                <Loader show={this.state.showLoader} />
                <AlertDialog
                    open={this.state.showAlertModal}
                    setOpen={this.hideAlertView}
                    title={this.errMsgTitle}
                    message={this.errMsgBody}
                />
                <div className="w-100 pl-0 pr-0 contentPaneBottom">
                    {this.renderContent()}
                </div>
            </>
        );
    }
}

export default Settings;
