import React, { useEffect, useState } from "react";
import {
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Checkbox,
    Menu,
    MenuItem,
    Modal,
    FormControl,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./style.css";
import { Table } from "react-bootstrap";
import CustomSearchDropdown from "../../../../stylized/CustomDropDown/index";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchFilterData,
    openInstance,
} from "../../../../../actions/DashboardActions";
import http from "../../../../../bae-client/HTTP";
import CustomDateDropdown from "../../../../stylized/CustomDateDropdown";
import { getParsedDateByDateAndTime } from "../../../../../bae-client/Utils";
import Loader from "../../../../stylized/Loader";
import { setActivePage, setActiveRipple } from "../../../../../actions";
import { setSnackbarAction } from "../../../../../services/services/app";
import AddDocuments from "../document-chain/modals/AddDocuments";
import AddInstance from "../document-chain/modals/AddInstance";
import DocumentViewerModal from "../../../../stylized/DocumentViewerModal";
import DocViwer from "../../../../stylized/DocViwer";
import AlertDialog from "../../../../stylized/AlertDialog";
import CustomSnackbar from "../../../../stylized/CustomSnackbar/CustomSnackbar";
import {
    setAllInstanceWithFilterAlert,
    setSelectedAlerts,
    setFinalFilter,
    setSelectedExceptionAlertIds,
    setSelectedFlowIdsAlert,
    setSelectedTimeFilterGlobalAlert,
    setSelectedFilterOptions,
    setAlertLogData,
    setInstanceList,
    setHomeAlertLogData,
} from "../../../../../actions/DocumentChainActions";
import { Box } from "@material-ui/core";
import "./style.css";

const AlertLog = () => {
    const selectedFlowIdsAlert = useSelector(
        (state) => state.selectedFlowIdsAlert
    );
    const selectedTimeFilterGlobalAlert = useSelector(
        (state) => state.selectedTimeFilterGlobalAlert
    );
    const selectedExceptionIdsAlert = useSelector(
        (state) => state.selectedExceptionIdsAlert
    );
    const selectedFinalFilter = useSelector(
        (state) => state.selectedFinalFilter
    );
    const selectedAlertIds = useSelector((state) => state.selectedAlertIds);
    const allInstanceWithFilterAlert = useSelector(
        (state) => state.allInstanceWithFilterAlert
    );
    const selectedFilterOptions = useSelector(
        (state) => state.selectedFilterOptions
    );
    const alertLogData = useSelector((state) => state.alertLogData);
    const homeAlertLogData = useSelector((state) => state.homeAlertLogData);

    const dispatch = useDispatch();
    const [genericList, setGenericList] = React.useState([]);
    const [instanceList, setInstanceLists] = React.useState([]);
    const [stepNameList, setStepNameList] = React.useState([]);
    const [isApplyDisabled, setIsApplyDisabled] = useState(true);

    const [
        selectedGenericDropdownLocalValue,
        setSelectedGenericDropdownLocalValue,
    ] = React.useState(null);
    const [
        selectedAlertTypeDropdownLocalValue,
        setSelectedAlertTypeDropdownLocalValue,
    ] = React.useState([]);
    const [
        selectedInstanceDropdownLocalValue,
        setSelectedInstanceDropdownLocalValue,
    ] = React.useState([]);
    const [
        selectedStepNamesDropdownLocalValue,
        setSelectedStepNamesDropdownLocalValue,
    ] = React.useState([]);

    const [dateErr, setDateErr] = React.useState(false);
    const [selectedTimeState, setSelectedTimeState] = React.useState({});
    const filterRawDataRedux = useSelector(
        (state) => state.DashboardDCFilterData
    );
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [selected, setSelected] = React.useState([]);
    const [alertData, setAlertData] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [checkDisplay, setCheckDisplay] = useState(false);
    const [allWithFilter, setAllWithFilter] = useState([]);
    const [isDocumentAlert, setIsDocumentAlert] = React.useState(false);
    const [isDocAdditionAlert, setIsDocAdditionAlert] = React.useState(false);
    const [isRippleAlert, setIsRippleAlert] = React.useState(false);
    const [isExceptionAlert, setIsExceptionAlert] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showDocumentModal, setShowDocumentModal] = React.useState(false);
    const [values, setValues] = React.useState(null);
    const [flowInfo, setFlowInfo] = React.useState(null);
    const [showAddInstanceModal, setShowAddInstanceModal] = useState(false);

    const [checkRead, setCheckRead] = useState(true);
    const [className, setClassName] = React.useState("headerSortDown");
    const [classAlertTypeDate, setclassAlertTypeDate] =
        React.useState("headerSortDown");
    const [classDescDate, setclassDescDate] = React.useState("headerSortDown");

    const [classCDate, setclassCDate] = React.useState("headerSortDown");
    const [classStatusDate, setclassStatusDate] =
        React.useState("headerSortDown");
    const [classCategoryDate, setclassCategoryDate] =
        React.useState("headerSortDown");
    const [classSourceDate, setclassSourceDate] =
        React.useState("headerSortDown");
    const InstanceList = useSelector((state) => state.InstanceList);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [selectedInstance, setSelectedInstance] = React.useState(null);
    const [documentIdState, setDocumentIdState] = React.useState(null);
    const [documentModalShow, setDocumentModalShow] = useState(false);
    const [currentShowFileName, setCurrentShowFileName] = useState("");
    const [currentShowFileLocation, setCurrentShowFileLocation] = useState("");
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const setOpenDialog = (flag) => setOpenSnackMsg(flag);
    const [openSnackMsg, setOpenSnackMsg] = useState(false);
    const [message, setMessage] = useState("");
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (allWithFilter.length) setAlertData(allWithFilter);
    }, [allWithFilter]);
    useEffect(() => {
        selectedFinalFilter === null
            ? alertLogData?.length
                ? setAlertData(alertLogData)
                : void 0
            : setAlertData(selectedFinalFilter);
    }, []);
    useEffect(() => {
        if (!alertLogData) {
            setShowLoader(true);
        } else setShowLoader(false);
        if (
            selectedFinalFilter === null &&
            alertLogData?.length &&
            !selectedOptions.includes("closed")
        ) {
            // setAlertData(alertLogData);
            sortData("desc", alertLogData, "Date");
        } else {
            if (!validateToApply()) {
                handleApplyFilter(true, true);
            }
        }
    }, [alertLogData]);
    // useEffect(() => {
    //     loadAlerts();
    // }, [filterRawDataRedux]);
    useEffect(() => {
        if (selectedFinalFilter !== null) {
            setAlertData(selectedFinalFilter);
        }
    }, [selectedFinalFilter]);
    const style = {
        position: "absolute",
        top: "50%",
        left: "53%",
        transform: "translate(-50%, -50%)",
        // width: "211px",
        // height: "280px",
        // maxWidth: "432px",
        bgcolor: "background.paper",
        border: "1px solid #D7DEEA",
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        borderRadius: "8px",
    };

    const modalTitleStyle = {
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "28px",
        letterSpacing: "-0.017em",
        textAlign: "left",
    };

    const closeButtonStyle = {
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
        fontSize: "24px",
    };
    const resetButtonStyle = {
        width: "83px",
        height: "36px",
        padding: "6px 16px",
        gap: "8px",
        borderRadius: "4px",
        border: "1px solid #D7DEEA",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };
    const applyButtonStyle = {
        width: "92px",
        height: "36px",
        padding: "6px 16px",
        gap: "8px",
        borderRadius: "4px",
        backgroundColor: "#0B5CD7",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const updateAlertData = (alerts) => {
        let finalAlertData = [];
        alerts.forEach((alert) => {
            if (alert.hasOwnProperty("exceptionLogObjectId")) {
                if (alert.status !== "CLOSED") {
                    finalAlertData.push(alert);
                }
            } else {
                if (alert.isCleared === false) {
                    finalAlertData.push(alert);
                }
            }
        });
        return finalAlertData;
    };
    const loadAlerts = (sortReset) => {
        const url = "alert/list?status=OPEN&size=50";
        setShowLoader(true);
        http.sendRestRequest(url, "GET")
            .then((response) => {
                // setAlertData(response);
                // if (isSorted) sortData(
                //     sortReset
                //         ? "desc"
                //         : orderBy === "asc"
                //             ? "desc"
                //             : "asc",
                //     // response
                // )
                const sortedAlerts = [...response].sort(
                    (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
                );
                isSorted
                    ? sortData(
                          sortReset
                              ? "desc"
                              : orderBy === "asc"
                              ? "desc"
                              : "asc",
                          response,
                          orderByColumn.current
                      )
                    : dispatch(setAlertLogData(sortedAlerts));
                setAllWithFilter(sortedAlerts);
                setShowLoader(false);
            })
            .catch((error) => {
                setShowLoader(false);
                console.error("Error fetching alert data: ", error);
            });
    };
    const alertTypeList = [
        {
            text: "DELAYED",
            value: "DELAYED",
            id: "DELAYED",
        },
        {
            text: "SKIPPED",
            value: "SKIPPED",
            id: "SKIPPED",
        },
        {
            text: "KEY MISSING",
            value: "KEY MISSING",
            id: "KEY MISSING",
        },
        {
            text: "KEY MISMATCH",
            value: "KEY MISMATCH",
            id: "KEY MISMATCH",
        },
        {
            text: "PROACTIVE ALERT",
            value: "PROACTIVE ALERT",
            id: "PROACTIVE ALERT",
        },
        {
            text: "DATE CHANGED",
            value: "DATE CHANGED",
            id: "DATE CHANGED",
        },
        {
            text: "RIPPLE",
            value: "ripple",
            id: "RIPPLE",
        },
        {
            text: "DOCUMENT",
            value: "DOCUMENT",
            id: "DOCUMENT",
        },
        {
            text: "OTHERS",
            value: "OTHERS",
            id: "OTHERS",
        },
    ];

    useEffect(() => {
        fetchLatestFlowsData();
    }, []);
    const createGenericDropdownList = (response) => {
        let itemList = [];
        response.forEach((filterItem) => {
            itemList.push({
                text: filterItem.genericRegistry.genericDisplayName,
                value: filterItem.genericRegistry.genericDisplayName,
                id: filterItem.genericRegistry.genericId,
            });
        });
        itemList.sort((a, b) => a.text.localeCompare(b.text));

        setGenericList(itemList);
    };
    const handleOptionClick = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((o) => o !== option));
        } else {
            setSelectedOptions([option]);
        }
    };
    const handleOptionClickAll = () => {
        if (selectedOptions.length === 4) {
            setSelectedOptions([]);
        } else {
            setSelectedOptions(["read", "unread", "open", "closed"]);
        }
    };

    const fetchLatestFlowsData = () => {
        http.sendRestRequest("documentChain/objects")
            .then((response) => {
                dispatch(fetchFilterData(response));
                createGenericDropdownList(response);
            })
            .catch((err) => console.log(err));
    };
    const handleAlertTypeSelection = (index, selectedExceptionsValues) => {
        setSelectedAlertTypeDropdownLocalValue(selectedExceptionsValues);
        setIsApplyDisabled(false);
    };
    const handleSelectedTimeFilterData = (data) => {
        setSelectedTimeState(data);
        setIsApplyDisabled(false);
    };
    function getSelectedGenericIndex(selectedGeneric) {
        let genericIndex;
        filterRawDataRedux.forEach((generic, index) => {
            if (generic.genericRegistry.genericId === selectedGeneric.id) {
                genericIndex = index;
            }
        });
        return genericIndex;
    }
    const createInstanceDropdownList = (genericIndex) => {
        let instances = filterRawDataRedux[genericIndex].instances;
        let instanceList = [];
        instances.forEach((instance) => {
            instanceList.push({
                text: instance.instanceName,
                value: instance.instanceName,
                id: instance.instanceId,
            });
        });
        setInstanceLists(instanceList);
    };
    const createStepNamesDropdownList = (genericIndex) => {
        let itemList = [];
        filterRawDataRedux[genericIndex]?.stepNames.forEach((item) => {
            itemList.push({
                text: item,
                value: item,
                id: item,
            });
        });
        setStepNameList(itemList);
    };
    const createData = (
        id,
        alertType,
        flow,
        instance,
        stepName,
        description,
        date,
        index,
        isFlow,
        clickAction,
        clickActionParameters,
        instanceId,
        genericId,
        isRead,
        status,
        source,
        category
    ) => {
        return {
            id,
            alertType,
            flow,
            instance,
            stepName,
            description,
            date,
            index,
            isFlow,
            clickAction,
            clickActionParameters,
            instanceId,
            genericId,
            isRead,
            status,
            source,
            category,
        };
    };
    const handleGenericSelection = (index, selectedGeneric) => {
        setSelectedGenericDropdownLocalValue(selectedGeneric);
        let genericIndex = getSelectedGenericIndex(selectedGeneric);
        createInstanceDropdownList(genericIndex);
        createStepNamesDropdownList(genericIndex);
        setSelectedInstanceDropdownLocalValue([]);
        setIsApplyDisabled(false);
        setSelectedStepNamesDropdownLocalValue([]);
    };
    const handleInstancesSelection = (index, selectedInstances) => {
        setSelectedInstanceDropdownLocalValue(selectedInstances);
        setIsApplyDisabled(false);
    };
    const [opens, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClosed = () => {
        setOpen(false);
        setPage(0);
        setRowsPerPage(100);
        setIsApplyDisabled(false);
    };
    const handleStepNameSelection = (index, selectedStepNames) => {
        setSelectedStepNamesDropdownLocalValue(selectedStepNames);
        setIsApplyDisabled(false);
    };
    const listRows =
        alertData && Array.isArray(alertData)
            ? alertData.map((item, index) =>
                  createData(
                      item?.exceptionLogObjectId
                          ? item?.exceptionLogObjectId
                          : item.alertRegistryId,
                      item.exceptionType || item.alertType,
                      item.genericDisplayName,
                      item.instanceName,
                      item.stepName,
                      item.exceptionText || item.alertBody,
                      getParsedDateByDateAndTime(item.createdOn),
                      index,
                      item?.exceptionLogObjectId ? true : false,
                      item.clickAction,
                      item.clickActionParameters,
                      item.instanceId,
                      item.genericId,
                      item?.isRead,
                      item?.status,
                      item?.source,
                      item?.category
                  )
              )
            : [];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        console.log(+event.target.value);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = listRows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    useEffect(() => {
        setSelectedAlertTypeDropdownLocalValue(selectedAlertIds);

        setSelectedTimeState(selectedTimeFilterGlobalAlert);
        if (selectedFlowIdsAlert) {
            setSelectedGenericDropdownLocalValue(selectedFlowIdsAlert);
            let genericIndex = getSelectedGenericIndex(selectedFlowIdsAlert);
            if (genericIndex) {
                createInstanceDropdownList(genericIndex);
                createStepNamesDropdownList(genericIndex);
            }
        }
        setSelectedStepNamesDropdownLocalValue(selectedExceptionIdsAlert);
        setSelectedInstanceDropdownLocalValue(allInstanceWithFilterAlert);
        setSelectedOptions(selectedFilterOptions);
    }, [
        selectedAlertIds,
        selectedTimeFilterGlobalAlert,
        selectedFlowIdsAlert,
        selectedExceptionIdsAlert,
        allInstanceWithFilterAlert,
        selectedFilterOptions,
    ]);
    const getLast14Days = () => {
        const today = new Date(); // Current date
        const endDate = today; // End date is today
        const startDate = new Date(today); // Clone the current date for start date
        startDate.setDate(today.getDate() - 14); // Subtract 14 days for start date

        return {
            startDate: startDate.toISOString().split("T")[0], // Format as YYYY-MM-DD
            endDate: endDate.toISOString().split("T")[0], // Format as YYYY-MM-DD
        };
    };

    const handleReset = () => {
        const data = {
            selectedTimeType: "Last 14 days",
            ...getLast14Days(), // Get the dynamic start and end dates
        };

        setSelectedTimeState(data);
        setSelectedAlertTypeDropdownLocalValue([]);
        setStepNameList([]);
        setIsApplyDisabled(true);
        setInstanceLists([]);
        setSelectedGenericDropdownLocalValue([]);
        setSelectedStepNamesDropdownLocalValue([]);
        setSelectedInstanceDropdownLocalValue([]);
        setSelectedOptions([]);
        dispatch(setSelectedFlowIdsAlert(null));
        dispatch(setSelectedExceptionAlertIds(null));
        dispatch(setSelectedTimeFilterGlobalAlert(null));
        dispatch(setAllInstanceWithFilterAlert(null));
        dispatch(setSelectedAlerts(null));
        dispatch(setFinalFilter(null));
        dispatch(setSelectedFilterOptions([]));
        setclassAlertTypeDate("headerSortDown");
        setclassCDate("headerSortDown");
        setclassStatusDate("headerSortDown");
        setclassCategoryDate("headerSortDown");
        setclassSourceDate("headerSortDown");
        setclassDescDate("headerSortDown");
        setOrderBy("asc");
        setIsSorted(false);
        setDateErr(false);
        const sortedAlerts = [...alertLogData].sort(
            (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
        );
        alertLogData?.length
            ? setAlertData(sortedAlerts)
            : loadAlerts("resetSort");
    };

    const handleApplyFilter = (notUpdateRedux, alertsUpdateByRedux) => {
        let putPayload;
        let startDate, endDate;
        let selectedOptionsLocal;

        if (alertsUpdateByRedux) {
            selectedOptionsLocal = selectedFilterOptions;
            const stepNames = Array.isArray(selectedExceptionIdsAlert)
                ? selectedExceptionIdsAlert.map((item) => item.id)
                : [];
            let alertTypes = Array.isArray(selectedAlertIds)
                ? selectedAlertIds.map((item) => item.value)
                : [];
            const genericIds = selectedFlowIdsAlert && selectedFlowIdsAlert.id;
            const instanceIds = Array.isArray(allInstanceWithFilterAlert)
                ? allInstanceWithFilterAlert.map((item) => item.id)
                : [];
            startDate =
                selectedTimeFilterGlobalAlert &&
                selectedTimeFilterGlobalAlert.startDate;
            endDate =
                selectedTimeFilterGlobalAlert &&
                selectedTimeFilterGlobalAlert.endDate;

            let documentTypeIndex = alertTypes.indexOf("DOCUMENT");
            if (documentTypeIndex >= 0) {
                alertTypes[documentTypeIndex] = "document addition";
                alertTypes.push("Document Intersection");
            }
            putPayload = {
                // includeClearedAlerts: checkDisplay,
                alertTypes: alertTypes,
                genericIds: genericIds ? [genericIds] : [],
                instanceIds: instanceIds,
                stepNames: stepNames,
            };
        } else {
            selectedOptionsLocal = selectedOptions;
            const stepNames = Array.isArray(selectedStepNamesDropdownLocalValue)
                ? selectedStepNamesDropdownLocalValue.map((item) => item.id)
                : [];
            let alertTypes = Array.isArray(selectedAlertTypeDropdownLocalValue)
                ? selectedAlertTypeDropdownLocalValue.map((item) => item.value)
                : [];
            const genericIds =
                selectedGenericDropdownLocalValue &&
                selectedGenericDropdownLocalValue.id;
            const instanceIds = Array.isArray(
                selectedInstanceDropdownLocalValue
            )
                ? selectedInstanceDropdownLocalValue.map((item) => item.id)
                : [];

            startDate = selectedTimeState && selectedTimeState.startDate;
            endDate = selectedTimeState && selectedTimeState.endDate;
            let documentTypeIndex = alertTypes.indexOf("DOCUMENT");
            if (documentTypeIndex >= 0) {
                alertTypes[documentTypeIndex] = "document addition";
                alertTypes.push("Document Intersection");
            }
            putPayload = {
                // includeClearedAlerts: checkDisplay,
                alertTypes: alertTypes,
                genericIds: genericIds ? [genericIds] : [],
                instanceIds: instanceIds,
                stepNames: stepNames,
            };
        }

        if (
            startDate !== "" &&
            startDate !== null &&
            startDate !== undefined &&
            startDate !== "NaN-NaN-NaN" &&
            endDate !== "" &&
            endDate !== null &&
            endDate !== undefined &&
            endDate !== "NaN-NaN-NaN"
        ) {
            putPayload = {
                ...putPayload,
                startDate: `${startDate} 00:00:00.000`,
                endDate: `${endDate} 23:59:59.999`,
            };
        }
        if (
            selectedOptionsLocal.includes("read") &&
            !selectedOptionsLocal.includes("unread")
        ) {
            putPayload = { ...putPayload, isRead: true };
        }
        if (
            !selectedOptionsLocal.includes("read") &&
            selectedOptionsLocal.includes("unread")
        ) {
            putPayload = { ...putPayload, isRead: false };
        }
        if (
            selectedOptionsLocal.includes("open") &&
            !selectedOptionsLocal.includes("closed")
        ) {
            putPayload = { ...putPayload, status: "OPEN" };
        }
        if (
            !selectedOptionsLocal.includes("open") &&
            selectedOptionsLocal.includes("closed")
        ) {
            putPayload = { ...putPayload, status: "CLOSED" };
        }
        if (selectedOptionsLocal.includes("closed")) {
            setShowLoader(true);
            http.sendRestRequest("alert/filter", "POST", {
                ...putPayload,
                size: 50,
            }).then((data) => {
                let filteredData = data;

                sortData("desc", filteredData, "Date");
                setShowLoader(false);
            });
        } else {
            if (alertsUpdateByRedux) return;
            let url = "alert/filter";
            http.sendRestRequest(url, "POST", {
                ...putPayload,
                status: "OPEN",
                size: 50,
            }).then((data) => {
                let filteredData = data;

                isSorted
                    ? sortData(
                          orderBy === "asc" ? "desc" : "asc",
                          filteredData,
                          orderByColumn.current
                      )
                    : setAlertData(filteredData);
                // if (notUpdateRedux) return;
                dispatch(
                    setSelectedFlowIdsAlert(
                        alertsUpdateByRedux
                            ? selectedFlowIdsAlert
                            : selectedGenericDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedExceptionAlertIds(
                        alertsUpdateByRedux
                            ? selectedExceptionIdsAlert
                            : selectedStepNamesDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedTimeFilterGlobalAlert(
                        alertsUpdateByRedux
                            ? selectedTimeFilterGlobalAlert
                            : selectedTimeState
                    )
                );
                dispatch(
                    setAllInstanceWithFilterAlert(
                        alertsUpdateByRedux
                            ? allInstanceWithFilterAlert
                            : selectedInstanceDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedAlerts(
                        alertsUpdateByRedux
                            ? selectedAlertIds
                            : selectedAlertTypeDropdownLocalValue
                    )
                );
                dispatch(setSelectedFilterOptions(selectedOptionsLocal));
                const sortedAlerts = [...filteredData].sort(
                    (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
                );
                dispatch(setFinalFilter(sortedAlerts));
                handleClosed();
            });
        }
    };

    const handleClick = (event, index, row) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(row.id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row.id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const useStyles = makeStyles({
        root: {
            borderRadius: "4px !important",
            display: "inline-flex",
            flexDirection: "column",
            position: "relative",
            minWidth: "0px",
            padding: "0px",
            margin: "8px",
            border: "0px",
            verticalAlign: "top",
            width: "100%",
        },
        moreBtn: {
            borderRadius: "6px !important",
            display: "inline-flex",
            flexDirection: "column",
            position: "relative",
            minWidth: "0px",
            padding: "0px",
            margin: "8px",
            border: "0px",
            verticalAlign: "top",
            width: "97%",
        },
        dateRoot: {
            marginBottom: "5%",
            marginLeft: "0.5%",
        },
        compareDate: {
            marginBottom: "7%",
        },
    });
    const classes = useStyles();
    const orderByColumn = React.useRef("");
    const isSelected = (index) => selected.indexOf(index) !== -1;
    const [orderBy, setOrderBy] = React.useState("asc");
    const [isSorted, setIsSorted] = React.useState(false);
    function sortAscending(a, b) {
        if (
            a[orderByColumn.current]?.toUpperCase() <
            b[orderByColumn.current]?.toUpperCase()
        ) {
            return -1;
        }
        if (
            a[orderByColumn.current]?.toUpperCase() >
            b[orderByColumn.current]?.toUpperCase()
        ) {
            return 1;
        }
        return 0;
    }
    function sortDescending(a, b) {
        if (
            b[orderByColumn.current]?.toUpperCase() <
            a[orderByColumn.current]?.toUpperCase()
        ) {
            return -1;
        }
        if (
            b[orderByColumn.current]?.toUpperCase() >
            a[orderByColumn.current]?.toUpperCase()
        ) {
            return 1;
        }
        return 0;
    }
    const sortAscendingAlert = (a, b) => {
        let valueA = a.alertType || a.exceptionType || ""; // Get alertType or exceptionType from the first object
        let valueB = b.alertType || b.exceptionType || ""; // Get alertType or exceptionType from the second object

        // Convert to lowercase to ensure case-insensitive sorting
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();

        // Compare values alphabetically
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
    };
    const convertHtmlToText = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, "text/html");

        // Select all elements
        const allElements = doc.body.querySelectorAll("*"); // '*' selects all elements

        // Extract text and trim it to remove extra spaces and newlines
        const combinedText = Array.from(allElements)
            .map((element) => element.textContent.trim()) // Trim whitespace
            .filter((text) => text.length > 0) // Filter out empty strings
            .join(" "); // Join with a single space
        return combinedText;
    };
    const sortAscendingDescription = (a, b) => {
        let valueA = convertHtmlToText(a.exceptionText) || a.alertBody || "";
        let valueB = convertHtmlToText(b.exceptionText) || b.alertBody || "";

        // Convert to lowercase to ensure case-insensitive sorting
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();

        // Compare values alphabetically
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
    };

    const sortDescendingDescription = (a, b) => {
        let valueA = convertHtmlToText(a.exceptionText) || a.alertBody || "";
        let valueB = convertHtmlToText(b.exceptionText) || b.alertBody || "";

        // Convert to lowercase to ensure case-insensitive sorting
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();

        // Compare values alphabetically
        if (valueA < valueB) return 1;
        if (valueA > valueB) return -1;
        return 0;
    };
    const sortDescendingAlert = (a, b) => {
        let valueA = a.alertType || a.exceptionType || ""; // Get alertType or exceptionType from the first object
        let valueB = b.alertType || b.exceptionType || ""; // Get alertType or exceptionType from the second object

        // Convert to lowercase to ensure case-insensitive sorting
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();

        // Compare values alphabetically
        if (valueA < valueB) return 1;
        if (valueA > valueB) return -1;
        return 0;
    };

    const sortData = (orderBy, response, columnName) => {
        switch (columnName) {
            case "Alert Type":
                orderByColumn.current = "Alert Type";
                orderBy === "asc"
                    ? setclassAlertTypeDate("headerSortUp")
                    : setclassAlertTypeDate("headerSortDown");
                break;
            case "Date":
                orderByColumn.current = "createdOn";
                orderBy === "asc"
                    ? setclassCDate("headerSortUp")
                    : setclassCDate("headerSortDown");
                break;
            case "Status":
                orderByColumn.current = "status";
                orderBy === "asc"
                    ? setclassStatusDate("headerSortUp")
                    : setclassStatusDate("headerSortDown");
                break;
            case "Category":
                orderByColumn.current = "category";
                orderBy === "asc"
                    ? setclassCategoryDate("headerSortUp")
                    : setclassCategoryDate("headerSortDown");
                break;
            case "Source":
                orderByColumn.current = "source";
                orderBy === "asc"
                    ? setclassSourceDate("headerSortUp")
                    : setclassSourceDate("headerSortDown");
                break;
            case "Description":
                orderByColumn.current = "Description";
                orderBy === "asc"
                    ? setclassDescDate("headerSortUp")
                    : setclassDescDate("headerSortDown");
                break;
            case "Actions":
                return;
        }
        let dataToSort = response ? response : [...alertData];
        let sortedData;
        if (columnName === "Alert Type") {
            sortedData = dataToSort.sort(
                orderBy === "asc" ? sortAscendingAlert : sortDescendingAlert
            );
        } else if (columnName === "Description") {
            sortedData = dataToSort.sort(
                orderBy === "asc"
                    ? sortAscendingDescription
                    : sortDescendingDescription
            );
        } else {
            sortedData = dataToSort.sort(
                orderBy === "asc" ? sortAscending : sortDescending
            );
        }

        setAlertData([]);
        setAlertData(sortedData);
    };
    const handleSorting = (columnName) => {
        setIsSorted(true);

        sortData(orderBy, null, columnName);
        orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    };
    const columns = [
        { id: "alertType", label: "Alert Type", minWidth: 129 },
        // { id: "flow", label: "Flow", minWidth: 70 },
        // { id: "instance", label: "Instance", minWidth: 30 },
        // { id: "stepName", label: "Step Name", minWidth: 115 },
        { id: "status", label: "Status", minWidth: 40 },
        { id: "category", label: "Category", minWidth: 40 },
        { id: "source", label: "Source", minWidth: 159 },
        { id: "description", label: "Description", minWidth: 159 },
        { id: "date", label: "Date", minWidth: 50 },
        { id: "actions", label: "Actions", minWidth: 30 },
    ];
    const getIconSrc = (type) => {
        switch (type) {
            case "PROACTIVE ALERT":
                return "./images/delayedAlert.svg";
            case "DELAYED":
                return "./images/stepAlert.svg";
            case "SKIPPED":
                return "./images/skipped.svg";
            case "KEY MISSING":
                return "./images/delayedAlert.svg";
            case "KEY MISMATCH":
                return "./images/delayedAlert.svg";
            case "DATE CHANGED":
                return "./images/delayedAlert.svg";
            case "RIPPLE":
                return "./images/rip.svg";
            case "DOCUMENT":
                return "./images/docss.svg";
            case "OTHERS":
                return "./images/othersAlert.svg";
            default:
                return null;
        }
    };
    const renderViewDocumentModal = (event) => {
        return (
            <DocViwer
                docName={currentShowFileName}
                fileLocation={currentShowFileLocation}
            ></DocViwer>
        );
    };
    const handleDocumentModalClose = () => {
        setDocumentModalShow(false);
        setCurrentShowFileLocation("");
    };
    const addNewInstance = (name, tags, branchId, branchName) => {
        setShowLoader(true);
        setShowAddInstanceModal(false);
        let encodedFamilyTags = encodeURIComponent(tags);
        http.sendRestRequest(
            `documentChain/instance?genericId=${
                selectedRow.id
            }&instanceName=${encodeURIComponent(
                name
            )}&familyTags=${encodedFamilyTags}&branchId=${branchId}&branchName=${branchName}`,
            "POST"
        )
            .then(() => {
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "Instance created successfully!",
                    })
                );
                http.sendRestRequest(
                    `documentChain/instance/list?genericId=${selectedRow.id}`
                )
                    .then((instanceData) => {
                        setShowLoader(false);
                        const prevInstances = [...InstanceList].map(
                            (instance) => instance.instanceId
                        );
                        dispatch(setInstanceList(instanceData));

                        const newInstance = instanceData.filter(
                            (instance) =>
                                !prevInstances.includes(instance.instanceId)
                        );
                        setSelectedInstance(newInstance[0]);
                        setShowDocumentModal(true);
                    })
                    .catch((error) => {
                        setShowLoader(false);
                        dispatch(
                            setSnackbarAction({
                                open: true,
                                message: error.error,
                                severity: "error",
                            })
                        );
                    });
                fetchLatestFlowsData();
            })
            .catch((error) => {
                setShowLoader(false);
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: error.error,
                        severity: "error",
                    })
                );
            });
    };
    const rippleAlertClickHandler = (alert) => {
        if (alert.alertType === "ripple") {
            let rippleRegistryId = parseInt(alert.clickActionParameters);
            let url = `ripple/rippleRegistry/${rippleRegistryId}`;
            http.sendRestRequest(url, "GET").then((resp) => {
                let rippleData = resp;
                rippleData["rippleId"] = resp.rippleRegistryId;
                dispatch(setActiveRipple(rippleData));

                document.getElementById("nav_Ripple").click();
            });
        }
    };
    const openAddInstanceModal = () => {
        setShowAddInstanceModal(true);
        setShowDocumentModal(false);
    };
    const closeInstance = () => {
        setShowAddInstanceModal(false);
        setShowDocumentModal(true);
    };
    const openInstancePage = () => {
        const libData = {
            genericId: selectedAlert.genericId,
            genericName: selectedAlert.flow,
            instanceIds: [selectedAlert.instanceId],
        };
        // dispatch(setActivePage("DocuChain"));
        dispatch(openInstance(libData));
        document.getElementById("nav_Flow").click();
    };
    const openFileHandler = (selectedAlert) => {
        setShowLoader(true);
        http.sendRestRequest(
            `document/documentDetail?documentId=${parseInt(
                selectedAlert.clickActionParameters
            )}`
        )
            .then((response) => {
                setShowLoader(false);
                setCurrentShowFileName(response.documentName);
                setDocumentModalShow(true);
                setCurrentShowFileLocation(response);
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
                console.log(error);
            });
    };
    const handleClose = (e) => {
        if (e && e.target && e.target.innerText) {
            if (e.target.innerText === "Open Instance") {
                openInstancePage();
            } else if (e.target.innerText === "Open in Ripple") {
                // openInstancePage();
                rippleAlertClickHandler(selectedAlert);
            } else if (e.target.innerText === "Attach to Flow") {
                //attachToFlow();
                if (selectedAlert?.clickActionParameters) {
                    let flowName,
                        instanceName,
                        branchName,
                        stepName = "";
                    const data =
                        selectedAlert?.clickActionParameters?.split("&&");
                    const documentId = data[0];
                    setDocumentIdState(documentId);
                    if (documentId) {
                        http.sendRestRequest("document/documentInfo", "POST", [
                            parseInt(documentId),
                        ])
                            .then((result) => {
                                const locationIds =
                                    result[0]?.locationIds?.split("\\");
                                const workspaceId = locationIds[0];
                                const spaceId = locationIds[1];
                                let folderId;
                                if (locationIds.length === 2) {
                                    folderId = 0;
                                } else {
                                    folderId =
                                        locationIds[locationIds.length - 1];
                                }

                                const value = {
                                    workSpaceId: workspaceId,
                                    spaceId,
                                    folderId:
                                        folderId === spaceId ? "0" : folderId,
                                    documentId,
                                };
                                setValues(value);
                                const flowInfo = data[1]?.split(":");
                                if (flowInfo?.length > 1)
                                    if (flowInfo[0] === "Flow") {
                                        flowName = flowInfo[1];
                                    } else if (flowInfo[0] === "Instance") {
                                        const arr = flowInfo[1].split("/");
                                        flowName = arr[0];
                                        instanceName = arr[1];
                                    } else if (flowInfo[0] === "Step") {
                                        const arr = flowInfo[1].split("/");
                                        flowName = arr[0];
                                        instanceName = arr[1];
                                        branchName = arr.length === 4 && arr[2];
                                        stepName = arr[arr.length - 1];
                                    }
                                setFlowInfo({
                                    flowName,
                                    instanceName,
                                    branchName,
                                    stepName,
                                });
                                setShowDocumentModal(true);
                            })
                            .catch((err) => {
                                console.error(err);
                                dispatch(
                                    setSnackbarAction({
                                        snackbarOpen: true,
                                        snackbarType: "error",
                                        snackbarMessage:
                                            err?.error ||
                                            "Something went wrong!",
                                    })
                                );
                            });
                    }
                }
            } else if (e.target.innerText === "Open File") {
                openFileHandler(selectedAlert);
            }
            handleMarkAsReadOrUnRead("read", [selectedAlert.id]);
        }

        setAnchorEl(null);
        setIsDocumentAlert(false);
        setIsRippleAlert(false);
        setIsExceptionAlert(false);
        setIsDocAdditionAlert(false);
    };
    const handleClickMenu = (event, row) => {
        if (row.alertType === "Document Intersection") {
            setIsDocumentAlert(true);
            setSelectedAlert(row);
        } else if (row.alertType === "document addition") {
            setIsDocAdditionAlert(true);
            setSelectedAlert(row);
        } else if (row.alertType === "ripple") {
            setIsRippleAlert(true);
            setSelectedAlert(row);
        } else if (row.isFlow) {
            setIsExceptionAlert(true);
            setSelectedAlert(row);
        }
        setAnchorEl(event.currentTarget);
    };

    const getCell = (column, row, value, rid, cid) => {
        let isRead = row?.isRead;
        if (column === "actions")
            return (
                <div
                    className={"cell_style"}
                    style={{
                        cursor: "pointer",
                        height: "24px",
                    }}
                    id={"menu_option_" + rid}
                    onClick={(event) => {
                        // event.stopPropagation();
                        handleClickMenu(event, row);
                    }}
                >
                    <img
                        alt={"options"}
                        title="option"
                        src="./images/options.png"
                        style={{ marginLeft: "20px" }}
                    ></img>
                </div>
            );
        else if (column === "alertType") {
            let alertType =
                value.toUpperCase().split(" ")[0] === "DOCUMENT"
                    ? "DOCUMENT"
                    : value.toUpperCase();
            return (
                <div
                    className={`${styles.alertTypeCell} cell_style`}
                    style={{
                        width: "121px",
                        height: "24px",
                    }}
                    title={alertType}
                >
                    {getIconSrc(alertType) && (
                        <img
                            src={getIconSrc(alertType)}
                            alt={`${value} alert icon`}
                            style={{
                                width: "33px",
                                height: "24px",
                                paddingRight: "6px",
                            }}
                        />
                    )}
                    <span
                        className={
                            !isRead ? "checkMark cell_bold_style" : "cell_style"
                        }
                    >
                        {value.toUpperCase().split(" ")[0]}
                    </span>
                </div>
            );
        } else if (column === "Status" || column === "Category") {
            return (
                <div
                    className={`cell_style`}
                    style={{
                        width: "100px",
                        height: "24px",
                    }}
                    title={value}
                >
                    <span
                        className={
                            !isRead ? "checkMark cell_bold_style" : "cell_style"
                        }
                    >
                        {value}
                    </span>
                </div>
            );
        } else if (column === "description")
            return (
                <div
                    className={
                        !isRead
                            ? "checkMark textEllipsis cell_bold_style"
                            : "textEllipsis cell_style"
                    }
                    title={value}
                    dangerouslySetInnerHTML={{
                        __html: value,
                    }}
                    style={{
                        maxWidth: "280px",
                        height: "24px",
                    }}
                ></div>
            );
        else if (column === "source")
            return (
                <div
                    className={
                        !isRead
                            ? "checkMark textEllipsis cell_bold_style"
                            : "textEllipsis cell_style"
                    }
                    title={value}
                    style={{
                        maxWidth: "200px",
                        height: "24px",
                    }}
                >
                    {value}
                </div>
            );
        else
            return (
                <div
                    className={
                        !isRead
                            ? "checkMark textEllipsis cell_bold_style"
                            : "textEllipsis cell_style"
                    }
                    id={"flow_" + value}
                    title={value}
                    style={{
                        // width:"301px",
                        maxWidth: "135px",
                        height: "24px",
                    }}
                >
                    {value}
                </div>
            );
    };
    const handleMarkAsOpenandClosed = (type) => {
        const selectedAlerts = [];
        const selectedExceptions = [];
        let updatedData = JSON.parse(JSON.stringify(alertData));
        alertData.forEach((alert, alertIndex) => {
            let alertId = alert?.exceptionLogObjectId
                ? alert?.exceptionLogObjectId
                : alert.alertRegistryId;
            selected.forEach((selectedId) => {
                if (selectedId === alertId) {
                    if (alert.hasOwnProperty("alertRegistryId")) {
                        updatedData[alertIndex].status =
                            type === "OPEN" ? "OPEN" : "CLOSED";
                        selectedAlerts.push({
                            alertRegistryId: alert.alertRegistryId,
                            status: type === "OPEN" ? "OPEN" : "CLOSED",
                        });
                    } else {
                        let obj = {
                            genericId: alert.genericId,
                            exceptionLogObjectId: alert.exceptionLogObjectId,
                            status: type === "OPEN" ? "OPEN" : "CLOSED",
                        };
                        updatedData[alertIndex].status =
                            type === "OPEN" ? "OPEN" : "CLOSED";
                        selectedExceptions.push(obj);
                    }
                }
            });
        });
        if (selectedAlerts.length || selectedExceptions.length) {
            setShowLoader(true);

            const requests = [];

            if (selectedAlerts.length) {
                const url = "alert/status";
                requests.push(http.sendRestRequest(url, "PUT", selectedAlerts));
            }

            if (selectedExceptions.length) {
                const url = "documentChain/exception/status";
                requests.push(
                    http.sendRestRequest(url, "PUT", selectedExceptions)
                );
            }

            Promise.all(requests)
                .then(() => {
                    setOpenDialog(true);
                    let status =
                        type.toLowerCase() === "open" ? "opened" : "closed";
                    setMessage(`Alert ${status} successfully!`);
                    selectedFinalFilter === null
                        ? dispatch(setAlertLogData(updatedData)) // updateAlertLogData(selectedAlerts, selectedExceptions)//loadAlerts()
                        : dispatch(setFinalFilter(updatedData));
                    // handleReset();
                    setShowLoader(false);
                    setSelected([]);
                    if (selectedAlerts.length || selectedExceptions.length) {
                        let idArrayAlerts = [];
                        let idArrayExceptions = [];

                        // Get alertRegistryId array if there are selected alerts
                        if (selectedAlerts.length) {
                            idArrayAlerts = selectedAlerts.map(
                                (alert) => alert.alertRegistryId
                            );
                        }

                        // Get exceptionLogObjectId array if there are selected exceptions
                        if (selectedExceptions.length) {
                            idArrayExceptions = selectedExceptions.map(
                                (alert) => alert.exceptionLogObjectId
                            );
                        }

                        // Combine both conditions to handle CLOSED type
                        if (type === "CLOSED") {
                            const filterRedux = alertLogData?.filter(
                                (alert) =>
                                    !idArrayAlerts.includes(
                                        alert.alertRegistryId
                                    ) &&
                                    !idArrayExceptions.includes(
                                        alert.exceptionLogObjectId
                                    )
                            );

                            const filterReduxHome = homeAlertLogData?.filter(
                                (alert) =>
                                    !idArrayAlerts.includes(
                                        alert.alertRegistryId
                                    ) &&
                                    !idArrayExceptions.includes(
                                        alert.exceptionLogObjectId
                                    )
                            );

                            // Dispatch both sets of data
                            dispatch(setHomeAlertLogData(filterReduxHome));
                            dispatch(setAlertLogData(filterRedux));
                        }
                    }

                    if (selectedOptions.includes("closed")) {
                        setSelectedOptions([]);
                        dispatch(setSelectedFilterOptions([]));
                    }
                })
                .catch((e) => {
                    console.log(e);
                    setShowLoader(false);
                });
        }
    };

    const handleMarkAsReadOrUnRead = (type, markId) => {
        const selectedAlerts = [];
        const selectedExceptions = [];
        let selectedIds = markId ? markId : selected;
        let updatedData = JSON.parse(JSON.stringify(alertData));
        alertData.forEach((alert, alertIndex) => {
            let alertId = alert?.exceptionLogObjectId
                ? alert?.exceptionLogObjectId
                : alert.alertRegistryId;
            selectedIds.forEach((selectedId) => {
                if (selectedId === alertId) {
                    if (alert.hasOwnProperty("alertRegistryId")) {
                        updatedData[alertIndex].isRead =
                            type === "read" ? true : false;
                        selectedAlerts.push({
                            alertRegistryId: alert.alertRegistryId,
                            isRead: type === "read" ? true : false,
                        });
                    } else {
                        let obj = {
                            genericId: alert.genericId,
                            exceptionLogObjectId: alert.exceptionLogObjectId,
                            isRead: type === "read" ? true : false,
                        };
                        updatedData[alertIndex].isRead =
                            type === "read" ? true : false;
                        selectedExceptions.push(obj);
                    }
                }
            });
        });
        if (selectedAlerts.length || selectedExceptions.length) {
            setShowLoader(true);

            const requests = [];

            if (selectedAlerts.length) {
                const url = "alert/readOrUnread";
                requests.push(http.sendRestRequest(url, "PUT", selectedAlerts));
            }

            if (selectedExceptions.length) {
                const url = "documentChain/exception/readOrUnread";
                requests.push(
                    http.sendRestRequest(url, "PUT", selectedExceptions)
                );
            }

            Promise.all(requests)
                .then(() => {
                    setOpenDialog(true);
                    setMessage(`Marked as ${type} successfully!`);
                    setShowLoader(false);
                    setSelected([]);
                    let mappedAlerts = [];
                    if (selectedAlerts.length) {
                        const idArray = selectedAlerts.map(
                            (alert) => alert.alertRegistryId
                        );
                        if (type === "read") {
                            mappedAlerts = alertLogData?.map((alert) => {
                                if (idArray.includes(alert.alertRegistryId)) {
                                    return { ...alert, isRead: true };
                                } else {
                                    return alert;
                                }
                            });
                        } else if (type === "unread") {
                            mappedAlerts = alertLogData?.map((alert) => {
                                if (idArray.includes(alert.alertRegistryId)) {
                                    return { ...alert, isRead: false };
                                } else {
                                    return alert;
                                }
                            });
                        }
                    }
                    if (selectedExceptions.length) {
                        const idArray = selectedExceptions.map(
                            (alert) => alert.exceptionLogObjectId
                        );
                        if (type === "read") {
                            if (mappedAlerts.length)
                                mappedAlerts = mappedAlerts.map((alert) => {
                                    if (
                                        idArray.includes(
                                            alert.exceptionLogObjectId
                                        )
                                    ) {
                                        return { ...alert, isRead: true };
                                    } else {
                                        return alert;
                                    }
                                });
                            else
                                mappedAlerts = alertLogData?.map((alert) => {
                                    if (
                                        idArray.includes(
                                            alert.exceptionLogObjectId
                                        )
                                    ) {
                                        return { ...alert, isRead: true };
                                    } else {
                                        return alert;
                                    }
                                });
                        } else if (type === "unread") {
                            if (mappedAlerts.length)
                                mappedAlerts = mappedAlerts.map((alert) => {
                                    if (
                                        idArray.includes(
                                            alert.exceptionLogObjectId
                                        )
                                    ) {
                                        return { ...alert, isRead: false };
                                    } else {
                                        return alert;
                                    }
                                });
                            else
                                mappedAlerts = alertLogData?.map((alert) => {
                                    if (
                                        idArray.includes(
                                            alert.exceptionLogObjectId
                                        )
                                    ) {
                                        return { ...alert, isRead: false };
                                    } else {
                                        return alert;
                                    }
                                });
                        }
                    }
                    if (selectedOptions.includes("closed")) {
                        setSelectedOptions([]);
                        dispatch(setSelectedFilterOptions([]));
                    }

                    dispatch(setAlertLogData(mappedAlerts));
                    setAlertData(mappedAlerts);
                })
                .catch((e) => {
                    console.log(e);
                    setShowLoader(false);
                });
        }
    };
    const validateToApply = () => {
        const hasFiltersSelected =
            selectedGenericDropdownLocalValue?.id ||
            selectedAlertIds?.length ||
            selectedStepNamesDropdownLocalValue?.length ||
            selectedExceptionIdsAlert?.length ||
            selectedAlertTypeDropdownLocalValue?.length ||
            selectedInstanceDropdownLocalValue?.length ||
            allInstanceWithFilterAlert?.length ||
            (selectedTimeState?.startDate && selectedTimeState?.endDate) ||
            selectedOptions?.length ||
            selectedFilterOptions?.length;

        if (dateErr) {
            return dateErr; // Return date error if present
        }

        return !hasFiltersSelected; // Disable if no filters selected
    };

    return (
        <div className="">
            <div id="dropdownContainerRow1" className={styles.alertsHeader}>
                <img src="./images/exclamation-circle.svg" alt="" />
                <span>Alerts</span>
            </div>
            <>
                {!selected.length ? (
                    <div
                        id="dropdownContainerRow1"
                        className="m-0 w-100 mb-3"
                        style={{
                            height: "85px",
                            justifyContent: "space-start",
                            display: "flex",
                            flexWrap: "nowrap",
                            alignItems: "center",
                            paddingLeft: "0.8%",
                        }}
                    >
                        <CustomSearchDropdown
                            items={alertTypeList}
                            label={"Alert Type"}
                            id={"btn_select_generic"}
                            idInfo={"generic"}
                            multiSelect={true}
                            isSearchable={true}
                            isChipView={false}
                            handleSelection={handleAlertTypeSelection}
                            selection={selectedAlertTypeDropdownLocalValue}
                            customClassName={classes.root}
                            customWidth={92}
                        ></CustomSearchDropdown>
                        <CustomDateDropdown
                            setDateErr={setDateErr}
                            dateErr={dateErr}
                            setSelectedTimeFilter={handleSelectedTimeFilterData}
                            selection={selectedTimeState}
                            customClassName={"date_range_class"}
                            label={"Time"}
                            customWidth={92}
                        ></CustomDateDropdown>
                        <CustomSearchDropdown
                            items={genericList}
                            label={"Flow"}
                            id={"btn_select_generic"}
                            idInfo={"generic"}
                            multiSelect={false}
                            isSearchable={true}
                            isChipView={false}
                            handleSelection={handleGenericSelection}
                            selection={selectedGenericDropdownLocalValue}
                            customClassName={classes.root}
                            customWidth={92}
                        ></CustomSearchDropdown>
                        <CustomSearchDropdown
                            items={stepNameList}
                            label={"Step Name"}
                            id={"btn_select_generic"}
                            idInfo={"generic"}
                            multiSelect={true}
                            isSearchable={true}
                            isChipView={false}
                            handleSelection={handleStepNameSelection}
                            selection={selectedStepNamesDropdownLocalValue}
                            customClassName={classes.root}
                            customWidth={92}
                        ></CustomSearchDropdown>
                        <CustomSearchDropdown
                            items={instanceList}
                            label={"Instance"}
                            id={"btn_select_generic"}
                            idInfo={"generic"}
                            multiSelect={true}
                            isSearchable={true}
                            isChipView={false}
                            handleSelection={handleInstancesSelection}
                            selection={selectedInstanceDropdownLocalValue}
                            customClassName={classes.root}
                            customWidth={92}
                        ></CustomSearchDropdown>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "1%",
                            }}
                        >
                            <button
                                onClick={handleOpen}
                                // id="header_btn_more"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginLeft: "1%",
                                    height: "36px",
                                    border: "1px solid #ccc",
                                    padding: "7px 5px",
                                    backgroundColor: "#fff",
                                    borderRadius: "4px",
                                }}
                            >
                                <span
                                    style={{
                                        width: "95px",
                                        // marginRight: "9px",
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        letterSpacing: "0.4px",
                                        textAlign: "left",
                                    }}
                                    title="More Filters"
                                >
                                    More Filters
                                </span>
                                <img src="./images/chevron-down.svg" alt="" />
                            </button>

                            <Modal
                                open={opens}
                                onClose={handleClosed}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "6px",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginTop: "-12px",
                                            marginLeft: "4px",
                                        }}
                                    >
                                        <div
                                            style={closeButtonStyle}
                                            onClick={handleClosed}
                                        >
                                            &times;
                                        </div>
                                    </div>
                                    <FormControl className="invisibleScroller">
                                        <li
                                            key="read"
                                            onClick={() =>
                                                handleOptionClick("read")
                                            }
                                            style={{
                                                display: "flex",
                                                gap: "6px",
                                                marginBottom: "10px",
                                                cursor: "pointer",
                                                listStyleType: "none",
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedOptions.includes(
                                                    "read"
                                                )}
                                                onChange={() =>
                                                    handleOptionClick("read")
                                                }
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                style={{ marginRight: "8px" }}
                                            />
                                            Read
                                        </li>
                                        <li
                                            key="unread"
                                            onClick={() =>
                                                handleOptionClick("unread")
                                            }
                                            style={{
                                                display: "flex",
                                                gap: "6px",
                                                marginBottom: "10px",
                                                cursor: "pointer",
                                                listStyleType: "none",
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedOptions.includes(
                                                    "unread"
                                                )}
                                                onChange={() =>
                                                    handleOptionClick("unread")
                                                }
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                style={{ marginRight: "8px" }}
                                            />
                                            Unread
                                        </li>

                                        <li
                                            key="closed"
                                            onClick={() =>
                                                handleOptionClick("closed")
                                            }
                                            style={{
                                                display: "flex",
                                                gap: "6px",
                                                marginBottom: "10px",
                                                cursor: "pointer",
                                                listStyleType: "none",
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedOptions.includes(
                                                    "closed"
                                                )}
                                                onChange={() =>
                                                    handleOptionClick("closed")
                                                }
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                style={{ marginRight: "8px" }}
                                            />
                                            Closed
                                        </li>
                                    </FormControl>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            margin: "0px 4px",
                                            gap: "6px",
                                        }}
                                    >
                                        <button
                                            onClick={handleClosed}
                                            id="btn_reset"
                                            title="Cancel"
                                            style={resetButtonStyle}
                                        >
                                            <em>Cancel</em>
                                        </button>
                                        <button
                                            onClick={handleClosed}
                                            id="btn_apply"
                                            title="Confirm"
                                            style={applyButtonStyle}
                                        >
                                            <em>Confirm</em>
                                        </button>
                                    </div>
                                </Box>
                            </Modal>
                        </div>
                        <div
                            type="button"
                            className="btn_class"
                            title="Reset All Filters"
                            value={"Reset All Filters"}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 500,
                                maxWidth: "200px",
                                lineHeight: "24px",
                                letterSpacing: "0.4px",
                                textAlign: "left",
                                border: "1px solid #000000",
                                color: "black",
                            }}
                            onClick={handleReset}
                        >
                            Reset All Filters
                        </div>

                        <div style={{ margin: "8px" }}>
                            <button
                                className={"btn share modalButton"}
                                type="button"
                                id={"btn_vie"}
                                title="Apply"
                                value={"Submit"}
                                disabled={isApplyDisabled}
                                onClick={() => handleApplyFilter(false)}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                ) : (
                    <div
                        id="dropdownContainerRow1"
                        className="m-0 w-100 mb-3"
                        style={{
                            justifyContent: "space-start",
                            height: "85px",
                            display: "flex",
                            flexWrap: "nowrap",
                            alignItems: "center",
                            paddingLeft: "0.8%",
                        }}
                    >
                        <div
                            type="button"
                            className="btn_class"
                            title="Mark As Read"
                            value={"Mark As Read"}
                            style={{
                                width: "12%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px",
                                letterSpacing: "0.4px",
                                textAlign: "left",
                                border: "1px solid #000000",
                                color: "black",
                            }}
                            onClick={() => handleMarkAsReadOrUnRead("read")}
                        >
                            Mark As Read
                        </div>
                        <div
                            type="button"
                            className="btn_class"
                            title="Mark As Unread"
                            value={"Mark As Unread"}
                            style={{
                                width: "12%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px",
                                letterSpacing: "0.4px",
                                textAlign: "left",
                                border: "1px solid #000000",
                                color: "black",
                            }}
                            onClick={() => handleMarkAsReadOrUnRead("unread")}
                        >
                            Mark As Unread
                        </div>
                        {/* <div
                            type="button"
                            className="btn_class"
                            title="Mark As Open"
                            value={"Mark As Open"}
                            style={{
                                width: "12%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px",
                                letterSpacing: "0.4px",
                                textAlign: "left",
                                border: "2px solid #000000",
                                color: "black",
                            }}
                            onClick={() => handleMarkAsOpenandClosed("OPEN")}
                        >
                            Mark As Open
                        </div> */}
                        <div
                            type="button"
                            className="btn_class"
                            title="Mark As Closed"
                            value={"Mark As Closed"}
                            style={{
                                width: "12%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px",
                                letterSpacing: "0.4px",
                                textAlign: "left",
                                border: "1px solid #000000",
                                color: "black",
                            }}
                            onClick={() => handleMarkAsOpenandClosed("CLOSED")}
                        >
                            Mark As Closed
                        </div>
                    </div>
                )}
            </>

            <Paper
                style={{ overflowX: "auto", width: "97%", marginLeft: "1.5%" }}
                id="alert_tab"
            >
                <TableContainer
                    id={"table_alert"}
                    className="invisibleScroller"
                >
                    {listRows && listRows.length ? (
                        <Table
                            stickyHeader
                            aria-label="sticky table"
                            style={{ width: "100%" }}
                        >
                            <TableHead
                                sx={{
                                    position: "sticky",
                                    top: "-1px",
                                    backgroundColor: "#EAEEF5",
                                    zIndex: "1",
                                }}
                            >
                                <TableRow>
                                    {<TableCell></TableCell>}
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            indeterminate={
                                                selected.length > 0 &&
                                                selected.length <
                                                    listRows.length
                                            }
                                            checked={
                                                listRows.length > 0 &&
                                                selected.length ===
                                                    listRows.length
                                            }
                                            onChange={handleSelectAllClick}
                                            inputProps={{
                                                "aria-label":
                                                    "select all alerts",
                                            }}
                                        />
                                    </TableCell>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={"left"}
                                            style={{
                                                minWidth: column.minWidth,
                                                cursor: "pointer",
                                            }}
                                            className={
                                                ["Actions"].includes(
                                                    column.label
                                                )
                                                    ? null
                                                    : {
                                                          "Alert Type":
                                                              classAlertTypeDate,
                                                          Description:
                                                              classDescDate,
                                                          Date: classCDate,
                                                          Source: classSourceDate,
                                                          Status: classStatusDate,
                                                          Category:
                                                              classCategoryDate,
                                                      }[column.label] || null
                                            }
                                            title={column.label}
                                            onClick={
                                                column.label !== "Actions"
                                                    ? () =>
                                                          handleSorting(
                                                              column.label
                                                          )
                                                    : null
                                            }
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listRows
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, rid) => {
                                        const isItemSelected = isSelected(
                                            row.id
                                        );
                                        const labelId = `enhanced-table-checkbox-${rid}`;
                                        return (
                                            <>
                                                <TableRow
                                                    hover
                                                    sx={{ height: "56px" }}
                                                    role="checkbox"
                                                    aria-checked={
                                                        isItemSelected
                                                    }
                                                    tabIndex={-1}
                                                    style={
                                                        !row?.isRead
                                                            ? {
                                                                  cursor: "pointer",
                                                              }
                                                            : null
                                                    }
                                                    onClick={
                                                        !row?.isRead
                                                            ? () =>
                                                                  handleMarkAsReadOrUnRead(
                                                                      "read",
                                                                      [row.id]
                                                                  )
                                                            : null
                                                    }
                                                    key={row.index}
                                                    selected={isItemSelected}
                                                >
                                                    {!row?.isRead ? (
                                                        <TableCell
                                                            style={{
                                                                padding: "0px",
                                                                width: "1px",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: "12px",
                                                                    height: "65px",
                                                                    padding:
                                                                        "0px",

                                                                    background:
                                                                        "#0B5CD7",
                                                                }}
                                                            ></div>
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell
                                                            style={{
                                                                padding: "0px",
                                                                width: "1px",
                                                            }}
                                                        ></TableCell>
                                                    )}
                                                    <TableCell
                                                        padding="checkbox"
                                                        onClick={(event) =>
                                                            handleClick(
                                                                event,
                                                                row.index,
                                                                row
                                                            )
                                                        }
                                                        sx={{
                                                            height: "56px",
                                                            lineHeight: "1",
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                isItemSelected
                                                            }
                                                            inputProps={{
                                                                "aria-labelledby":
                                                                    labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    {columns.map((column) => {
                                                        const value =
                                                            row[column.id];
                                                        const cellId = `cell-${rid}-${column.id}`;
                                                        return (
                                                            <TableCell
                                                                sx={{
                                                                    height: "56px",
                                                                    lineHeight:
                                                                        "1",
                                                                }}
                                                                key={column.id}
                                                                align={"left"}
                                                                id={cellId}
                                                            >
                                                                {getCell(
                                                                    column.id,
                                                                    row,
                                                                    value,
                                                                    rid,
                                                                    cellId
                                                                )}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            </>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    ) : (
                        <div style={{ textAlign: "center", padding: "12%" }}>
                            No Results Found.
                        </div>
                    )}
                </TableContainer>
                {isDocumentAlert ? (
                    <Menu
                        id="demo-customized-menu"
                        MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                            <div>
                                {/* <img
                                className="deleteIcon"
                                title="Attach to Flow"
                                src="./images/Rename.svg"
                                alt="Attach to Flow"
                            /> */}
                                <span
                                    className="btn_title"
                                    title="Attach to Flow"
                                >
                                    Attach to Flow
                                </span>
                            </div>
                        </MenuItem>
                    </Menu>
                ) : null}
                {isDocAdditionAlert ? (
                    <Menu
                        id="demo-customized-menu"
                        MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                            <div>
                                {/* <img
                                    className="deleteIcon"
                                    title="Attach to Flow"
                                    src="./images/Rename.svg"
                                    alt="Attach to Flow"
                                /> */}
                                <span className="btn_title" title="Open File">
                                    Open File
                                </span>
                            </div>
                        </MenuItem>
                    </Menu>
                ) : null}
                {isRippleAlert ? (
                    <Menu
                        id="demo-customized-menu"
                        MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                            <div>
                                {/* <img
                                className="deleteIcon"
                                title="Attach to Flow"
                                src="./images/Rename.svg"
                                alt="Attach to Flow"
                            /> */}
                                <span
                                    className="btn_title"
                                    title="Open in Ripple"
                                >
                                    Open in Ripple
                                </span>
                            </div>
                        </MenuItem>
                    </Menu>
                ) : null}
                {isExceptionAlert ? (
                    <Menu
                        id="demo-customized-menu"
                        MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                            <div>
                                {/* <img
                                className="deleteIcon"
                                title="Attach to Flow"
                                src="./images/Rename.svg"
                                alt="Attach to Flow"
                            /> */}
                                <span
                                    className="btn_title"
                                    title="Open Instance"
                                >
                                    Open Instance
                                </span>
                            </div>
                        </MenuItem>
                    </Menu>
                ) : null}
                {showDocumentModal ? (
                    <AddDocuments
                        documentId={documentIdState}
                        setSelectedRow={setSelectedRow}
                        openAddInstanceModal={openAddInstanceModal}
                        values={values}
                        folderId={values?.folderId}
                        showDocumentModal={showDocumentModal}
                        closeModal={() => setShowDocumentModal(false)}
                        flowInfo={flowInfo}
                        // documentCloseAlert={documentCloseAlert}
                        // flowInfo={{
                        //     flowName: "21 st march",
                        //     instanceName: "Instance 1",
                        //     stepName: "",
                        // }}
                    />
                ) : null}
                {showAddInstanceModal ? (
                    <AddInstance
                        selectedGenericInstances={InstanceList}
                        selectedInstance={selectedInstance}
                        showAddInstanceModal={showAddInstanceModal}
                        closeModal={closeInstance}
                        currentFamilyTags={[]}
                        currentInstanceName={""}
                        heading={"Add New Instance"}
                        branchsId={"1"}
                        branchsName={"1"}
                        disabled={false}
                        editNewInstance={""}
                        addNewInstance={addNewInstance}
                    ></AddInstance>
                ) : null}
                <DocumentViewerModal
                    showHeader={true}
                    footer="false"
                    show={documentModalShow}
                    size="lg"
                    onHide={handleDocumentModalClose}
                    title={currentShowFileName}
                    modalBodyClass="documentViewModal"
                    onclick={() => {}}
                    submitButtonText="Close"
                    headerTitle={currentShowFileName}
                    // fileSource={currentShowFileLocation}
                >
                    {documentModalShow ? renderViewDocumentModal() : null}
                </DocumentViewerModal>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={listRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                setOpen={setShowAlertDialog}
                title={""}
                isAlert={false}
            ></AlertDialog>
            {/* <CustomSnackbar
                open={openSnackMsg}
                setOpen={setOpenDialog}
                alertMessage={message}
            ></CustomSnackbar> */}
            <Loader show={showLoader}></Loader>
        </div>
    );
};

export default AlertLog;
