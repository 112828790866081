import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalView from "../../../../../stylized/ModalView";
import http from "../../../../../../bae-client/HTTP";
import { useNavigate } from "react-router-dom";

import ContactList from "../../communicator/chatSection/newChatUser/contactList";
import { setActivePage } from "../../../../../../actions";
import { setSharedData } from "../../../../../../actions/CommunicatorActions";
import "./style.css";

import AlertDialog from "../../../../../stylized/AlertDialog";
import { convertObjectMapToArrayMap } from "../../../../../stylized/multilevel-select-option";
import {
    fetchUserListData,
    fetchDirectoryListData,
} from "../../../LeftPane/Directory/DirectoryActions";
import {
    directoryList,
    directoryUsersList,
} from "../../../LeftPane/Directory/DirectoryReducers";
import Loader from "../../../../../stylized/Loader";
import { useUsersQuery } from "../../../../../../services/services/usersAPI";

export default function ShareFile({
    showShareModal,
    closeModal,
    parentFolderId,
    lastSelectedRowData,
    showData,
    folderData,
    space,
    activeWorkspace,
}) {
    const [userArray, setUserArray] = useState([]);
    const [allContacts, setAllContacts] = useState([]);
    const [error, setError] = useState("");
    const [isChecked, setIsChecked] = useState(true);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const workspaceIdToName = useSelector((state) => state.workspaceIdToName);
    const spaceIdToName = useSelector((state) => state.spaceIdToName);
    const directoryList = useSelector((state) => state.directoryList);
    const directoryUsersList = useSelector((state) => state.directoryUsersList);
    const { data: users } = useUsersQuery();
    const [showLoader, setShowLoader] = useState(false);
    const handleDropdownSelect = (userArray) => {
        if (userArray.length) {
            setError("");
            setUserArray(userArray);
        } else {
            setUserArray([]);
        }
    };
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    useEffect(() => {
        dispatch(fetchDirectoryListData());
        // useEffect(() => {
        //     setShowLoader(true)
        //     http.sendRestRequest("user/?active=true")
        //         .then((res) => {
        //             const filteredItems = res.filter(
        //                 (user) => user.userId !== parseInt(sessionStorage.userId)
        //             );
        //             setShowLoader(false)
        //             setAllContacts(filteredItems);
        //         })
        //         .catch((err) => {
        //             setShowLoader(false)
        //             setShowAlertDialog(true);
        //             setAlertDialogMessage(error.error);
        //             console.log(err);
        //         });
    }, []);

    // useEffect(() => {
    //     if (directoryList && directoryList.length) {
    //         console.log("share file");
    //         dispatch(
    //             fetchUserListData(
    //                 directoryList[directoryList.length - 1].directoryId
    //             )
    //         );
    //     }
    // }, [directoryList]);

    useEffect(() => {
        if (directoryUsersList && directoryUsersList.length) {
            const filteredItems = users.filter(
                (user) => user.userId !== parseInt(sessionStorage.userId)
            );
            let contacts = [];
            directoryUsersList.forEach((user) => {
                filteredItems.forEach((item) => {
                    if (
                        user.emailAddress.toLowerCase() ===
                        item.userEmail.toLowerCase()
                    ) {
                        contacts.push(item);
                    }
                });
            });
            setAllContacts(contacts);
        }
    }, [directoryUsersList]);
    const shareRipple = (selectedRippleData, users) => {
        let selectedRippleLogData = selectedRippleData;
        let workspaceFilterList = [];
        let spaceFilterList = [];
        let folderFilterList = [];

        if (selectedRippleLogData.filterCriteriaWorkspaces !== "") {
            selectedRippleLogData.filterCriteriaWorkspaces
                .split(",")
                .forEach((workspaceId) => {
                    const workspaceName =
                        workspaceIdToName?.get(parseInt(workspaceId)) ||
                        "Unknown Workspace";
                    workspaceFilterList.push(workspaceName);
                });
        }

        if (selectedRippleLogData.filterCriteriaSpaces !== "") {
            selectedRippleLogData.filterCriteriaSpaces
                .split(",")
                .forEach((spaceId) => {
                    const spaceName =
                        spaceIdToName?.get(parseInt(spaceId)) ||
                        "Unknown Space";
                    spaceFilterList.push(spaceName);
                });
        }

        if (selectedRippleLogData.filterCriteriaFolders !== "") {
            folderFilterList =
                selectedRippleLogData.filterCriteriaFolders.split(",");
        }

        const selectedRawObj = {
            rippleName: selectedRippleLogData.rippleName,
            searchCriteria: selectedRippleLogData.searchCriteria,
            filterCriteriaFolders: folderFilterList,
            filterCriteriaSpaces: spaceFilterList,
            filterCriteriaWorkspaces: workspaceFilterList,
            filterCriteriaDates: selectedRippleLogData.filterCriteriaDates,
        };

        dispatch(
            setSharedData({
                users: users,
                message: selectedRawObj,
                messageType: "RIPPLE_OBJECT",
            })
        );
    };

    // const shareFile = (fileData, users) => {
    //     if (fileData && folderData) {
    //         const fileObject = folderData[parseInt(fileData.id)];
    //         const message = {
    //             documentId: fileObject.documentId,
    //             folderFileName: fileData.name,
    //         };
    //         dispatch(
    //             setSharedData({
    //                 users: users,
    //                 message: message,
    //                 messageType: "FILE_OBJECT",
    //             })
    //         );
    //     }
    // };
    const shareFile = (fileData, users, download) => {
        if (fileData && folderData) {
            const message = {
                documentId: parseInt(fileData.documentId),
                folderFileName: fileData.name,
                isEditable: fileData.isEditable,
                isDownload: download,
            };

            if (fileData.type === "folder") {
                dispatch(
                    setSharedData({
                        users: users,
                        message: {
                            ...message,
                            documentId: parseInt(fileData?.id),
                        },
                        messageType: "MSG_FOLDER_OBJECT",
                        folderStructure: {
                            folderFileId: fileData.id,
                        },
                    })
                );
            } else if (fileData.type.toLowerCase() === "email") {
                dispatch(
                    setSharedData({
                        users: users,
                        message: {
                            ...message,
                            documentId: parseInt(fileData?.id),
                        },
                        messageType: "MSG_EMAIL_OBJECT",
                    })
                );
            } else
                dispatch(
                    setSharedData({
                        users: users,
                        message: message,
                        messageType: "FILE_OBJECT",
                    })
                );
        }
    };
    const onSubmit = () => {
        if (!userArray.length) setError("Please select at least one user");
        else {
            const users = userArray.map((user) => parseInt(user.userId));
            if (showData) shareRipple(showData, users);
            else if (lastSelectedRowData)
                shareFile(lastSelectedRowData, users, isChecked);
            dispatch(setActivePage("Communicator"));
            let basePathName = window.runtimeConfig.instanceName
                ? "/" + window.runtimeConfig.instanceName
                : "";
            navigate(`${basePathName}/chat`);
        }
    };
    return (
        <>
            <ModalView
                showHeader="false"
                footer="false"
                show={showShareModal}
                size="md"
                onHide={closeModal}
                title={"Share"}
                id="btn_folder_submit"
                value={"Share"}
                onclick={closeModal}
                type="submit-cancel"
                submitButtonText="Close"
                onSubmit={onSubmit}
            >
                <div
                    className="row doCenter"
                    style={{ flexDirection: "column" }}
                >
                    {/* <img src="./images/Space 2.svg"></img> */}
                    <div
                        className="updateSpaceNameTitle addFolder ShareFile"
                        style={{ marginLeft: "-220px" }}
                    >
                        {showData ? "Share Ripple" : "Share Folder / File"}
                    </div>

                    <div
                        id={"newChatAddress"}
                        style={{
                            padding: "8px 16px",
                            minHeight: "64px",
                        }}
                    >
                        <span
                            style={{
                                float: "left",
                                fontSize: "small",
                                paddingRight: "8px",
                                marginTop: "9px",
                            }}
                        >
                            To:
                        </span>
                        <div style={{ float: "left" }} id="contactsSelection">
                            <ContactList
                                style={{ border: "none" }}
                                dropdownList={allContacts}
                                handleDropdownSelect={handleDropdownSelect}
                            ></ContactList>
                        </div>
                        {showData ? null : (
                            <div
                                style={{
                                    float: "left",
                                    display: "flex",
                                    marginLeft: "28px",
                                }}
                                id="allow_download"
                            >
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <p style={{ margin: "5px 12px" }}>
                                    Allow other users to download the documents
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                {error && (
                    <>
                        <small
                            className="doCenter"
                            style={{
                                color: "red",
                                paddingLeft: "18px",
                                margin: "-8px 192px 0px 0px",
                            }}
                        >
                            {error}
                        </small>
                    </>
                )}
            </ModalView>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
            <Loader show={showLoader} />
        </>
    );
}
