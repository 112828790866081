import React, { useEffect, useState } from "react";
import {
    Menu,
    MenuItem,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { Table } from "react-bootstrap";
import "./generalTable.css";

const GeneralTable = ({
    columns,
    openModal,
    restoreFile,
    data,
    deletedItems,
    rowsPerPageOptions = [10, 25, 50, 100], // Configurable rows per page
    onRowClick, // Callback for row click
    onSort, // Callback for custom sort behavior
    renderEmptyState, // Optional component for empty state
    module, // Module type (e.g., "Workspace", "trash")
    deleteWorkspace,
    renameWorkspace,
    viewBtnCliked,
    selectedRowIndex,
}) => {
    const [page, setPage] = useState(0);
    const [className, setClassName] = React.useState("headerSortDown");
    const [classMDate, setclassMDate] = React.useState("headerSortDown");
    const [classAction, setClassAction] = React.useState("");
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [orderBy, setOrderBy] = useState("asc");
    const [anchorEl, setAnchorEl] = useState(null);
    const [sortedColumn, setSortedColumn] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setAnchorEl(openModal);
    }, [openModal]);

    const handleAction = (action, row) => {
        switch (action) {
            case "Rename":
                renameWorkspace && renameWorkspace(row);
                break;
            case "Delete":
                if (module === "Workspace") {
                    deleteWorkspace && deleteWorkspace(row);
                } else if (module === "trash") {
                    deletedItems && deletedItems(row);
                }
                break;
            case "Restore":
                restoreFile && restoreFile(row);
                break;
            default:
                console.warn("Unhandled action:", action);
        }
    };

    const handleClose = (e) => {
        if (e && e.target && e.target.innerText) {
            const action = e.target.innerText;
            handleAction(action, selectedRow); // Ensure selectedRow is correctly set
        }
        setAnchorEl(null);
    };
    const handleSort = (columnId) => {
        const newOrder = orderBy === "asc" ? "desc" : "asc";
        setOrderBy(newOrder);
        setSortedColumn(columnId);
        onSort && onSort(columnId, newOrder);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleSorting = (e) => {
        if (e.target.innerText === "Actions") {
            setClassName("");
        } else if (e.target.innerText === "Title") {
            orderBy === "asc"
                ? setClassName("headerSortDown")
                : setClassName("headerSortUp");
        } else {
            orderBy === "asc"
                ? setclassMDate("headerSortDown")
                : setclassMDate("headerSortUp");
        }

        setSortedColumn(e.target.innerText);
        handleSort(e.target.innerText, orderBy);
        orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const paginatedData = data.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const handleClickMenu = (event, row) => {
        setSelectedRow(row);
        setAnchorEl(event.currentTarget);
    };

    return (
        <Paper
            className="invisibleScroller fixed-pagination-container "
            style={{ overflowX: "auto", margin: "auto" }}
            id="rooot"
        >
            <div className="table-content">
                <TableContainer>
                    {data.length ? (
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            style={{
                                                minWidth: column.minWidth,
                                                cursor: "pointer",
                                                textAlign: column.textAlign,
                                            }}
                                            className={
                                                column.label === "Actions" ||
                                                column.label === "Ripple Search"
                                                    ? classAction
                                                    : column.label === "Title"
                                                    ? className
                                                    : classMDate
                                            }
                                            onClick={handleSorting}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.map((row, index) => (
                                    <TableRow
                                        hover
                                        key={row.id || index}
                                        className={
                                            selectedRowIndex % rowsPerPage ===
                                            index
                                                ? "rippleLogRowOutline"
                                                : ""
                                        }
                                        onClick={() => {
                                            if (
                                                typeof onRowClick === "function"
                                            ) {
                                                if (module === "RippleLog") {
                                                    onRowClick(
                                                        rowsPerPage * page +
                                                            index
                                                    );
                                                } else {
                                                    onRowClick(row);
                                                }
                                            } else {
                                                console.error(
                                                    "onRowClick is not a function"
                                                );
                                            }
                                        }}
                                    >
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    cursor:
                                                        row.workspaceType ===
                                                            "SYSTEM" &&
                                                        column.id === "Actions"
                                                            ? "not-allowed"
                                                            : "pointer",
                                                    textAlign: column.textAlign,
                                                }}
                                                onClick={(event) => {
                                                    if (
                                                        column.id ===
                                                            "Actions" &&
                                                        row.workspaceType ===
                                                            "SYSTEM"
                                                    ) {
                                                        event.stopPropagation(); // Prevent any actions for SYSTEM rows
                                                    } else if (
                                                        column.id === "Actions"
                                                    ) {
                                                        handleClickMenu(
                                                            event,
                                                            row
                                                        );
                                                    }
                                                }}
                                            >
                                                {column.id === "Trashbin" ? (
                                                    <span
                                                        className="textEllipsis"
                                                        title={row[column.id]}
                                                    >
                                                        {row[column.id]}
                                                    </span>
                                                ) : column.renderCell ? (
                                                    column.renderCell(
                                                        row[column.id],
                                                        row,
                                                        index
                                                    )
                                                ) : column.id === "Actions" ? (
                                                    <div
                                                        style={{
                                                            cursor:
                                                                row.workspaceType ===
                                                                "SYSTEM"
                                                                    ? "not-allowed"
                                                                    : "pointer",
                                                            height: "24px",
                                                        }}
                                                    >
                                                        <img
                                                            alt={"options"}
                                                            title="option"
                                                            src="./images/options.png"
                                                            style={{
                                                                pointerEvents:
                                                                    row.workspaceType ===
                                                                    "SYSTEM"
                                                                        ? "none"
                                                                        : "auto",
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    row[column.id]
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        renderEmptyState || (
                            <div
                                style={{ textAlign: "center", padding: "12%" }}
                            >
                                No Results Found.
                            </div>
                        )
                    )}
                </TableContainer>
            </div>
            <div className="table-pagination">
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {module === "Workspace" && (
                    <>
                        <MenuItem onClick={handleClose}>
                            <span>Rename</span>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <span>Delete</span>
                        </MenuItem>
                    </>
                )}
                {module === "trash" && (
                    <>
                        <MenuItem onClick={handleClose}>
                            <span>Restore</span>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <span>Delete</span>
                        </MenuItem>
                    </>
                )}
            </Menu>
        </Paper>
    );
};

export default GeneralTable;
