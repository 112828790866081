import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import { useEffect, useState } from "react";
import http from "../../../../../../bae-client/HTTP";
import { setSnackbarAction } from "../../../../../../services/services/app";
import { EmptyHomeScreen } from "../../../../../stylized/EmptyHomeScreen";
import { getParsedDateByDateAndTime } from "../../../../../../bae-client/Utils";
import Loader from "../../../../../stylized/Loader";
import {
    setActivePageAction,
    setActiveWorkspaceAction,
    setSpaceListAction,
    setWorkspaceRedirectionAction,
    workspaceIdToNameReducer,
} from "../../../../../../services/services/app";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../../../../../actions";
import { openInstance } from "../../../../../../actions/DashboardActions";
import { fetchHierarchyResponse } from "../../../../../../actions/HierarchyActions";
import { useNavigate } from "react-router";
import Loading from "../../../../../stylized/Loading";

const RecentActivity = () => {
    const navigate = useNavigate();
    const [recentActivityData, setRecentActivityData] = useState([]);
    const [showLoader, setShowLoader] = React.useState(false);
    const [alertsLoading, setAlertsLoading] = useState(true);
    const dispatch = useDispatch();
    const workspaceList = useSelector((state) => state.workspaceList);
    useEffect(() => {
        setShowLoader(true);
        let url = "userInteractions/recentActivity";

        http.sendRestRequest(url, "GET")
            .then((resp) => {
                setShowLoader(false);
                setAlertsLoading(false);
                setRecentActivityData(resp);
            })
            .catch((err) => {
                setShowLoader(false);
                setAlertsLoading(false);
                console.log(err);
            });
    }, []);
    const useStyles = makeStyles({
        timelineContent: {
            cursor: "pointer",
            wordBreak: "break-word",
            fontFamily: "Montserrat",
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "20px",
            letterSpacing: "-0.006em",
            textAlign: "left",
        },
        timelineDate: {
            width: "100%",
            fontSize: "0.7rem",
            fontFamily: "Montserrat",
            fontWeight: "600",
            lineHeight: "1rem",
            letterSpacing: "-0.006em",
            textAlign: "left",
        },
        timelineDateWrapper: {
            width: "100%",
            top: "0rem",
            flex: "0",
        },
        titleText: {
            fontSize: "0.7rem",
            fontFamily: "sans-serif",
            fontWeight: "600",
            lineHeight: "1rem",
            letterSpacing: "0.00938em",
            userSelect: "none",
        },
    });
    const classes = useStyles();
    const handleActivityClick = (item) => {
        if (item?.objectType === "INSTANCE") {
            setShowLoader(true);
            const payload = {
                endDate: "",
                exceptions: [],
                genericIds: [],
                nextSteps: [],
                startDate: "",
            };
            http.sendRestRequest(
                "documentChain/generic/instanceList",
                "POST",
                payload
            )
                .then((resp) => {
                    const resultInstance = resp.find(
                        (instance) => instance.instanceId === item.objectId
                    );
                    if (resultInstance === undefined) {
                        setShowLoader(false);
                        dispatch(
                            setSnackbarAction({
                                open: true,
                                message: `Instance Got Deleted!`,
                                severity: "error",
                            })
                        );
                        return;
                    }
                    const libData = {
                        genericId: resultInstance.genericId,
                        genericName: resultInstance.genericName,
                        instanceIds: [item.objectId],
                    };
                    let basePathName = window.runtimeConfig.instanceName
                        ? "/" + window.runtimeConfig.instanceName
                        : "";
                    navigate(`${basePathName}/flow`);
                    dispatch(setActivePage("DocuChain"));
                    dispatch(openInstance(libData));
                    setShowLoader(false);
                })
                .catch((error) => {
                    setShowLoader(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: error.error,
                            severity: "error",
                        })
                    );
                    return;
                });
        } else if (item?.objectType === "FLOW") {
            setShowLoader(true);
            http.sendRestRequest("documentChain/generic/list")
                .then((flows) => {
                    setShowLoader(false);
                    const resultflows = flows.find(
                        (flow) => flow.genericId === item.objectId
                    );
                    if (resultflows) {
                        const libData = {
                            genericId: resultflows.genericId,
                            genericName: resultflows.genericName,
                            instanceIds: [],
                        };

                        dispatch(setActivePage("DocuChain"));
                        dispatch(openInstance(libData));
                    } else {
                        dispatch(
                            setSnackbarAction({
                                open: true,
                                message: `Flow Got Deleted!`,
                                severity: "error",
                            })
                        );
                        return;
                    }
                })
                .catch((error) => {
                    setShowLoader(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: error.error,
                            severity: "error",
                        })
                    );
                });
        } else if (item?.objectType === "SPACE") {
            const spaceId = item?.objectId;
            const idArray = item?.path?.split("//") || [];
            const workspaceId = idArray[0];
            const resWorkspace = workspaceList.find(
                (workspace) =>
                    parseInt(workspace.workspaceId) === parseInt(workspaceId)
            );

            http.sendRestRequest(
                `workspace/spaceList?workspaceId=${workspaceId}`
            )
                .then((spaces) => {
                    const space = spaces.find(
                        (space) => space.spaceId === spaceId
                    );
                    if (!space) {
                        dispatch(
                            setSnackbarAction({
                                open: true,
                                message: `Space Got Archived/Deleted!`,
                                severity: "error",
                            })
                        );
                        return;
                    } else {
                        const breadCrumbItems = [
                            {
                                id: resWorkspace?.workspaceId,
                                name: resWorkspace?.workspaceName,
                                type: "workspace",
                                parentId: 0,
                            },
                        ];
                        breadCrumbItems.push({
                            id: space?.spaceId,
                            name: space?.spaceName,
                            type: "space",
                            parentId: resWorkspace?.workspaceId,
                        });
                        let activeSpace = {
                            id: space?.spaceId,
                            name: space?.spaceName,
                            updatedOn: space?.updatedOn,
                            createdOn: space?.createdOn,
                            isShared: space?.isShared ? "Shared" : "Personal",
                            isStarred: space?.isStarred,
                            type: "space",
                            documentId: null,
                        };
                        dispatch(
                            setWorkspaceRedirectionAction(
                                {
                                    activeSpace: activeSpace,
                                    breadCrumbItems: breadCrumbItems,
                                    folderId: 0,
                                    folderName: activeSpace?.name,
                                    activeWorkspace: {
                                        workspaceId: resWorkspace?.workspaceId,
                                        workspaceName:
                                            resWorkspace?.workspaceName,
                                    },
                                },
                                true
                            )
                        );
                        dispatch(setActivePage("Workspace"));
                    }
                })
                .catch((error) => {
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: error.error,
                            severity: "error",
                        })
                    );
                });
        } else if (item?.objectType === "FOLDER") {
            const folderId = item?.objectId;
            const idArray = item?.path?.split("/") || [];
            let folderName = "";
            setShowLoader(true);
            http.sendRestRequest(`folders/folderDetails?folderId=${folderId}`)
                .then((resp) => {
                    setShowLoader(false);
                    const folderIdArray = resp.folderIdPath.split("/");
                    let parentFolderId = 0;
                    if (folderIdArray.length !== 1) {
                        parentFolderId =
                            folderIdArray[folderIdArray.length - 2];
                    }
                    const folderNameArray = resp.folderNamePath.split("/");
                    const spaceId = resp.spaceId;
                    const workspaceId = idArray[0];
                    const resWorkspace = workspaceList.find(
                        (workspace) =>
                            parseInt(workspace.workspaceId) ===
                            parseInt(workspaceId)
                    );
                    http.sendRestRequest(
                        `folders/folderData?spaceId=${spaceId}&folderId=${parentFolderId}`
                    )
                        .then((res) => {
                            let isFolderPresent = res.find(
                                (item) => item.objectId === folderId
                            );
                            if (!isFolderPresent) {
                                dispatch(
                                    setSnackbarAction({
                                        open: true,
                                        message: `Folder Got Archived!`,
                                        severity: "error",
                                    })
                                );
                                setShowLoader(false);
                                return;
                            } else {
                                http.sendRestRequest(
                                    `workspace/spaceList?workspaceId=${workspaceId}`
                                )
                                    .then((spaces) => {
                                        const space = spaces.find(
                                            (space) => space.spaceId === spaceId
                                        );
                                        if (!space) {
                                            dispatch(
                                                setSnackbarAction({
                                                    open: true,
                                                    message: `Space Got Deleted!`,
                                                    severity: "error",
                                                })
                                            );
                                            return;
                                        } else {
                                            const breadCrumbItems = [
                                                {
                                                    id: resWorkspace?.workspaceId,
                                                    name: resWorkspace?.workspaceName,
                                                    type: "workspace",
                                                    parentId: 0,
                                                },
                                            ];

                                            breadCrumbItems.push({
                                                id: space?.spaceId,
                                                name: space?.spaceName,
                                                type: "space",
                                                parentId:
                                                    resWorkspace?.workspaceId,
                                            });
                                            let activeSpace = {
                                                id: space?.spaceId,
                                                name: space?.spaceName,
                                                updatedOn: space?.updatedOn,
                                                createdOn: space?.createdOn,
                                                isShared: space?.isShared
                                                    ? "Shared"
                                                    : "Personal",
                                                isStarred: space?.isStarred,
                                                type: "space",
                                                documentId: null,
                                            };
                                            folderIdArray.forEach(
                                                (element, index) => {
                                                    if (
                                                        parseInt(folderId) ===
                                                        parseInt(element)
                                                    ) {
                                                        folderName =
                                                            folderNameArray[
                                                                index
                                                            ];
                                                    }
                                                    breadCrumbItems.push({
                                                        id: parseInt(element),
                                                        name: folderNameArray[
                                                            index
                                                        ],
                                                        type: "folder",
                                                        parentId:
                                                            index === 0
                                                                ? 0
                                                                : parseInt(
                                                                      folderIdArray[
                                                                          index -
                                                                              1
                                                                      ]
                                                                  ),
                                                    });
                                                }
                                            );
                                            const redirectionObject = {
                                                breadCrumbItems:
                                                    breadCrumbItems,
                                                folderId: parseInt(folderId),
                                                folderName,
                                                type: "folder",
                                                activeSpace: activeSpace,
                                                activeWorkspace: {
                                                    workspaceId:
                                                        resWorkspace?.workspaceId,
                                                    workspaceName:
                                                        resWorkspace?.workspaceName,
                                                },
                                            };
                                            dispatch(
                                                setWorkspaceRedirectionAction(
                                                    redirectionObject,
                                                    true
                                                )
                                            );
                                            dispatch(
                                                setActivePage("Workspace")
                                            );
                                        }
                                        setShowLoader(false);
                                    })
                                    .catch((err) => {
                                        setShowLoader(false);
                                        console.log(err);
                                        dispatch(
                                            setSnackbarAction({
                                                open: true,
                                                message: `Space Got Deleted!`,
                                                severity: "error",
                                            })
                                        );
                                        return;
                                    });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            setShowLoader(false);
                        });
                })
                .catch((err) => {
                    setShowLoader(false);
                    console.log(err);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: `Folder Is not present in the given location!`,
                            severity: "error",
                        })
                    );
                    return;
                });
        } else if (item?.objectType === "WORKSPACE") {
            const resultworkspace = workspaceList.find(
                (workspace) => workspace.workspaceId === item.objectId
            );

            if (resultworkspace === undefined) {
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: `Workspace Got Deleted!`,
                        severity: "error",
                    })
                );
                return;
            }

            setShowLoader(true);
            localStorage.setItem("workspaceId", resultworkspace.workspaceId);
            http.sendRestRequest(
                `workspace/spaceList?workspaceId=${resultworkspace.workspaceId}`
            )
                .then((response) => {
                    dispatch(
                        setActiveWorkspaceAction({
                            workspaceId: resultworkspace.workspaceId,
                            workspaceName: resultworkspace.workspaceName,
                            workspaceType: "USER",
                        })
                    );
                    dispatch(setSpaceListAction(response));
                    dispatch(setActivePageAction("Workspace"));
                    setShowLoader(false);
                })
                .catch((err) => {
                    console.log(err);
                    setShowLoader(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: err.error,
                            severity: "error",
                        })
                    );
                });
        }
    };

    return (
        <>
            {alertsLoading ? (
                <Loading />
            ) : recentActivityData.length === 0 ? (
                <>
                    <EmptyHomeScreen
                        heading="Recent Activity"
                        image="./images/recent.svg"
                        subTitle="No recent activity yet!"
                    />
                    {/* <Loader show={showLoader} /> */}
                </>
            ) : (
                <div id="recentview-Container">
                    <div className="heading">Recent Activity</div>
                    <div className="timeline_view_container invisibleScroller">
                        <Timeline>
                            {recentActivityData.map((item, idx) => (
                                <TimelineItem key={idx}>
                                    <TimelineOppositeContent color="textSecondary">
                                        <Typography
                                            className={classes.timelineDate}
                                        >
                                            {getParsedDateByDateAndTime(
                                                item.createdOn
                                            )}
                                        </Typography>
                                    </TimelineOppositeContent>

                                    <TimelineSeparator>
                                        <TimelineDot color="primary" />
                                        {recentActivityData.length - 1 !==
                                            idx && (
                                            <TimelineConnector
                                                style={{ minHeight: "50px" }}
                                            />
                                        )}
                                    </TimelineSeparator>

                                    <div style={{ width: "70%" }}>
                                        <TimelineContent>
                                            <Typography
                                                style={{ width: "300px" }}
                                                className={
                                                    classes.timelineContent +
                                                    " textEllipsis"
                                                }
                                                title={item.description}
                                                onClick={() =>
                                                    handleActivityClick(item)
                                                }
                                            >
                                                {item.description}
                                            </Typography>
                                        </TimelineContent>
                                    </div>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </div>
                    {/* <Loader show={showLoader} /> */}
                </div>
            )}
        </>
    );
};

export default RecentActivity;
