import { Switch } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import http from "../../../../../../bae-client/HTTP";
import { setSnackbarAction } from "../../../../../../services/services/app";

const FlowRuleRow = ({
    ruleName,
    paragraph,
    active,
    rule,
    renameHandler,
    deleteHandler,
    loadAllRules,
    setShowLoader,
}) => {
    const dispatch = useDispatch();
    const workspaceList = useSelector((state) => state.workspaceList);
    const [isToggled, setToggled] = React.useState(true);
    const [ruleMetaData, setRuleMetaData] = React.useState("");

    const handleToggle = () => {
        // setToggled(!isToggled);
        setShowLoader(true);
        const putPayload = {
            genericRuleId: rule.genericRuleId,
            isActive: !active,
        };
        console.log(rule, "test");
        let url = `generic/Rule?genericId=${rule.genericRuleId}`;
        http.sendRestRequest(url, "PUT", putPayload)
            .then(() => {
                setShowLoader(false);
                dispatch(
                    setSnackbarAction({
                        message: "Flow Rule updated successfully",
                        severity: "success",
                        open: true,
                    })
                );
                loadAllRules();
            })
            .catch((error) => {
                console.log(error);
                setShowLoader(false);
                dispatch(
                    setSnackbarAction({
                        message: error.error,
                        severity: "error",
                        open: true,
                    })
                );
            });
    };

    const getMetaData = async (rule) => {
        // eslint-disable-line
        const { workspaceId, spaceId, folderId, storageLocation } = rule;
        let storage = storageLocation;

        if (parseInt(folderId)) {
            let message = `From ${storage} attach files to `;
            const flowAttributes = rule.flowAttributes;
            const flow = flowAttributes.find(
                (f) => f.attributeName === "Flow Name"
            );
            if (flow) {
                message += "Flow " + flow?.regularExpression;
                setRuleMetaData(message);
            }
            const step = flowAttributes.find(
                (f) => f.attributeName === "Step Name"
            );
            if (step) {
                message += `${flow ? " & " : ""}Step ${
                    step?.regularExpression
                }`;
                setRuleMetaData(message);
            }
            if (!flow && !step) {
                message += ` steps of the generic`;
            }
            setRuleMetaData(message);
        } else {
            let message = `From ${storage} attach files to `;
            const flowAttributes = rule.flowAttributes;
            const flow = flowAttributes.find(
                (f) => f.attributeName === "Flow Name"
            );
            if (flow) {
                message += "Flow " + flow?.regularExpression;
                setRuleMetaData(message);
            }
            const step = flowAttributes.find(
                (f) => f.attributeName === "Step Name"
            );
            if (step) {
                message += `${flow ? " & " : ""}Step ${
                    step?.regularExpression
                }`;
                setRuleMetaData(message);
            }
            if (!flow && !step) {
                message += ` steps of the generic`;
            }
            setRuleMetaData(message);
        }
    };

    React.useEffect(() => {
        if (rule && workspaceList && workspaceList.length) getMetaData(rule);
    }, [rule, workspaceList]);
    return (
        <>
            <div className="row-heading">
                <Switch
                    checked={active}
                    style={{ cursor: "pointer" }}
                    onClick={handleToggle}
                />
                <div className="rule-name">
                    <div className="rule-heading textEllipsis" title={ruleName}>
                        {ruleName}
                    </div>
                    <div
                        title={ruleMetaData}
                        className="textEllipsis"
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            letterSpacing: "-0.006em",
                            textAlign: "left",
                            color: "#21242C",
                        }}
                    >
                        {ruleMetaData}
                    </div>
                </div>
                <div
                    style={{
                        width: "10%",
                        display: "flex",
                        justifyContent: "space-around",
                    }}
                >
                    <span
                        className="edit-btn"
                        title="Edit Rule"
                        style={{ marginRight: "-36px" }}
                        onClick={() => renameHandler(rule)}
                    >
                        <img
                            src="./images/edit_rule.svg"
                            alt=""
                            style={{ width: "24px", height: "24px" }}
                        />
                    </span>
                    <span
                        title="Delete Rule"
                        className="delete-btn"
                        onClick={() => deleteHandler(rule)}
                    >
                        <img
                            src="./images/trash-1.svg"
                            alt=""
                            style={{ width: "24px", height: "24px" }}
                        />
                    </span>
                </div>

                {/* <button
                    className={"btn share modalButton"}
                    type="button"
                    id={"createNew"}
                    style={{
                        minWidth: "4rem",
                        height: "30px",

                        background: "#0798EA",
                        textTransform: "capitalize",
                    }}
                    title="Run"
                    value={"Run"}
                    onClick={runFunction}
                >
                    Run
                </button> */}
            </div>
            <div style={{ border: "1px solid #D7DEEA" }}></div>
        </>
    );
};

export default FlowRuleRow;
