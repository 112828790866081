import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import {
    getParsedDateByDateAndTime,
    convertUTCTimeToLocalTime,
} from "../../../../../../bae-client/Utils";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import http from "../../../../../../bae-client/HTTP";
import { Switch } from "@mui/material";
import AddDocumentChainModal from "../docu-chain-list/add-docu-chain-modal";
import RenameDocumentChainModal from "../docu-chain-list/add-docu-chain-modal/rename";
import Menu from "@mui/material/Menu";
import AlertDialog from "../../../../../stylized/AlertDialog";
import Loader from "../../../../../stylized/Loader";
import CustomSnackbar from "../../../../../stylized/CustomSnackbar/CustomSnackbar";
import MenuItem from "@mui/material/MenuItem";
import "./style.css";
import { connect, useSelector } from "react-redux";
import { fetchDocumentChainList } from "../../.../../../../../../actions/DocumentChainActions";
import {
    fetchFilterData,
    setGenericIdToDisplayNameMap,
} from "../../../../../../actions/DashboardActions";
import { getDateByFormat } from "../../../../../../bae-client/Utils";
import config from "../../../../../../config";

const columns = [
    { id: "genericDisplayName", label: "Name", minWidth: 170 },
    { id: "dateCreated", label: "Date Created", minWidth: 170 },
    {
        id: "dateModified",
        label: "Date Modified",
        minWidth: 170,
    },
    {
        id: "active",
        label: "Active",
        minWidth: 100,
    },
    {
        id: "",
        label: "Actions",
        minWidth: 100,
    },
];

function createData(
    docuChain,
    dateCreated,
    dateModified,
    genericId,
    genericDisplayName
) {
    return {
        docuChain,
        dateCreated,
        dateModified,
        genericId,
        genericDisplayName,
    };
}

function DocList({
    list,
    editDocumentChain,
    selectedRow,
    fetchDocumentChainList,
    setSelectedRow,
    setShowDocumentChain,
    setEditGeneric,
    handleSort,
}) {
    const listRows = list.map((item) =>
        createData(
            item.genericName,
            item.createdOn,
            item.updatedOn,
            item.genericId,
            item.genericDisplayName
        )
    );

    const dispatch = useDispatch();
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";
    var GenericTitle = useRef("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showLoader, setShowLoader] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [alertDialogShow, setAlertDialogShow] = React.useState(false);
    const [errorAlert, setErrorAlert] = React.useState({
        showAlertView: false,
        isAlert: false,
        alertDialogTitle: "",
        alertDialogMessage: "",
    });
    const [showCloneDocumentChainModal, setShowCloneDocumentChainModal] =
        React.useState(false);
    const [renameDocumentChainModal, setRenameShowCloneDocumentChainModal] =
        React.useState(false);
    const [orderBy, setOrderBy] = React.useState("asc");
    const [sortedColumn, setSortedColumn] = React.useState("");
    const [classAction, setClassAction] = React.useState("");
    const [className, setClassName] = React.useState("headerSortDown");
    const [classMDate, setclassMDate] = React.useState("headerSortDown");
    const [classCDate, setclassCDate] = React.useState("headerSortDown");
    const [alertDialogContent, setAlertDialogContent] = React.useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const showAlertView = (
        title,
        body,
        messageId = "",
        buttonId = "",
        isError = true
    ) => {
        // Update the Alert Dialog Content
        setAlertDialogContent({
            title,
            message: body,
            messageId,
            buttonId,
        });

        // Decide whether to show the alert dialog or the snackbar
        if (isError) {
            setAlertDialogShow(true); // Show the alert dialog for errors
        } else {
            setSnackbarMessage(body); // Use the body as the message for the snackbar
            setShowSnackbar(true); // Show the snackbar for non-error cases
        }
    };

    const open = Boolean(anchorEl);

    const handleClick = (event, row) => {
        setSelectedRow(row);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        const action = e.currentTarget.dataset.action; // Retrieve the data-action attribute

        if (action === "edit" && selectedRow) {
            setShowDocumentChain(true);
            setEditGeneric(true);
        } else {
            setAnchorEl(null);
        }
    };

    const openAlertView = (title, body) => {
        errorAlert["showAlertView"] = true;
        errorAlert["alertDialogMessage"] = body;
        errorAlert["alertDialogTitle"] = title;
        setErrorAlert({ ...errorAlert });
    };
    const deleteClickHandler = (e) => {
        setAnchorEl(null);
        setAlertDialogContent({
            title: "Delete Generic",
            message: "Are you sure you want to Delete the selected Generic?",
            isAlert: true,
            buttonName: "Delete",
            buttonId: "btn_delete_generic",
        });
        setAlertDialogShow(true);
    };
    const deleteGeneric = (selectedGeneric) => {
        return new Promise((resolve, reject) => {
            const url = `documentChain/generic?genericId=${selectedRow.genericId}`;

            http.sendRestRequest(url, "DELETE", selectedGeneric)
                .then((resp) => {
                    if (resp === 200) {
                        resolve(resp);
                        setShowSnackbar(false); // Ensure snackbar is not visible prematurely
                        handleClose(); // Close the modal if everything is successful
                    } else {
                        reject({
                            error: "Unexpected response from the server.",
                        });
                    }
                })
                .catch((err) => {
                    reject(err); // Reject with error to handle externally
                });
        });
    };

    const handleConfirmationDialog = (response, isDelete) => {
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });

        if (response === "YES" && isDelete === "DELETE") {
            const body = selectedRow;
            setShowLoader(true);

            deleteGeneric(body)
                .then((resp) => {
                    if (resp === 200) {
                        showAlertView(
                            "Success",
                            "Generic Deleted successfully!",
                            "",
                            "",
                            false // Show snackbar for success
                        );
                        fetchLatestFlowsData();
                        fetchDocumentChainList(selectedRow.genericId);
                    }
                })
                .catch((err) => {
                    const errorMessage =
                        err?.error ||
                        "An unexpected error occurred. Please try again.";
                    showAlertView(
                        "Operation Failed",
                        errorMessage,
                        "",
                        "",
                        true // Show alert dialog for error
                    );
                })
                .finally(() => {
                    setShowLoader(false);
                });
        }
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getCell = (column, row, value, rid, cid) => {
        if (column === "")
            return (
                <div
                    onClick={(event) =>
                        isReadOnlyUser ? null : handleClick(event, row)
                    }
                    className={isReadOnlyUser ? "readOnly" : ""}
                    style={{
                        cursor: "pointer",
                        height: "24px",
                    }}
                    id={"menu_option_" + rid}
                >
                    <img
                        alt={"options"}
                        title="option"
                        src="./images/options.png"
                        style={{ marginLeft: "20px" }}
                    ></img>
                </div>
            );
        else if (column === "active")
            return (
                <Switch
                    defaultChecked
                    disabled={isReadOnlyUser}
                    className={isReadOnlyUser ? "readOnly" : ""}
                />
            );
        else if (column === "genericDisplayName")
            return (
                <div
                    onClick={(event) => {
                        setSelectedRow(row);
                        setShowDocumentChain(true);
                    }}
                    className="textEllipsis"
                    style={{
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        letterSpacing: "-0.006em",
                        textAlign: "left",
                        width: "562px",
                        cursor: "pointer",
                        color: "#00000",
                        paddingLeft: "20px",
                    }}
                    id={"flow_" + value}
                    title={value}
                >
                    {value}
                </div>
            );
        else {
            let parsedDate = getParsedDateByDateAndTime(value);

            return parsedDate;
        }
    };
    const newDocListClickHandler = (e) => {
        setShowCloneDocumentChainModal(true);
        GenericTitle.current = e.target.innerText;
        setAnchorEl(null);
    };
    const renameDocListClickHandler = (e) => {
        setRenameShowCloneDocumentChainModal(true);
        GenericTitle.current = e.target.innerText;
        setAnchorEl(null);
    };

    const closeModal = () => {
        setShowCloneDocumentChainModal(false);
        setRenameShowCloneDocumentChainModal(false);
    };
    const fetchLatestFlowsData = () => {
        http.sendRestRequest("documentChain/objects")
            .then((response) => {
                dispatch(fetchFilterData(response));
                let genericIdToDisplayNameMap = new Map();
                let flows = response.map((item) => item.genericRegistry);
                flows.map((item) =>
                    genericIdToDisplayNameMap.set(
                        item.genericId,
                        item.genericDisplayName
                    )
                );
                dispatch(
                    setGenericIdToDisplayNameMap(genericIdToDisplayNameMap)
                );
            })
            .catch((err) => console.log(err));
    };
    const addCloneDocumentChain = (exsisting, genric, display) => {
        setShowCloneDocumentChainModal(false);
        setShowLoader(true);
        http.sendRestRequest(
            `documentChain/generic/cloneGeneric?sourceGenericName=${encodeURIComponent(
                exsisting
            )}&targetGenericName=${encodeURIComponent(
                genric
            )}&targetGenericDisplayName=${encodeURIComponent(display)}`,
            "POST"
        )
            .then((response) => {
                setSnackbarMessage("Flow cloned successfully!");
                fetchLatestFlowsData();
                setShowSnackbar(true);
                setShowLoader(false);
                dispatch(fetchDocumentChainList());
            })
            .catch((err) => {
                // showAlertView("Operation Failed", err.error, "", "", true);
                setShowLoader(false);
            });
    };

    const renameDocumentChain = (id, genericDisplayName, documentChainName) => {
        setRenameShowCloneDocumentChainModal(false);
        setShowLoader(true);
        let url = config.editable(sessionStorage.user)
            ? `documentChain/generic?genericId=${id}&genericName=${genericDisplayName}&genericDisplayName=${documentChainName}`
            : `documentChain/generic?genericId=${id}&genericName=${genericDisplayName}&genericDisplayName=${documentChainName}`;

        http.sendRestRequest(url, "PUT")
            .then((response) => {
                setSnackbarMessage("Flow renamed successfully!");
                fetchLatestFlowsData();
                setShowSnackbar(true);
                dispatch(fetchDocumentChainList());
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err.error);
                err.error &&
                    showAlertView("Operation Failed", err.error, "", "", true);
                setShowLoader(false);
            });
    };

    const handleSorting = (e) => {
        if (e.target.innerText === "Actions") {
            setClassName("");
        } else if (e.target.innerText === "Name") {
            orderBy === "asc"
                ? setClassName("headerSortDown")
                : setClassName("headerSortUp");
        } else if (e.target.innerText === "Date Modified") {
            orderBy === "asc"
                ? setclassMDate("headerSortDown")
                : setclassMDate("headerSortUp");
        } else {
            orderBy === "asc"
                ? setclassCDate("headerSortDown")
                : setclassCDate("headerSortUp");
        }

        if (e.target.innerText === "Active" || e.target.innerText === "")
            return;
        setSortedColumn(e.target.innerText);
        handleSort(e.target.innerText, orderBy);
        orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    };

    return (
        <>
            <Loader show={showLoader}></Loader>
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
            <AlertDialog
                // open={showAlertDialog}
                // message={alertDialogMessage}
                // title={""}
                // isAlert={false}
                // setOpen={changeOpenDialogFlag}
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                handleConfirmationDialog={handleConfirmationDialog}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
                messageId={alertDialogContent.messageId}
                buttonId={alertDialogContent.buttonId}
                buttonName={alertDialogContent.buttonName}
            ></AlertDialog>
            <AddDocumentChainModal
                showAddDocumentChainModal={showCloneDocumentChainModal}
                closeModal={closeModal}
                openModal={newDocListClickHandler}
                list={list}
                ids={
                    GenericTitle.current === "Clone"
                        ? "div_cloneheading_flow"
                        : "div_createheading_flow"
                }
                selectedRow={selectedRow}
                heading={
                    GenericTitle.current === "Clone"
                        ? "Clone the generic flow"
                        : "Create New Flow"
                }
                addCloneDocumentChain={addCloneDocumentChain}
            ></AddDocumentChainModal>
            <RenameDocumentChainModal
                showRenameDocumentChainModal={renameDocumentChainModal}
                closeModal={closeModal}
                selectedRow={selectedRow === null ? "" : selectedRow}
                openModal={renameDocListClickHandler}
                list={list}
                // genericName={
                //     list.length &&
                //     list.filter((generic) =>
                //         selectedRow.includes(generic.genericId)
                //     )[0]?.docuChain
                // }
                heading={"Rename Flow"}
                renameDocumentChain={renameDocumentChain}
            ></RenameDocumentChainModal>
            <Paper
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
                id="generic_table"
            >
                <TableContainer
                    sx={{ paddingTop: "0px", height: "90%", flexBasis: "90%" }}
                    className="invisibleScroller"
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead sx={{ backgroundColor: "#eaeef5" }}>
                            <TableRow sx={{ backgroundColor: "#eaeef5" }}>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            minWidth: column.minWidth,
                                            fontWeight: "600",
                                            backgroundColor: "#eaeef5",
                                        }}
                                        title={column.label}
                                    >
                                        <div
                                            style={{
                                                paddingLeft:
                                                    column.id == "docuChain"
                                                        ? "20px"
                                                        : "",
                                                cursor:
                                                    column.id == "actions"
                                                        ? "default"
                                                        : "pointer",
                                            }}
                                            className={
                                                column.label === "Active" ||
                                                column.label === "Actions"
                                                    ? classAction
                                                    : column.label === "Name"
                                                    ? className
                                                    : column.label ===
                                                      "Date Modified"
                                                    ? classMDate
                                                    : classCDate
                                            }
                                            onClick={handleSorting}
                                            role="button"
                                        >
                                            {column.label}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listRows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, rowIndex) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.docuChain}
                                        >
                                            {columns.map(
                                                (column, columnIndex) => {
                                                    const value =
                                                        row[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                        >
                                                            {getCell(
                                                                column.id,
                                                                row,
                                                                value,
                                                                rowIndex, // Pass the rowIndex to getCell
                                                                columnIndex // Pass the columnIndex to getCell
                                                            )}
                                                        </TableCell>
                                                    );
                                                }
                                            )}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={listRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ height: "10%", flexBasis: "10%", overflow: "hidden" }}
                />
                <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={handleClose}
                        id="editGen"
                        data-action="edit"
                    >
                        <div>
                            <img
                                id="editGen"
                                className="renameIcon"
                                src="./images/Rename.svg"
                                alt="Edit"
                                title="Edit"
                            />
                            <span className="btn_title" title="Edit">
                                Edit
                            </span>
                        </div>
                    </MenuItem>

                    <MenuItem onClick={deleteClickHandler} id="deleteGen">
                        <div>
                            <img
                                className="deleteIcon"
                                title="Delete"
                                src="./images/delete.svg"
                                alt="Delete"
                            />
                            <span className="btn_title" title="Delete">
                                Delete
                            </span>
                        </div>
                    </MenuItem>
                    <MenuItem onClick={newDocListClickHandler} id="cloneGen">
                        <div>
                            <img
                                className="renameIcon"
                                src="./images/clone.svg"
                                alt="Clone"
                                title="Clone"
                            />
                            <span className="btn_title" title="Clone">
                                Clone
                            </span>
                        </div>
                    </MenuItem>
                    <MenuItem
                        onClick={renameDocListClickHandler}
                        id="renameGen"
                    >
                        <div>
                            <img
                                className="renameIcon"
                                src="./images/rename-icon2.svg"
                                alt="Rename"
                                title="Rename"
                            />
                            <span className="btn_title" title="Rename">
                                Rename
                            </span>
                        </div>
                    </MenuItem>
                </Menu>
            </Paper>
        </>
    );
}
const mapDispatchToProps = () => {
    return {
        // setActiveSpace,
        // setActiveWorkspace,
        // addSpace,
        // fetchSpaceListData,
        // setBoardList

        // resetFolderListData,
        fetchDocumentChainList,
    };
};

const mapStateToProps = (state) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps())(DocList);
