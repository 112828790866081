import { TextField, RaisedButton, Button } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import http from "../../../../../../../bae-client/HTTP";
import TextBox from "../../../../../../stylized/TextBox";
import ChatMessages from "./chatMessages";
import { newMessage } from "../../../../../../../actions/WebSocketActions";
import ShareOptions from "./shareOptions";
import { getIconPathByType } from "../../../../../common/IconList";
import {
    fetchFilterData,
    setGenericIdToNameMap,
    setGenericNameToIdMap,
} from "../../../../../../../actions/DashboardActions";
import Loader from "../../../../../../stylized/Loader";
import AlertDialog from "../../../../../../stylized/AlertDialog";
export default function ChatHistory({
    handleSendMessage,
    selectedUser,
    newUser,
    addNewUser,
    isNewChat,
    newChatUser,
}) {
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [message, setMessage] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const [chatBackHistory, setChatBackHistory] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [isSharableItem, setIsSharableItem] = useState(false);
    const [showLoder, setShowLoder] = useState(false);
    const [messageDetails, setMessageDetails] = useState(null);
    const [shareableData, setShareableData] = useState(null);

    const dispatch = useDispatch();
    const webSocketNotificationReceived = useSelector(
        (state) => state.webSocketNotification
    );

    const messageEl = useRef(null);
    const styles = {
        padding: "8px",
        width: "100%",
    };
    const containerStyle = {
        backgroundColor: "white",
        padding: 15,
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        flexBasis: "90%",
    };
    const style = {
        display: "block",
        margin: "5px 0",
    };
    const isMe = true;
    const floatDirection = isMe ? "right" : "left";
    const nameColor = isMe ? "green" : "red";
    const margin = isMe ? " 0 0 0 40px" : "0 40px 0 0 ";

    const textStyle = {
        float: floatDirection,
        backgroundColor: "#0798EA",
        padding: "6px 10px",
        borderRadius: "15px",
        margin: margin,
        textAlign: "left",
    };

    const nameStyle = {
        color: nameColor,
        float: floatDirection,
    };
    const filterRawData = useSelector((state) => state.DashboardDCFilterData);
    useEffect(() => {
        if (!filterRawData) {
            setShowLoder(true);
            http.sendRestRequest("documentChain/objects")
                .then((response) => {
                    dispatch(fetchFilterData(response));
                    setShowLoder(false);
                })
                .catch((err) => console.log(err));
        }
    }, []);
    useEffect(() => {
        if (filterRawData) {
            createMapGenericIdToName();
        }
    }, [filterRawData]);
    const createMapGenericIdToName = () => {
        setShowLoder(true);
        let genericIdToNameMap = new Map();
        let genericNameToIdMap = new Map();
        filterRawData.forEach((filterItem) => {
            genericIdToNameMap.set(
                filterItem.genericRegistry.genericName.toLowerCase(),
                filterItem.genericRegistry.genericId
            );
            genericNameToIdMap.set(
                filterItem.genericRegistry.genericId,
                filterItem.genericRegistry.genericName.toLowerCase()
            );
        });
        dispatch(setGenericNameToIdMap(genericIdToNameMap));
        dispatch(setGenericIdToNameMap(genericNameToIdMap));
        setShowLoder(false);
    };

    useEffect(() => {
        if (selectedUser && selectedUser.chatCuboidId) {
            http.sendRestRequest(
                `communicator/chatMessageList?chatMessageCuboidId=${selectedUser.chatCuboidId}`
            )
                .then((res) => {
                    setChatHistory(res);
                    setChatBackHistory(res);
                })
                .catch((err) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                    console.log(err);
                });
        } else setChatHistory([]);
    }, [selectedUser]);
    useEffect(() => {
        if ((newUser && !selectedUser) || isNewChat) setChatHistory([]);
    }, [newUser]);
    useEffect(() => {
        if (messageEl && messageEl.current) {
            const currentMessageEl = messageEl.current;
            const observer = new MutationObserver(() => {
                currentMessageEl.scroll({
                    top: currentMessageEl.scrollHeight,
                    behavior: "auto",
                });
            });
            observer.observe(currentMessageEl, { childList: true });
        }
    }, [chatHistory]);

    const handleSend = (event) => {
        // console.log(messageDetails, "s", shareableData);
        if (message || messageDetails) {
            if (messageDetails && messageDetails.message) {
                handleSendMessage(
                    message + "|" + messageDetails.message,
                    messageDetails.messageType === "MSG_INSTANCE_STEP" ||
                        messageDetails.messageType === "MSG_FLOW" ||
                        messageDetails.messageType === "MSG_WORKSPACE_OBJECT"
                        ? messageDetails.messageType
                        : `MSG_${messageDetails.messageType}`,
                    shareableData
                );
            } else {
                handleSendMessage(message);
            }
            setMessage("");
            setIsSharableItem(false);
            setMessageDetails(null);
        }
        setErrorMsg("");
    };
    useEffect(() => {
        setMessage("");
    }, [selectedUser, newChatUser]);
    const handleTextChange = (value, id) => {
        if (value.length === 2000) {
            setMessage(value);
            setErrorMsg("Message size should be maximum 2000 characters");
        } else {
            setMessage(value);
            setErrorMsg("");
        }
    };
    const getMessage = () => {
        if (!messageDetails) return "";
        if (messageDetails.messageType === "FILE_OBJECT") {
            const sharedObject = JSON.parse(messageDetails.message);
            return sharedObject.folderFileName;
        }
        if (
            messageDetails.messageType === "FOLDER_OBJECT" ||
            messageDetails.messageType === "SPACE_OBJECT"
        ) {
            const sharedObject = JSON.parse(messageDetails.message);
            return Object.keys(sharedObject)[0];
        }
        if (messageDetails.messageType === "RIPPLE_OBJECT") {
            const sharedObject = JSON.parse(messageDetails.message);
            return sharedObject.rippleName || "Unsaved Ripple";
        }
        if (messageDetails.messageType === "MSG_INSTANCE_STEP") {
            const sharedObject = JSON.parse(messageDetails.message);
            if (sharedObject.shareType === "step") {
                return sharedObject.genericStepName || "Unsaved Step";
            } else {
                return sharedObject.instanceName || "Unsaved Insatnce";
            }
        }
        if (messageDetails.messageType === "MSG_FLOW") {
            const sharedObject = JSON.parse(messageDetails.message);
            return sharedObject.genericDisplayName;
        }
        if (messageDetails.messageType === "MSG_WORKSPACE_OBJECT") {
            const sharedObject = JSON.parse(messageDetails.message);
            return sharedObject.workspaceName;
        }
    };
    const renderIcon = (type) => {
        switch (type) {
            case "FILE_OBJECT":
                return getIconPathByType("file_small");
            case "FOLDER_OBJECT":
                return getIconPathByType("folder_small");
            case "SPACE_OBJECT":
                return getIconPathByType("space_small");
            case "MSG_WORKSPACE_OBJECT":
                return getIconPathByType("workspace_small");
            case "RIPPLE_OBJECT":
                return getIconPathByType("ripple_small");
            case "MSG_INSTANCE_STEP":
                return getIconPathByType("documentChain");
            case "MSG_FLOW":
                return getIconPathByType("documentChain");
            default:
                return null;
        }
    };
    const getSharableItem = () => {
        if (isSharableItem)
            return (
                <div
                    className="text_msg_body"
                    style={{
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: "#FFFFFF",
                        width: "auto",
                        maxWidth: "50%",
                        marginLeft: "2px",
                        marginTop: "5px",
                    }}
                >
                    <div
                        className="row w-100 m-0"
                        style={{
                            padding: "5px",
                            display: "flex",
                            alignItems: "center",
                            flexFlow: "row",
                        }}
                    >
                        <div
                            id="msg_text_icon"
                            className="doCenter"
                            style={{ width: "auto" }}
                        >
                            <img
                                src={renderIcon(messageDetails.messageType)}
                                alt=""
                                className="chat_Content_icon"
                            />
                        </div>
                        <div
                            className="ml-2 textEllipsis"
                            style={{ width: "100%" }}
                            title={getMessage()}
                        >
                            <span>{getMessage()}</span>
                        </div>
                        <div
                            class="doCenter"
                            style={{ width: "auto", paddingLeft: "10px" }}
                            role="button"
                            tabindex="0"
                            onClick={() => {
                                setIsSharableItem(false);
                                setMessageDetails(null);
                            }}
                        >
                            <img
                                src="./images/cancel-icon-chat.svg"
                                style={{ height: "15px" }}
                            ></img>
                        </div>
                    </div>
                </div>
            );
        else return null;
    };
    const getFolderStructure = (object) => {
        return {
            [object.objectName]: {
                files:
                    (object.children &&
                        object.children.length &&
                        object.children
                            .filter((item) => {
                                return item.documentId;
                            })
                            .map((item) => {
                                return `${item.documentId}:${item.objectName}`;
                            })) ||
                    [],
                folders:
                    (object.children &&
                        object.children.length &&
                        object.children
                            .filter((item) => {
                                return !item.documentId;
                            })
                            .map((item) => {
                                return getFolderStructure(item);
                            })) ||
                    [],
            },
        };
    };
    const getSendMessage = (message, messageType, folderStructure) => {
        let returnMessage = "";
        if (messageType === "SPACE_OBJECT") {
            returnMessage = JSON.stringify(
                // getFolderStructure({
                //     objectName: message.spaceName,
                //     children: folderStructure,
                // })
                {
                    [message.spaceName]: message.spaceId,
                    isDownload: message.isDownload ? message.isDownload : false,
                    shareUpdates: message.shareUpdates
                        ? message.shareUpdates
                        : false,
                    receiveUpdates: message.receiveUpdates
                        ? message.receiveUpdates
                        : false,
                }
            );
        } else if (messageType === "FOLDER_OBJECT") {
            returnMessage = JSON.stringify({
                [message.folderFileName]: message.folderFileId,
                isDownload: message.isDownload ? message.isDownload : false,
                shareUpdates: message.shareUpdates
                    ? message.shareUpdates
                    : false,
                receiveUpdates: message.receiveUpdates
                    ? message.receiveUpdates
                    : false,
            });
        } else if (messageType === "RIPPLE_OBJECT") {
            returnMessage = JSON.stringify(message);
        } else if (messageType === "MSG_INSTANCE_STEP" || "MSG_FLOW") {
            if (messageType === "FILE_OBJECT") {
                returnMessage = JSON.stringify({
                    ...message,
                    isEditable: folderStructure.hasOwnProperty("isEditable")
                        ? folderStructure.isEditable
                        : false,
                });
            } else {
                returnMessage = JSON.stringify(message);
            }
        } else {
            returnMessage = messageType
                ? `${message.documentId}:${message.folderFileName}`
                : message;
        }

        setMessageDetails({ messageType: messageType, message: returnMessage });
        return returnMessage;
    };
    const shareAttachment = (shareObject, folderStructure, typeOfMsg) => {
        setShareableData(shareObject);
        if (typeOfMsg) {
            const messageDetails = getSendMessage(shareObject, typeOfMsg);
        } else {
            const messageDetails = getSendMessage(
                shareObject,
                !shareObject.workspaceId
                    ? "RIPPLE_OBJECT"
                    : !shareObject.folderFileName
                    ? "SPACE_OBJECT"
                    : shareObject.documentId !== -1
                    ? "FILE_OBJECT"
                    : "FOLDER_OBJECT",
                folderStructure
            );
        }
        setIsSharableItem(true);
        // handleSendMessage(
        //     shareObject,
        //     !shareObject.workspaceId
        //         ? "RIPPLE_OBJECT"
        //         : !shareObject.folderFileName
        //         ? "SPACE_OBJECT"
        //         : shareObject.documentId
        //         ? "FILE_OBJECT"
        //         : "FOLDER_OBJECT",
        //     folderStructure
        // );
    };
    return (
        <>
            <div
                id={"communicatorChatHistoryContainer"}
                style={containerStyle}
                className="invisibleScroller"
                ref={messageEl}
            >
                {chatBackHistory.length && !isNewChat
                    ? chatBackHistory.map((chat) => (
                          <ChatMessages messageDetails={chat}></ChatMessages>
                      ))
                    : chatHistory.length
                    ? chatHistory.map((chat) => (
                          <ChatMessages messageDetails={chat}></ChatMessages>
                      ))
                    : null}
            </div>
            <div style={{ flexBasis: "10%", padding: "4px" }}>
                <div style={styles}>
                    <TextBox
                        showChild={isSharableItem}
                        child={getSharableItem()}
                        type="text"
                        wrap="soft"
                        value={message}
                        id="chatMessageInput"
                        className="updateSpaceName chatTextBox w-100 m-0"
                        divClass="w-100"
                        onchange={(value, id) => handleTextChange(value, id)}
                        label="Type a message here..."
                        formControl="Off"
                        doCenter={false}
                        maxLength="2000"
                        onEnterKey={
                            ((message.length > 0 || messageDetails) &&
                                isNewChat &&
                                newChatUser.length > 0) ||
                            ((message.length > 0 || messageDetails) &&
                                !isNewChat &&
                                selectedUser &&
                                selectedUser.hasOwnProperty("chatRegistryId"))
                                ? handleSend
                                : () => {}
                        }
                        disabled={
                            !addNewUser && (selectedUser || newChatUser.length)
                                ? false
                                : true
                        }
                    ></TextBox>
                    <div
                        className="row m-0 w-100"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <ShareOptions
                            shareAttachment={shareAttachment}
                            // disable={selectedUser || (newUser && ) ? false : true}
                            disable={
                                !addNewUser &&
                                (selectedUser || newChatUser.length)
                                    ? false
                                    : true
                            }
                        ></ShareOptions>
                        {errorMsg && (
                            // <div className="w-100 m-0">
                            <small style={{ color: "red" }}>{errorMsg}</small>
                            // </div>
                        )}
                        <span
                            id={"sendChatMessage"}
                            style={{
                                float: "right",
                                cursor: "pointer",
                                paddingTop: "8px",
                                width: "auto",
                            }}
                            onClick={
                                ((message.length > 0 || messageDetails) &&
                                    isNewChat &&
                                    newChatUser.length > 0) ||
                                ((message.length > 0 || messageDetails) &&
                                    !isNewChat &&
                                    selectedUser &&
                                    selectedUser.hasOwnProperty(
                                        "chatRegistryId"
                                    ))
                                    ? handleSend
                                    : null
                            }
                        >
                            <img
                                className={
                                    ((message.length > 0 || messageDetails) &&
                                        isNewChat &&
                                        newChatUser.length > 0) ||
                                    ((message.length > 0 || messageDetails) &&
                                        !isNewChat &&
                                        selectedUser &&
                                        selectedUser.hasOwnProperty(
                                            "chatRegistryId"
                                        ))
                                        ? "cursorPointer"
                                        : "cursorNotAllowed"
                                }
                                alt="new chat"
                                src="./images/send_message.svg"
                            ></img>
                        </span>
                    </div>
                </div>
                <Loader show={showLoder}></Loader>
                <AlertDialog
                    open={showAlertDialog}
                    message={alertDialogMessage}
                    title={""}
                    isAlert={false}
                ></AlertDialog>
            </div>
        </>
    );
}
