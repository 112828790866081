import React, { Component } from "react";
import { connect } from "react-redux";
import http from "../../../bae-client/HTTP";
import user from "../../../bae-client/User";
import {
    validateEmail,
    ValidateEmail,
    validateUserName,
    validatePasswordPatern,
} from "../../../bae-client/Validations";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import AlertDialog from "../../stylized/AlertDialog";
import OutlineTextBoxView from "../../stylized/OutlineTextBoxView";
import "./SettingsGeneral.css";
import { resetStore } from "../../../actions";
import { removeUserSession } from "../../functional/common/Login/ActiveUser";
import ModalView from "../../stylized/ModalView";
import * as Mixpanel from "../../../mix-panel/Mixpanel";
import Loader from "../../stylized/Loader";
import Inputs from "../../Inputs";
import { Button } from "react-bootstrap";

class SettingsGeneral extends Component {
    constructor(props) {
        super(props);
        this.firstName = null;
        this.lastName = null;
        this.companyName = null;
        this.userEmail = null;
        this.userName = null;
        this.phoneNumber = null;
        this.password = null;
        this.bwUserInfo = user.getBwUserInfo();

        this.state = {
            dataFetched: false,
            showAlertModal: false,
            showLoader: false,
            firstName: "",
            lastName: "",
            companyName: "",
            userEmail: "",
            userName: "",
            phoneNumber:
                this.bwUserInfo && this.bwUserInfo["contactNumber"]
                    ? this.bwUserInfo["contactNumber"]
                    : "",
            password: "",
            newPassword: "",
            newPasswordRetype: "",
            firstNameErrorMsg: "",
            lastNameErrorMsg: "",
            companyNameErrorMsg: "",
            userEmailErrorMsg: "",
            userNameErrorMsg: "",
            phoneNumberErrorMsg: "",
            passwordErrorMsg: "",
            newPasswordErrorMsg: "",
            newPasswordRetypeErrorMsg: "",
            error: "",
            confirmPasswordErrorMsg: "",
            errorMsg: "",
            showChangePasswordModal: false,
            isFormDirty: false,
        };
    }
    componentDidMount = () => {
        this.bwUserInfo = user.getBwUserInfo();
        if (!this.bwUserInfo)
            http.sendRestRequest(
                `user/${parseInt(sessionStorage.getItem("userId"))}`
            )
                .then((response) => {
                    user.setBwUserInfo(response);
                    this.bwUserInfo = user.getBwUserInfo();
                    this.setData();
                })
                .catch((err) => console.log(err));
        else this.setData();
    };
    componentDidUpdate = () => {
        if (
            (this.errMsgTitle === "Email Change" ||
                this.errMsgTitle === "Password Change") &&
            !this.state.showAlertModal
        ) {
            this.props.handleLogout();
            this.props.setUser(false);
            this.props.resetStore();
            removeUserSession();
        }
    };
    validateFirstName = () => {
        let isValidName = validateUserName(this.firstName);
        if (isValidName === true) {
            this.setState({ firstNameErrorMsg: "" });
            return true;
        } else {
            this.setState({ firstNameErrorMsg: isValidName });
        }
    };
    validateLastName = () => {
        let isValidLastName = validateUserName(this.lastName);
        if (isValidLastName === true) {
            this.setState({ lastNameErrorMsg: "" });
            return true;
        } else {
            this.setState({ lastNameErrorMsg: isValidLastName });
        }
    };
    validateCompanyName = () => {
        let isValidField = validateUserName(this.companyName);
        if (isValidField === true) {
            this.setState({ companyNameErrorMsg: "" });
            return true;
        } else {
            this.setState({ companyNameErrorMsg: isValidField });
        }
    };
    validateEmailText = () => {
        let isValidField = validateEmail(this.userEmail);
        if (isValidField === true) {
            this.setState({ userEmailErrorMsg: "" });
            return true;
        } else {
            this.setState({ userEmailErrorMsg: isValidField });
        }
    };
    validateUserNameText = () => {
        let isValidField = validateUserName(this.userName);
        if (isValidField === true) {
            this.setState({ userNameErrorMsg: "" });
            return true;
        } else {
            this.setState({ userNameErrorMsg: isValidField });
        }
    };
    validatePasswordText = (password, index) => {
        let currentPassword = atob(sessionStorage.token.split(":")[1]).split(
            ":"
        )[1];
        if (index === 1) {
            let isValidPasswordPattern = validatePasswordPatern(password);
            if (isValidPasswordPattern === true) {
                if (currentPassword === password)
                    this.setState({
                        newPasswordErrorMsg:
                            "Password cannot be the same as old password!",
                    });
                else this.setState({ newPasswordErrorMsg: "" });
            } else {
                this.setState({ newPasswordErrorMsg: isValidPasswordPattern });
                return;
            }
        } else {
            if (index === 0) {
                if (currentPassword === this.state.password)
                    this.setState({
                        passwordErrorMsg: "",
                    });
                else
                    this.setState({
                        passwordErrorMsg:
                            "Password not matching with existing one!",
                    });
            } else {
                if (this.state.newPassword === this.state.newPasswordRetype)
                    this.setState({
                        newPasswordRetypeErrorMsg: "",
                        error: "",
                    });
                else
                    this.setState({
                        newPasswordRetypeErrorMsg: "Password not matching!",
                        error: "Password not matching!",
                    });
            }
        }
    };

    validatePhoneNumber(phoneNo) {
        if (phoneNo !== "") {
            return isValidPhoneNumber(phoneNo);
        } else {
            return true;
        }
    }

    setIsFormDirty = () => {
        if (
            this.userEmail === this.bwUserInfo["userEmail"] &&
            this.firstName === this.bwUserInfo["firstName"] &&
            this.lastName === this.bwUserInfo["lastName"] &&
            this.companyName === this.bwUserInfo["companyName"] &&
            (this.phoneNumber === this.bwUserInfo["contactNumber"] ||
                !this.state.phoneNumber)
        ) {
            this.setState({ isFormDirty: false });
        } else {
            this.setState({ isFormDirty: true });
        }
    };
    setData = () => {
        if (this.bwUserInfo) {
            let bwUserInfo = user.getBwUserInfo();

            //  this.bwUserInfo;
            if (
                bwUserInfo["firstName"] !== undefined &&
                bwUserInfo["firstName"] !== null
            ) {
                this.firstName = bwUserInfo["firstName"];
            } else {
                this.firstName = "First Name";
            }
            if (
                bwUserInfo["lastName"] !== undefined &&
                bwUserInfo["lastName"] !== null
            ) {
                this.lastName = bwUserInfo["lastName"];
            } else {
                this.lastName = "Last Name";
            }
            if (
                bwUserInfo["companyName"] !== undefined &&
                bwUserInfo["companyName"] !== null
            ) {
                this.companyName = bwUserInfo["companyName"];
            } else {
                this.companyName = "e.g. Tesla Motors";
            }
            if (
                bwUserInfo["userEmail"] !== undefined &&
                bwUserInfo["userEmail"] !== null
            ) {
                this.userEmail = bwUserInfo["userEmail"];
            } else {
                this.userEmail = "john.smith@example.com";
            }
            if (
                bwUserInfo["userEmail"] !== undefined &&
                bwUserInfo["userEmail"] !== null
            ) {
                this.userName = bwUserInfo["userEmail"];
            } else {
                this.userName = "@example";
            }
            if (
                bwUserInfo["contactNumber"] !== undefined &&
                bwUserInfo["contactNumber"] !== null
            ) {
                this.phoneNumber = bwUserInfo["contactNumber"];
            } else {
                this.phoneNumber = "";
            }
            this.setState({ dataFetched: true });
        }
    };
    handleKeyDown = (e) => {
        if (e.key === " " && this.firstName.length === 0) {
            e.preventDefault();
        }
    };
    setFromText = (value, id, event) => {
        switch (id) {
            case "inp_settings_general_firstName":
                {
                    this.firstName = value;
                    this.setState({ firstName: value });
                    this.setIsFormDirty();
                }
                break;
            case "inp_settings_general_lastName":
                {
                    this.lastName = value;
                    this.setState({ lastName: value });
                    this.setIsFormDirty();
                }
                break;
            case "inp_settings_general_companyname":
                {
                    this.companyName = value;
                    this.setState({ companyName: value });
                    this.setIsFormDirty();
                }
                break;
            case "inp_settings_general_email":
                {
                    this.userEmail = value.trim();
                    this.setState({ userEmail: value.trim() });
                    this.setIsFormDirty();
                }
                break;
            case "inp_settings_general_phoneNumber":
                {
                    this.phoneNumber = value;
                    this.setState({ phoneNumber: value });
                    this.setIsFormDirty();
                }
                break;
            case "inp_settings_general_userName":
                {
                    this.userName = value;
                    this.setState({ userName: value });
                }
                break;
            case "inp_settings_general_password":
                {
                    this.password = value;
                    this.setState({ password: value });
                }
                break;
            case "inp_settings_general_new_password":
                {
                    this.password = value;
                    this.setState({ newPassword: value, error: "" });
                }
                break;
            case "inp_settings_general_new_password_retype":
                {
                    this.password = value;
                    this.setState({ newPasswordRetype: value, error: "" });
                }
                break;
        }
    };
    resetData = () => {
        let bwUserInfo = this.bwUserInfo;
        if (
            bwUserInfo["firstName"] === undefined &&
            this.firstName === "First Name"
        ) {
            this.firstName = "";
        }
        if (
            bwUserInfo["lastName"] === undefined &&
            this.lastName === "Last Name"
        ) {
            this.lastName = "";
        }
        if (
            bwUserInfo["companyName"] === undefined &&
            this.companyName === "e.g. Tesla Motors"
        ) {
            this.companyName = "";
        }
        if (
            bwUserInfo["userEmail"] === undefined &&
            this.userEmail === "john.smith@example.com"
        ) {
            this.userEmail = "";
        }
        if (
            bwUserInfo["userEmail"] === undefined &&
            this.userName === "@example"
        ) {
            this.userName = "";
        }
        if (
            bwUserInfo["contactNumber"] === undefined &&
            this.phoneNumber === "555-555-5555"
        ) {
            this.phoneNumber = "";
        }
    };

    changePassword = () => {
        this.errMsgTitle = "";
        if (
            !this.state.passwordErrorMsg &&
            !this.state.newPasswordErrorMsg &&
            !this.state.newPasswordRetypeErrorMsg &&
            this.state.password &&
            this.state.newPassword &&
            this.state.newPasswordRetype
        ) {
            if (this.state.newPassword === this.state.newPasswordRetype) {
                http.sendRestRequest("password/changePassword", "PUT", {
                    newPassword: btoa(this.state.newPassword),
                    currentPassword: btoa(this.state.password),
                })
                    .then((response) => {
                        if (this.state.newPassword) {
                            this.setState({ showChangePasswordModal: false });
                            this.showAlertView(
                                "Password Change",
                                "You have changed your password. Please login again!"
                            );
                            Mixpanel.callUserIdentityEvent("Password Change");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.showAlertView(error.errorCode, error.error);
                    });
            } else {
                this.setState({
                    error: "Password and Re-enter password are not matching.",
                });
            }
        }
    };

    updateUserProfile = () => {
        this.errMsgTitle = "";

        this.resetData();

        let isEmailChange = this.bwUserInfo["userEmail"] !== this.userEmail;

        let data = {
            userId: this.bwUserInfo["userId"],
            userEmail: this.userEmail,
            externalUserId: this.userEmail,
            firstName: this.firstName.trim(),
            lastName: this.lastName.trim(),
            companyName: this.companyName.trim(),
            contactNumber: this.state.phoneNumber,
        };

        let url = "user";
        let method = "PUT";
        this.setState({ showLoader: true });
        http.sendRestRequest(url, method, data)
            .then((response) => {
                // if (response === 200) {
                // alert("Data updated");
                if (isEmailChange) {
                    this.showAlertView(
                        "Email Change",
                        "You have changed your email, Please login again!"
                    );
                    this.setState({ showLoader: false });
                    Mixpanel.callUserIdentityEvent("Email Change");
                    return;
                }
                this.showAlertView("", "Data updated");
                let userId = http.getUserId();
                this.userName = this.userName.trim();
                this.companyName = this.companyName.trim();
                this.lastName = this.lastName.trim();
                this.setState({
                    userName: this.userName,
                    companyName: this.companyName,
                    lastName: this.lastName,
                });

                let url = "user/" + userId;
                http.sendRestRequest(url)
                    .then((response) => {
                        user.setBwUserInfo(response);
                        this.setState({ isFormDirty: false });
                        this.setState({ showLoader: false });
                    })
                    .catch((error) => {
                        this.setState({ showLoader: false });
                        console.log(error);
                    });
                // }
            })
            .catch((error) => {
                this.setState({ showLoader: false });
                console.log(error);
                console.error(error.path + " " + error.error);
            });
        // }
    };
    renderContent = () => {
        return (
            <div className="settingsContainer">
                <div>
                    <span
                        className="billingHeading"
                        style={{ paddingBottom: "19px" }}
                    >
                        Personal Details
                    </span>
                </div>
                <div className="marginTopTen">
                    <div className="row label m-0 w-100 value">
                        <div
                            className="col-md-4 form-group"
                            style={{ marginBottom: "1.5rem" }}
                        >
                            <label className="settingsGeneralLabel textEllipsis">
                                First Name
                            </label>

                            <Inputs
                                onChange={this.setFromText}
                                value={this.firstName}
                                type="text"
                                id="inp_settings_general_firstName"
                                placeholder="First Name"
                                handleKeyDown={this.handleKeyDown}
                                isError={
                                    this.state.firstNameErrorMsg &&
                                    this.state.firstNameErrorMsg.length > 0
                                }
                                icon={false}
                            />
                        </div>
                        <div
                            className="col-md-4 form-group"
                            style={{ marginBottom: "1.5rem" }}
                        >
                            <label className="settingsGeneralLabel textEllipsis">
                                Last Name
                            </label>
                            <Inputs
                                onChange={this.setFromText}
                                value={this.lastName}
                                type="text"
                                id="inp_settings_general_lastName"
                                placeholder="Last Name"
                                handleKeyDown={this.handleKeyDown}
                                isError={
                                    this.state.lastNameErrorMsg &&
                                    this.state.lastNameErrorMsg.length > 0
                                }
                                icon={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="marginTopTen" style={{ marginTop: "-19px" }}>
                    <div className="row label m-0 w-100 value">
                        <div className="col-md-8 form-group md-5">
                            <label className="settingsGeneralLabel textEllipsis">
                                Company Name
                            </label>
                            <Inputs
                                onChange={this.setFromText}
                                value={this.companyName}
                                type="text"
                                disable={!!this.companyName}
                                id="inp_settings_general_companyname"
                                placeholder="Company Name"
                                handleKeyDown={this.handleKeyDown}
                                isError={
                                    this.state.companyNameErrorMsg &&
                                    this.state.companyNameErrorMsg.length > 0
                                }
                                icon={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="marginTopTen">
                    <div className="row  m-0 w-100 label value">
                        <div className="col-md-8 form-group md-5">
                            <label className="settingsGeneralLabel textEllipsis">
                                Email Address
                            </label>
                            <Inputs
                                onChange={this.setFromText}
                                value={this.userEmail}
                                type="text"
                                id="inp_settings_general_email"
                                placeholder="Email"
                                disable={!!this.userEmail}
                                isError={
                                    this.state.userEmailErrorMsg &&
                                    this.state.userEmailErrorMsg.length > 0
                                }
                                icon={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="marginTopTen">
                    <div className="row m-0 w-100 label value">
                        <div className="col-md-8 form-group md-5">
                            <label className="settingsGeneralLabel textEllipsis">
                                User Name
                            </label>
                            <Inputs
                                onChange={this.setFromText}
                                value={this.userName}
                                type="text"
                                id="inp_settings_general_userName"
                                placeholder="User Name"
                                disable={!!this.userName}
                                isError={
                                    this.state.userNameErrorMsg &&
                                    this.state.userNameErrorMsg.length > 0
                                }
                                icon={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="marginTopTen">
                    <div className="row m-0 w-100 label value">
                        <div className="col-md-8 form-group md-5">
                            <label className="settingsGeneralLabel textEllipsis">
                                Phone Number
                            </label>
                            <PhoneInput
                                id="inp_settings_general_phoneNumber"
                                className=""
                                placeholder="Enter Phone Number"
                                value={this.state.phoneNumber}
                                onChange={(value) => {
                                    if (value !== undefined) {
                                        this.phoneNumber = value;
                                        this.setState({ phoneNumber: value });
                                        this.setIsFormDirty();
                                    } else {
                                        if (this.phoneNumber == "") return;
                                        this.phoneNumber = "";
                                        this.setState({
                                            phoneNumber: this.phoneNumber,
                                        });
                                        this.setIsFormDirty();
                                    }
                                }}
                            ></PhoneInput>
                            {this.state.phoneNumber &&
                                !this.validatePhoneNumber(
                                    this.state.phoneNumber
                                ) && (
                                    <div className="row m-0 w-100">
                                        <span
                                            className="err_msg"
                                            id="err_inp_signUp_department_dropDown"
                                        >
                                            {
                                                "Please, enter a valid phone number!"
                                            }
                                        </span>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>

                <div className="marginTopTen">
                    <div className="row  m-0 w-100 label value">
                        <div className="col-md-8 form-group md-5">
                            <label className="settingsGeneralLabel textEllipsis">
                                Unity Central Email ID
                            </label>
                            {this.userEmail}
                        </div>
                    </div>
                </div>
                <div className="marginTopTen">
                    <div className="row m-0 w-100 label value">
                        <div className="col-md-8 form-group md-5">
                            <label className="settingsGeneralLabel textEllipsis">
                                Title
                            </label>
                            <Inputs
                                onChange={this.setFromText}
                                value={this.userName}
                                type="text"
                                id="inp_settings_general_userName"
                                placeholder="Title"
                                disable={!!this.userName}
                                isError={
                                    this.state.userNameErrorMsg &&
                                    this.state.userNameErrorMsg.length > 0
                                }
                                icon={false}
                            />
                        </div>
                    </div>

                    {/* <TextBox id='username' className="loginPageTextBox  loginTextBoxLarge" type="text" onchange={this.setFromText} label= formControl="Off"></TextBox> */}
                </div>

                <div className="marginTopTen doCenter">
                    <Button
                        id="generalSettingsButtonChange"
                        disabled={""}
                        onClick={() =>
                            this.setState({
                                showChangePasswordModal: true,
                                password: "",
                                newPassword: "",
                                newPasswordRetype: "",
                                passwordErrorMsg: "",
                                newPasswordErrorMsg: "",
                                newPasswordRetypeErrorMsg: "",
                                error: "",
                            })
                        }
                        className="cursorPointer btn share modalButton"
                        style={{
                            boxShadow: "none",
                            backgroundColor: "white",
                        }}
                        title="Change Password"
                        variant={"outlined"}
                    >
                        Change Password
                    </Button>
                    <Button
                        id="generalSettingsButton"
                        onClick={this.updateUserProfile}
                        disabled={
                            !this.userName ||
                            !this.firstName ||
                            !this.lastName ||
                            !this.companyName ||
                            !this.userEmail ||
                            this.state.errorMsg ||
                            this.state.firstNameErrorMsg ||
                            this.state.lastNameErrorMsg ||
                            this.state.companyNameErrorMsg ||
                            this.state.userEmailErrorMsg ||
                            this.state.phoneNumberErrorMsg ||
                            this.state.newPasswordErrorMsg ||
                            this.state.confirmPasswordErrorMsg ||
                            this.state.newPasswordRetypeErrorMsg ||
                            this.state.error ||
                            !this.validatePhoneNumber(this.state.phoneNumber) ||
                            !this.state.isFormDirty
                                ? true
                                : false
                        }
                        className="cursorPointer btn share modalButton"
                        style={{
                            boxShadow: "none",
                            backgroundColor: "#0B5CD7",
                        }}
                        variant={"contained"}
                        title="Save Changes"
                    >
                        Save Changes
                    </Button>
                </div>
            </div>
        );
    };

    hideAlertView = () => {
        // if(this.errMsgTitle === "Password Change") {
        //     this.setState({ showAlertModal: false });
        // } else {
        //     this.setState({ showAlertModal: false, showChangePasswordModal: true });
        // }
        this.setState({
            showAlertModal: false,
            showChangePasswordModal: false,
        });
    };
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true, showChangePasswordModal: false });
    };
    renderAlertModal = () => {
        return (
            <AlertDialog
                open={this.state.showAlertModal}
                setOpen={this.hideAlertView}
                title={this.errMsgTitle}
                message={this.errMsgBody}
            ></AlertDialog>
        );
    };

    renderChangePasswordModal = () => {
        return (
            <ModalView
                onSubmit={this.changePassword}
                id="addWorkspaceModalBtn"
                value="Okay"
                title="Okay"
                show={this.state.showChangePasswordModal}
                onHide={() => this.setState({ showChangePasswordModal: false })}
                footer={"false"}
                type="submit-cancel"
                size="md"
                closeBtnId={"btn_addspace_close"}
                modalId={"modal_addspace"}
            >
                <div
                    className="row doCenter"
                    style={{
                        flexDirection: "column",
                        width: "70%",
                        margin: "auto",
                    }}
                >
                    <div
                        style={{
                            marginBottom: "24px",
                            marginTop: "-28px",
                            fontWeight: "bold",
                            fontSize: "22px",
                        }}
                    >
                        Change Password
                    </div>
                    <div style={{ padding: "12px 0px", width: "100%" }}>
                        <OutlineTextBoxView
                            disabled={false}
                            id="inp_settings_general_password"
                            parentDivClass="mb-0"
                            inputClass={"settingInput"}
                            placeholder="Current Password"
                            value={this.state.password}
                            type={"password"}
                            onChangeInput={this.setFromText}
                            onBlurInput={() =>
                                this.validatePasswordText(
                                    this.state.password,
                                    0
                                )
                            }
                            errorMsg={this.state.passwordErrorMsg}
                        ></OutlineTextBoxView>
                    </div>
                    <div style={{ padding: "12px 0px", width: "100%" }}>
                        <OutlineTextBoxView
                            disabled={false}
                            id="inp_settings_general_new_password"
                            type={"password"}
                            parentDivClass="mb-0"
                            inputClass={"settingInput"}
                            placeholder="New Password"
                            value={this.state.newPassword}
                            onChangeInput={this.setFromText}
                            onBlurInput={() =>
                                this.validatePasswordText(
                                    this.state.newPassword,
                                    1
                                )
                            }
                            errorMsg={this.state.newPasswordErrorMsg}
                        ></OutlineTextBoxView>
                    </div>
                    <div style={{ padding: "12px 0px", width: "100%" }}>
                        <OutlineTextBoxView
                            disabled={false}
                            type={"password"}
                            id="inp_settings_general_new_password_retype"
                            parentDivClass="mb-0"
                            inputClass={"settingInput"}
                            placeholder="Re-enter Password"
                            value={this.state.newPasswordRetype}
                            onChangeInput={this.setFromText}
                            onBlurInput={() =>
                                this.validatePasswordText(
                                    this.state.newPasswordRetype,
                                    2
                                )
                            }
                            errorMsg={this.state.newPasswordRetypeErrorMsg}
                        ></OutlineTextBoxView>
                    </div>
                </div>
            </ModalView>
        );
    };
    render() {
        return (
            <div
                className="col-12 invisibleScroller"
                style={{
                    overflowY: "auto",
                    paddingTop: "4px",
                    height: "110%",
                    // paddingRight: "130px",
                }}
            >
                {this.renderContent()}
                {this.renderAlertModal()}
                <Loader show={this.state.showLoader} />
                {this.renderChangePasswordModal()}
            </div>
        );
    }
}
const mapDispatchToProps = () => {
    return {
        resetStore,
    };
};

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps())(SettingsGeneral);
