import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "../../../../../stylized/workspaceModal.css";
import {
    fetchFolderListData,
    resetFolderListData,
} from "../../../../../../actions/FolderActions";
import WorkspaceModalInfo from "../../../../../stylized/workspaceModalInfo";
import { getParsedDateByDateAndTime } from "../../../../../../bae-client/Utils";
import { setActiveFolderData } from "../../../../../../actions";
import user from "../../../../../../bae-client/User";
import DocViwer from "../../../../../stylized/DocViwer";
import Loader from "../../../../../stylized/Loader";
import AlertDialog from "../../../../../stylized/AlertDialog";
import ModalView from "../../../../../stylized/ModalView";
import DocumentViewerModal from "../../../../../stylized/DocumentViewerModal";
import TableView from "../../../../../stylized/TableView";
import GridList from "../GridList";
import { getDateByFormat } from "../../../../../../bae-client/Utils";
import EmptyFolderPage from "../EmptyFolderPage";
import { useDropzone } from "react-dropzone";
import { setFeedItemToRedirect } from "../../../../../../actions/InformationFeedActions";
import http from "../../../../../../bae-client/HTTP";
import ComposerModal from "../ComposerModal";
import { object } from "prop-types";
import RecentActivityManager from "../../../../../../bae-client/RecentActivityManager";
function SpaceContainer({
    handleAddFolderClick,
    addNewFile,
    isFileFolderDeleted,
    invokingElement,
    archivedItems,
    isInfoVisible,
    isListState,
    selectedSpace,
    uploadDocumentsInSpace,
    selectedSpaceId,
    fetchFolderListData,
    resetFolderListData,
    recentDocumentId,
    setIsFileOrFolderSelected,
    addFolderBreadCrumb,
    setComposerModalShows,
    changeFlag,
    changeFolderStructure,
    changeParentFolderId,
    handleLastSelectedRowData,
    lastSelectedRowData,
    recentUploadedFolderId,
    folderBreadCrumb,
    folderToIdMap,
    setIsMove,
    changeFolderToIdArray,
    handleisSpaceSelected,
    handleFileOrFolderSelected,
    setLastSelectedRowData,
    changeIsFileFolderSelected,
    setActiveFolderList,
    setFolderData,
    isSnackbarOpen,
    closeSnackbar,
    emailRender,
    isFileOrFolderSelected,
    //setIsRenameEnabled,
    setSelectedFolderId,
    displayViewData,
    setEmailRender,
    selectedRowData,
    parentFolderId,
    setActiveFolderData,
    setSelectedFolder,
    currentWorkspace,
    infoData,
    viewData,
    workspaceName,
    spaceListValue,
    activeWorkspace,
    recentlyUploadedFileNames,
    setRecentlyUploadedFileNames,
}) {
    const chatList = useSelector((state) => state.chatList);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [folderId, setFolderId] = useState(0);
    const [folderName, setFolderName] = useState("");
    const [objectType, setObjectType] = useState("");
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const folderListData = useSelector((state) => state.folderListData);
    const activeSpace = useSelector((state) => state.activeSpace);
    const activeFolderData = useSelector((state) => state.activeFolder);
    const informationFeedItemToRender = useSelector(
        (state) => state.informationFeedItemToRender
    );
    const dropFiles = [];
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
    });

    const [tableRows, setTableRows] = useState([]);
    const [loadPage, setLoadPage] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    //const [activeFolderData, setActiveFolderData] = useState(null);
    const [documentModalShow, setDocumentModalShow] = useState(false);
    const [currentShowFileName, setCurrentShowFileName] = useState("");
    const [currentShowFileLocation, setCurrentShowFileLocation] = useState("");
    const [fileFolderGridList, setFileFolderGridList] = useState([]);
    const [activeCard, setActiveCard] = useState(-1);
    const [selectedCheckedData, setSelectedCheckedData] = useState([]);
    const [emailFolderArray, setEmailFolderArray] = useState([]);
    const [ComposerModalShow, setComposerModalShow] = useState(false);
    const [noteString, setNoteString] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useDispatch();
    // this useEffect is used for NewState which is used in workspaceContianer
    useEffect(() => {
        setActiveCard(-1);
    }, [isFileFolderDeleted]);

    useEffect(() => {
        if (!folderListData) {
            setShowLoader(true);
        }
        return () => {
            setShowLoader(false);
            resetFolderListData();
        };
    }, []);
    useEffect(() => {
        if (
            !tableRows.length &&
            activeFolderData &&
            Object.keys(activeFolderData).length
        ) {
            setShowLoader(true);
        }
    }, [tableRows]);
    useEffect(() => {
        setShowLoader(true);
        resetFolderListData();
    }, [selectedSpaceId]);
    useEffect(() => {
        if (selectedSpace.id) {
            fetchFolderListData(selectedSpace.id);
            recentViews(
                selectedSpace.id,
                selectedSpace.name,
                selectedSpace.type
            );
            setShowLoader(false);
        }
    }, [recentDocumentId, recentUploadedFolderId]);
    useEffect(() => {
        if (recentlyUploadedFileNames.length) {
            recentlyUploadedFileNames.forEach((fileName) => {
                Object.values(folderListData).forEach((value) => {
                    if (fileName === value.objectName) {
                        let recentActivity = new RecentActivityManager();
                        let dataobj = {
                            objectType: "FILE",
                            objectId: value.documentId,
                            description: `Uploaded ${value.objectName}`,
                        };
                        recentActivity
                            .createRecentActivity(dataobj)
                            .then((recentRes) => {})
                            .catch((err) => console.log(err.error));
                    }
                });
            });
            setRecentlyUploadedFileNames([]);
        }
        if (folderListData === null) {
            setShowLoader(true);
        } else {
            setActiveFolderData(folderListData);
            setFolderData(folderListData);
            if (folderBreadCrumb && folderBreadCrumb.split("/").length > 2) {
                activeFolderStructureData(folderListData);
            }
        }
    }, [folderListData]);

    useEffect(() => {
        dispatch(setFeedItemToRedirect(null));
        if (emailFolderArray.length && emailRender && tableRows.length) {
            if (emailFolderArray.length) {
                handleFolderClick(
                    null,
                    emailFolderArray[0],
                    null,
                    null,
                    tableRows[0]
                );
                const updatedArray = [...emailFolderArray];
                updatedArray.shift();
                setEmailFolderArray(updatedArray);
            } else {
                setEmailRender(false);
            }
        }
    }, [emailFolderArray, emailRender, tableRows]);

    useEffect(() => {
        if (
            informationFeedItemToRender &&
            informationFeedItemToRender.folderNames
        ) {
            const folderNameArray =
                informationFeedItemToRender.folderNames.split("\\");

            setEmailFolderArray(folderNameArray);
        }
    }, [informationFeedItemToRender]);

    const sortDataByCreatedDate = () => {
        let arrayOfObj = Object.entries(activeFolderData).sort(
            (a, b) =>
                Date.parse(b[1].createdOn.split(".")[0]) -
                Date.parse(a[1].createdOn.split(".")[0])
        );
        let obj = new Map();
        arrayOfObj.forEach((item) => {
            obj.set(item[0], item[1]);
        });
    };
    useEffect(() => {
        dropFiles.push(acceptedFiles);
        if (acceptedFiles.length) {
            uploadDocumentsInSpace(dropFiles);
        }
    }, [acceptedFiles]);
    useEffect(() => {
        setShowLoader(true);
        if (activeFolderData) {
            sortDataByCreatedDate();
            generateTableData(activeFolderData);
            createFileFolderGridList(activeFolderData);
            setShowLoader(false);
        }
    }, [activeFolderData]);
    useEffect(() => {
        if (changeFlag) {
            changeFolderStructure(false);
            setActiveFolderData(folderListData);
            setFolderData(folderListData);
        }
    }, [changeFlag]);
    useEffect(() => {
        if (folderBreadCrumb && folderBreadCrumb.split("/").length > 2) {
            handleFileOrFolderSelected(false);
            activeFolderStructureData({ ...folderListData });
            setSelectedCheckedData([]);
        } else if (
            folderBreadCrumb &&
            folderBreadCrumb.split("/").length === 2
        ) {
            handleisSpaceSelected(false);
        }
    }, [folderBreadCrumb]);
    const activeFolderStructureData = (folderStructure) => {
        let foldersArray = folderBreadCrumb.split("/");
        foldersArray.shift();
        foldersArray.shift();
        for (const key in folderStructure) {
            if (folderStructure.hasOwnProperty(key)) {
                if (
                    folderStructure[key].objectName ===
                        foldersArray[foldersArray.length - 1]
                            .toString()
                            .trim() &&
                    key.toString() === folderToIdMap[foldersArray.length - 1] &&
                    folderStructure[key].children
                ) {
                    setActiveFolderData(folderStructure[key].children);
                    setFolderData(folderStructure[key].children);
                } else if (
                    folderStructure[key].children &&
                    Object.keys(folderStructure[key].children).length !== 0 &&
                    folderStructure[key].children.constructor === Object
                ) {
                    activeFolderStructureData(folderStructure[key].children);
                }
            }
        }
    };
    const recentViews = (id, name, type) => {
        setShowLoader(true);
        let url = "userInteractions/workspace/recentlyViewed";
        let viewdata = {
            objectId: id,
            objectName: name,
            objectType: type,
            spaceId: selectedSpace.id,
        };
        let data = {
            workspaceId: activeWorkspace.activeWorkspaceID,
            description: JSON.stringify(viewdata),
        };
        http.sendRestRequest(url, "POST", data)
            .then((resp) => {
                setShowLoader(false);
                // fetchFolderListData(selectedSpace.id);
            })
            .catch((err) => {
                setShowLoader(false);
            });
    };
    const hideAlertView = () => {
        setShowAlertDialog(false);
    };
    const showModalView = (event, index, fileName, row) => {
        if (isSnackbarOpen === true) {
            closeSnackbar();
        }
        setShowLoader(true);
        http.sendRestRequest(
            `document/documentDetail?documentId=${row.documentId}`
        )
            .then((response) => {
                setShowLoader(false);
                setCurrentShowFileName(fileName);
                // if (fileName.slice(-5) === ".html") {
                //     const decodedData = Buffer.from(
                //         response.documentStream,
                //         "base64"
                //     );
                //     const noteStringValue = decodedData.toString("utf8");
                //     setNoteString(noteStringValue);
                //     setComposerModalShow(true);
                // } else {
                //     setNoteString("");
                //     setDocumentModalShow(true);
                // }
                setDocumentModalShow(true);
                setCurrentShowFileLocation(response);
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
                console.log(error);
            });
    };
    const renderViewDocumentModal = (event) => {
        return (
            <DocViwer
                docName={currentShowFileName}
                fileLocation={currentShowFileLocation}
            ></DocViwer>
        );
    };
    const handleDocumentModalClose = () => {
        setDocumentModalShow(false);
        setCurrentShowFileLocation("");
    };
    const renderInformationPanel = (hiddenColumn) => {
        let userInfo = user.getUserInfo();
        let fileCount = 0;
        let folderCount = 0;
        if (
            activeFolderData !== null &&
            Object.keys(activeFolderData).length > 0 &&
            selectedCheckedData.length > 0
        ) {
            Object.keys(activeFolderData).map((obj) => {
                if (selectedCheckedData.includes(parseInt(obj))) {
                    if (activeFolderData[obj].documentId == 0) {
                        folderCount++;
                    } else if (activeFolderData[obj].documentId > 0) {
                        fileCount++;
                    }
                }
            });
        }

        return (
            <div className={hiddenColumn + " p-0 spaceListInfoWrapper"}>
                <div
                    className="ml-3 mr-3 spaceListInfoSection doCenter"
                    style={{ marginTop: "0.9rem" }}
                >
                    <div className="spacelistInfoTitle">Details</div>
                    <div className="spaceListInfoHrLine"></div>
                    <div
                        className="spacelistInfoRows"
                        style={{ paddingLeft: "30px" }}
                    >
                        {selectedCheckedData.length === 0 ? (
                            <div className="spacelistInfoHeader">
                                {selectedSpace.name}
                            </div>
                        ) : selectedCheckedData.length === 1 ? (
                            <>
                                <div className="spacelistInfoHeader">
                                    Help Guide
                                </div>
                                <div className="spacelistInfoHeader">
                                    Date Last Opened
                                </div>
                                <div className="spacelistInfoRow">
                                    {lastSelectedRowData.createdOn}
                                </div>
                                <div className="spacelistInfoHeader">
                                    Date Created
                                </div>
                                <div className="spacelistInfoRow">
                                    {lastSelectedRowData.updatedOn}
                                </div>
                                <div className="spacelistInfoHeader">
                                    Created By
                                </div>
                                <div className="spacelistInfoRow">
                                    {userInfo.username}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="spacelistInfoHeader">{`${folderCount} Folder Selected`}</div>
                                <div className="spacelistInfoHeader">{`${fileCount} File Selected`}</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    };
    const newHeadCells = [
        {
            id: "Name",
            numeric: true,
            disablePadding: true,
            width: 280,
            label: "Name",
            align: "left",
        },
        {
            id: "DateLastModified",
            numeric: false,
            disablePadding: true,
            width: 250,
            label: "Date Last Modified",
            align: "center",
        },
        {
            id: "DateCreated",
            numeric: true,
            disablePadding: true,
            width: 250,
            label: "Date Created",
            align: "center",
        },
        {
            id: "shared",
            numeric: false,
            disablePadding: true,
            width: 150,
            label: "Shared",
            align: "center",
        },
        {
            id: "owner",
            numeric: false,
            disablePadding: true,
            width: 150,
            label: "Owner",
            align: "center",
        },
        {
            id: "Starred",
            numeric: false,
            disablePadding: true,
            width: 150,
            label: "Starred",
            align: "center",
        },
    ];
    const createData = (
        id,
        type,
        name,
        createdOn,
        btnName,
        updatedOn,
        documentId,
        shared,
        owner,
        isStarred,
        parentFolderId,
        currentWorkspace
    ) => {
        return {
            id,
            type,
            name,
            createdOn,
            btnName,
            updatedOn,
            documentId,
            shared,
            owner,
            isStarred,
            parentFolderId,
            currentWorkspace,
        };
    };
    const getDocType = (docName) => {
        if (docName !== undefined) {
            let docType = docName.split(".");
            return (docType = docType[docType.length - 1]);
        }
    };

    const getOwnerName = (ownerId) => {
        let owner = null;
        chatList.forEach((chat) => {
            chat.participantUserIds.forEach((participant) => {
                if (participant.id === ownerId) {
                    owner = participant;
                }
            });
        });
        return owner !== null ? owner.firstName + " " + owner.lastName : "";
        // let owner = chatList.filter(
        //     (item) => item.messageBy === ownerId
        // )[0].participantUserIds.filter(
        //     (item) => item.id === ownerId
        // )[0];
        // return owner.firstName + " " + owner.lastName;
    };

    const generateTableData = (object) => {
        let fileList = [];
        let folderList = [];
        let rowsData = [];
        if (object !== null && object !== undefined) {
            let Keys = Object.keys(object);

            Keys.forEach((key, index) => {
                let txDate = "Loading";
                let id = key;
                let name = object[key].objectName;
                let documentId = object[key].documentId;
                let txDateAfterCheck = getParsedDateByDateAndTime(
                    object[key].createdOn
                );
                // let createdOn = getDateByFormat(
                //     object[key].createdOn,
                //     "mm/dd/yyyy"
                // );
                // let updatedOn = getDateByFormat(
                //     object[key].updatedOn,
                //     "mm/dd/yyyy"
                // );
                let createdOn = object[key].createdOn;
                let updatedOn = object[key].updatedOn;
                let dataType = documentId > 0 ? "file" : "folder";
                let type = dataType === "file" ? getDocType(name) : "folder";
                let btnName = dataType === "folder" ? "Open" : "View";
                let shared = object[key].isShared ? "Shared" : "Private";
                let isStarred = object[key].isStarred;
                let owner =
                    object[key].sharedBy === 0 ||
                    object[key].sharedBy ===
                        parseInt(sessionStorage.getItem("userId"))
                        ? ""
                        : getOwnerName(object[key].sharedBy);
                documentId > 0
                    ? fileList.push(
                          createData(
                              id,
                              type,
                              name,
                              createdOn,
                              btnName,
                              updatedOn,
                              documentId,
                              shared,
                              owner
                          )
                      )
                    : folderList.push(
                          createData(
                              id,
                              type,
                              name,
                              createdOn,
                              btnName,
                              updatedOn,
                              documentId,
                              shared,
                              owner,
                              isStarred,

                              parentFolderId,
                              currentWorkspace
                          )
                      );
            });
            folderList.sort((a, b) => {
                return (
                    Date.parse(b.createdOn.split(".")[0]) -
                    Date.parse(a.createdOn.split(".")[0])
                );
            });
            fileList.sort((a, b) => {
                return (
                    Date.parse(b.createdOn.split(".")[0]) -
                    Date.parse(a.createdOn.split(".")[0])
                );
            });
            folderList.filter((folderItem) => {
                return (
                    (folderItem.createdOn = getParsedDateByDateAndTime(
                        folderItem.createdOn
                    )),
                    (folderItem.updatedOn = getParsedDateByDateAndTime(
                        folderItem.updatedOn
                    )),
                    (folderItem.shared = folderItem.shared),
                    (folderItem.owner = folderItem.owner)
                );
            });
            fileList.filter((fileItem) => {
                return (
                    (fileItem.createdOn = getParsedDateByDateAndTime(
                        fileItem.createdOn
                    )),
                    (fileItem.updatedOn = getParsedDateByDateAndTime(
                        fileItem.updatedOn
                    )),
                    (fileItem.shared = fileItem.shared),
                    (fileItem.owner = fileItem.owner)
                );
            });
            rowsData = [...folderList, ...fileList];
        }
        setActiveFolderList(rowsData);
        setTableRows(rowsData);
        setLoadPage(rowsData.length ? "folder" : "noFolder");
        archivedItems([]);
        setShowLoader(false);
    };
    const folderClick = (folderStructure, text, map) => {
        for (const key in folderStructure) {
            if (folderStructure.hasOwnProperty(key)) {
                if (
                    folderStructure[key].objectName.trim() ===
                        text.toString().trim() &&
                    folderStructure[key].children
                ) {
                    map.push(key);
                    setActiveFolderData(folderStructure[key].children);
                    setFolderData(folderStructure[key].children);
                    addFolderBreadCrumb(folderStructure[key].objectName);
                    changeParentFolderId(key.toString());
                } else if (
                    folderStructure[key].children &&
                    Object.keys(folderStructure[key].children).length === 0 &&
                    folderStructure[key].children.constructor === Object
                ) {
                    folderClick(folderStructure[key].children, text, map);
                }
            }
        }
    };
    const handleCheckboxClick = (e, index, id, rowData, selectedIds) => {
        setIsMove("");
        let showValue = tableRows.find(
            (obj) => parseInt(obj.id) === selectedIds[0]
        );
        if (
            rowData.type === "folder" &&
            selectedIds &&
            selectedIds.length == 1
        ) {
            setSelectedFolderId(selectedIds[0]);
            //setIsRenameEnabled(true);
        } else if (showValue !== undefined) {
            if (
                selectedIds &&
                selectedIds.length === 1 &&
                showValue.type === "folder"
            ) {
                setSelectedFolderId(selectedIds[0]);
            }

            //setIsRenameEnabled(true);
        } else {
            //setIsRenameEnabled(false);
            setSelectedFolderId(null);
        }
        if (selectedIds && selectedIds.length > 0) {
            handleFileOrFolderSelected(true);
        } else {
            handleFileOrFolderSelected(false);
        }
        handleLastSelectedRowData(rowData);
        setSelectedCheckedData(selectedIds);
        archivedItems(selectedIds);
    };
    const addStarFill = (id, star) => {
        let url = "folders/starred";
        let data = {
            folderRegistryId: parseInt(id),
            isStarred: star,
        };
        http.sendRestRequest(url, "PUT", data)
            .then((resp) => {
                setShowLoader(false);
                fetchFolderListData(selectedSpace.id);
            })
            .catch((err) => {
                setShowLoader(false);
            });
    };
    const handleFolderClick = (e, name, id, index, row) => {
        //setIsRenameEnabled(false);
        setSelectedFolder(row);
        if (row.type === "folder") {
            recentViews(row.id, row.name, "folder");
        }

        e && e.stopPropagation(); //stop propagation
        setShowLoader(true);
        const folderStructure = { ...activeFolderData };
        const map = [...folderToIdMap];
        setTableRows([]);
        setLoadPage(null);
        archivedItems([]);
        const text = name;
        folderClick(folderStructure, text, map);
        changeFolderToIdArray(map);
        setShowLoader(false);
    };

    const getImageAsType = (type) => {
        let source = "";
        switch (type) {
            case "folder":
                source = "./images/FOLDERLarge.png";
                break;
            case "txt":
                source = "./images/TXT.svg";
                break;
            case "pdf":
                source = "./images/PDFLarge.png";
                break;
            case "docx":
                source = "./images/word-icon.svg";
                break;
            case "xlsx":
                source = "./images/excel-icon.svg";
                break;
            case "pptx":
                source = "./images/ppt-icon.svg";
                break;
            case "xml":
                source = "./images/fileTypeIcon/xml-file.svg";
                break;
            case "gif":
                source = "./images/fileTypeIcon/gif-icon.svg";
                break;
            case "jpg":
                source = "./images/fileTypeIcon/jpg-icon.svg";
                break;
            case "svg":
                source = "./images/fileTypeIcon/svg-icon.svg";
                break;
            case "mp3":
                source = "./images/fileTypeIcon/mp3-icon.svg";
                break;
            case "mp4":
                source = "./images/fileTypeIcon/mp4-icon.svg";
                break;
            default:
                source = "./images/basicfile.png";
                break;
        }
        return source;
    };
    const createFileFolderGridList = (list) => {
        let fileList = [];
        let folderList = [];
        let gridList = [];

        if (list !== undefined) {
            Object.keys(list).forEach((item, id) => {
                let obj = {};
                obj["name"] = list[item].objectName;
                obj["id"] = item;
                let fileName =
                    list[item].documentId > 0
                        ? list[item].objectName
                        : ".folder";
                let fileType = fileName.split(".").pop();
                obj["fileType"] = fileType;
                obj["imageSource"] = getImageAsType(fileType);
                list[item].documentId > 0
                    ? fileList.push(obj)
                    : folderList.push(obj);
            });
            gridList = [...folderList, ...fileList];
            setFileFolderGridList(gridList);
            setShowLoader(false);
        }
    };
    const changeActiveCard = (spaceInstance, e, id, fileName, fileType) => {
        if (fileType !== "folder") {
            showModalView(null, null, fileName);
            setActiveCard(id);
        } else {
            const folderStructure = { ...activeFolderData };
            const map = [...folderToIdMap];
            setFileFolderGridList([]);

            folderClick(folderStructure, fileName, map);
            changeFolderToIdArray(map);
            createFileFolderGridList(folderStructure);
        }
    };
    const handleCheckAllClick = (checkAll, selectedIds) => {
        //setIsRenameEnabled(false);
        changeIsFileFolderSelected(checkAll);
        let ids = [];
        archivedItems(selectedIds);
        if (selectedIds !== undefined) {
            selectedIds.map((key) => {
                ids.push(parseInt(key));
            });
        }
        setSelectedCheckedData(ids);
        if (!checkAll) {
            archivedItems([]);
            setLastSelectedRowData([]);
        }
    };

    const changeSelectedCard = (name, id, selectedId) => {
        setSelectedCheckedData([parseInt(selectedId)]);
        isFileOrFolderSelected(true);
        selectedRowData([parseInt(selectedId)]);
        setActiveCard(id);
    };
    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));
    const renderFileOrFolderGridList = () => {
        return (
            <GridList
                folderBreadCrumb={folderBreadCrumb}
                gridListData={fileFolderGridList}
                selectedCard={activeCard}
                changeSelectedCard={changeSelectedCard}
                changeActiveCard={changeActiveCard}
                handleCheckboxClick={handleCheckboxClick}
            ></GridList>
        );
    };
    const openModal = () => {
        setIsOpen(true);
        infoData();
        viewData();
    };
    const renderFileOrFolderforSpace = () => {
        let columns = isInfoVisible ? "col-9" : "col-12";
        let hiddenColumn = isInfoVisible ? "col-3" : "col-0";
        return (
            <div>
                {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="info_btn" onClick={openModal}>
                        <img src="./images/info-circle.svg" alt=""></img>
                        <p className="info-text">Info</p>
                    </button>
                </div> */}
                <div className="row ml-1 mr-1 mt-4">
                    <div
                        className={columns + " p-0 workspaceIconRow"}
                        style={{
                            height: "74vh",
                            background: "white",
                            borderRadius: "10px",
                            boxShadow: "1px 1px 20px 4px rgba(0,0,0,0.1)",
                        }}
                    >
                        {loadPage === "noFolder" && (
                            <div {...getRootProps({ className: "dropzone" })}>
                                <EmptyFolderPage
                                    {...getInputProps()}
                                    addNewFile={invokingElement}
                                    handleAddFolderClick={handleAddFolderClick}
                                    currentWorkspace={currentWorkspace}
                                ></EmptyFolderPage>
                            </div>
                        )}
                        {loadPage === "folder" &&
                            (isListState ? (
                                <div
                                    {...getRootProps({ className: "dropzone" })}
                                >
                                    <TableView
                                        {...getInputProps()}
                                        title={false}
                                        setTableRows={setTableRows}
                                        rows={tableRows}
                                        addStarFill={addStarFill}
                                        showModal={showModalView}
                                        columns={newHeadCells}
                                        handleSpaceSelectionClick={
                                            handleFolderClick
                                        }
                                        tableWrapperClass="spacelistTable"
                                        fileOrFolderList={true}
                                        files={files}
                                        handleCheckboxClick={
                                            handleCheckboxClick
                                        }
                                        folderBreadCrumb={folderBreadCrumb}
                                        handleCheckAllClick={
                                            handleCheckAllClick
                                        }
                                    ></TableView>
                                </div>
                            ) : (
                                renderFileOrFolderGridList()
                            ))}
                    </div>
                    {isInfoVisible
                        ? renderInformationPanel(hiddenColumn)
                        : null}
                </div>
            </div>
        );
    };
    return (
        <>
            {renderFileOrFolderforSpace()}
            <DocumentViewerModal
                showHeader={true}
                footer="false"
                show={documentModalShow}
                size="lg"
                onHide={handleDocumentModalClose}
                title={currentShowFileName}
                modalBodyClass="documentViewModal"
                onclick={() => {}}
                submitButtonText="Close"
                headerTitle={currentShowFileName}
                // fileSource={currentShowFileLocation}
            >
                {documentModalShow ? renderViewDocumentModal() : null}
            </DocumentViewerModal>

            {ComposerModalShow ? (
                <ComposerModal
                    setComposerModalShow={setComposerModalShow}
                    selectedSpace={selectedSpace}
                    parentFolderId={parentFolderId}
                    isSpaceSelected={selectedSpace}
                    noteString={noteString}
                    title={currentShowFileName}
                    show={ComposerModalShow}
                    setShowAlertDialog={setShowAlertDialog}
                    setAlertDialogMessage={setAlertDialogMessage}
                    onHide={() => {
                        setComposerModalShow(false);
                        setCurrentShowFileLocation("");
                        setIsFileOrFolderSelected(false);
                    }}
                ></ComposerModal>
            ) : null}
            <Loader show={showLoader} />
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                setOpen={hideAlertView}
                title={""}
                isAlert={false}
            ></AlertDialog>
            {isOpen ? (
                <WorkspaceModalInfo
                    open={isOpen}
                    workspaceName={activeWorkspace}
                    spaceListValue={spaceListValue}
                    handleOpen={openModal}
                    handleClose={setIsOpen}
                    displayViewData={displayViewData}
                ></WorkspaceModalInfo>
            ) : null}
        </>
    );
}

const mapDispatchToProps = () => {
    return {
        fetchFolderListData,
        resetFolderListData,
        setActiveFolderData,
    };
};

const mapStateToProps = (state) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps())(SpaceContainer);
