import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../stylized/Loader";
import {
    setActiveFolderData,
    setActivePage,
    setActiveSpace,
    setActiveWorkspace,
    setBoardList,
} from "../../actions";
import { setFeedItemToRedirect } from "../../actions/InformationFeedActions";
import { fetchFolderListData } from "../../actions/FolderActions";
import Space from "../../bae-client/Space";
import { fetchSpaceListData } from "../../actions/SpaceActions";
import http from "../../bae-client/HTTP";

const None = () => {
    const dispatch = useDispatch();
    const worksapceInfoRedirection = useSelector(
        (state) => state.worksapceInfoRedirection
    );
    const spaceListData = useSelector((state) => state.spaceListData);
    const folderListData = useSelector((state) => state.folderListData);
    const activeWorkspace = useSelector((state) => state.activeWorkspace);
    const workspaceList = useSelector((state) => state.workspaceList);

    const [isLocationView, setIsLocationView] = useState(false);
    const [isFolderLocation, setIsFolderLocation] = useState(false);
    const [selectedFolderOrSpaceDetails, setSelectedFolderOrSpaceDetails] =
        useState({});
    const [folderNamePath, setFolderNamePath] = React.useState("");
    const [folderIdsPath, setFolderIdsPath] = React.useState("");

    useEffect(() => {
        if (folderListData !== null && isLocationView) {
            setIsLocationView(false);
            // setActiveFolderTree();
            setActiveWorkspaceTree();
        }
    }, [folderListData]);

    useEffect(() => {
        if (selectedFolderOrSpaceDetails.workspaceId) renderLocation();
    }, [selectedFolderOrSpaceDetails]);
    useEffect(() => {
        if (spaceListData && spaceListData.length && isLocationView) {
            if (isFolderLocation) {
                // setIsLocationView(false);
                dispatch(
                    fetchFolderListData(worksapceInfoRedirection.node.spaceId)
                );
                //setActiveWorkspaceTree();
            } else {
                setIsLocationView(false);
                setActiveWorkspaceTree();
            }
        }
    }, [spaceListData]);
    useEffect(() => {
        if (worksapceInfoRedirection) {
            setTimeout(() => {
                let selectedFolderOrSpaceDetailsObj = {};
                selectedFolderOrSpaceDetailsObj["workspaceId"] =
                    worksapceInfoRedirection.workspace.activeWorkspaceID;
                selectedFolderOrSpaceDetailsObj["workspaceName"] =
                    worksapceInfoRedirection.workspace.activeWorkspaceName;

                // let spaceList = spaceListData ? spaceListData : [];
                // spaceList.forEach((item, i) => {
                //     let options = {};
                //     options["spaceName"] = item.spaceName; //spaceNames[i]
                //     options["spaceId"] = item.spaceId; //spaceIDs[i]
                //     options["archive"] = item.archive;
                //     if (worksapceInfoRedirection.node.spaceId === item.spaceId) {
                //         selectedFolderOrSpaceDetailsObj["spaceId"] = item.spaceId;
                //         selectedFolderOrSpaceDetailsObj["spaceName"] =
                //             item.spaceName;
                //     }
                // });
                setIsLocationView(true);
                if (
                    worksapceInfoRedirection.node.objectId ||
                    worksapceInfoRedirection.node.objectId === 0
                ) {
                    selectedFolderOrSpaceDetailsObj["folderId"] =
                        worksapceInfoRedirection.node.spacePersistenceId;
                    setIsFolderLocation(true);
                } else {
                    setIsFolderLocation(false);
                }
                setSelectedFolderOrSpaceDetails({
                    ...selectedFolderOrSpaceDetailsObj,
                });
            }, 500);
        }
    }, [worksapceInfoRedirection]);

    const convertObjToMap = (object, map) => {
        Object.entries(object).forEach(([k, v]) => {
            map.set(k, v);
            if (v && typeof v === "object") {
                convertObjToMap(v, map);
            }
        });
        return map;
    };

    const renderLocation = () => {
        dispatch(
            fetchSpaceListData(selectedFolderOrSpaceDetails["workspaceId"])
        );
    };

    const setActiveWorkspaceTree = () => {
        let ws = null;
        if (workspaceList)
            ws = workspaceList.find(
                (element) =>
                    element.workspaceId ===
                    parseInt(selectedFolderOrSpaceDetails.workspaceId)
            );
        else ws = selectedFolderOrSpaceDetails;
        let activeSpace = null;
        let activeWSObject = {};
        activeWSObject["activeWorkspaceID"] =
            selectedFolderOrSpaceDetails.workspaceId;
        activeWSObject["activeWorkspaceName"] = ws.workspaceName;
        activeWSObject["spaceList"] = spaceListData ? spaceListData : [];
        let spaceList = spaceListData ? spaceListData : [];
        let spaceArray = [];
        spaceList.forEach((item, i) => {
            let spaceInstance = new Space();
            let options = {};
            options["spaceName"] = item.spaceName; //spaceNames[i]
            options["spaceId"] = item.spaceId; //spaceIDs[i]
            options["archive"] = item.archive;
            spaceInstance.setData(options);
            if (worksapceInfoRedirection.node.spaceId === item.spaceId) {
                activeSpace = options;
                // selectedSpaceIndex = i;
            }
            spaceArray.push(spaceInstance);
        });

        if (isFolderLocation) {
            setIsFolderLocation(false);
            let activeFolderObj = {};
            let folderList = folderListData ? folderListData : [];
            const map = new Map();
            let objMap = convertObjToMap(folderList, map);
            let selectedFolderId =
                selectedFolderOrSpaceDetails["folderId"].toString();
            activeFolderObj = objMap.get(selectedFolderId);
            // dispatch(setActiveFolderData(activeFolderObj.children));
            let url = "folders/folderDetails?folderId=" + selectedFolderId;
            http.sendRestRequest(url, "GET")
                .then((resp) => {
                    dispatch(
                        setFeedItemToRedirect({
                            folderNames: resp.folderNamePath.replaceAll(
                                "/",
                                "\\"
                            ),
                            // .split("\\")
                            // .reverse()
                            // .join("\\"),
                            folderIds: resp.folderIdPath.replaceAll("/", "\\"),
                            // .split("\\")
                            // .reverse()
                            // .join("\\"),
                        })
                    );
                    dispatch(setActiveWorkspace(activeWSObject));
                    // dispatch(setBoardList(spaceArray);
                    // this.props.setActiveSpace(null);
                    dispatch(setActivePage("Workspace"));
                    dispatch(setActiveSpace(activeSpace));
                })
                .catch((e) => console.log(e));
        } else {
            dispatch(setActiveWorkspace(activeWSObject));
            // dispatch(setBoardList(spaceArray);
            // this.props.setActiveSpace(null);
            dispatch(setActivePage("Workspace"));
            dispatch(setActiveSpace(activeSpace));
        }
    };
    return <Loader show={true}></Loader>;
};

export default None;
