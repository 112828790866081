import { BWGraph } from "./RippleGraph";
import config from "./config";
import { response } from "./helper";

let dla = {};
const getFolderNodes = (
    fileFolderElement,
    bwGraph,
    id,
    spaceId,
    folderName,
    count,
    folderSpaceId,
    workspaceId,
    isCollapsed
) => {
    if (fileFolderElement.documentId) {
        bwGraph.addNode(
            id + "_" + fileFolderElement.folderId,
            "-1",
            [spaceId],
            fileFolderElement.documentName,
            "Doc",
            folderSpaceId,
            workspaceId,
            isCollapsed
        );
    } else
        bwGraph.addNode(
            id,
            "-1",
            [spaceId],
            fileFolderElement.folderName,
            "Folder" + count,
            folderSpaceId,
            workspaceId,
            isCollapsed
        );
    count++;
    if (fileFolderElement.children) {
        for (const key in fileFolderElement.children) {
            if (Object.hasOwnProperty.call(fileFolderElement.children, key)) {
                let element = fileFolderElement.children[key];
                element.folderId = fileFolderElement.folderId;
                getFolderNodes(
                    element,
                    bwGraph,
                    key,
                    id,
                    element.folderName,
                    count,
                    folderSpaceId,
                    workspaceId,
                    element.isCollapsed
                );
            }
        }
    }
};
function convertJsonToBwGraph(rippleData) {
    const sourceIdArray = [];
    const bwGraph = new BWGraph();
    const newResponse = { ...rippleData };
    for (const key in newResponse) {
        if (Object.hasOwnProperty.call(newResponse, key)) {
            const element = newResponse[key];
            if (element.chatCuboidId) {
                let chatNode = bwGraph.getNode(element.chatRegistryId);
                if (!chatNode)
                    bwGraph.createRoot(
                        element.chatRegistryId,
                        "-1",
                        "Chat",
                        "Chats"
                    );
                const user = element.usernames[0];
                let userNode = bwGraph.getNode(user);
                if (!userNode)
                    bwGraph.addNode(
                        user,
                        "-1",
                        [element.chatRegistryId],
                        user,
                        "Username"
                    );
                let messageNode = bwGraph.getNode(element.chatId);
                if (!messageNode) {
                    let displayRippleName = "";
                    let rippleNameValue = "";
                    let messageString = element.chatMessage;
                    let jsonObject;
                    const textedMessage = messageString.split("|")[0];
                    if (messageString.includes("rippleName")) {
                        if (textedMessage === "") {
                            displayRippleName = JSON.parse(
                                messageString.slice(1)
                            );
                        }
                        rippleNameValue =
                            textedMessage !== ""
                                ? textedMessage
                                : displayRippleName.rippleName === ""
                                ? "Unsaved Ripple"
                                : displayRippleName.rippleName;
                        bwGraph.addNode(
                            element.chatId,
                            "-1",
                            [user],
                            rippleNameValue,
                            "Message"
                        );
                    } else if (messageString.includes("folderFileName")) {
                        if (textedMessage === "") {
                            jsonObject = JSON.parse(messageString.substring(1));
                        }
                        const folderFileName =
                            textedMessage === ""
                                ? jsonObject.folderFileName
                                : textedMessage;
                        bwGraph.addNode(
                            element.chatId,
                            "-1",
                            [user],
                            folderFileName,
                            "Message"
                        );
                    } else if (messageString.includes("genericDisplayName")) {
                        if (textedMessage === "") {
                            jsonObject = JSON.parse(messageString.substring(1));
                        }
                        const genericDisplayName =
                            textedMessage === ""
                                ? jsonObject.genericDisplayName
                                : textedMessage;
                        bwGraph.addNode(
                            element.chatId,
                            "-1",
                            [user],
                            genericDisplayName,
                            "Message"
                        );
                    } else if (
                        !messageString.includes("rippleName") &&
                        !messageString.includes("folderFileName") &&
                        messageString.includes("|")
                    ) {
                        if (textedMessage === "") {
                            jsonObject = JSON.parse(messageString.substring(1));
                        }
                        const folderSpaceName =
                            textedMessage === ""
                                ? Object.keys(jsonObject)[0]
                                : textedMessage;
                        bwGraph.addNode(
                            element.chatId,
                            "-1",
                            [user],
                            folderSpaceName,
                            "Message"
                        );
                    } else {
                        bwGraph.addNode(
                            element.chatId,
                            "-1",
                            [user],
                            element.chatMessage,
                            "Message"
                        );
                    }
                }
            } else {
                bwGraph.createRoot(
                    key,
                    "-1",
                    element.workspaceName,
                    "Workspace"
                );
                for (const space in element.spaces) {
                    if (Object.hasOwnProperty.call(element.spaces, space)) {
                        const spaceElement = element.spaces[space];
                        bwGraph.addNode(
                            space,
                            "-1",
                            [key],
                            spaceElement.spaceName,
                            "Space",
                            space,
                            element.workspaceId
                        );
                        for (const fileFolder in spaceElement.children) {
                            if (
                                Object.hasOwnProperty.call(
                                    spaceElement.children,
                                    fileFolder
                                )
                            ) {
                                const fileFolderElement =
                                    spaceElement.children[fileFolder];
                                getFolderNodes(
                                    fileFolderElement,
                                    bwGraph,
                                    fileFolder,
                                    space,
                                    fileFolderElement.folderName,
                                    1,
                                    space,
                                    element.workspaceId,
                                    fileFolderElement.isCollapse
                                );
                            }
                        }
                    }
                }
            }
        }
    }
    // for (let id in dla) {
    //     let parentId = "-1";
    //     let dla_i = dla[id];

    //     let chatId = "chatId";
    //     let chatMessage = dla_i.chatMessage;
    //     let rippleChatMessageId = dla_i.rippleChatMessageId;
    //     let chatName = "Chat";
    //     let username = dla_i.username;
    //     let DocName = dla_i.docName;
    //     let TransactionId = dla_i.transactionId;
    //     let WorkspaceName = dla_i.workspaceName;
    //     let WID = dla_i.workspaceId;
    //     let spaceName = dla_i.spaceName;
    //     let SpaceID = dla_i.spaceId;
    //     let Folder1 = dla_i.folder1 !== null ? dla_i.folder1 : "";
    //     let Folder2 = dla_i.folder2 !== null ? dla_i.folder2 : "";
    //     let Folder3 = dla_i.folder3 !== null ? dla_i.folder3 : "";
    //     let Folder4 = dla_i.folder4 !== null ? dla_i.folder4 : "";
    //     let Folder5 = dla_i.folder5 !== null ? dla_i.folder5 : "";
    //     let Folder6 = dla_i.folder6 !== null ? dla_i.folder6 : "";
    //     let Folder7 = dla_i.folder7 !== null ? dla_i.folder7 : "";
    //     let Folder8 = dla_i.folder8 !== null ? dla_i.folder8 : "";
    //     let Folder9 = dla_i.folder9 !== null ? dla_i.folder9 : "";
    //     let Folder10 = dla_i.folder10 !== null ? dla_i.folder10 : "";
    //     let F1id = dla_i.f1Id ? dla_i.f1Id : "";
    //     let F2id = dla_i.f2Id ? dla_i.f2Id : "";
    //     let F3id = dla_i.f3Id ? dla_i.f3Id : "";
    //     let F4id = dla_i.f4Id ? dla_i.f4Id : "";
    //     let F5id = dla_i.f5Id ? dla_i.f5Id : "";
    //     let F6id = dla_i.f6Id ? dla_i.f6Id : "";
    //     let F7id = dla_i.f7Id ? dla_i.f7Id : "";
    //     let F8id = dla_i.f8Id ? dla_i.f8Id : "";
    //     let F9id = dla_i.f9Id ? dla_i.f9Id : "";
    //     let F10id = dla_i.f10Id ? dla_i.f10Id : "";
    //     if (dla_i.documentId) {
    //         let workspaceNode = bwGraph.getNode(WID);
    //         if (!workspaceNode)
    //             bwGraph.createRoot(WID, "-1", WorkspaceName, "Workspace");
    //         let spaceNode = bwGraph.getNode(SpaceID);
    //         if (!spaceNode)
    //             bwGraph.addNode(SpaceID, "-1", [WID], spaceName, "Space");
    //         parentId = SpaceID;
    //         if (Folder1 !== "") {
    //             let Folder1Node = bwGraph.getNode(F1id);
    //             if (!Folder1Node)
    //                 bwGraph.addNode(F1id, "-1", [SpaceID], Folder1, "Folder1");
    //             parentId = F1id;
    //         }
    //         if (Folder2 !== "") {
    //             let Folder2Node = bwGraph.getNode(F2id);
    //             if (!Folder2Node)
    //                 bwGraph.addNode(F2id, "-1", [F1id], Folder2, "Folder2");
    //             parentId = F2id;
    //         }
    //         if (Folder3 !== "") {
    //             let Folder3Node = bwGraph.getNode(F3id);
    //             if (!Folder3Node)
    //                 bwGraph.addNode(F3id, "-1", [F2id], Folder3, "Folder3");
    //             parentId = F3id;
    //         }
    //         if (Folder4 !== "") {
    //             let Folder4Node = bwGraph.getNode(F4id);
    //             if (!Folder4Node)
    //                 bwGraph.addNode(F4id, "-1", [F3id], Folder4, "Folder4");
    //             parentId = F4id;
    //         }
    //         if (Folder5 !== "") {
    //             let Folder5Node = bwGraph.getNode(F5id);
    //             if (!Folder5Node)
    //                 bwGraph.addNode(F5id, "-1", [F4id], Folder5, "Folder5");
    //             parentId = F5id;
    //         }
    //         if (Folder6 !== "") {
    //             let Folder6Node = bwGraph.getNode(F6id);
    //             if (!Folder6Node)
    //                 bwGraph.addNode(F6id, "-1", [F5id], Folder6, "Folder6");
    //             parentId = F6id;
    //         }
    //         if (Folder7 !== "") {
    //             let Folder7Node = bwGraph.getNode(F7id);
    //             if (!Folder7Node)
    //                 bwGraph.addNode(F7id, "-1", [F6id], Folder7, "Folder7");
    //             parentId = F7id;
    //         }
    //         if (Folder8 !== "") {
    //             let Folder8Node = bwGraph.getNode(F8id);
    //             if (!Folder8Node)
    //                 bwGraph.addNode(F8id, "-1", [F7id], Folder8, "Folder8");
    //             parentId = F8id;
    //         }
    //         if (Folder9 !== "") {
    //             let Folder9Node = bwGraph.getNode(F9id);
    //             if (!Folder9Node)
    //                 bwGraph.addNode(F9id, "-1", [F8id], Folder9, "Folder9");
    //             parentId = F9id;
    //         }
    //         if (Folder10 !== "") {
    //             let Folder10Node = bwGraph.getNode(F10id);
    //             if (!Folder10Node)
    //                 bwGraph.addNode(F10id, "-1", [F9id], Folder10, "Folder10");
    //             parentId = F10id;
    //         }
    //         let documentNode = bwGraph.getNode(id);
    //         if (!documentNode)
    //             bwGraph.addNode(id, "-1", [parentId], DocName, "Doc");
    //     } else {
    //         let chatNode = bwGraph.getNode(chatId);
    //         if (!chatNode) bwGraph.createRoot(chatId, "-1", chatName, "Chats");
    //         let userNode = bwGraph.getNode(username);
    //         if (!userNode)
    //             bwGraph.addNode(username, "-1", [chatId], username, "Username");
    //         parentId = rippleChatMessageId;
    //         let messageNode = bwGraph.getNode(id);
    //         if (!messageNode)
    //             bwGraph.addNode(id, "-1", [username], chatMessage, "Message");
    //     }
    // }

    return bwGraph;
}
const getExtension = (type) => {
    let src = null;
    const fileType = type.split(".").pop().toLowerCase().trim();

    switch (fileType) {
        case "txt":
            src = "./images/fileTypeIcon/text.svg";
            break;
        case "pdf":
            src = "./images/fileTypeIcon/pdf.svg";
            break;
        case "docx":
        case "doc":
            src = "./images/word-icon.svg";
            break;
        case "jpg":
            src = "./images/fileTypeIcon/jpg-icon.svg";
            break;
        case "svg":
            src = "./images/fileTypeIcon/svg-icon.svg";
            break;
        case "mp3":
            src = "./images/fileTypeIcon/mp3-icon.svg";
            break;
        case "gif":
            src = "./images/fileTypeIcon/gif-icon.svg";
            break;
        case "mp4":
            src = "./images/fileTypeIcon/mp4-icon.svg";
            break;
        case "xlsx":
        case "xls":
        case "xlsm":
            src = "./images/excel-icon.svg";
            break;
        case "pptx":
        case "ppt":
            src = "./images/ppt-icon.svg";
            break;
        case "xml":
            src = "./images/fileTypeIcon/xml-file.svg";
            break;
        case "html":
            src = "./images/fileTypeIcon/htmlIcon.svg";
            break;

        default:
            src = "./images/basicfile.png";
            break;
    }
    return src;
};
const getImg = (type, label) => {
    let src = null;
    switch (type) {
        case "Workspace":
            src = "./images/Group 410.svg";
            break;
        case "Space":
            src = "./images/Group 441.svg";
            break;
        case "Folder1":
            src = "./images/Group 438.svg";
            break;
        case "Folder2":
            src = "./images/Group 438.svg";
            break;
        case "Folder3":
            src = "./images/Group 438.svg";
            break;
        case "Folder4":
            src = "./images/Group 438.svg";
            break;
        case "Folder5":
            src = "./images/Group 438.svg";
            break;
        case "Folder6":
            src = "./images/Group 438.svg";
            break;
        case "Folder7":
            src = "./images/Group 438.svg";
            break;
        case "Folder8":
            src = "./images/Group 438.svg";
            break;
        case "Folder9":
            src = "./images/Group 438.svg";
            break;
        case "Folder10":
            src = "./images/Group 438.svg";
            break;
        case "Chats":
            src = "./images/UC-Icons-Color/communicator-icon.svg";
            break;
        case "Message":
            src = "./images/fileTypeIcon/chat-left 2.svg";
            break;
        case "Username":
            src = "./images/fileTypeIcon/Vector.svg";
            break;

        default:
            src = getExtension(label);
    }
    return src;
};

const getNodeBorderColor = (type) => {
    let color = null;
    switch (type) {
        case "Workspace":
            color = "2px solid #02B734";
            break;
        case "Chats":
            color = "2px solid #02B734";
            break;
        case "Space":
            color = "2px solid #37B4FF";
            break;
        case "Username":
            color = "2px solid #37B4FF";
            break;
        case "Folder1":
            color = "2px solid #EACC2E";
            break;
        case "Folder2":
            color = "2px solid #EACC2E";
            break;
        case "Folder3":
            color = "2px solid #EACC2E";
            break;
        case "Folder4":
            color = "2px solid #EACC2E";
            break;
        case "Folder5":
            color = "2px solid #EACC2E";
            break;
        case "Folder6":
            color = "2px solid #EACC2E";
            break;
        case "Folder7":
            color = "2px solid #EACC2E";
            break;
        case "Folder8":
            color = "2px solid #EACC2E";
            break;
        case "Folder9":
            color = "2px solid #EACC2E";
            break;
        case "Folder10":
            color = "2px solid #EACC2E";
            break;
        default:
            color = "2px solid #000000";
    }
    return color;
};
const getNodeColor = (type) => {
    let color = null;
    switch (type) {
        case "Workspace":
            color = config[1]["WorkspaceColor"];
            break;
        case "Chats":
            color = config[1]["WorkspaceColor"];
            break;
        case "Space":
            color = config[2]["SpaceColor"];
            break;
        case "Username":
            color = config[2]["SpaceColor"];
            break;
        case "Folder1":
            color = config[3]["Folder1Color"];
            break;
        case "Folder2":
            color = config[4]["Folder2Color"];
            break;
        case "Folder3":
            color = config[5]["Folder3Color"];
            break;
        case "Folder4":
            color = config[6]["Folder4Color"];
            break;
        case "Folder5":
            color = config[7]["Folder5Color"];
            break;
        case "Folder6":
            color = config[8]["Folder6Color"];
            break;
        case "Folder7":
            color = config[9]["Folder7Color"];
            break;
        case "Folder8":
            color = config[10]["Folder8Color"];
            break;
        case "Folder9":
            color = config[11]["Folder9Color"];
            break;
        case "Folder10":
            color = config[12]["Folder10Color"];
            break;
        default:
            color = config[0]["DocumentColor"];
    }
    return color;
};
function convertBwGraphToReactFlow(bwGraph, updateElements) {
    const position = { x: 0, y: 0 };
    const edgeType = "smoothstep";
    let nodes = [];
    let edges = [];
    let elements = [];
    let i = 0;
    let bwGraphNodes = bwGraph.getNodes();
    let bwGraphEdges = bwGraph.getEdges();

    bwGraphNodes.forEach((element, index) => {
        let node = {};
        let data = {};
        let style = {
            overflow: "hidden",
            textOverflow: "ellipsis",
        };
        let type = element.type;
        let label = element.label;
        node["id"] = element.id;
        data["docId"] = element.id;
        data["spaceId"] = element.spaceId;
        data["workspaceId"] = element.workspaceId;
        node["type"] = "selectorNode";
        data["nodeType"] = type[0] === "F" ? "Folder" : type;
        type[0] === "F"
            ? (data["expandCollapse"] = (isCollapsed) => {
                  updateElements({
                      workspaceId: element.workspaceId,
                      spaceId: element.spaceId,
                      folderId: element.id,
                      isCollapsed: isCollapsed,
                  });
              })
            : (data["expandCollapse"] = null);
        data["label"] = element.label;
        data["img"] = getImg(type, label);
        data["isCollapsed"] = element.isFolderCollapsed;
        node["data"] = data;
        style["white-space"] = "pre";
        style["background"] = getNodeColor(type);
        style["border-radius"] = "10px";
        style["border"] = getNodeBorderColor(type);
        node["style"] = style;
        node.position = position;
        nodes.push(node);
        i++;
    });
    bwGraphEdges.forEach((element) => {
        let edge = {};
        // fill: "#1B75BC",
        // stroke: "#1B75BC",
        let style = {
            strokeWidth: "1.5",
            color: "#1B75BC",
        };
        edge["id"] = element.source + element.target;
        edge["markerEndId"] = "edge_" + element.source + element.target;
        edge["source"] = element.source;
        edge["target"] = element.target;
        edge["type"] = "step";
        edge["style"] = style;
        edge["arrowHeadType"] = "arrowclosed";
        edge["step"] = "StepEdge";
        edges.push(edge);
    });
    elements = nodes.concat(edges);
    return elements;
}

function RippleReactFlow(rippleData, updateElements) {
    if (rippleData) {
        return convertBwGraphToReactFlow(
            convertJsonToBwGraph(rippleData),
            updateElements
        );
    } else
        return convertBwGraphToReactFlow(
            convertJsonToBwGraph(),
            updateElements
        );
}

export default RippleReactFlow;
