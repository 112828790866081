import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import "./EmailAlertModal.css";
import CustomSearchDropdown from "../../../../../stylized/CustomDropDown/index";
import { makeStyles } from "@material-ui/core/styles";
import { Switch } from "@mui/material";
import { validateEmail } from "../../../../../../bae-client/Validations";
import RecureModalView from "../../RippleLog/RecureModalView";
import { convertUTCTimeToLocalTime } from "../../../../../../bae-client/Utils";

const useStyles = makeStyles({
    root: {
        // display: "flex",
        // width: "100%",
        // // height: '36px', // Using 'fit-content' for Hug effect
        // padding: "7px 16px 7px 16px", // Top Right Bottom Left
        // borderRadius: "4.32px !important",
        // // border: "1.08px solid #D7DEEA !important", // You might want to specify a color as well
        // justifyContent: "space-between",
        // // marginTop:"16px !important",
        // marginLeft: "0px !important",
        // maxWidth: "9rem",
        borderRadius: "6px !important",
        display: "inline-flex",
        flexDirection: "column",
        position: "relative",
        minWidth: "0px",
        padding: "0px",
        margin: "8px",
        border: "0px",
        verticalAlign: "top",
        width: "462px !important",
    },

    from: {
        // width: "50%",
        // // height: '36px', // Using 'fit-content' for Hug effect
        // padding: "7px 16px 7px 16px", // Top Right Bottom Left
        // borderRadius: "4.32px",
        // // border: "1.08px solid #D7DEEA !important",
        // justifyContent: "space-between",
        // marginLeft: "0px !important",
        // maxWidth: "9rem",
        borderRadius: "6px !important",
        display: "inline-flex",
        flexDirection: "column",
        position: "relative",
        minWidth: "0px",
        padding: "0px",
        margin: "8px",
        border: "0px",
        verticalAlign: "top",
        width: "100%",
    },
    to: {
        // width: "50%",
        // // height: '36px', // Using 'fit-content' for Hug effect
        // padding: "7px 16px 7px 16px", // Top Right Bottom Left
        // borderRadius: "4.32px",
        // // border: "1.08px solid #D7DEEA !important",
        // justifyContent: "space-between",
        // maxWidth: "9rem",
        borderRadius: "6px !important",
        display: "inline-flex",
        flexDirection: "column",
        position: "relative",
        minWidth: "0px",
        padding: "0px",
        margin: "8px",
        border: "0px",
        verticalAlign: "top",
        width: "100%",
    },
});
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    border: "1px solid #D7DEEA",
    boxShadow: 24,
    padding: "24px",
    borderRadius: "4px",
    // maxHeight: "500px",
};
const textStyles = {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "-0.006em",
    textAlign: "left",
    color: "#000000",
    fontStyle: "normal",
};
export default function DashboardModal({
    emailModal,
    genericList,
    setEmailModal,
    instanceMapList,
    toContactsMapData,
    fromContactsMapData,
    setSelectedRowData,
    alertTypeList,
    isRowEdit,
    editRowData,
    stepNameMap,
    setIsRowEdit,
    handleSubmit,
}) {
    const classes = useStyles();
    const primiseTypeList = [
        { text: "Initiation" },
        { text: "Commitment" },
        { text: "Fulfillment" },
        { text: "Settlement" },
        { text: "Completion" },
    ];
    const ExceptionTypeList = [
        { text: "DELAYED", id: 0 },
        { text: "SKIPPED", id: 1 },
        { text: "KEY MISSING", id: 2 },
        { text: "KEY MISMATCH", id: 3 },
        { text: "PROACTIVE ALERT", id: 4 },
        { text: "DATE CHANGED", id: 5 },
        { text: "OTHERS", id: 6 },
    ];
    const timeRangeList = [
        { text: "Last 1 day" },
        { text: "Last 7 days" },
        { text: "Last 15 days" },
        { text: "Last 30 Days" },
        { text: "Last 90 days" },
    ];
    const [text, setText] = useState("");
    const [recurTextTime, setRecurTextTime] = useState("");
    const [displayRecurText, setDisplayRecurText] = useState("");
    const [showRecuremodal, setShowRecureModal] = useState(false);
    const [selectedGeneric, setSelectedGeneric] = useState([]);
    const [instancesList, setInstancesList] = useState([]);
    const [fromList, setFromList] = useState([]);
    const [toList, setToList] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState([]);
    const [selectedSteps, setSelectedSteps] = useState([]);
    const [selectedFromContacts, setSelectedFromContacts] = useState([]);
    const [selectedToContacts, setSelectedToContacts] = useState([]);

    const [stepNameList, setStepNameList] = useState([]);
    const [selectedPromise, setSelectedPromise] = useState([]);
    const [selectedException, setSelectedException] = useState([]);
    const [selectedTimeRange, setSelectedTimeRange] = useState(null);
    const [genericError, setGenericError] = useState("");
    const [recurError, setRecurError] = useState("");
    const updateAllListValues = (genericValueList) => {
        let instances = [];
        let stepNames = [];
        let from = [];
        let to = [];
        genericValueList &&
            genericValueList.map((item) => {
                instances.push(...instanceMapList.get(item.text));
                stepNames.push(...stepNameMap.get(item.text));
                from.push(...fromContactsMapData.get(item.text));
                to.push(...toContactsMapData.get(item.text));
            });
        setStepNameList(
            [...new Set(stepNames.map((item) => item.text))].map(
                (text, index) => ({
                    id: index,
                    text,
                })
            )
        );
        setInstancesList(instances);
        setFromList(from);
        setToList(to);
    };
    useEffect(() => {
        if (emailModal && editRowData) {
            setText(editRowData.searchText);
            let rippleLogTitle =
                editRowData.reportRecur.split("|")[0] +
                "|" +
                convertUTCTimeToLocalTime(
                    editRowData.reportRecur.split("|")[1]
                );
            setDisplayRecurText(rippleLogTitle);
            setRecurTextTime(editRowData.reportRecur);
            setSelectedGeneric(
                editRowData.genericNames.map((item) => {
                    return { text: item };
                })
            );
            setSelectedInstance({ text: editRowData.instanceNames[0] });
            setSelectedSteps(
                editRowData.stepNames.map((item) => {
                    return { text: item };
                })
            );
            setSelectedFromContacts({ text: editRowData.fromContacts[0] });
            setSelectedToContacts({ text: editRowData.toContacts[0] });

            setSelectedPromise({ text: editRowData.promiseTypes[0] });
            setSelectedException(
                editRowData.exceptionTypes.map((item) => {
                    return { text: item };
                })
            );
            setSelectedTimeRange(
                editRowData.timeRange === ""
                    ? "Time Range"
                    : { text: editRowData.timeRange }
            );
            updateAllListValues(
                editRowData.genericNames.map((item) => {
                    return { text: item };
                })
            );
        } else {
            setRecurTextTime("");
            setSelectedGeneric([]);
            setSelectedInstance([]);
            setSelectedSteps([]);
            setSelectedFromContacts([]);
            setSelectedToContacts([]);
            setSelectedPromise([]);
            setSelectedException([]);
            setText("");
            setSelectedTimeRange(null);
            setStepNameList([]);
            setInstancesList([]);
            setFromList([]);
            setToList([]);
            setDisplayRecurText("");
        }
    }, [emailModal]);

    const handleGeneric = (index, values) => {
        setSelectedGeneric(values);
        setSelectedSteps([]);
        setSelectedInstance([]);
        setSelectedFromContacts([]);
        setSelectedToContacts([]);
        updateAllListValues(values);
        setGenericError("");
    };

    const handleStepNames = (index, values) => {
        setSelectedSteps(values);
    };
    const closeModal = () => {
        setIsRowEdit(false);
        setEmailModal(false);
        setSelectedRowData(null);
        setRecurTextTime("");
    };
    const handleInstance = (index, values) => {
        setSelectedInstance(values);
        setSelectedSteps([]);
        setSelectedFromContacts([]);
        setSelectedToContacts([]);
    };
    const handleFromData = (index, values) => {
        setSelectedFromContacts(values);
    };
    const handleToData = (index, values) => {
        setSelectedToContacts(values);
    };
    const hnadlePromiseSelection = (index, values) => {
        setSelectedPromise(values);
    };
    const handleException = (index, values) => {
        setSelectedException(values);
    };
    const handleChangeText = (event) => {
        const inputText = event.target.value.trim();
        if (inputText.length === 0) {
            setText("");
        } else {
            setText(event.target.value);
        }
    };
    const handleTimeRange = (index, values) => {
        setSelectedTimeRange(values);
    };
    const changeRecurCell = (selectedTime, dateObj, isClick) => {
        setRecurError("");
        let recureStr = "";
        let recureStrNew = "";
        let daysArray = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
        daysArray.forEach((day) => {
            if (dateObj[day]) {
                recureStrNew += day.toLocaleUpperCase() + ",";
            }
        });
        if (!recureStrNew) recureStrNew = "MON,TUE,WED,THU,FRI,SAT,SUN";
        else recureStrNew = recureStrNew.slice(0, -1);
        setDisplayRecurText(
            recureStrNew + "|" + convertUTCTimeToLocalTime(selectedTime)
        );
        recureStrNew += "|" + selectedTime;
        recureStr = selectedTime;
        setRecurTextTime(recureStrNew);
        setExistingTime(selectedTime);
    };

    const setExistingTime = (dateString) => {
        let existingTime = convertUTCTimeToLocalTime(dateString)
            .split(" ")[0]
            .split(":");
        let existingAA = convertUTCTimeToLocalTime(dateString).split(" ")[1];
        let existingHH = 0;
        if (existingAA === "PM") {
            existingHH = 12 + parseInt(existingTime[0]);
        } else {
            existingHH = existingTime[0];
        }
        let existingMM = existingTime[1];
        let existingSS = existingTime[2];

        let curruntDate = new Date();
        curruntDate.setHours(existingHH);
        curruntDate.setMinutes(existingMM);
        curruntDate.setSeconds(existingSS);
    };
    const submitHandler = () => {
        let errors = false;
        if (selectedGeneric.length === 0) {
            setGenericError("Please select at least one generic name");
            errors = true;
        } else {
            setGenericError("");
        }
        if (!recurTextTime) {
            setRecurError("Please select recurrence");
            errors = true;
        } else {
            setRecurError("");
        }
        if (errors) {
            return;
        }
        closeModal();
        let payload = {
            genericNames: selectedGeneric.map((item) => item.text),
            instanceNames: selectedInstance.text ? [selectedInstance.text] : [],
            stepNames: selectedSteps.map((step) => step.text),
            fromContacts: selectedFromContacts.text
                ? [selectedFromContacts.text]
                : [],
            toContacts: selectedToContacts.text
                ? [selectedToContacts.text]
                : [],
            promiseTypes: selectedPromise.text ? [selectedPromise.text] : [],
            exceptionTypes: selectedException.map((item) => item.text),
            timeRange: selectedTimeRange !== null ? selectedTimeRange.text : "",
            searchText: text,
            reportRecur: recurTextTime,
        };
        if (isRowEdit) {
            payload["dashboardReportRuleId"] = parseInt(
                editRowData.dashboardReportRuleId
            );
        }
        handleSubmit(payload, isRowEdit);
    };
    return (
        <div>
            <Modal
                style={{ background: "rgba(0, 0, 0, 0.5)", zIndex: "100" }}
                open={emailModal}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={style}>
                    <div
                        className="row doCenter"
                        style={{ flexDirection: "column" }}
                    >
                        <div className="email-header">
                            <div className="head-text">
                                {isRowEdit
                                    ? "Edit Dashboard Report Email"
                                    : "Create Dashboard Report Email"}
                            </div>
                            <img
                                src="./images/close-btn.svg"
                                alt=""
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setEmailModal(false);
                                }}
                            />
                        </div>
                        <div
                            className="invisibleScroller"
                            style={{ maxHeight: "400px" }}
                        >
                            <div className="email-inputs">
                                <div>
                                    <CustomSearchDropdown
                                        items={genericList}
                                        label={"Generic Name"}
                                        id={"btn_select_generic"}
                                        idInfo={"generic"}
                                        multiSelect={true}
                                        isSearchable={true}
                                        isChipView={false}
                                        handleSelection={handleGeneric}
                                        selection={selectedGeneric}
                                        customClassName={classes.root}
                                        textStyles={textStyles}
                                    />
                                    {genericError && (
                                        <small
                                            className="error-message"
                                            style={{ color: "red" }}
                                        >
                                            {genericError}
                                        </small>
                                    )}
                                </div>
                                <div>
                                    <CustomSearchDropdown
                                        items={instancesList}
                                        label={"Instance Name"}
                                        id={"btn_select_Instance"}
                                        idInfo={"instance"}
                                        multiSelect={false}
                                        isSearchable={true}
                                        isChipView={false}
                                        handleSelection={handleInstance}
                                        selection={selectedInstance}
                                        customClassName={classes.root}
                                        textStyles={textStyles}
                                    />
                                </div>
                                <div>
                                    <CustomSearchDropdown
                                        items={stepNameList}
                                        label={"Step Name"}
                                        id={"btn_select_step_name"}
                                        idInfo={"transaction"}
                                        multiSelect={true}
                                        isSearchable={true}
                                        isChipView={false}
                                        handleSelection={handleStepNames}
                                        selection={selectedSteps}
                                        customClassName={classes.root}
                                        textStyles={textStyles}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <CustomSearchDropdown
                                        items={fromList}
                                        label={"From"}
                                        id={"btn_select_from"}
                                        idInfo={"from"}
                                        multiSelect={false}
                                        isSearchable={true}
                                        isChipView={false}
                                        handleSelection={handleFromData}
                                        selection={selectedFromContacts}
                                        customClassName={classes.from}
                                        textStyles={textStyles}
                                    />
                                    <CustomSearchDropdown
                                        items={toList}
                                        label={"To"}
                                        id={"btn_select_to"}
                                        idInfo={"to"}
                                        multiSelect={false}
                                        isSearchable={true}
                                        isChipView={false}
                                        handleSelection={handleToData}
                                        selection={selectedToContacts}
                                        customClassName={classes.to}
                                        textStyles={textStyles}
                                    />
                                </div>
                                <div>
                                    <CustomSearchDropdown
                                        items={primiseTypeList}
                                        label={"Promise Type"}
                                        id={"btn_select_promise"}
                                        idInfo={"promise"}
                                        multiSelect={false}
                                        isSearchable={true}
                                        isChipView={false}
                                        handleSelection={hnadlePromiseSelection}
                                        selection={selectedPromise}
                                        customClassName={classes.root}
                                        textStyles={textStyles}
                                    />
                                </div>
                                <div>
                                    <CustomSearchDropdown
                                        items={ExceptionTypeList}
                                        label={"Exception Type"}
                                        id={"btn_select_exception"}
                                        idInfo={"exception"}
                                        multiSelect={true}
                                        isSearchable={true}
                                        isChipView={false}
                                        handleSelection={handleException}
                                        selection={selectedException}
                                        customClassName={classes.root}
                                        textStyles={textStyles}
                                    />
                                </div>
                                <div>
                                    <CustomSearchDropdown
                                        items={timeRangeList}
                                        label={"Time Range"}
                                        id={"btn_select_timeRange"}
                                        idInfo={"TimeRange"}
                                        multiSelect={false}
                                        isSearchable={true}
                                        isChipView={false}
                                        handleSelection={handleTimeRange}
                                        selection={selectedTimeRange}
                                        customClassName={classes.root}
                                        textStyles={textStyles}
                                    />
                                </div>
                                <div style={{ marginBottom: "8px" }}>
                                    <div className="input-label">
                                        Search Text
                                    </div>
                                    <div
                                        style={{
                                            width: "477px",
                                            height: "36px",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Enter Text"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                padding: "6px 12px 6px 12px",
                                                borderRadius: "4px",
                                                border: "1px solid #9DA5B4",
                                                padding: "4px",
                                                boxShadow:
                                                    "1px 2px 2px 0px #0000001F inset",
                                            }}
                                            value={text}
                                            onChange={handleChangeText}
                                        />
                                    </div>
                                </div>
                                <button
                                    style={{
                                        marginTop: "12px",
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        width: "100%",
                                        lineHeight: "22px",
                                        letterSpacing: "-0.006em",
                                        textAlign: "left",
                                        color: "black",
                                        background: "white",
                                        border: "1px solid #D7DEEA",
                                        justifyContent: "start",
                                        textTransform: "none",
                                        padding: "7px 16px 7px 16px",
                                        borderRadius: "4px",
                                    }}
                                    onClick={() => {
                                        setShowRecureModal(true);
                                    }}
                                >
                                    {displayRecurText === ""
                                        ? "Report Recurrence"
                                        : displayRecurText}
                                </button>
                                {recurError && (
                                    <small
                                        className="error-message"
                                        style={{ color: "red" }}
                                    >
                                        {recurError}
                                    </small>
                                )}

                                {/* <div style={{ marginBottom: "8px" }}>
                                <div className="input-label">
                                    Report Recurrance
                                </div>
                                <div
                                    style={{
                                        width: "477px",
                                        height: "36px",
                                        borderRadius: "4px",
                                    }}
                                >
                                    
                                </div>
                            </div> */}
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "477px",
                                height: "52px",
                                padding: "16px 0px 0px 0px",
                                gap: "8px",
                            }}
                        >
                            <button
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "none",
                                    width: "83px",
                                    // height: '36px',
                                    padding: "6px 16px 6px 16px",
                                    borderRadius: "4px",
                                    border: "1px solid #D7DEEA", // You might want to specify the border style, e.g., solid
                                    gap: "8px",
                                    background: "#FFFFFF",
                                    color: "#000000",
                                    textTransform: "capitalize",
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: "500 !important",
                                    lineHeight: "24px",
                                    letterSpacing: "0.4000000059604645px",
                                    textAlign: "left",
                                }}
                                onClick={closeModal}
                                title="Cancel"
                            >
                                cancel
                            </button>
                            <button
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "none",
                                    width: "83px",
                                    // height: '36px',
                                    padding: "6px 16px 6px 16px",
                                    borderRadius: "4px",
                                    gap: "8px",
                                    background: "#0B5CD7",
                                    textTransform: "capitalize",
                                    color: "#FFFFFF",
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: "500 !important",
                                    lineHeight: "24px",
                                    letterSpacing: "0.4000000059604645px",
                                    textAlign: "left",
                                }}
                                onClick={submitHandler}
                                title="Okay"
                            >
                                Okay
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <RecureModalView
                data={editRowData && editRowData.reportRecur}
                show={showRecuremodal}
                onHide={() => setShowRecureModal(false)}
                handleDisableRipple={() => {}}
                onclick={() => setShowRecureModal(false)}
                onSubmit={(
                    selectedEveryCount,
                    selectedEveryType,
                    selectedTime,
                    dateObj,
                    isClick
                ) => {
                    changeRecurCell(selectedTime, dateObj, isClick);
                    setShowRecureModal(false);
                }}
                isDashboardConfig={true}
            ></RecureModalView>
        </div>
    );
}
